import React from "react";
import "../../css/component.css";

const ImageTextContent = ({ imageSrc, heading }) => {
  return (
    <div className="zu_common_banner_content">
      <img src={imageSrc} alt="banner img" />
      <h1>{heading}</h1>
    </div>
  );
};

export default ImageTextContent;
