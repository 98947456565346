import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
// import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";

toast.configure();

//registerUser
export const registerUser = createAsyncThunk(
  "registerUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post("/users/register", data.data);
      if (response.status === 201) {
        notify(
          "User registered successfully! Please check your email!",
          "success"
        );
        data.navigate(routes.signupcheckyouremail);
        return response.data;
      }
    } catch (error) {
      notify(error.response.data.message);
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

export const getUsersApprovalList = createAsyncThunk(
  "getUsersApprovalList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get-user-list?start=${data.start || 0}&limit=${
          data.limit || 10
        }&waiting_for_approval=${data.waitingForApproval || 0}&delete_status=${
          data.delete_status || ""
        }&search_key=${data.search_key || ""}`,
        authHeaders()
      );
      if (data.waitingForApproval === 0) {
        return {
          data: response.data,
          totalCount: response.headers["x-total-count"],
          allUsers: true,
        };
      } else if (data.waitingForApproval === 1) {
        return {
          data: response.data,
          allUsers: false,
          totalCount: "",
        };
      }
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getFinancialReportList = createAsyncThunk(
  "getFinancialReportList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/job/get-job-list-for-financial-report`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getLeadReportList = createAsyncThunk(
  "getLeadReportList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/leads/lead-list-for-report`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const adminUpdateUser = createAsyncThunk(
  "adminUpdateUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/users/admin-update-user",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        await thunkAPI.dispatch(
          getUsersApprovalList({ start: 0, limit: 2, waitingForApproval: 1 })
        );
        if (data.cb) {
          return data.cb(null, response.data);
        }
        toast.success("Successfully update user status!", {
          autoClose: 3000,
        });
      }
      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

//LoginUser
export const loginUser = createAsyncThunk(
  "LoginUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post("/users/login", data.data);
      if (response?.status === 200) {
        notify("Welcome Back", "success");
        return response.data;
      }
    } catch (error) {
      notify(error.response.data.message);
      return isRejectedWithValue(error.response.data.message);
    }
  }
);
//AdminLoginAsUser
export const loginAsUser = createAsyncThunk(
  "loginAsUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/users/admin-login-as-user",
        data.data,
        authHeaders()
      );
      if (response?.status === 200) {
        notify("Welcome Back", "success");
        return response.data;
      }
    } catch (error) {
      notify(error.response.data.message);
      return isRejectedWithValue(error.response.data.message);
    }
  }
);
// confirmUser
export const confirmUser = createAsyncThunk(
  "confirmUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post("/users/confirm-user", data.data);
      if (response.status === 200) {
        toast.success("User Verified!", {
          autoClose: 2000,
        });
        data.navigate(`${routes.profileedit}`);
      }
      return response.data;
    } catch (error) {
      console.log("err", error.response);
    }
  }
);

//forgotPassword
export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post("/users/forgot-password", data.data);
      if (response.status === 200) {
        toast.success("Please check your emails for next steps", {
          autoClose: 3000,
        });
        data.navigate(routes.forgotpasswordcheckyouremail);
      }
      return response.data;
    } catch (error) {
      // return data.cb(null, error);
      notify(error.response.data.message);
    }
  }
);

//resetPassword
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post("/users/reset-password", data.data);
      if (response.status === 200) {
        toast.success("Your password has been reset", {
          autoClose: 3000,
        });
        data.navigate(routes.login);
      }
      return response.data;
    } catch (error) {
      // return data.cb(null, error);
      notify(error.response.data.message);
      data.navigate(routes.forgotpassword);
    }
  }
);

// getProfile
export const getProfile = createAsyncThunk(
  "getProfile",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get-profile?user_id=${data.id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("error", error.response);
      return error;
    }
  }
);

export const clearUserProfile = createAsyncThunk(
  "clearUserProfile",
  async () => {
    return;
  }
);

export const clearUserListForAdmin = createAsyncThunk(
  "clearUserListForAdmin",
  async () => {
    return;
  }
);

export const clearProfile = createAsyncThunk("clearProfile", async () => {
  return;
});

// getApplicantProfile
export const getApplicantProfile = createAsyncThunk(
  "getApplicantProfile",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get-profile?user_id=${data.id}`,
        authHeaders()
      );
      if (response.status === 200) {
        if (data.cb) {
          return data.cb(null, response.data);
        }
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
    }
  }
);

// editProfile
export const editProfile = createAsyncThunk(
  "editProfile",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/users/edit-profile",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Your profile has been updated", {
          autoClose: 3000,
        });

        if (data.cb) {
          return {
            data: data.cb(null, response.data),
            proloader: data.preLoader,
          };
        }
      }
      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// Profile submit for approval
export const profileForApproval = createAsyncThunk(
  "profileForApproval",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        "/users/profile-submit-for-approve",
        authHeaders()
      );
      if (response.status === 200) {
        // thunkAPI.dispatch(getProfile({ id: data.id }));
        toast.success("Profile submitted. You will be emailed when approved", {
          autoClose: 3000,
        });
        data.navigate(routes.profile);
        return response.data;
      }
    } catch (error) {
      console.log("error", error);
      // return data.cb(null, error);
    }
  }
);

// Checkuser Profile compeleted or not
export const checkUserProfile = createAsyncThunk(
  "checkUserProfile",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        "/job/check-profile-status",
        authHeaders()
      );
      if (response.status === 200) {
        return data.cb(response.data);
      }
      // return response.data;
    } catch (error) {
      // return data.cb(null, error);
    }
  }
);

// CLEAR USERJOBWAIT
export const clearUserJobWait = createAsyncThunk(
  "clearUserJobWait",
  async () => {
    return;
  }
);

export const checkUserJobWait = createAsyncThunk(
  "checkUserJobWait",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        "/job/check-user-has-offer",
        authHeaders()
      );
      // if (response.status === 200) {
      //   toast.success("Create User Successfully !", {
      //     autoClose: 3000,
      //   });
      //   return data.cb(response.data);
      // }
      return response.data;
    } catch (error) {
      // return data.cb(null, error);
    }
  }
);

// CLEAR JOB PENDING REVIEW
export const clearJobPendingReview = createAsyncThunk(
  "clearJobPendingReview",
  async () => {
    return;
  }
);

export const checkJobPendingReview = createAsyncThunk(
  "checkJobPendingReview",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        "/job/check-user-has-job-for-review",
        authHeaders()
      );
      // if (response.status === 200) {
      //   toast.success("Create User Successfully !", {
      //     autoClose: 3000,
      //   });
      //   return data.cb(response.data);
      // }
      return response.data;
    } catch (error) {
      // return data.cb(null, error);
    }
  }
);

export const checkPendingReviewForBuesiness = createAsyncThunk(
  "checkPendingReviewForBuesiness",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        "/job/check-user-has-job-for-review-for-business-user",
        authHeaders()
      );
      // if (response.status === 200) {
      //   toast.success("Create User Successfully !", {
      //     autoClose: 3000,
      //   });
      //   return data.cb(response.data);
      // }
      return response.data;
    } catch (error) {
      // return data.cb(null, error);
    }
  }
);

export const userCardList = createAsyncThunk(
  "userCardList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get("/users/get-card-list", authHeaders());
      if (response.status === 200) {
        if (data.cb) {
          return data.cb(null, response.data);
        }
        return response.data;
      }
    } catch (error) {
      console.log("errormessage", error.response.data.message);
    }
  }
);

export const createStripeCustomer = createAsyncThunk(
  "createStripeCustomer",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/users/create-stripe-customer",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Credit Card added successfully!", {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
        return response.data;
      }
    } catch (error) {
      console.log("errormessage", error.response.data.message);
      notify(error.response.data.message);
      return data.cb(error);
    }
  }
);

export const deleteCreditCard = createAsyncThunk(
  "deleteCreditCard",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.delete(
        `/users/delete-card-entry/${data.id}`,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Credit card deleted successfully", {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
        return response.data;
      }
    } catch (error) {
      console.log("errormessage", error.response.data.message);
      notify(error.response.data.message);
    }
  }
);

export const crewPayoutDetails = createAsyncThunk(
  "crewPayoutDetails",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/users/crew-payout-details",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 3000,
        });
        // if (data.cb) {
        //   return data.cb(null, response.data);
        // }
        return response.data;
      }
    } catch (error) {
      console.log("errormessage", error.response.data.message);
      notify(error.response.data.message);
    }
  }
);
export const notificationsSettings = createAsyncThunk(
  "notificationsSettings",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/users/update-notification-settings",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
        return response.data;
      }
    } catch (error) {
      console.log("errormessage", error.response.data.message);
      notify(error.response.data.message);
    }
  }
);

export const getNotificationsSettings = createAsyncThunk(
  "getNotificationsSettings",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        "/users/get-notification-setting",
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("errormessage", error.response.data.message);
      notify(error.response.data.message);
    }
  }
);

export const getNotificationsDetails = createAsyncThunk(
  "getNotificationsDetails",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get-notification?start=${data.start || 0}&limit=${
          data.limit || 10
        }`,
        authHeaders()
      );
      if (response.status === 200) {
        return {
          data: response.data,
          totalCount: response.headers["x-total-count"],
        };
      }
    } catch (error) {
      console.log("errormessage", error.response.data.message);
      notify(error.response.data.message);
    }
  }
);

export const getPayoutDetails = createAsyncThunk(
  "getPayoutDetails",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        "/users/get-crew-payout-details",
        authHeaders()
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log("errormessage", error.response.data.message);
      notify(error.response.data.message);
    }
  }
);
// get REVIEW LIST
export const getReviewList = createAsyncThunk(
  "getReviewList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/job/get-review?start=${data.start || 0}&limit=${
          data.limit || 10
        }&user_id=${data.user_id || ""}`,
        authHeaders()
      );

      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      // return data.cb(null, error);
      return error;
    }
  }
);

export const getInvoiceList = createAsyncThunk(
  "getInvoiceList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/job/get-job-list-for-invoice?start=${data.start || 0}&limit=${
          data.limit || 10
        }`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      return error;
    }
  }
);

// GET COUNT FOR DASHBOARD
// http://localhost:5001/users/get-user-count-job-amount-dashboard

export const getCountForDashboard = createAsyncThunk(
  "getCountForDashboard",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        "/users/get-user-count-job-amount-dashboard",
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("errormessage", error.response.data.message);
      notify(error.response.data.message);
    }
  }
);

//createSlice
const initialUser =
  localStorage.getItem("token") && localStorage.getItem("token") !== "undefined"
    ? JSON.parse(localStorage.getItem("token"))
    : null;

const initialState = {
  registerUser: null,
  loginAsUser: null,
  loader: false,
  extraLoader: false,
  proloader: false,
  loginAsLoader: false,
  error: "",
  totalCount: "",
  user: initialUser,
  // confirmUser: null,
  getProfile: null,
  getApplicantProfile: null,
  editProfile: null,
  checkUserJobWait: null,
  checkJobPendingReview: null,
  createStripeCustomer: null,
  userCardList: [],
  crewPayoutDetails: null,
  getPayoutDetails: null,
  getReviewList: null,
  getNotificationsDetails: null,
  getNotificationsSettings: null,
  invoices: [],
  getFinancialReportList: [],
  getLeadReportList: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.getProfile = null;
      state.getApplicantProfile = null;
      state.checkJobPendingReview = null;
      state.checkUserJobWait = null;
      localStorage.removeItem("token");
    },
  },

  extraReducers: (builder) => {
    //registerUser
    builder.addCase(registerUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.loader = false;
      state.registerUser = action.payload;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //loginUser
    builder.addCase(loginUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loader = false;
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      state.user = action.payload.token;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //loginAsUser
    builder.addCase(loginAsUser.pending, (state) => {
      state.loginAsLoader = true;
    });
    builder.addCase(loginAsUser.fulfilled, (state, action) => {
      // state.loginAsLoader = false;
      let token = localStorage.getItem("token");
      localStorage.setItem(
        "token",
        JSON.stringify(action.payload?.admin_login_as_user_token)
      );
      localStorage.setItem(
        "admin_login_as_user_token",
        JSON.stringify(action.payload?.admin_login_as_user_token)
      );
      localStorage.setItem("token1", token);
      state.loginAsUser = action.payload.admin_login_as_user_token;
      window.location.href = `${process.env.REACT_APP_LOAD_URL}${routes.dashboard}`;
    });
    builder.addCase(loginAsUser.rejected, (state, action) => {
      state.loginAsLoader = false;
      state.error = action.payload;
    });

    // confirmUser
    builder.addCase(confirmUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(confirmUser.fulfilled, (state, action) => {
      state.loader = false;
      localStorage.setItem("token", JSON.stringify(action.payload));
      state.user = action.payload;
    });
    builder.addCase(confirmUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //forgotPassword
    builder.addCase(forgotPassword.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.loader = false;
      state.forgotPassword = action.payload;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //resetPassword
    builder.addCase(resetPassword.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loader = false;
      state.resetPassword = action.payload;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //getProfile
    builder.addCase(getProfile.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loader = false;
      state.proloader = false;
      state.getProfile = action.payload;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //clearUserProfile
    builder.addCase(clearUserProfile.fulfilled, (state, action) => {
      state.loader = false;
      state.getApplicantProfile = null;
    });
    //clearUserListForAdmin

    builder.addCase(clearUserListForAdmin.fulfilled, (state, action) => {
      state.loader = false;
      state.allUsers = [];
    });

    //clearProfile
    builder.addCase(clearProfile.fulfilled, (state, action) => {
      state.loader = false;
      state.getProfile = null;
    });

    //getApplicantProfile
    builder.addCase(getApplicantProfile.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getApplicantProfile.fulfilled, (state, action) => {
      state.loader = false;
      state.getApplicantProfile = action.payload;
    });
    builder.addCase(getApplicantProfile.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //editProfile
    builder.addCase(editProfile.pending, (state) => {
      state.loader = true;
      state.proloader = true;
    });
    builder.addCase(editProfile.fulfilled, (state, action) => {
      state.loader = false;
      state.editProfile = action.payload;
    });
    builder.addCase(editProfile.rejected, (state, action) => {
      state.loader = false;
      state.proloader = false;
      state.error = action.payload;
    });

    //checkUserProfile
    builder.addCase(checkUserProfile.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(checkUserProfile.fulfilled, (state, action) => {
      state.loader = false;
      state.checkUserProfile = action.payload;
    });
    builder.addCase(checkUserProfile.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // clearUserJobWait
    builder.addCase(clearUserJobWait.fulfilled, (state, action) => {
      state.loader = false;
      state.checkUserJobWait = null;
    });

    //checkUserJobWait
    builder.addCase(checkUserJobWait.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(checkUserJobWait.fulfilled, (state, action) => {
      state.loader = false;
      state.checkUserJobWait = action.payload;
    });
    builder.addCase(checkUserJobWait.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //checkBusinessUserJobWait
    builder.addCase(checkPendingReviewForBuesiness.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(
      checkPendingReviewForBuesiness.fulfilled,
      (state, action) => {
        state.loader = false;
        state.checkPendingReviewForBuesiness = action.payload;
      }
    );
    builder.addCase(
      checkPendingReviewForBuesiness.rejected,
      (state, action) => {
        state.loader = false;
        state.error = action.payload;
      }
    );

    // clearJobPendingReview
    builder.addCase(clearJobPendingReview.fulfilled, (state, action) => {
      state.loader = false;
      state.checkJobPendingReview = null;
    });

    //checkJobPendingReview
    builder.addCase(checkJobPendingReview.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(checkJobPendingReview.fulfilled, (state, action) => {
      state.loader = false;
      state.checkJobPendingReview = action.payload;
    });
    builder.addCase(checkJobPendingReview.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    builder.addCase(getUsersApprovalList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getUsersApprovalList.fulfilled, (state, action) => {
      state.loader = false;
      if (action.payload?.allUsers) {
        state.allUsers = action.payload.data;
        state.totalCount = action?.payload?.totalCount;
      } else if (!action.payload?.allUsers) {
        state.userApprovalList = action.payload?.data;
      }
    });
    builder.addCase(getUsersApprovalList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // createStripeCustomer
    builder.addCase(createStripeCustomer.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createStripeCustomer.fulfilled, (state, action) => {
      state.loader = false;
      state.createStripeCustomer = action.payload;
    });
    builder.addCase(createStripeCustomer.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //profileForApproval
    builder.addCase(profileForApproval.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(profileForApproval.fulfilled, (state, action) => {
      state.loader = false;
      state.proloader = false;
      state.profileForApproval = action.payload;
    });
    builder.addCase(profileForApproval.rejected, (state, action) => {
      state.loader = false;
      state.proloader = false;
      state.error = action.payload;
    });

    //getFinancialReportList
    builder.addCase(getFinancialReportList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getFinancialReportList.fulfilled, (state, action) => {
      state.loader = false;
      state.getFinancialReportList = action.payload;
    });
    builder.addCase(getFinancialReportList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //getLeadReportList
    builder.addCase(getLeadReportList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getLeadReportList.fulfilled, (state, action) => {
      state.loader = false;
      state.getLeadReportList = action.payload;
    });
    builder.addCase(getLeadReportList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // crewPayoutDetails
    builder.addCase(crewPayoutDetails.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(crewPayoutDetails.fulfilled, (state, action) => {
      state.loader = false;
      state.crewPayoutDetails = action.payload;
    });
    builder.addCase(crewPayoutDetails.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getPayoutDetails
    builder.addCase(getPayoutDetails.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getPayoutDetails.fulfilled, (state, action) => {
      state.loader = false;
      state.getPayoutDetails = action.payload;
    });
    builder.addCase(getPayoutDetails.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // // get userCardList
    // builder.addCase(userCardList.pending, (state) => {
    //   state.loader = true;
    // });
    // builder.addCase(userCardList.fulfilled, (state, action) => {
    //   console.log("action.payload", action);
    //   state.loader = false;
    //   state.userCardList = action.payload;
    // });
    // builder.addCase(userCardList.rejected, (state, action) => {
    //   state.loader = false;
    //   state.error = action.payload;
    // });

    // getReviewList
    builder.addCase(getReviewList.pending, (state) => {
      state.extraLoader = true;
    });
    builder.addCase(getReviewList.fulfilled, (state, action) => {
      state.extraLoader = false;
      state.getReviewList = action.payload;
    });
    builder.addCase(getReviewList.rejected, (state, action) => {
      state.extraLoader = false;
      state.error = action.payload;
    });

    // getNotificationsSettings
    builder.addCase(getNotificationsSettings.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getNotificationsSettings.fulfilled, (state, action) => {
      state.loader = false;
      state.getNotificationsSettings = action.payload;
    });
    builder.addCase(getNotificationsSettings.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getNotificationsDetails
    builder.addCase(getNotificationsDetails.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getNotificationsDetails.fulfilled, (state, action) => {
      state.loader = false;
      state.getNotificationsDetails = action.payload;
    });
    builder.addCase(getNotificationsDetails.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getInvoiceList
    builder.addCase(getInvoiceList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getInvoiceList.fulfilled, (state, action) => {
      state.loader = false;
      state.invoices = action.payload;
    });
    builder.addCase(getInvoiceList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    builder.addCase(getCountForDashboard.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCountForDashboard.fulfilled, (state, action) => {
      state.loader = false;
      state.loginAsLoader = false;
      state.getCountForDashboard = action.payload;
    });
    builder.addCase(getCountForDashboard.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});
export const { logout } = userSlice.actions;
export default userSlice.reducer;
