import React from "react";

const OfferAcceptModal = ({ hideOfferAcceptModal }) => {
  return (
    <div className="zu_common_modal zu_common_center_modal zu_common_text_modal zu_offer_accepted_modal">
      <div className="zu_common_modal_content">
        <h2 className="zu_common_modal_heading">Offer Accepted</h2>
        <p className="zu_common_modal_paragraph">
          Congratulations! You are now officially booked on this job.
        </p>
        <div className="zu_modal_common_btn_row">
          <button
            type="button"
            className="zu_common_btn zu_btn_gray"
            onClick={() => hideOfferAcceptModal()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferAcceptModal;
