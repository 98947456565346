import React from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../css/ConfirmBox.css";
import { routes } from "../../constants";

import { logo} from "../../icons";

const ConfirmPasswordPage = () => {
  return (
    <div className="zu_confirm_box_page">
      <div className="zu_confirm_box">
        <div className="zu_confirm_box_content">
          <Link to={routes.login} className="zu_confirm_box_logo">
            {logo}
          </Link>
          <h2 className="zu_confirm_box_heading">Successful password reset!</h2>
          <p className="zu_confirm_box_paragraph">You can now use your new password to log in to your account! 🙌</p>
          <Link to={routes.login} className="zu_common_btn zu_btn_theme_color">Login</Link>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPasswordPage;