import React from "react";
import CardList from "./CardList";

const AddExtraHourModal = ({
  closeExtraHourModal,
  values,
  setValues,
  addExtraHourHandler,
  jobDetail,
  addExtraAmountHandler,
  cardData,
}) => {
  // const [showExtraHourField, setShowExtraHourField] = useState(true);

  const changeInputFieldHandler = () => {
    setValues({
      ...values,
      showExtraHourField: !values.showExtraHourField,
      extra_hour: "",
      extra_hour_err: "",
      custom_amount: "",
      custom_amount_err: "",
      custom_amount_reason: "",
      custom_amount_reason_err: "",
      creditCardIdErr: "",
      cardDataErr: "",
      cardNumberErr: "",
      expiryErr: "",
      cvcErr: "",
    });
  };

  const handleCardRadio = (id) => {
    setValues({
      ...values,
      creditCardIdErr: "",
      cardDataErr: "",
      cardNumberErr: "",
      expiryErr: "",
      cvcErr: "",
      creditCardId: id,
    });
  };

  const handleHourInputChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      extra_hour: e.target.value,
      extra_hour_err: "",
    });
  };

  const handleCustomAmountInputChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      custom_amount: e.target.value,
      custom_amount_err: "",
    });
  };

  const handleCustomAmountReasonChange = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      custom_amount_reason: e.target.value,
      custom_amount_reason_err: "",
    });
  };

  return (
    <div className="zu_common_modal zu_common_center_modal">
      <div className="zu_common_modal_content">
        <div className="zu_common_modal_heading_paragraph">
          <h2 className="zu_common_modal_heading">Increase Crew Payment</h2>
          <p className="zu_common_modal_paragraph">
            Would you like to add additional hours to this job, include a tip,
            or reimburse any job related expenses to this crew payout?
          </p>
        </div>

        {values.showExtraHourField ? (
          <div className="zu_common_modal_text_input zu_extra_hour_div">
            <span className="zu_add_extra_hour_input_label">
              Add Extra Hours
            </span>
            <input
              className="zu_add_extra_hour_input"
              placeholder="Number of Hours"
              type="number"
              id="hourInput"
              name="number_of_hour"
              onChange={handleHourInputChange}
              value={values.extra_hour}
              onKeyPress={(e) =>
                !/^\d*\.?\d*$/.test(e.key) && e.preventDefault()
              }
            />
            {values.extra_hour_err ? (
              <span className="err_text">{values.extra_hour_err}</span>
            ) : null}
          </div>
        ) : (
          <>
            <div className="zu_common_modal_text_input zu_extra_hour_div">
              <span className="zu_add_extra_hour_input_label">
                Add Custom Amount
              </span>
              <input
                className="zu_add_extra_hour_input"
                placeholder="Enter Amount"
                type="number"
                id="custom_amount"
                name="custom_amount"
                onChange={handleCustomAmountInputChange}
                value={values.custom_amount}
                onKeyPress={(e) =>
                  !/^\d*\.?\d*$/.test(e.key) && e.preventDefault()
                }
              />
              {values.custom_amount_err ? (
                <span className="err_text">{values.custom_amount_err}</span>
              ) : null}
            </div>
            <div className="zu_common_modal_text_input zu_extra_hour_div">
              <span className="zu_add_extra_hour_input_label">
                What is this custom payment for?
              </span>
              <input
                className="zu_add_extra_hour_input"
                placeholder="Describe your reasons"
                type="text"
                id="custom_amount_reason"
                name="custom_amount_reason"
                onChange={handleCustomAmountReasonChange}
                value={values.custom_amount_reason}
              />
              {values.custom_amount_reason_err ? (
                <span className="err_text">
                  {values.custom_amount_reason_err}
                </span>
              ) : null}
            </div>
          </>
        )}
        <p className="zu_add_hour_or_btn">OR</p>
        <div className="zu_add_custom_or_add_extra_hour_div">
          <button
            type="button"
            className="zu_add_custom_or_add_extra_hour_btn"
            onClick={changeInputFieldHandler}
          >
            {values.showExtraHourField
              ? "Add Custom Amount"
              : "Add Additional Hour"}
          </button>
        </div>
        <div className="zu_card_input_for_custom_amount">
          <CardList
            cardData={values.cardData}
            creditCardId={values.creditCardId}
            handleCardRadio={handleCardRadio}
            creditCardIdErr={values.creditCardIdErr}
            cardBlinkClass={values.cardBlinkClass}
            cardBlinkClassTwo={values.cardBlinkClassTwo}
            cardNumber={values.cardNumber}
            expiry={values.expiry}
            cvc={values.cvc}
            cardNumberErr={values.cardNumberErr}
            expiryErr={values.expiryErr}
            cvcErr={values.cvcErr}
            cardDataErr={values.cardDataErr}
            setValues={setValues}
            values={values}
          />
        </div>
        {(values?.custom_amount || values?.extra_hour) && (
          <div>
            <p className="zu_extra_amount_payable_text">
              Extra Amount Payable:{" $"}
              {values.showExtraHourField && values?.extra_hour
                ? (
                    jobDetail?.price_per_hour * values.extra_hour +
                    +(jobDetail?.price_per_hour * values.extra_hour * 0.05)
                  )?.toFixed(2)
                : !values.showExtraHourField && values?.custom_amount
                ? (
                    +values?.custom_amount +
                    +values?.custom_amount * 0.05
                  )?.toFixed(2)
                : ""}
            </p>
            <p className="zu_extra_amount_payable_text_small">
              Includes $
              {values.showExtraHourField && values?.extra_hour
                ? (+(
                    jobDetail?.price_per_hour *
                    values.extra_hour *
                    0.05
                  ))?.toFixed(2)
                : !values.showExtraHourField && values?.custom_amount
                ? (+values?.custom_amount * 0.05)?.toFixed(2)
                : ""}{" "}
              Shootzu Fee
            </p>
          </div>
        )}
        <div className="zu_modal_common_btn_row">
          <button
            type="button"
            className="zu_common_btn"
            onClick={closeExtraHourModal}
          >
            Back
          </button>
          {values.showExtraHourField ? (
            <button
              type="button"
              className="zu_common_btn zu_btn_light_green zu_lead_btn zu_extra_hour_btn"
              onClick={addExtraHourHandler}
            >
              <span>Process Payment</span>
            </button>
          ) : (
            <button
              type="button"
              className="zu_common_btn zu_btn_light_green zu_lead_btn zu_extra_hour_btn"
              onClick={addExtraAmountHandler}
            >
              <span>Process Payment</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddExtraHourModal;
