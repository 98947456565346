import React from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../css/ConfirmBox.css";
import { routes } from "../../constants";

import { logo } from "../../icons";

const SignUpCheckYourEmailPage = () => {
  return (
    <div className="zu_confirm_box_page">
      <div className="zu_confirm_box">
        <div className="zu_confirm_box_content">
          <Link to={routes.login} className="zu_confirm_box_logo">
            {logo}
          </Link>
          <h2 className="zu_confirm_box_heading">Check your email</h2>
          <p className="zu_confirm_box_paragraph">
            To confirm your email address, tap the button in the email we sent
            to you.
          </p>

          <p className="zu_confirm_box_signin_link">
            Already a Member? <Link to={routes.login}>Sign in</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpCheckYourEmailPage;
