import React from "react";
import { closeIcon, download2Icon } from "../../icons";

const ImgShowMsgModal = ({
  showImg,
  downloadFilehandler,
  setShowImg,
  imgUrl,
}) => {
  return (
    <div className={`zu_chat_img_zoom_modal ${showImg && "zu_modal_show"}`}>
      <button
        type="button"
        className="zu_chat_img_zoom_modal_img_download"
        onClick={() => downloadFilehandler(imgUrl)}
      >
        {download2Icon}
      </button>
      <button
        type="button"
        className="zu_chat_img_zoom_modal_close"
        onClick={() => setShowImg(false)}
      >
        {closeIcon}
      </button>
      <img
        src={`${process.env.REACT_APP_CHAT_FILE_BASE_URL}/${imgUrl}`}
        alt="modal img"
        className="zu_chat_img_zoom_modal_img"
      />
    </div>
  );
};

export default ImgShowMsgModal;
