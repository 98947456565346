import moment from "moment";
import React, { useEffect } from "react";
import {
  download2Icon,
  eyeIcon,
  msgFileIcon,
  msgPinnedIcon,
} from "../../icons";
import MsgToggle from "./MsgToggle";

const MsgChatBox = ({
  messagesArr,
  showImgModalHandler,
  downloadFilehandler,
  loggedInId,
  loading,
  stateLoader,
  editMsgHandler,
  editMsg,
  deleteMsgHandler,
  pinMsgHandler,
  specificPinnedMsg,
  bottomRef,
  bottomRef2,
  messageRef,
  setLastMsgId,
}) => {
  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [specificPinnedMsg, messagesArr]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messagesArr, specificPinnedMsg]);

  useEffect(() => {
    setLastMsgId(
      messagesArr[messagesArr.length - 1]?.id
        ? messagesArr[messagesArr.length - 1].id
        : 0
    );
  }, [messagesArr]);

  return (
    <>
      <div
        className={`zu_chat_msg_container ${editMsg && "is-edit-text-on"} ${
          messagesArr?.length === 0 ? "d-flex" : "d-block"
        }`}
        id="zu_chat_msg_container"
      >
        {messagesArr?.length === 0 ? (
          <p className="zu_no_msg_found"> NO MESSAGES</p>
        ) : (
          <>
            {messagesArr?.map((msg, i) => {
              return (
                <div
                  className="zu_chat_msg_box_content"
                  key={msg?.id}
                  ref={!specificPinnedMsg ? bottomRef : bottomRef2}
                >
                  {i === 0 ? (
                    <div className="zu_chat_msg_box_time">
                      <span>
                        {moment(msg.created_at).utc().format("ll") ===
                        moment().utc().format("ll")
                          ? "Today"
                          : moment().subtract(1, "days").utc().format("ll") ===
                            moment(messagesArr[i].created_at).utc().format("ll")
                          ? "Yesterday"
                          : moment(msg.created_at).utc().format("ll")}
                      </span>
                    </div>
                  ) : moment(messagesArr[i]?.created_at).utc().format("ll") !==
                    moment(messagesArr[i - 1]?.created_at)
                      .utc()
                      .format("ll") ? (
                    <div className="zu_chat_msg_box_time">
                      <span>
                        {moment(messagesArr[i].created_at)
                          .utc()
                          .format("ll") === moment().utc().format("ll")
                          ? "Today"
                          : moment().subtract(1, "days").utc().format("ll") ===
                            moment(messagesArr[i].created_at).utc().format("ll")
                          ? "Yesterday"
                          : moment(messagesArr[i].created_at)
                              .utc()
                              .format("ll")}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {msg.sender_id !== loggedInId ? (
                    <div
                      className="zu_chat_msg_recive"
                      ref={
                        msg?.pin_id === specificPinnedMsg ? messageRef : null
                      }
                    >
                      {messagesArr[i]?.sender_id !==
                      messagesArr[i - 1]?.sender_id ? (
                        <img
                          src={`${process.env.REACT_APP_FILE_BASE_URL}/${msg.sender_profile_photo}`}
                          alt="user img"
                          className="zu_chat_msg_recive_img"
                        />
                      ) : messagesArr[i]?.created_at !==
                        messagesArr[i - 1]?.created_at ? (
                        <img
                          src={`${process.env.REACT_APP_FILE_BASE_URL}/${msg.sender_profile_photo}`}
                          alt="user img"
                          className="zu_chat_msg_recive_img"
                        />
                      ) : (
                        <div className="zu_chat_msg_recive_img_none"></div>
                      )}
                      <div className="zu_chat_msg_recive_text">
                        {messagesArr[i]?.sender_id !==
                        messagesArr[i - 1]?.sender_id ? (
                          <span className="zu_chat_msg_recive_text_name">
                            {msg.sender_first_name + " " + msg.sender_last_name}{" "}
                            | {moment(msg.created_at).format("LT")}
                            {msg?.pin_id && msgPinnedIcon}
                          </span>
                        ) : moment(messagesArr[i]?.created_at).format("LT") !==
                          moment(messagesArr[i - 1]?.created_at).format(
                            "LT"
                          ) ? (
                          <span className="zu_chat_msg_recive_text_name">
                            {msg.sender_first_name + " " + msg.sender_last_name}{" "}
                            | {moment(msg.created_at).format("LT")}
                            {msg?.pin_id && msgPinnedIcon}
                          </span>
                        ) : (
                          msg.pin_id && (
                            <span className="zu_chat_msg_recive_text_name">
                              {msg.sender_first_name +
                                " " +
                                msg.sender_last_name}{" "}
                              | {moment(msg.created_at).format("LT")}
                              {msg?.pin_id && msgPinnedIcon}
                            </span>
                          )
                        )}

                        {msg.media_url === "" || msg.media_url === null ? (
                          <div className="zu_chat_msg_recive_text_msg_box_row">
                            <div className="zu_chat_msg_recive_text_msg_box">
                              {/* <p className="zu_chat_msg_recive_text_msg">
                              {msg.message_text}
                            </p> */}
                              <pre
                                className="zu_chat_msg_recive_text_msg"
                                dangerouslySetInnerHTML={{
                                  __html: msg.message_text,
                                }}
                              />
                            </div>
                            <MsgToggle
                              msg={msg}
                              onlyPin={true}
                              pinMsgHandler={pinMsgHandler}
                            />
                          </div>
                        ) : (
                          <div className="zu_chat_msg_recive_img_msg_box_row">
                            {msg.media_url?.match(/\.(jpeg|jpg|gif|png)$/) !==
                            null ? (
                              <div className="zu_chat_msg_recive_img_text_msg_box">
                                <div className="zu_chat_msg_recive_img_msg_box">
                                  <img
                                    src={`${process.env.REACT_APP_CHAT_FILE_BASE_URL}/${msg.media_url}`}
                                    alt="msg img"
                                  />
                                  <div className="zu_chat_msg_recive_img_button_row">
                                    <button
                                      type="button"
                                      className="zu_chat_msg_recive_img_button"
                                      onClick={() =>
                                        showImgModalHandler(msg.media_url)
                                      }
                                    >
                                      {eyeIcon}
                                    </button>
                                    <button
                                      type="button"
                                      className="zu_chat_msg_recive_img_button"
                                      onClick={() =>
                                        downloadFilehandler(
                                          msg.media_url,
                                          msg.media_name
                                        )
                                      }
                                    >
                                      {download2Icon}
                                    </button>
                                  </div>
                                </div>
                                {msg.message_text && (
                                  <pre
                                    className="zu_chat_msg_recive_img_msg_box_text"
                                    dangerouslySetInnerHTML={{
                                      __html: msg.message_text,
                                    }}
                                  />
                                )}
                              </div>
                            ) : (
                              <div className="zu_chat_msg_recive_file_msg_box">
                                <div className="zu_chat_msg_recive_file_inner_msg_box">
                                  <div className="zu_chat_msg_recive_file_msg_icon">
                                    {msgFileIcon}
                                    <p>{msg.mediaName?.split(".")[1]}</p>
                                  </div>
                                  <div className="zu_chat_msg_recive_file_msg_name">
                                    <p>{msg.media_name}</p>
                                    {/* <span>6 MB</span> */}
                                  </div>
                                  <button
                                    type="button"
                                    className="zu_chat_msg_recive_file_msg_button"
                                    onClick={() =>
                                      downloadFilehandler(
                                        msg.media_url,
                                        msg.media_name
                                      )
                                    }
                                  >
                                    {download2Icon}
                                  </button>
                                </div>
                                {msg.message_text && (
                                  <pre
                                    className="zu_chat_msg_recive_file_msg_box_text"
                                    dangerouslySetInnerHTML={{
                                      __html: msg.message_text,
                                    }}
                                  />
                                )}
                              </div>
                            )}
                            <MsgToggle
                              msg={msg}
                              onlyPin={true}
                              pinMsgHandler={pinMsgHandler}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="zu_chat_msg_send"
                      ref={
                        msg?.pin_id === specificPinnedMsg ? messageRef : null
                      }
                    >
                      <div className="zu_chat_msg_send_text">
                        {messagesArr[i]?.sender_id !==
                        messagesArr[i - 1]?.sender_id ? (
                          <span className="zu_chat_msg_send_text_name">
                            {msg?.pin_id && msgPinnedIcon}
                            {moment(msg.created_at).format("LT")}
                          </span>
                        ) : moment(messagesArr[i]?.created_at).format("LT") !==
                          moment(messagesArr[i - 1]?.created_at).format(
                            "LT"
                          ) ? (
                          <span className="zu_chat_msg_send_text_name">
                            {msg?.pin_id && msgPinnedIcon}
                            {moment(msg.created_at).format("LT")}
                          </span>
                        ) : (
                          msg?.pin_id && (
                            <span className="zu_chat_msg_send_text_name">
                              {msg?.pin_id && msgPinnedIcon}
                              {moment(msg.created_at).format("LT")}
                            </span>
                          )
                        )}
                        {msg.media_url === "" || msg.media_url === null ? (
                          <div className="zu_chat_msg_send_text_msg_box_row">
                            <div className="zu_chat_msg_send_text_msg_box">
                              <pre
                                className="zu_chat_msg_send_text_msg"
                                dangerouslySetInnerHTML={{
                                  __html: msg.message_text,
                                }}
                              />
                            </div>
                            <MsgToggle
                              onlyText={true}
                              editMsgHandler={editMsgHandler}
                              msg={msg}
                              deleteMsgHandler={deleteMsgHandler}
                              pinMsgHandler={pinMsgHandler}
                            />
                          </div>
                        ) : (
                          <div className="zu_chat_msg_send_img_msg_box_row">
                            {msg.media_url.match(/\.(jpeg|jpg|gif|png)$/) !==
                            null ? (
                              <div className="zu_chat_msg_send_img_text_msg_box">
                                <div className="zu_chat_msg_send_img_msg_box">
                                  <img
                                    src={`${process.env.REACT_APP_CHAT_FILE_BASE_URL}/${msg.media_url}`}
                                    alt="msg img"
                                  />
                                  <div className="zu_chat_msg_send_img_button_row">
                                    <button
                                      type="button"
                                      className="zu_chat_msg_send_img_button"
                                      onClick={() =>
                                        showImgModalHandler(msg.media_url)
                                      }
                                    >
                                      {eyeIcon}
                                    </button>
                                    <button
                                      type="button"
                                      className="zu_chat_msg_send_img_button"
                                      onClick={() =>
                                        downloadFilehandler(
                                          msg.media_url,
                                          msg.media_name
                                        )
                                      }
                                    >
                                      {download2Icon}
                                    </button>
                                  </div>
                                </div>
                                {msg.message_text && (
                                  <pre
                                    className="zu_chat_msg_send_img_msg_box_text"
                                    dangerouslySetInnerHTML={{
                                      __html: msg.message_text,
                                    }}
                                  />
                                )}
                              </div>
                            ) : (
                              <div className="zu_chat_msg_send_file_msg_box">
                                <div className="zu_chat_msg_send_file_inner_msg_box">
                                  <div className="zu_chat_msg_send_file_msg_icon">
                                    {msgFileIcon}
                                    <p>{msg.media_name?.split(".")[1]}</p>
                                  </div>
                                  <div className="zu_chat_msg_send_file_msg_name">
                                    <p>{msg.media_name}</p>
                                    {/* <span>6 MB</span> */}
                                  </div>
                                  <button
                                    type="button"
                                    className="zu_chat_msg_send_file_msg_button"
                                    onClick={() =>
                                      downloadFilehandler(
                                        msg.media_url,
                                        msg.media_name
                                      )
                                    }
                                  >
                                    {download2Icon}
                                  </button>
                                </div>
                                {msg.message_text && (
                                  // <p className="zu_chat_msg_send_file_msg_box_text">
                                  //   {msg.message_text}
                                  // </p>
                                  <pre
                                    className="zu_chat_msg_send_file_msg_box_text"
                                    dangerouslySetInnerHTML={{
                                      __html: msg.message_text,
                                    }}
                                  />
                                )}
                              </div>
                            )}

                            <MsgToggle
                              editMsgHandler={editMsgHandler}
                              deleteMsgHandler={deleteMsgHandler}
                              msg={msg}
                              pinMsgHandler={pinMsgHandler}
                              onlyText={
                                msg.media_url.match(/\.(jpeg|jpg|gif|png)$/) !==
                                  null && msg.message_text !== ""
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {(loading || stateLoader) && (
              <div className="zu_chat_msg_box_content zu_chat_msg_box_content_loader">
                <div className="zu_chat_msg_send">
                  <div className="zu_chat_msg_send_text">
                    <div className="zu_chat_msg_send_text_msg_box_row">
                      <div className="zu_chat_msg_send_text_msg_box">
                        <div className="zu_chat_msg_send_text_msg">
                          <div className="zu_chat_msg_box_content_loader_box"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MsgChatBox;
