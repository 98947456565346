import { PDFExport } from "@progress/kendo-react-pdf";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LeadTaxInvoice from "../../component/Layout/LeadInvoice";
import { downloadIcon } from "../../icons";

const LeadsInvoicePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { lead } = location?.state;
  const pdfExportComponent = React.useRef(null);
  const container = React.useRef(null);

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <main className="zu_all_page_main_content">
      <div className="download_invoice_btn">
        <button className="go_back_invoice_btn" onClick={() => navigate(-1)}>
          Go Back
        </button>
        <button
          className="zu_common_btn zu_btn_voilet"
          onClick={exportPDFWithComponent}
        >
          {downloadIcon} Download Invoice
        </button>
      </div>
      <PDFExport
        ref={pdfExportComponent}
        paperSize="auto"
        margin={40}
        fileName={`Invoice for ${lead?.id}`}
        author="Zl ReactJs Team"
      >
        <div ref={container}>
          <LeadTaxInvoice lead={lead} />
        </div>
      </PDFExport>
    </main>
  );
};

export default LeadsInvoicePage;
