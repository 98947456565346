import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";

const CSVDownloadButton = ({ data, fileName }) => {
  let columnKeys = data ? data : [];
  let column;

  column = columnKeys?.map((data) => {
    Object.preventExtensions(columnKeys);
    let categories =
      data?.user_worker_roles && JSON.parse(data?.user_worker_roles);

    return fileName === "User-Report"
      ? [
          data.id,
          data.first_name,
          data.last_name,
          data.email,
          data.business_name,
          data.address,
          categories,
          moment(data.created_at).utc().format("DD/MM/YYYY"),
          data.status,
          data.total_number_of_job_posted,
          data.times_hired,
        ]
      : fileName === "Job-Report.csv"
      ? [
          data.id,
          data.job_status,
          moment(data.date).utc().format("DD/MM/YYYY"),
          data.hired_date && moment(data.hired_date).utc().format("DD/MM/YYYY"),
          data.owner_first_name + " " + data.owner_last_name,
          data.crew_first_name
            ? data.crew_first_name + " " + data.crew_last_name
            : "",
          data.title,
          data.job_type,
          data.address,
          data.extra_hour
            ? data.extra_hour * data.price_per_hour +
              data.price_per_hour * data.number_of_hour +
              (data.extra_hour * data.price_per_hour +
                data.price_per_hour * data.number_of_hour) *
                0.05
            : data.custom_amount
            ? data.custom_amount +
              data.custom_amount * 0.05 +
              (data.price_per_hour * data.number_of_hour +
                data.price_per_hour * data.number_of_hour * 0.05)
            : data.price_per_hour * data.number_of_hour +
              data.price_per_hour * data.number_of_hour * 0.05,
          data.extra_hour
            ? data.extra_hour * data.price_per_hour +
              data.price_per_hour * data.number_of_hour -
              (data.extra_hour * data.price_per_hour +
                data.price_per_hour * data.number_of_hour) *
                0.02
            : data.custom_amount
            ? data.custom_amount -
              data.custom_amount * 0.02 +
              (data.price_per_hour * data.number_of_hour -
                data.price_per_hour * data.number_of_hour * 0.02)
            : data.price_per_hour * data.number_of_hour -
              data.price_per_hour * data.number_of_hour * 0.02,
          data.extra_hour
            ? data.extra_hour * data.price_per_hour +
              data.price_per_hour * data.number_of_hour +
              (data.extra_hour * data.price_per_hour * 0.05 +
                data.price_per_hour * data.number_of_hour * 0.05) -
              (data.extra_hour * data.price_per_hour +
                data.price_per_hour * data.number_of_hour -
                (data.extra_hour * data.price_per_hour * 0.02 +
                  data.price_per_hour * data.number_of_hour * 0.02))
            : data.custom_amount
            ? data.custom_amount +
              data.custom_amount * 0.05 +
              (data.price_per_hour * data.number_of_hour +
                data.price_per_hour * data.number_of_hour * 0.05) -
              (data.custom_amount -
                data.custom_amount * 0.02 +
                (data.price_per_hour * data.number_of_hour -
                  data.price_per_hour * data.number_of_hour * 0.02))
            : data.price_per_hour * data.number_of_hour +
              data.price_per_hour * data.number_of_hour * 0.05 -
              (data.price_per_hour * data.number_of_hour -
                data.price_per_hour * data.number_of_hour * 0.02),
        ]
      : fileName === "Financial-Report"
      ? [
          data.id,
          data.job_status,
          moment(data.job_date).utc().format("DD/MM/YYYY"),
          data.first_payment_date &&
            moment(data.first_payment_date).utc().format("DD/MM/YYYY"),
          data.owner_first_name + " " + data.owner_last_name,
          data.owner_gst_status,
          +(data.price_per_hour * data.number_of_hour)?.toFixed(2),
          +(data.price_per_hour * data.number_of_hour * 0.05)?.toFixed(2),
          +(
            (data.price_per_hour * data.number_of_hour) / 2 +
            data.price_per_hour * data.number_of_hour * 0.05
          )?.toFixed(2),
          data.first_payment_date &&
            moment(data.first_payment_date).utc().format("DD/MM/YYYY"),
          +((data.price_per_hour * data.number_of_hour) / 2)?.toFixed(2),
          data.second_payment_date &&
            moment(data.second_payment_date).utc().format("DD/MM/YYYY"),
          data.custom_amount
            ? data.custom_amount.toFixed(2)
            : data.extra_hour
            ? (data.extra_hour * data.price_per_hour).toFixed(2)
            : "",
          data.custom_amount
            ? (data.custom_amount * 0.05).toFixed(2)
            : data.extra_hour
            ? (data.extra_hour * data.price_per_hour * 0.05).toFixed(2)
            : "",
          data.custom_amount
            ? (data.custom_amount * 0.02).toFixed(2)
            : data.extra_hour
            ? (data.extra_hour * data.price_per_hour * 0.02).toFixed(2)
            : "",
          +(
            data.price_per_hour * data.number_of_hour +
            (data.custom_amount
              ? data.custom_amount
              : data.extra_hour
              ? data.extra_hour * data.price_per_hour
              : 0)
          ).toFixed(2),
          +(
            data.price_per_hour * data.number_of_hour * 0.05 +
            (data.custom_amount
              ? data.custom_amount * 0.05
              : data.extra_hour
              ? data.extra_hour * data.price_per_hour * 0.05
              : 0)
          ).toFixed(2),
          +(
            data.price_per_hour * data.number_of_hour +
            (data.custom_amount
              ? data.custom_amount
              : data.extra_hour
              ? data.extra_hour * data.price_per_hour
              : 0) +
            (data.price_per_hour * data.number_of_hour * 0.05 +
              (data.custom_amount
                ? data.custom_amount * 0.05
                : data.extra_hour
                ? data.extra_hour * data.price_per_hour * 0.05
                : 0))
          ).toFixed(2),
          data.crew_first_name + " " + data.crew_last_name,
          +(
            (data.price_per_hour * data.number_of_hour +
              (data.custom_amount
                ? data.custom_amount
                : data.extra_hour
                ? data.extra_hour * data.price_per_hour
                : 0)) *
            0.02
          ).toFixed(2),
          +(
            data.price_per_hour * data.number_of_hour +
            (data.custom_amount
              ? data.custom_amount
              : data.extra_hour
              ? data.extra_hour * data.price_per_hour
              : 0) -
            (data.price_per_hour * data.number_of_hour +
              (data.custom_amount
                ? data.custom_amount
                : data.extra_hour
                ? data.extra_hour * data.price_per_hour
                : 0)) *
              0.02
          ).toFixed(2),
          data.complete_job_date &&
            moment(data.complete_job_date).utc().format("DD/MM/YYYY"),
          data.job_status === "CANCELLED"
            ? data.date_for_cancel_and_done_status &&
              moment(data.date_for_cancel_and_done_status)
                .utc()
                .format("DD/MM/YYYY")
            : "",
        ]
      : [
          data.id,
          moment(data.purchase_date).utc().format("DD/MM/YYYY"),
          data.first_name + " " + data.last_name,
          data.business_email,
          data.client_email,
          data.lead_amount - data.lead_amount / 11,
          data.lead_amount / 11,
          data.lead_amount,
        ];
  });

  let headers =
    fileName === "User-Report"
      ? [
          [
            "User ID",
            "First Name",
            "Last Name",
            "Email Address",
            "Bussiness Name",
            "Town / City",
            "Categories",
            "Date Joined",
            "Status",
            "Number of Posts",
            "Times Hired as Crew",
          ],
          ...column,
        ]
      : fileName === "Job-Report.csv"
      ? [
          [
            "Job ID",
            "Job Status",
            "Date of Job",
            "Date of Hire",
            "Job Owner",
            "Hired Crew",
            "Title of Job",
            "Job Category",
            "Location of Job",
            "Total Job Amount",
            "Total Crew Payment",
            "Shootzu Fee",
          ],
          ...column,
        ]
      : fileName === "Financial-Report"
      ? [
          [
            "Job ID",
            "Job Status",
            "Date of Job",
            "Date of Hire",
            "Job Owner",
            "GST",
            "ORIGINAL JOB AMOUNT",
            "ORIGINAL FEE ON JOB AMOUNT",
            "PAYMENT 1",
            "PAYMENT 1 DATE",
            "PAYMENT 2",
            "PAYMENT 2 DATE",
            "EXTRA PAYMENT",
            "EXTRA FEE TO BUSINESS",
            "EXTRA FEE TO CREW",
            "FINAL JOB AMOUNT (INC EXTRA)",
            "TOTAL FEE TO BUSINESS",
            "TOTAL CHARGED TO BUSINESS",
            "CREW HIRED (NAME)",
            "TOTAL SHOOTZU FEE TO CREW",
            "TOTAL PAID TO CREW",
            "Payment Completed Date",
            "Date job was cancelled",
          ],
          ...column,
        ]
      : [
          [
            "Lead ID",
            "Purchase Date",
            "Purchased By",
            "Business Email",
            "Client Email",
            "Lead Amount",
            "10% GST",
            "Total Charged",
          ],
          ...column,
        ];

  return (
    <CSVLink
      data={headers}
      filename={fileName}
      className="zu_common_btn zu_btn_light_green"
      enclosingCharacter={`"`}
    >
      DOWNLOAD READY
    </CSVLink>
  );
};

export default CSVDownloadButton;
