import React from "react";
import "../../css/component.css";
import image from "../../images/default-user.jpg";

import { locationIcon } from "../../icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getApplicantProfile } from "../../store/slice/userSlice";
import { useState } from "react";
import { jobWorkerRoleClassname, jobWorkerRoleName } from "../../constants";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";
import SpinLoader from "../Common/SpinLoader";
import Loader from "../Common/Loader";
import { Modal } from "react-bootstrap";
import ApplicantDetailModal from "./ApplicantDetailModal";

const FindJobsColumn = ({
  job,
  hasFindJob,
  showModal,
  jobLoadingState,
  jobLoading,
  applyBtnHandler,
}) => {
  const [lessText, setLessText] = useState(false);
  const [ownerDetail, setOwnerDetail] = useState("");
  const [ownerDetailModal, setOwnerDetailModal] = useState(false);
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.user.loader);

  let word = "";
  const wordFunction = (id) => {
    for (let i = 0; i < 15; i++) {
      word += " " + id[i];
    }
    return word;
  };

  const readMoreHandler = () => {
    setLessText(!lessText);
  };

  const openOwnerModalHandler = () => {
    dispatch(
      getApplicantProfile({
        id: job?.owner,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setOwnerDetailModal(true);
            setOwnerDetail(res);
          }
        },
      })
    );
  };

  const hideOwnerDetailModal = () => {
    setOwnerDetailModal(false);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="zu_jobs_column" key={job?.Id}>
        <div className="zu_jobs_column_left_content">
          <img
            src={
              job?.profile_photo
                ? `${process.env.REACT_APP_FILE_BASE_URL}/${job?.profile_photo}`
                : image
            }
            alt="user profile"
            className="zu_jobs_column_left_user_img"
          />
          <div className="zu_jobs_column_name_description_text">
            <div className="zu_jobs_column_name_row">
              <h3 role="button" onClick={(e) => showModal(job?.id, job?.owner)}>
                {job?.title}
              </h3>
              <span onClick={openOwnerModalHandler} role="button">
                By {capitalizeFirstLetter(job?.first_name)}{" "}
                {capitalizeFirstLetter(job?.last_name)}
              </span>
              <p>
                {locationIcon} {job?.address}
              </p>
            </div>
            <div className="zu_jobs_column_price_time_text">
              <p>{moment(job?.date).utc().format("DD MMM YYYY")}</p>
              <p>{job?.number_of_hour} Hours</p>
              <p>
                $
                {job?.price_per_hour && (job?.price_per_hour * 0.98).toFixed(2)}{" "}
                Per Hour
              </p>
              <p className="zu_jobs_column_right_price_text">
                $
                {(
                  job?.number_of_hour *
                  (job?.price_per_hour && job?.price_per_hour * 0.98)
                ).toFixed(2)}{" "}
                AUD Total
              </p>
            </div>
            {job?.brief?.split(" ")?.length < 16 ? (
              <pre className="zu_jobs_column_description_text zu_jobs_column_brief_pre">
                {job?.brief}
              </pre>
            ) : (
              <pre className="zu_jobs_column_description_text zu_jobs_column_brief_pre">
                {lessText ? job?.brief : wordFunction(job?.brief?.split(" "))}{" "}
                {job?.brief?.split(" ")?.length > 10 && (
                  <span className="readmore_link" onClick={readMoreHandler}>
                    {lessText ? "Read less..." : "Read more..."}
                  </span>
                )}
              </pre>
            )}
            <div className="zu_jobs_column_label_row">
              {job?.job_worker_roles &&
                JSON.parse(job?.job_worker_roles).map((i, id) => {
                  return (
                    <span
                      className={`zu_common_label ${jobWorkerRoleClassname(
                        i
                      )} `}
                      key={`zu_label${id}`}
                    >
                      {i === "DRONE_OPERATOR" ||
                      i === "PHOTO_EDITOR" ||
                      i === "VIDEO_EDITOR"
                        ? jobWorkerRoleName(i)
                        : i}
                    </span>
                  );
                })}
              <span
                className={`zu_common_label zu_common_label_light`}
                key={`zu_label`}
              >
                JOB ID: {job?.id}
              </span>
            </div>
            <button
              //   to={routes.managejobs}

              className="zu_jobs_column_price_more_detail_btn zu_find_jobs_column_more_detail"
              onClick={(e) => showModal(job?.id, job?.owner)}
            >
              {jobLoadingState === job?.id && jobLoading ? (
                <SpinLoader />
              ) : (
                "More Details"
              )}
            </button>
          </div>
          <div className="zu_jobs_column_price_more_detail">
            <p>{moment(job?.date).utc().format("DD MMM YYYY")}</p>
            <p>{job?.number_of_hour} Hours</p>
            <p>
              ${job?.price_per_hour && (job?.price_per_hour * 0.98)?.toFixed(2)}{" "}
              Per Hour
            </p>
            <p className="zu_jobs_column_right_price_text">
              $
              {(
                job?.number_of_hour *
                (job?.price_per_hour && job?.price_per_hour * 0.98)
              ).toFixed(2)}{" "}
              AUD Total
            </p>
          </div>
        </div>
        <div className="zu_jobs_column_right_content">
          {hasFindJob && (
            <div className="zu_find_jobs_column_right_content">
              <button
                className={`zu_common_btn zu_btn_light_green`}
                onClick={(e) => applyBtnHandler(job?.id, job?.owner)}
              >
                APPLY NOW
              </button>
              <span className={`zu_jobs_column_right_payment_text`}>
                <i>{job?.total_applicant_count} Applicants</i>
              </span>
              <button
                className="zu_jobs_column_price_more_detail_btn zu_find_jobs_column_more_detail"
                // className="zu_jobs_column_price_more_detail_btn zu_find_jobs_column_more_detail"
                onClick={(e) => showModal(job?.id, job?.owner)}
              >
                {jobLoadingState === job?.id && jobLoading ? (
                  <SpinLoader />
                ) : (
                  "More Details"
                )}
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={ownerDetailModal}
        onHide={hideOwnerDetailModal}
        centered
        className="zu_common_text_modal zu_job_completed_modal"
      >
        <ApplicantDetailModal
          hideOwnerDetailModal={hideOwnerDetailModal}
          ownerDetail={ownerDetail}
        />
      </Modal>
    </>
  );
};

export default FindJobsColumn;
