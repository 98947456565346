import React from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants";

const ProfileAttentionModal = ({ hideProfileModal, approvalStatus }) => {
  const navigate = useNavigate();

  return (
    <div className="zu_common_modal zu_common_center_modal">
      <div className="zu_common_modal_content">
        <h2 className="zu_common_modal_heading">
          {approvalStatus === "PENDING"
            ? "Pending Approval"
            : "Need Profile Approval!"}
        </h2>
        <p className="zu_common_modal_paragraph">
          {approvalStatus === "PENDING"
            ? "Your profile is still awaiting approval. Please wait for an acceptance email before posting and applying for jobs"
            : "You have to approve your profile first then you can apply and post the jobs"}
        </p>
        <div className="zu_modal_common_btn_row">
          {approvalStatus !== "PENDING" && (
            <button
              onClick={() => navigate(routes.profileedit)}
              type="button"
              className="zu_common_btn zu_btn_light_green"
            >
              GO TO PROFILE
            </button>
          )}

          <button
            onClick={() => hideProfileModal()}
            type="button"
            className="zu_common_btn zu_btn_gray"
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileAttentionModal;
