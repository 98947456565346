import React from "react";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";
import ReactStars from "react-rating-stars-component";
import {
  calendarFillIcon,
  dollorIcon,
  ratingFillStarIcon,
  ratingLineStarIcon,
} from "../../icons";
import moment from "moment";
import { useDispatch } from "react-redux";
import { jobReview, updateJob } from "../../store/slice/postSlice";
import { ratingTextValue } from "../../constants";

const ReviewBusinessModal = ({
  jobDetail,
  values,
  setValues,
  handleInputChange,
  errors,
  closeModelHanlder,
  id,
  showExtraHourModal,
}) => {
  const dispatch = useDispatch();

  const ratingChanged = (rating) => {
    setValues({ ...values, rating });
  };

  const completeJobHandler = (e) => {
    e.preventDefault();

    const data = {
      job: jobDetail?.id,
      feedback_type: "AS_BUSINESS",
      receiver: jobDetail?.shooter,
      star: ratingTextValue(values),
      review: values.review,
    };

    const updateJobData = {
      job_status: "COMPLETED",
    };
    dispatch(
      updateJob({
        data: updateJobData,
        id: id,
        cb: (err, res) => {
          if (err) {
          } else {
            dispatch(
              jobReview({
                data,
                cb: (err, res) => {
                  if (err) {
                    console.log("err.response", err.response.data.message);
                  } else {
                    setValues({
                      ...values,
                      completePostModal: false,
                      jobReviewModal: true,
                    });
                  }
                },
              })
            );
          }
        },
      })
    );
  };

  return (
    <div className="zu_common_modal zu_common_center_modal">
      <div className="zu_common_modal_content">
        <div className="zu_common_modal_heading_paragraph">
          <h2 className="zu_common_modal_heading">Review / Complete Job</h2>
          <p className="zu_common_modal_paragraph">
            HOW WAS YOUR EXPERIENCE WORKING WITH
          </p>
        </div>
        <h2 className="zu_common_modal_second_heading">
          {capitalizeFirstLetter(jobDetail?.crew_first_name)}{" "}
          {capitalizeFirstLetter(jobDetail?.crew_last_name)}
        </h2>
        <span className="zu_common_modal_sub_heading">{jobDetail?.title}</span>
        <div className="zu_common_modal_rating_star_row">
          <ReactStars
            count={5}
            size={40}
            emptyIcon={ratingLineStarIcon}
            filledIcon={ratingFillStarIcon}
            value={values.rating}
            onChange={ratingChanged}
          />
        </div>
        <div className="zu_common_modal_text_input">
          <textarea
            name="review"
            placeholder="write your review"
            className="zu_common_modal_textarea"
            id="review"
            value={values.review}
            onChange={handleInputChange}
          ></textarea>
          {errors?.review ? (
            <span className="err_text">{errors?.review}</span>
          ) : null}
        </div>
        <div className="zu_common_modal_other_detail_row">
          <div className="zu_common_modal_other_detail_column">
            {calendarFillIcon}
            <div className="zu_common_modal_other_detail_text">
              <h3>Date of Job</h3>
              <p>{moment(jobDetail?.date).utc().format("DD MMM YYYY")}</p>
            </div>
          </div>
          <div className="zu_common_modal_other_detail_column">
            {dollorIcon}
            <div className="zu_common_modal_other_detail_text">
              <h3>Job Amount</h3>
              <p>
                ${" "}
                {(jobDetail?.extra_hour
                  ? (jobDetail?.extra_hour + jobDetail?.number_of_hour) *
                    jobDetail?.price_per_hour
                  : jobDetail?.number_of_hour * jobDetail?.price_per_hour
                ).toFixed(2)}
              </p>
            </div>
          </div>
        </div>
        <div className="zu_modal_common_btn_row">
          {!jobDetail?.extra_hour && !jobDetail?.custom_amount && (
            <div
              className="zu_warning_message zu_alert_for_extra_hour alert alert-warning fade show"
              role="alert"
            >
              <strong>
                Would you like to add additional hours to this job, include a
                tip, or reimburse any job related expenses before finalising
                this crew payout?
              </strong>
              <div className="div_for_extra_hour_btn">
                <button
                  type="button"
                  className="extra_hour_btn"
                  onClick={showExtraHourModal}
                >
                  Increase Crew Payment
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="zu_modal_common_btn_row">
          <button
            type="button"
            className="zu_common_link_btn"
            onClick={closeModelHanlder}
          >
            Back
          </button>
          <button
            type="button"
            className="zu_common_btn zu_btn_light_green zu_modal_common_submit_btn"
            onClick={completeJobHandler}
          >
            Complete Job
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewBusinessModal;
