import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants";
import { Form, FloatingLabel } from "react-bootstrap";

import { eyeIcon, hideEyeIcon, logo, rightArrow } from "../../icons";
import { useForm } from "../../hooks/useForm";
import Input from "../../component/Common/Input";
import Select from "react-select";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../store/slice/userSlice";
import Loader from "../../component/Common/Loader";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";

const SignUpPage = () => {
  const ref = useRef(false);
  const tempOption = process.env.REACT_APP_COUNTRY_OPTIONS.split(",").map(
    (v) => {
      return { value: v.split(":")[0], label: v.split(":")[1] };
    }
  );
  const countryOptions = tempOption;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useSelector((state) => state.user.loader);
  const initialFValues = {
    town: "",
    country: { value: "AU", label: "Australia" },
    first_name: "",
    last_name: "",
    business_name: "",
    email: "",
    password: "",
    inputTypePassword: "password",
    confirm_password: "",
    inputTypeConfirmPassword: "password",
    profession: [],
    policy: false,
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;

    if ("town" in fieldValues) {
      temp.town = fieldValues.town ? "" : "Town is required!";
    }
    if ("country" in fieldValues) {
      temp.country = fieldValues.country ? "" : "Country is required!";
    }
    if ("first_name" in fieldValues) {
      temp.first_name = fieldValues.first_name ? "" : "First name is required!";
    }
    if ("last_name" in fieldValues) {
      temp.last_name = fieldValues.last_name ? "" : "Last name is required!";
    }
    if ("business_name" in fieldValues) {
      temp.business_name = fieldValues.business_name
        ? ""
        : "Business name is required!";
    }
    if ("email" in fieldValues) {
      if (!fieldValues.email) {
        temp.email = "Email is required!";
      } else {
        temp.email = "";
      }
    }
    if ("password" in fieldValues) {
      if (!fieldValues.password) {
        temp.password = "Password is required!";
      } else {
        temp.password =
          fieldValues.password.length > 5
            ? ""
            : "Password must contain at least 6 characters!";
      }
    }
    if ("confirm_password" in fieldValues) {
      temp.confirm_password = fieldValues.confirm_password
        ? ""
        : "Please reconfirm your password";
    }
    if ("profession" in fieldValues) {
      temp.profession =
        fieldValues.profession?.length !== 0
          ? ""
          : "Please select at least one category!";
    }
    if ("policy" in fieldValues) {
      temp.policy = fieldValues.policy
        ? ""
        : "You must accept the Privacy Policy and Terms of Service to use Shootzu";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const emailValidate = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
    var mailReg = new RegExp(mailformat);
    if (!values.email.match(mailReg)) {
      setErrors({ ...errors, email: "Please enter a valid email!" });
    } else {
      setErrors({ ...errors, email: "" });
      return true;
    }
  };

  const passwordValidate = () => {
    if (values.password !== values.confirm_password) {
      setErrors({
        ...errors,
        confirm_password: "The password confirmation does not match!",
      });
    } else {
      setErrors({
        ...errors,
        confirm_password: "",
      });
    }
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    passwordValidate();
  }, [values.confirm_password]);

  useEffect(() => {
    if (ref.current === true) {
      if (!values.policy) {
        setErrors({
          ...errors,
          policy: "Please accept the Privacy Policy and Terms of Service!",
        });
      } else {
        setErrors({
          ...errors,
          policy: "",
        });
      }
    }
    ref.current = true;
  }, [values.policy]);

  const policyHandleChange = () => {
    setValues({ ...values, policy: !values.policy });
  };

  const showPasswordHandler = (e) => {
    e.preventDefault();
    if (values.inputTypePassword === "password") {
      setValues({
        ...values,
        inputTypePassword: "text",
      });
    } else {
      setValues({
        ...values,
        inputTypePassword: "password",
      });
    }
  };

  const showConfirmPasswordHandler = (e) => {
    e.preventDefault();
    if (values.inputTypeConfirmPassword === "password") {
      setValues({
        ...values,
        inputTypeConfirmPassword: "text",
      });
    } else {
      setValues({
        ...values,
        inputTypeConfirmPassword: "password",
      });
    }
  };

  const getStartedHandler = (e) => {
    e.preventDefault();

    if (validate() && emailValidate()) {
      const data = {
        address: values.town,
        country: values.country.value,
        first_name: capitalizeFirstLetter(values.first_name),
        last_name: capitalizeFirstLetter(values.last_name),
        business_name: values.business_name,
        email: values.email,
        user_password: values.password,
        user_worker_roles: values.profession,
        role: "ROLE_USER",
      };

      dispatch(registerUser({ data, navigate }));
    }
  };

  const [autocomplete, setAutocomplete] = useState(null);

  const googleAPIKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleAPIKey,
    libraries: ["places"],
  });

  if (!isLoaded) {
    return <Skeleton />;
  }

  const onLoad = (autocomplete) => {
    const restrictedCountry =
      process.env.REACT_APP_RESTRICTED_COUNTRY.split(",");

    autocomplete.setComponentRestrictions({
      country: restrictedCountry,
    });
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      setValues({ ...values, town: autocomplete.getPlace().formatted_address });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  if (loader) {
    return <Loader />;
  }

  return (
    <div className="zu_signup_page_content">
      <div className="zu_auth_page_mobile_logo">
        <Link to={routes.dashboard}>{logo}</Link>
      </div>
      <section className="zu_signup_section">
        <div className="zu_signup_content">
          <Link to={routes.dashboard} className="zu_signup_form_logo">
            {logo}
          </Link>
          <span className="zu_signup_form_sub_heading">
            Photo & Video Professionals
          </span>
          <h2 className="zu_signup_form_heading">Join the Zu Crew for Free!</h2>
          <span className="zu_signup_form_mobile_sub_heading">
            Photo & Video Professionals
          </span>
          <h2 className="zu_signup_form_mobile_heading">
            Continue to your Account.
          </h2>
          <form className="zu_signup_form" type="submit">
            <div className="zu_signup_form_row">
              <div className="zu_signup_form_column">
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <FloatingLabel
                    label="Town/City"
                    className="zu_floting_input_content"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Town/City"
                      className="zu_floting_input"
                      onChange={handleInputChange}
                      name="town"
                    />
                    {errors?.town ? (
                      <span className="err_text">{errors?.town}</span>
                    ) : null}
                  </FloatingLabel>
                </Autocomplete>
              </div>
              <div className="zu_signup_form_column">
                <div className="zu_floting_input_content position-relative form-floating zu_floting_select2">
                  <Select
                    value={values.country}
                    placeholder="Select country"
                    className="basic-multi-select zu_floting_input form-control"
                    classNamePrefix="select"
                    name="country"
                    options={countryOptions}
                    onChange={(e) => {
                      handleInputChange(e, "country");
                    }}
                    isDisabled={true}
                  />
                  <label className="create_from_label">Country </label>
                  {errors?.country ? (
                    <span className="err_text">{errors?.country}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="zu_signup_form_row">
              <div className="zu_signup_form_column">
                <FloatingLabel
                  label="First Name"
                  className="zu_floting_input_content"
                >
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    className="zu_floting_input"
                    name="first_name"
                    id="first_name"
                    onChange={handleInputChange}
                    value={values.first_name}
                  />
                  {errors?.first_name && (
                    <span className="err_text">{errors?.first_name}</span>
                  )}
                </FloatingLabel>
              </div>
              <div className="zu_signup_form_column">
                <FloatingLabel
                  label="Last Name"
                  className="zu_floting_input_content"
                >
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    className="zu_floting_input"
                    name="last_name"
                    id="last_name"
                    onChange={handleInputChange}
                    value={values.last_name}
                  />
                  {errors?.last_name && (
                    <span className="err_text">{errors?.last_name}</span>
                  )}
                </FloatingLabel>
              </div>
              <div className="zu_signup_form_column">
                <FloatingLabel
                  label="Your Business Name"
                  className="zu_floting_input_content"
                >
                  <Form.Control
                    type="text"
                    placeholder="Your Business Name"
                    className="zu_floting_input"
                    name="business_name"
                    id="business_name"
                    onChange={handleInputChange}
                    value={values.business_name}
                  />
                  {errors?.business_name && (
                    <span className="err_text">{errors?.business_name}</span>
                  )}
                </FloatingLabel>
              </div>
              <div className="zu_signup_form_column">
                <FloatingLabel
                  label="Email"
                  className="zu_floting_input_content"
                >
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    className="zu_floting_input"
                    name="email"
                    id="email"
                    onChange={handleInputChange}
                    value={values.email}
                  />
                  {errors?.email && (
                    <span className="err_text">{errors?.email}</span>
                  )}
                </FloatingLabel>
              </div>
              <div className="zu_signup_form_column">
                <FloatingLabel
                  label="Password"
                  className="zu_floting_input_content zu_floting_password_input_content"
                >
                  <Form.Control
                    type={values.inputTypePassword}
                    placeholder="Password"
                    className="zu_floting_input"
                    name="password"
                    id="password"
                    onChange={handleInputChange}
                    value={values.password}
                  />
                  <button
                    onClick={showPasswordHandler}
                    className="zu_password_eye_btn"
                  >
                    {values.inputTypePassword === "password"
                      ? eyeIcon
                      : hideEyeIcon}
                  </button>
                  {errors?.password && (
                    <span className="err_text">{errors?.password}</span>
                  )}
                </FloatingLabel>
              </div>
              <div className="zu_signup_form_column">
                <FloatingLabel
                  label="Confirm Password"
                  className="zu_floting_input_content zu_floting_password_input_content"
                >
                  <Form.Control
                    type={values.inputTypeConfirmPassword}
                    placeholder="Confirm Password"
                    className="zu_floting_input"
                    name="confirm_password"
                    id="confirm_password"
                    onChange={handleInputChange}
                    value={values.confirm_password}
                  />
                  <button
                    onClick={showConfirmPasswordHandler}
                    className="zu_password_eye_btn"
                  >
                    {values.inputTypeConfirmPassword === "password"
                      ? eyeIcon
                      : hideEyeIcon}
                  </button>
                  {errors?.confirm_password && (
                    <span className="err_text">{errors?.confirm_password}</span>
                  )}
                </FloatingLabel>
              </div>
            </div>
            <div className="zu_signup_form_checkbox_categories">
              <p className="zu_signup_form_checkbox_categories_text">
                Which categories best suit your profession? Please tick all that
                apply to your business
              </p>
              <div></div>
              <div className="zu_signup_form_checkbox_categories_row">
                <Input
                  className="zu_common_checkbox"
                  type="checkbox"
                  label="Photographer"
                  id="photographer"
                  name="profession"
                  value="PHOTOGRAPHER"
                  onChange={(e) => handleInputChange(e, null, true)}
                  checked={values.profession?.includes("PHOTOGRAPHER")}
                />

                <Input
                  className="zu_common_checkbox"
                  type="checkbox"
                  label="Videographer"
                  id="videographer"
                  name="profession"
                  value="VIDEOGRAPHER"
                  onChange={(e) => handleInputChange(e, null, true)}
                  checked={values.profession?.includes("VIDEOGRAPHER")}
                />
                <Input
                  className="zu_common_checkbox"
                  type="checkbox"
                  label="Assistant"
                  id="assistant"
                  name="profession"
                  value="ASSISTANT"
                  onChange={(e) => handleInputChange(e, null, true)}
                  checked={values.profession?.includes("ASSISTANT")}
                />
                <Input
                  className="zu_common_checkbox"
                  type="checkbox"
                  label="Editor (Photos)"
                  id="editorPhotos"
                  name="profession"
                  value="PHOTO_EDITOR"
                  onChange={(e) => handleInputChange(e, null, true)}
                  checked={values.profession?.includes("PHOTO_EDITOR")}
                />
                <Input
                  className="zu_common_checkbox"
                  type="checkbox"
                  label="Editor (Videos)"
                  id="editorVideos"
                  name="profession"
                  value="VIDEO_EDITOR"
                  onChange={(e) => handleInputChange(e, null, true)}
                  checked={values.profession?.includes("VIDEO_EDITOR")}
                />
                <Input
                  className="zu_common_checkbox"
                  type="checkbox"
                  label="Agency - I only want to Post Jobs"
                  id="agencyJob"
                  name="profession"
                  value="AGENCYJOB"
                  onChange={(e) => handleInputChange(e, null, true)}
                  checked={values.profession?.includes("AGENCYJOB")}
                />
                {errors?.profession && (
                  <span className="err_text profession_err">
                    {errors?.profession}
                  </span>
                )}
              </div>
              <div className="zu_signup_form_tnc zu_common_checkbox">
                <input
                  type="checkbox"
                  id="policy"
                  name="policy"
                  value="policy"
                  onChange={() => policyHandleChange()}
                />
                <label htmlFor="policy">
                  By Signing up to Shootzu, means you agree to our{" "}
                  <a
                    target="_blank"
                    href=" https://www.shootzu.com/privacy-policy/"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    href="https://www.shootzu.com/terms/"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                </label>
              </div>
              {errors?.policy && (
                <span className="err_text">{errors?.policy}</span>
              )}
            </div>
            <button
              to={routes.signupcheckyouremail}
              className="zu_auth_form_submit_btn"
              onClick={getStartedHandler}
            >
              GET STARTED
              {rightArrow}
            </button>
            <p className="zu_signup_already_member_text">
              Already a Member? <Link to={routes.login}>Sign in</Link>
            </p>
          </form>
        </div>
      </section>
    </div>
  );
};

export default SignUpPage;
