import { useEffect, useState } from "react";

export function useForm(
  initialFValues,
  validateOnChange = false,
  validate = () => {},
  configData = false,
  configData1 = false
) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (configData || configData1) {
      setValues(initialFValues);
    }
  }, [configData, configData1]);

  const handleInputChange = (e, namefordrop, check = false) => {
    var final_name;
    var final_value;

    if (e.target) {
      const { name, value, files, checked } = e?.target;
      final_name = name;
      if (
        name === "phone" ||
        name === "abn" ||
        name === "bsb" ||
        name === "account_number" ||
        name === "phone_number" ||
        name === "price_per_hour" ||
        // name === "number_of_hour" ||
        name === "budget" ||
        name === "num_of_hours"
      ) {
        if (!new RegExp(/^[0-9]*$/).test(value)) return;
      }

      if (name === "number_of_hour") {
        if (!new RegExp(/^([1-9][0-9]*(\.?[5,0]{1})?)?$/).test(value)) return;
      }
      // if (name === "number_of_hour") {
      //   if (!new RegExp(/^([1-9][0-9])*(\.?[5,0]{1})?$/).test(value)) return;
      // }

      if (files?.length > 0 && !check) {
        final_value = files[0];
        setValues({
          ...values,
          [name]: files[0],
          [`${name}_preview`]: URL.createObjectURL(files[0]),
        });
      } else if (!files && !check) {
        final_value = value;
        setValues({
          ...values,
          [name]: value,
        });
      } else if (check) {
        // final_name = name;
        // final_value = checked;
        // setValues({
        //   ...values,
        //   [name]: checked,
        // });
        if (checked) {
          setValues({
            ...values,
            [name]: [...values[name], value],
          });
          setErrors({
            ...errors,
            [name]: "",
          });
        } else {
          setValues({
            ...values,
            [name]: values[name].filter((item) => item !== value),
          });
        }
      }
    } else {
      final_name = namefordrop;
      final_value = e;
      setValues({
        ...values,
        [namefordrop]: e,
      });
    }

    if (validateOnChange) {
      !check && validate({ [final_name]: final_value });
    }
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
  };
}
