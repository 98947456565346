import React from "react";
import { useDispatch } from "react-redux";
import { askQuestionForJob } from "../../store/slice/postSlice";

const AskQuestionModal = ({
  values,
  handleInputChange,
  errors,
  hideQuestionModal,
  setValues,
  setErrors,
}) => {
  const dispatch = useDispatch();

  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    if ("question" in fieldValues) {
      temp.question =
        fieldValues.question && fieldValues.question.trim()
          ? ""
          : "Please enter something!";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const questionHandler = () => {
    if (validate()) {
      const data = {
        job_id: values.findJobModal,
        message: values.question,
        owner_id: values.ownerId,
      };
      dispatch(
        askQuestionForJob({ data, business_user: "1", crew_member: "0" })
      );
      setValues({ ...values, askQuestionModal: false, question: "" });
    }
  };

  return (
    <div className="zu_common_modal zu_common_center_modal">
      <div className="zu_common_modal_content">
        <h2 className="zu_common_modal_heading">Ask a Question</h2>
        <div className="zu_common_modal_text_input">
          <textarea
            placeholder="Add your question here"
            className="zu_common_modal_textarea"
            name="question"
            id="question"
            value={values.question}
            onChange={handleInputChange}
          ></textarea>
          {errors?.question ? (
            <span className="err_text">{errors?.question}</span>
          ) : null}
        </div>
        <div className="zu_modal_common_btn_row">
          <button
            onClick={() => hideQuestionModal()}
            type="button"
            className="zu_common_btn zu_btn_gray zu_send_msg_modal_cancel_btn"
          >
            CLOSE
          </button>
          <button
            onClick={() => questionHandler()}
            type="button"
            className="zu_common_btn zu_btn_light_purple_color "
          >
            SEND
          </button>
        </div>
      </div>
    </div>
  );
};

export default AskQuestionModal;
