import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
// import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

// POST NEW JOB
export const postNewJob = createAsyncThunk(
  "postNewJob",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/job/post-job",
        data.data,
        authHeaders()
      );
      if (response.status === 200 || response.status === 201) {
        toast.success("Great! Job has been Posted", {
          autoClose: 3000,
        });
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

// UPDATE JOB
export const updateJob = createAsyncThunk(
  "updateJob",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/job/update-job/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Job details have been updated successfully", {
          autoClose: 3000,
        });
      }
      if (data.cancelJob) {
        thunkAPI.dispatch(getjobListbyUser({ confirm: "my_jobs" }));
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

// GET JOB LIST BY USER
export const getjobListbyUser = createAsyncThunk(
  "getjobListbyUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/job/get-job-list?start=${data.start || 0}&limit=${
          data.limit || 10
        }&confirm=${data.confirm || ""}&order_by=${
          data.order_by || ""
        }&job_status=${data.job_status || ""}&location=${
          data.location || ""
        }&job_type=${data.job_type || ""}&km=${data.km || 100}&admin=${
          data.admin || "0"
        }&search_key=${data.search_key || ""}`,
        authHeaders()
      );
      if (data.payPending === "1") {
        return {
          data: response.data,
          payPendingJobs: true,
        };
      } else {
        return {
          data: response.data,
          totalCount: response.headers["x-total-count"],
          totalCrewPayment: response.headers["x-total-payment"],
          payPendingJobs: false,
        };
      }
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

export const clearJobListByUser = createAsyncThunk("clearJobListByUser", () => {
  return;
});

// GET JOB LIST BY USER FOR DASHBOARD CONFIRMED JOBS
export const getJobsConfirmedBusiness = createAsyncThunk(
  "getJobsConfirmedBusiness",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/job/get-job-list?start=${data.start || 0}&limit=${
          data.limit || 10
        }&confirm=${data.confirm || ""}&order_by=&job_status=${
          data.job_status || ""
        }&location=${data.location || ""}&job_type=${data.job_type || ""}&km=${
          data.km || 100
        }&admin=${data.admin || "0"}&search_key=${data.search_key || ""}`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
        totalCrewPayment: response.headers["x-total-payment"],
      };
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// GET ALL JOB
export const getAllJob = createAsyncThunk(
  "getAllJob",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/job/get-all-job?start=${data.start || 0}&limit=${
          data.limit || 10
        }&order_by=&job_status=&location=${data.location || ""}&job_type=${
          data.job_type || ""
        }&job_worker_roles=${data.job_worker_roles || "ALL"}&km=${
          data.km || 100
        }`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
        totalCrewPayment: response.headers["x-total-payment"],
      };
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// GET JOB DETAILS
export const getJobDetail = createAsyncThunk(
  "getJobDetail",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/job/get-job-detail?job_id=${data.id}&confirm=${data.confirm || ""}`,
        authHeaders()
      );
      if (response.status === 200) {
        if (data.cb) {
          return data.cb(null, response.data);
        }
        return response.data;
      }
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// CLEAR JOB DETAILS
export const clearJobDetail = createAsyncThunk("clearJobDetail", async () => {
  return;
});

// APPLY FOR JOB
export const applyForJob = createAsyncThunk(
  "applyForJob",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/job/apply-for-job",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        // await thunkAPI.dispatch(getAllJob({}));
        toast.success("Application Sent - Good luck!", {
          autoClose: 3000,
        });
        data.navigate(`${routes.myJobs}/page/1`);
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

// ASK A QUESTION FOR JOB
export const askQuestionForJob = createAsyncThunk(
  "askQuestionForJob",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        // `/job/send-message?business_user=${
        //   data?.owner_id ? "1" : "0"
        // }&crew_member=${data?.owner_id ? "0" : "1"}`,

        `/job/send-message?business_user=${data?.business_user}&crew_member=${data?.crew_member}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Message has been sent successfully", {
          autoClose: 3000,
        });
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

// GET APPLICANT LIST
export const getApplicantList = createAsyncThunk(
  "getApplicantList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/job/job-applicant-list?start=0&limit=10000&job_id=${data.id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// SEND OFFER TO APPLICANT
export const sendOfferToApplicant = createAsyncThunk(
  "sendOfferToApplicant",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/job/send-offer-to-applicant",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Offer send successfully!", {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

//  Accept-Decline offer
export const acceptDeclineOffer = createAsyncThunk(
  "acceptDeclineOffer",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/job/accept-decline-offer",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        if (data.data.accepted === true) {
          toast.success("This offer has been accepted", {
            autoClose: 3000,
          });
        } else {
          toast.success("This offer has been declined", {
            autoClose: 3000,
          });
        }
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

// approve job status
export const approveJobStatus = createAsyncThunk(
  "approveChangeApplicantJobStatus",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/job/approve-change-applicant-job-status/${data.selectedJobId}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        thunkAPI.dispatch(
          getjobListbyUser({ admin: "1", job_status: data.job_status })
        );
        toast.success("Job has been approved!", {
          autoClose: 3000,
        });

        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

// DELETE APPLICANT FROM(APPLIED JOB)
export const cancelJobByApplicant = createAsyncThunk(
  "cancelJobByApplicant",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.delete(
        `/job/cancel-job-by-applicant?job_id=${data.id}`,
        authHeaders()
      );
      if (response.status === 200) {
        thunkAPI.dispatch(
          getjobListbyUser({ confirm: "my_jobs", job_status: "ALL" })
        );
        toast.success("This job has now been cancelled", {
          autoClose: 3000,
        });
        return response.data;
      }
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// SEND OFFER TO APPLICANT
export const jobReview = createAsyncThunk(
  "jobReview",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/job/add-review",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Your review has been posted", {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

// FINALIZE PAYMENT OF JOB
export const finalizePaymentOfJob = createAsyncThunk(
  "finalizePaymentOfJob",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/job/mark-as-done/${data.id}`,
        null,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Payment has been finalised successfully!", {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

// resend job alert
export const resendJobAlert = createAsyncThunk(
  "resendJobAlert",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/job/resend-job-alert/${data.id}`,
        null,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Job Alert Mail has been resent!", {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// GET JOB LIST BY USER
export const getjobListForBrief = createAsyncThunk(
  "getjobListForBrief",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/job/get-job-list?start=0&limit=1000000&km=100&admin=0&job_status=BRIEF&order_by=DESC`,
        authHeaders()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

const postSlice = createSlice({
  name: "post",
  initialState: {
    jobDetail: null,
    applyForJob: null,
    askQuestionData: null,
    getJobsConfirmedBusiness: null,
    error: null,
    loader: false,
    jobLoader: false,
    totalCrewPayment: "",
    totalCount: "",
    jobListForBrief: [],
  },
  reducers: {},

  // postNewJob
  extraReducers: (builder) => {
    builder.addCase(postNewJob.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(postNewJob.fulfilled, (state, action) => {
      state.loader = false;
      state.postNewJob = action.payload;
    });
    builder.addCase(postNewJob.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // updateJob
    builder.addCase(updateJob.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateJob.fulfilled, (state, action) => {
      state.loader = false;
      state.updateJob = action.payload;
    });
    builder.addCase(updateJob.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // clearJobListByUser

    builder.addCase(clearJobListByUser.fulfilled, (state, action) => {
      state.getjobListbyUser = [];
    });

    // getjobListbyUser
    builder.addCase(getjobListbyUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getjobListbyUser.fulfilled, (state, action) => {
      state.loader = false;
      if (action.payload?.payPendingJobs) {
        state.payPendingJobs = action.payload.data;
        state.totalCrewPayment = action.payload.totalCrewPayment;
      } else {
        state.getjobListbyUser = action.payload?.data;
        state.totalCrewPayment = action.payload?.totalCrewPayment;
        state.totalCount = action.payload?.totalCount;
      }
    });
    builder.addCase(getjobListbyUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getjobListbyUserConfirmed
    builder.addCase(getJobsConfirmedBusiness.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getJobsConfirmedBusiness.fulfilled, (state, action) => {
      state.loader = false;
      state.getJobsConfirmedBusiness = action.payload.data;
      state.totalCrewPayment = action.payload.totalCrewPayment;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getJobsConfirmedBusiness.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getAllJob
    builder.addCase(getAllJob.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getAllJob.fulfilled, (state, action) => {
      state.loader = false;
      state.getAllJob = action.payload;
    });
    builder.addCase(getAllJob.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getJobDetail
    builder.addCase(getJobDetail.pending, (state) => {
      state.jobLoader = true;
    });
    builder.addCase(getJobDetail.fulfilled, (state, action) => {
      state.jobLoader = false;
      state.jobDetail = action.payload;
    });
    builder.addCase(getJobDetail.rejected, (state, action) => {
      state.jobLoader = false;
      state.error = action.payload;
    });

    // applyForJob
    builder.addCase(applyForJob.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(applyForJob.fulfilled, (state, action) => {
      state.loader = false;
      state.applyForJob = action.payload;
    });
    builder.addCase(applyForJob.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // askQuestionForJob
    builder.addCase(askQuestionForJob.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(askQuestionForJob.fulfilled, (state, action) => {
      state.loader = false;
      state.askQuestionData = action.payload;
    });
    builder.addCase(askQuestionForJob.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getApplicantList
    builder.addCase(getApplicantList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getApplicantList.fulfilled, (state, action) => {
      state.loader = false;
      state.getApplicantList = action.payload;
    });
    builder.addCase(getApplicantList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // sendOfferToApplicant
    builder.addCase(sendOfferToApplicant.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(sendOfferToApplicant.fulfilled, (state, action) => {
      state.loader = false;
      state.sendOfferToApplicant = action.payload;
    });
    builder.addCase(sendOfferToApplicant.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // acceptDeclineOffer
    builder.addCase(acceptDeclineOffer.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(acceptDeclineOffer.fulfilled, (state, action) => {
      state.loader = false;
      state.acceptDeclineOffer = action.payload;
    });
    builder.addCase(acceptDeclineOffer.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // job approval status
    builder.addCase(approveJobStatus.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(approveJobStatus.fulfilled, (state, action) => {
      state.loader = false;
      state.acceptDeclineOffer = action.payload;
    });
    builder.addCase(approveJobStatus.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // cancel applicant
    builder.addCase(cancelJobByApplicant.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(cancelJobByApplicant.fulfilled, (state, action) => {
      state.loader = false;
      state.cancelJobByApplicant = action.payload;
    });
    builder.addCase(cancelJobByApplicant.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // jobReview
    builder.addCase(jobReview.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(jobReview.fulfilled, (state, action) => {
      state.loader = false;
      state.jobReview = action.payload;
    });
    builder.addCase(jobReview.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // finalizePaymentOfJob
    builder.addCase(finalizePaymentOfJob.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(finalizePaymentOfJob.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(finalizePaymentOfJob.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // resendJobAlert
    builder.addCase(resendJobAlert.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(resendJobAlert.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(resendJobAlert.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getjobListForBrief
    builder.addCase(getjobListForBrief.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getjobListForBrief.fulfilled, (state, action) => {
      state.jobListForBrief = action.payload.data;
      state.loader = false;
    });
    builder.addCase(getjobListForBrief.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // clear job detail
    builder.addCase(clearJobDetail.fulfilled, (state, action) => {
      // state.jobLoader = false;
      state.jobDetail = null;
    });
  },
});

export default postSlice.reducer;
