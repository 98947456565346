import React, { useEffect } from "react";
import AdminSidebar from "../../component/Layout/AdminSidebar";
import ImageTextContent from "../../component/Layout/ImageTextContent";
import findJobsGraphicImage from "../../images/FindJobsGraphicImage.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getjobListbyUser } from "../../store/slice/postSlice";
import {
  getFinancialReportList,
  getLeadReportList,
  getUsersApprovalList,
} from "../../store/slice/userSlice";
import CSVDownloadButton from "../../component/Common/CSVDownloadButton";

const AdminDashboardPage = () => {
  const dispatch = useDispatch();
  const jobList = useSelector((state) => state?.post?.getjobListbyUser) || [];
  const totalCrewPayment = useSelector((state) => state.post.totalCrewPayment);
  const totalCount = useSelector((state) => state.user?.totalCount) || "";
  const allUsers = useSelector((state) => state.user.allUsers) || [];
  const allFinancialDetail = useSelector(
    (state) => state.user.getFinancialReportList
  );
  const allLeadDetail = useSelector((state) => state.user.getLeadReportList);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  let payment =
    totalCrewPayment && numberWithCommas((+totalCrewPayment)?.toFixed(2));
  let users = totalCount && numberWithCommas(+totalCount);

  useEffect(() => {
    dispatch(
      getjobListbyUser({
        admin: "1",
        limit: "100000",
        start: 0,
      })
    );
    dispatch(
      getUsersApprovalList({
        start: 0,
        limit: 100000,
        waitingForApproval: 0,
        search_key: "",
      })
    );
    dispatch(getFinancialReportList());
    dispatch(getLeadReportList());
  }, [dispatch]);

  return (
    <>
      <AdminSidebar />
      <main className="zu_all_page_main_content">
        <div className="zu_admin_dashboard_page">
          <section className="zu_admin_dashboard_job_section">
            <div className="zu_admin_dashboard_job_content">
              <ImageTextContent
                imageSrc={findJobsGraphicImage}
                heading="Dashboard"
              />
              <div className="zu_dashboard_job_content_row">
                <div className="zu_dashboard_crew_payment_column zu_admin_dashboard_crew_payment_column">
                  <h2>Crew Payments</h2>
                  <span>Amount of jobs posted since 2019</span>
                  <p>{`$${totalCrewPayment && payment}`}</p>
                  <div className="zu_dashboard_job_content_column">
                    <div className="zu_dashboard_job_content_column_text">
                      <h2>JOB REPORT</h2>
                      <p>CSV of all jobs ever posted</p>
                    </div>
                  </div>
                  <CSVDownloadButton data={jobList} fileName="Job-Report.csv" />
                </div>
                <div className="zu_dashboard_crew_payment_column zu_admin_dashboard_crew_payment_column">
                  <h2>Find Crew</h2>
                  <span>The number of crew in our network</span>
                  <p>{`${users} Members`}</p>
                  <div className="zu_dashboard_job_content_column">
                    <div className="zu_dashboard_job_content_column_text">
                      <h2>USER REPORT</h2>
                      <p>CSV of all user details</p>
                    </div>
                  </div>
                  <CSVDownloadButton data={allUsers} fileName="User-Report" />
                </div>
                <div className="zu_dashboard_crew_payment_column zu_admin_dashboard_crew_payment_column">
                  <h2>Financial Details</h2>
                  <div className="zu_dashboard_job_content_column">
                    <div className="zu_dashboard_job_content_column_text">
                      <h2>FINANCIAL REPORT</h2>
                      <p>CSV of all financial details</p>
                    </div>
                  </div>
                  <CSVDownloadButton
                    data={allFinancialDetail}
                    fileName="Financial-Report"
                  />
                </div>
                <div className="zu_dashboard_crew_payment_column zu_admin_dashboard_crew_payment_column">
                  <h2>Lead Purchase Report</h2>
                  <div className="zu_dashboard_job_content_column">
                    <div className="zu_dashboard_job_content_column_text">
                      <h2>LEAD REPORT</h2>
                      <p>CSV of all Leads Purchased</p>
                    </div>
                  </div>
                  <CSVDownloadButton
                    data={allLeadDetail}
                    fileName="Lead-Report"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default AdminDashboardPage;
