import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/userSlice";
import postSlice from "./slice/postSlice";
import leadsSlice from "./slice/leadsSlice";
import authSlice from "./slice/authSlice";
import chatSlice from "./slice/chatSlice";

const store = configureStore({
  reducer: {
    post: postSlice,
    user: userReducer,
    leads: leadsSlice,
    chat: chatSlice,
    auth: authSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
