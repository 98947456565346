import React from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import Loader from "./Loader";
import { useState } from "react";
import Input from "./Input";

const AutoComplete = ({
  values,
  setValues,
  id,
  name,
  handleInputChange,
  errors,
  label,
  className,
  placeholder,
}) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const googleAPIKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const [libraries] = useState(["places"]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleAPIKey,
    libraries,
  });
  if (!isLoaded) {
    return <Loader />;
  }

  const onLoad = (autocomplete) => {
    const restrictedCountry =
      process.env.REACT_APP_RESTRICTED_COUNTRY.split(",");

    autocomplete.setComponentRestrictions({
      country: restrictedCountry,
    });
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      setValues({
        ...values,
        [name]: autocomplete.getPlace().formatted_address,
      });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      className="zu_common_filter_input_box"
    >
      <Input
        className={className ? className : "zu_common_filter_input_box"}
        labelClassName="zu_common_form_input_label"
        inputClassName="zu_common_form_input"
        errorClassName="err_text"
        label={label}
        placeholder={placeholder}
        type="text"
        id={id}
        name={name}
        value={values.location === "" ? null : values[name]}
        onChange={handleInputChange}
        error={errors ? errors[name] : null}
      />
    </Autocomplete>
  );
};

export default AutoComplete;
