import React from "react";

const OfferSentModal = ({ jobOfferConfirmCloseHandler }) => {
  return (
    <div className="zu_common_modal zu_common_center_modal zu_common_heading_paragraph_modal zu_offer_sent_modal">
      <div className="zu_common_modal_content">
        <div className="zu_common_heading_paragraph_modal_text">
          <h2 className="zu_common_modal_heading">OFFER SENT</h2>
          <p className="zu_common_modal_paragraph">
            Your chosen crew member now has 48 hours to either accept or decline
            this offer. You will be notified via email of the outcome.
          </p>
        </div>
        <div className="zu_modal_common_btn_row">
          <button
            type="button"
            className="zu_common_btn zu_btn_gray"
            onClick={jobOfferConfirmCloseHandler}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferSentModal;
