import React, { useEffect, useState } from "react";
import {
  ActionBtn,
  manageApproveUsersTableColums,
  manageUsersTableColums,
  manageUsersTableStyles,
  TableIdText,
  TableMailText,
} from "../../constants";
import CommonHeading from "../../component/Common/CommonHeading";
import ImageTextContent from "../../component/Layout/ImageTextContent";
import manageUsersGraphicImage from "../../images/ManageUsersGraphicImage.jpg";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  adminUpdateUser,
  clearUserListForAdmin,
  clearUserProfile,
  getUsersApprovalList,
} from "../../store/slice/userSlice";
import Pagination from "../../component/Pagination/Pagination";
import { useNavigate, useParams } from "react-router-dom";

import { Form, Modal } from "react-bootstrap";
import Loader from "../../component/Common/Loader";

const AdminManageUsersPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { page_number } = params;

  const userApprovalList = useSelector((state) => state.user.userApprovalList);
  const allUsers = useSelector((state) => state.user.allUsers);
  const loading = useSelector((state) => state.user.loader);

  let manageUsersApprovalTableColumsData = [];
  let manageUsersTableColumsData = [];
  const [approveCrewModal, setApproveCrewModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [userApprove, setUserApprove] = useState("");
  const [userDeleteStatus, setUserDeleteStatus] = useState("0");
  const [searchData, setSearchData] = useState("");

  const totalCount = useSelector((state) => state.user?.totalCount);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearUserProfile());
    dispatch(
      getUsersApprovalList({
        start: 0,
        limit: 10,
        waitingForApproval: 0,
        search_key: "",
        delete_status: userDeleteStatus,
      })
    );
    dispatch(
      getUsersApprovalList({
        start: 0,
        limit: 10,
        waitingForApproval: 1,
        search_key: "",
      })
    );
  }, [userDeleteStatus]);

  useEffect(() => {
    navigate(`/adminmanageusers/page/1`);
    const timer = setTimeout(() => {
      if (+page_number === 1) {
        dispatch(
          getUsersApprovalList({
            start: 0,
            limit: 10,
            waitingForApproval: 0,
            search_key: searchData,
            delete_status: userDeleteStatus,
          })
        );
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchData]);

  const onBlockingUser = (user) => {
    let data = { blocked: user.blocked == "0" ? "1" : "0", user_id: user.id };
    dispatch(adminUpdateUser({ data: data }))
      .then(() => {
        dispatch(
          getUsersApprovalList({
            start: 0,
            limit: 10,
            waitingForApproval: 0,
            search_key: "",
            delete_status: userDeleteStatus,
          })
        );
      })
      .then(() => navigate("/adminmanageusers/page/1"));
  };

  const CrewImageText = ({ user }) => {
    return (
      <div className="zu_crew_img_text_row">
        <img
          src={
            user?.profile_photo
              ? `${process.env.REACT_APP_FILE_BASE_URL}/${user?.profile_photo}`
              : "https://my-first-test-bucket-39.s3.ap-south-1.amazonaws.com/9/e3be1129-eafa-47fb-a116-3e7590c57931.png"
          }
          alt="user img"
        />
        <span className="zu_table_text">{`${user?.first_name}  ${user?.last_name}`}</span>
        {}
      </div>
    );
  };

  const onApprovingOrRejectingUser = (user) => {
    let data =
      user.approve == 1
        ? { status: "VERIFICATION_SUCCESS", user_id: user.user.id }
        : { status: "NEW", user_id: user.user.id };
    dispatch(adminUpdateUser({ data: data }));
  };

  const onChangingSearchField = (e) => {
    setSearchData(e.target.value);
  };
  // const onChangingSearchField = async (e) => {
  //   await navigate(`/adminmanageusers/page/1`);
  //   dispatch(
  //     getUsersApprovalList({
  //       start: 0,
  //       limit: 10,
  //       waitingForApproval: 0,
  //       search_key: `${e.target.value}`,
  //       delete_status: userDeleteStatus,
  //     })
  //   );
  // };

  allUsers?.map((user) => {
    manageUsersTableColumsData = [
      ...manageUsersTableColumsData,
      {
        id: user.id,
        userId: <TableIdText text={user.id} />,
        crewImgText: <CrewImageText user={user} />,
        mail: <TableMailText text={user.email} />,

        actionBtn:
          user.blocked === "0" ? (
            <ActionBtn
              userdeleteStatus={user.delete_status}
              buttonText="Block"
              buttonColor="zu_danger_btn2"
              onButtonClick={async (e) => {
                await setBlockModal(true);
                await setSelectedUser(user);
              }}
              onViewButtonClick={() => {
                dispatch(clearUserProfile());
                navigate(`/adminmanageusersapplicant/${user.id}`);
              }}
            />
          ) : (
            <ActionBtn
              buttonText="Unblock"
              buttonColor="zu_action_warning_btn2"
              onButtonClick={() => {
                setBlockModal(true);
                setSelectedUser(user);
              }}
              onViewButtonClick={() => {
                dispatch(clearUserProfile());
                navigate(`/adminmanageusersapplicant/${user.id}`);
              }}
            />
          ),
      },
    ];
  });

  userApprovalList &&
    userApprovalList?.map((user) => {
      manageUsersApprovalTableColumsData = [
        ...manageUsersApprovalTableColumsData,
        {
          id: <TableIdText text={user.id} />,
          crewImgText: <CrewImageText user={user} />,
          mail: <TableMailText text={user.email} />,
          actionBtn: (
            <>
              <ActionBtn
                buttonText="APPROVE"
                buttonColor="zu_btn_light_green"
                onButtonClick={() => {
                  setUserApprove(1);
                  setSelectedUser(user);
                  setApproveCrewModal(true);
                }}
                optionalButton={true}
                onAnotherButtonClick={() => {
                  setUserApprove(0);
                  setSelectedUser(user);
                  setApproveCrewModal(true);
                }}
                onViewButtonClick={() => {
                  dispatch(clearUserProfile());
                  navigate(`/adminmanageusersapplicant/${user.id}`);
                }}
                anotherButtonColor="zu_danger_btn"
              />
            </>
          ),
        },
      ];
    });

  const onPageChange = (page_number) => {
    dispatch(
      getUsersApprovalList({
        start: page_number,
        limit: 10,
        waitingForApproval: 0,
        search_key: searchData,
        delete_status: userDeleteStatus,
      })
    );
  };

  const handleUserFilterChange = (e) => {
    setUserDeleteStatus(e.target.value);
  };

  return (
    <>
      {loading && <Loader />}
      <main className="zu_all_page_main_content">
        <div className="zu_admin_manage_user_page">
          <section className="zu_admin_manage_user_banner_section">
            <div className="zu_admin_manage_user_banner_content">
              <ImageTextContent
                imageSrc={manageUsersGraphicImage}
                heading="Manage Users"
              />
            </div>
          </section>
          <section className="zu_admin_manage_user_table_section">
            <div className="d-lg-none zu_common_job_heading">
              <CommonHeading heading="Manage Users" />
            </div>
            <div className="zu_admin_manage_user_table_content">
              <div className="zu_admin_manage_user_table_row">
                <div className="zu_admin_manage_user_table_column">
                  <div className="zu_admin_manage_user_table_heading_row">
                    <h2 className="zu_admin_manage_user_table_heading">
                      New users for Approval
                    </h2>
                  </div>
                  <div className="zu_common_data_table zu_admin_manage_users_table zu_admin_new_users_table">
                    <DataTable
                      columns={manageApproveUsersTableColums}
                      data={manageUsersApprovalTableColumsData}
                      customStyles={manageUsersTableStyles}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div className="zu_admin_manage_user_table_column">
                  <div className="zu_admin_manage_user_table_heading_row">
                    <h2 className="zu_admin_manage_user_table_heading">
                      Search / Manage Users
                    </h2>
                    <div className="zu_common_filter_input_box zu_common_input_search_icon zu_user_filter">
                      <div className="zu_common_select_box">
                        <Form.Select
                          onChange={handleUserFilterChange}
                          id="user_status"
                          name="job_status"
                        >
                          <option value="0">Active Users</option>
                          <option value="1">Deleted Users</option>
                        </Form.Select>
                      </div>
                    </div>
                    <div className="zu_common_filter_input_box zu_common_input_search_icon">
                      <input
                        type="text"
                        placeholder="Search by Name or Email"
                        onChange={onChangingSearchField}
                      />
                    </div>
                  </div>
                  <div className="zu_common_data_table zu_admin_manage_users_table">
                    <DataTable
                      columns={manageUsersTableColums}
                      data={manageUsersTableColumsData}
                      customStyles={manageUsersTableStyles}
                    />
                    <Pagination
                      totalRecords={totalCount}
                      onPageChange={onPageChange}
                    />
                  </div>
                </div>

                <Modal
                  show={blockModal}
                  onHide={() => setBlockModal(false)}
                  centered
                  className="zu_purchase_lead_msg_modal"
                >
                  <div className="zu_common_modal zu_common_center_modal">
                    <div className="zu_common_modal_content">
                      <div className="zu_common_modal_heading_paragraph">
                        <h2 className="zu_common_modal_heading">
                          Block User Confirmation
                        </h2>
                        <p className="zu_common_modal_paragraph">
                          Are you sure you want to{" "}
                          {selectedUser?.blocked == "0" ? "block" : "unblock"}{" "}
                          this user?
                        </p>
                      </div>

                      <div className="zu_modal_common_btn_row">
                        <button
                          type="button"
                          className="zu_common_btn zu_btn_gray zu_send_msg_modal_cancel_btn"
                          onClick={() => {
                            setBlockModal(false);
                          }}
                        >
                          CANCEL
                        </button>
                        <button
                          type="button"
                          className="zu_common_btn zu_btn_light_green zu_send_msg_modal_approve_btn"
                          onClick={async () => {
                            await onBlockingUser(selectedUser);
                            setBlockModal(false);
                          }}
                        >
                          {selectedUser?.blocked == "0" ? "BLOCK" : "UNBLOCK"}
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>

                <Modal
                  show={approveCrewModal}
                  onHide={() => setApproveCrewModal(false)}
                  centered
                  className="zu_purchase_lead_msg_modal"
                >
                  <div className="zu_common_modal zu_common_center_modal">
                    <div className="zu_common_modal_content">
                      <div className="zu_common_modal_heading_paragraph">
                        <h2 className="zu_common_modal_heading">
                          User Approval
                        </h2>
                        <p className="zu_common_modal_paragraph">
                          Are you sure you want to{" "}
                          {userApprove === 0 ? "reject" : "approve"} this user?
                        </p>
                      </div>

                      <div className="zu_modal_common_btn_row">
                        <button
                          type="button"
                          className="zu_common_btn zu_btn_gray zu_send_msg_modal_cancel_btn"
                          onClick={() => {
                            setApproveCrewModal(false);
                          }}
                        >
                          CANCEL
                        </button>
                        <button
                          type="button"
                          className={`zu_common_btn ${
                            userApprove === 0
                              ? "zu_btn_pending_red"
                              : "zu_btn_light_green"
                          } zu_send_msg_modal_approve_btn`}
                          onClick={async () => {
                            await onApprovingOrRejectingUser({
                              approve: userApprove == 1 ? 1 : 0,
                              user: selectedUser,
                            });
                            setApproveCrewModal(false);
                          }}
                        >
                          {userApprove === 0 ? "REJECT" : "APPROVE"}
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default AdminManageUsersPage;
