import React from "react";
import {
  imageRemoveIcon,
  ratingFillStarIcon,
  ratingStarIcon,
} from "../../icons";
import image from "../../images/default-user.jpg";
import "../../css/component.css";
import { useDispatch, useSelector } from "react-redux";
import { userCardList } from "../../store/slice/userSlice";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  askQuestionForJob,
  sendOfferToApplicant,
} from "../../store/slice/postSlice";
import Loader from "../Common/Loader";
import { editIcon, plusIcon } from "../../icons";
import SendOfferModal from "./SendOfferModal";
import {
  capitalizeFirstLetter,
  webaddressValidation,
} from "../../helpers/commonFunction";
import SendMessageModal from "./SendMessageModal";
import { useForm } from "../../hooks/useForm";
import OfferSentModal from "./OfferSentModal";
import { useRef } from "react";

const ProfileUserDetaile = (props) => {
  const dispatch = useDispatch();
  const {
    profile,
    applicantDetail,
    jobD,
    profileCloseHandler,
    handleImage,
    profile_photo,
    filePreview,
    errorsPro,
  } = props;
  const navigate = useNavigate();
  const ref = useRef();
  const user = useSelector((state) => state.user.user);
  const loggedUserRole = user?.role;

  const loading = useSelector((state) => state.post.loader);
  const userLoading = useSelector((state) => state.user.loader);

  const initialFValues = {
    show: false,
    sendOfferModal: false,
    offerSendConfirmModal: false,
    cardData: [],
    creditCardId: "",
    creditCardIdErr: "",
    policy: false,
    policyErr: "",
    sendMessageModal: false,
    messageContent: "",
    errorMessage: "",
    cardNumber: "",
    cardNumberErr: "",
    expiry: "",
    expiryErr: "",
    cvc: "",
    cvcErr: "",
    cardDataErr: "",
    cardBlinkClass: false,
    cardBlinkClassTwo: false,
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const applicantBio =
    applicantDetail?.public_bio &&
    JSON.parse(applicantDetail?.public_bio)?.text;

  const userBio = profile?.public_bio && JSON.parse(profile?.public_bio)?.text;

  const sendOfferHandler = (e) => {
    e.preventDefault();
    dispatch(
      userCardList({
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setValues({
              ...values,
              sendOfferModal: true,
              cardData: res,
              creditCardId: "",
            });
          }
        },
      })
    );
  };

  const jobOfferCloseHandler = () => {
    setValues({
      ...values,
      sendOfferModal: false,
      offerSendConfirmModal: false,
      policy: false,
      creditCardId: "",
      creditCardIdErr: "",
      policyErr: "",
      cardBlinkClass: false,
      cardBlinkClassTwo: false,
      cardNumber: "",
      cardNumberErr: "",
      expiry: "",
      expiryErr: "",
      cvc: "",
      cvcErr: "",
      cardDataErr: "",
    });
  };

  const jobOfferConfirmCloseHandler = () => {
    setValues({
      ...values,
      sendOfferModal: false,
      offerSendConfirmModal: false,
    });
    navigate(-1);
  };

  const validate = () => {
    let valid = false;
    if (!values.creditCardId) {
      setValues({
        ...values,
        creditCardIdErr: "You have to select atleast one card!",
      });
    } else if (!values.policy) {
      setValues({
        ...values,
        policyErr: "Please accept the Privacy Policy and Terms of Service!",
      });
    } else {
      valid = true;
    }
    return valid;
  };

  const jobOfferSendHandler = () => {
    if (values.cardData.length === 0) {
      if (ref.current === true) {
        setValues({
          ...values,
          cardBlinkClass: !values.cardBlinkClass,
          cardBlinkClassTwo: !values.cardBlinkClassTwo,
        });
      } else {
        setValues({
          ...values,
          cardBlinkClassTwo: true,
        });
      }
      ref.current = true;
    } else if (validate()) {
      const data = {
        job_id: jobD.jobId,
        applicant_id: jobD.id,
        customer_card_id: values.creditCardId,
        capture: false,
      };

      dispatch(
        sendOfferToApplicant({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              setValues({
                ...values,
                sendOfferModal: false,
                offerSendConfirmModal: true,
                policy: false,
                cardNumber: "",
                cardNumberErr: "",
                expiry: "",
                expiryErr: "",
                cvc: "",
                cvcErr: "",
                cardDataErr: "",
                creditCardId: "",
              });
            }
          },
        })
      );
    }
  };

  const sendMessageOpenHandler = () => {
    setValues({
      ...values,
      sendMessageModal: true,
    });
  };

  const sendMessageCloseHandler = () => {
    setValues({
      ...values,
      sendMessageModal: false,
      errorMessage: "",
      messageContent: "",
    });
  };

  let ratingStars = Array.from(
    {
      length: applicantDetail
        ? applicantDetail?.avg_ratings?.toFixed(1)
        : profile?.avg_ratings?.toFixed(1),
    },
    (_, i) => i + 1
  );

  let emptyRatingStars = Array.from(
    { length: 5 - ratingStars?.length },
    (_, i) => i + 1
  );

  return (
    <>
      {(loading || userLoading) && <Loader />}
      <section className="zu_profile_user_detail_section">
        <div
          className={`zu_profile_user_detail_content ${
            props.hasMessage && "zu_profile_user_detail_message"
          }`}
        >
          <div className="zu_profile_user_detail_row">
            <div className="zu_profile_user_detail_photo_container">
              <div className="zu_profile_user_detail_photo">
                <>
                  {applicantDetail ? (
                    <img
                      src={
                        filePreview
                          ? filePreview
                          : applicantDetail?.profile_photo
                          ? `${process.env.REACT_APP_FILE_BASE_URL}/${applicantDetail?.profile_photo}`
                          : image
                      }
                      alt="shootzu user profile"
                    />
                  ) : (
                    <img
                      src={
                        filePreview
                          ? filePreview
                          : profile?.profile_photo
                          ? `${process.env.REACT_APP_FILE_BASE_URL}/${profile?.profile_photo}`
                          : image
                      }
                      alt="shootzu user profile"
                    />
                  )}
                  {props.hasProfileEdit && (
                    <div className="zu_open_image_picker">
                      <label htmlFor="profile_photo">
                        {profile_photo ? editIcon : plusIcon}
                        <input
                          type="file"
                          accept="image/*"
                          id="profile_photo"
                          className="d-none"
                          name="profile_photo"
                          onClick={(e) => (e.target.value = null)}
                          onChange={handleImage}
                        />
                      </label>
                      {profile_photo && (
                        <label onClick={profileCloseHandler}>
                          {imageRemoveIcon}
                        </label>
                      )}
                    </div>
                  )}
                </>
              </div>
              {errorsPro?.profile_photo && (
                <span className="err_text">{errorsPro?.profile_photo}</span>
              )}
            </div>
            <div className="zu_profile_user_detail_text">
              <div className="zu_profile_user_detail_text_row">
                <div className="zu_profile_user_detail_text_content">
                  <span className="zu_profile_user_detail_sub_heading">
                    {applicantDetail
                      ? applicantDetail?.business_name
                      : profile?.business_name}
                  </span>
                  <h2 className="zu_profile_user_detail_heading">
                    {applicantDetail
                      ? capitalizeFirstLetter(applicantDetail?.first_name) +
                        " " +
                        capitalizeFirstLetter(applicantDetail?.last_name)
                      : capitalizeFirstLetter(profile?.first_name || "") +
                        " " +
                        capitalizeFirstLetter(profile?.last_name || "")}
                  </h2>
                  <div className="zu_profile_user_detail_rating_row">
                    {ratingStars?.map((i, index) => ratingFillStarIcon)}
                    {emptyRatingStars?.map((i, index) => ratingStarIcon)}
                  </div>
                  <p className="zu_profile_user_detail_rating_text">
                    {applicantDetail
                      ? applicantDetail?.avg_ratings === null
                        ? "0"
                        : applicantDetail?.avg_ratings?.toFixed(1)
                      : profile?.avg_ratings === null
                      ? "0"
                      : profile?.avg_ratings?.toFixed(1)}{" "}
                    Stars
                  </p>
                  <div className="zu_website_sendOffer_btn_togther">
                    {props.hasButton &&
                      !applicantDetail &&
                      profile?.web_address && (
                        <a
                          href={webaddressValidation(profile?.web_address)}
                          target="_blank"
                          className="zu_common_btn zu_btn_theme_color"
                          rel="noreferrer"
                        >
                          {"Visit My Website"}
                        </a>
                      )}
                    {props.hasButton && applicantDetail?.web_address && (
                      <a
                        href={webaddressValidation(
                          applicantDetail?.web_address
                        )}
                        target="_blank"
                        className="zu_common_btn zu_btn_theme_color"
                        rel="noreferrer"
                      >
                        {"View Website"}
                      </a>
                    )}

                    {jobD &&
                      applicantDetail &&
                      loggedUserRole !== "ROLE_ADMIN" && (
                        <div
                          className={`d-lg-grid d-none ${
                            !applicantDetail?.web_address && "zu_send_offer_btn"
                          }`}
                        >
                          {!jobD?.offer_id && (
                            <button
                              type="button"
                              className="zu_common_btn zu_btn_light_green zu_profile_user_offer_btn "
                              onClick={(e) => sendOfferHandler(e)}
                            >
                              Send Job Offer
                            </button>
                          )}
                        </div>
                      )}
                  </div>
                  {jobD && applicantDetail && loggedUserRole !== "ROLE_ADMIN" && (
                    <div className="zu_profile_user_bottom_btn_row d-lg-none d-grid">
                      {/* <button
                        type="button"
                        className="zu_common_btn zu_profile_user_send_msg_btn"
                        onClick={(e) => sendMessageOpenHandler(e)}
                      >
                        Send Message
                      </button> */}
                      <button
                        className="zu_common_btn zu_profile_user_send_msg_btn"
                        onClick={() =>
                          navigate(`/messages`, {
                            state: {
                              job_owner_id: jobD?.jobOwnerId,
                              job_status: jobD?.jobStatus,
                              job_id: jobD?.jobId,
                              job_title: jobD?.title,
                              job_date: jobD?.date,
                              opp_user_id: applicantDetail?.id,
                              opp_first_name: applicantDetail?.first_name,
                              opp_last_name: applicantDetail?.last_name,
                              opp_profile_photo: applicantDetail?.profile_photo,
                            },
                          })
                        }
                      >
                        Send Message
                      </button>

                      {!jobD?.offer_id && (
                        <button
                          type="button"
                          className="zu_common_btn zu_btn_light_green zu_profile_user_offer_btn"
                          onClick={(e) => sendOfferHandler(e)}
                        >
                          Send Job Offer
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {props.hasAboutMe && (
                <div className="zu_profile_user_detail_about_text">
                  <span className="zu_profile_user_detail_about_text_heading">
                    {props.aboutHeading}
                  </span>

                  <div>
                    <pre
                      className="zu_public_bio_pre_content"
                      dangerouslySetInnerHTML={{
                        __html: applicantDetail ? applicantBio : userBio,
                      }}
                    />
                  </div>
                </div>
              )}
              {jobD && applicantDetail && loggedUserRole !== "ROLE_ADMIN" && (
                <div className="zu_profile_user_bottom_btn_row d-lg-grid d-none">
                  {/* <button
                    type="button"
                    className="zu_common_btn zu_profile_user_send_msg_btn"
                    onClick={(e) => sendMessageOpenHandler(e)}
                  >
                    Send Message
                  </button> */}
                  <button
                    className="zu_common_btn zu_profile_user_send_msg_btn"
                    onClick={() =>
                      navigate(`/messages`, {
                        state: {
                          job_owner_id: jobD?.jobOwnerId,
                          job_status: jobD?.jobStatus,
                          job_id: jobD?.jobId,
                          job_title: jobD?.title,
                          job_date: jobD?.date,
                          opp_user_id: applicantDetail?.id,
                          opp_first_name: applicantDetail?.first_name,
                          opp_last_name: applicantDetail?.last_name,
                          opp_profile_photo: applicantDetail?.profile_photo,
                        },
                      })
                    }
                  >
                    Send Message
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={values.sendOfferModal}
        onHide={() => jobOfferCloseHandler()}
        centered
        className="zu_send_offer_modal"
      >
        <SendOfferModal
          jobOfferCloseHandler={jobOfferCloseHandler}
          applicantDetail={applicantDetail}
          // policyHandler={policyHandler}
          jobD={jobD}
          jobOfferSendHandler={jobOfferSendHandler}
          values={values}
          setValues={setValues}
        />
      </Modal>
      <Modal
        show={values.offerSendConfirmModal}
        onHide={() => jobOfferConfirmCloseHandler()}
        centered
        className="zu_offer_sent_modal"
      >
        <OfferSentModal
          jobOfferConfirmCloseHandler={jobOfferConfirmCloseHandler}
        />
      </Modal>
      <Modal
        show={values.sendMessageModal}
        onHide={() => sendMessageCloseHandler()}
        centered
        className="zu_send_msg_modal"
      >
        <SendMessageModal
          sendMessageCloseHandler={sendMessageCloseHandler}
          applicantDetail={applicantDetail}
          messageContent={values.messageContent}
          errorMessage={values.errorMessage}
          values={values}
          setValues={setValues}
          jobDetail={jobD}
          sendMessageFlag={true}
        />
      </Modal>
    </>
  );
};

export default ProfileUserDetaile;
