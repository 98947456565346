import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../css/component.css";
import { useLocation } from "react-router-dom";
import { routes, adminSidebarLink } from "../../constants";
import { closeIcon, logo } from "../../icons";
import { clearUserListForAdmin, logout } from "../../store/slice/userSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { clearJobListByUser } from "../../store/slice/postSlice";

const AdminSidebar = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
    toast.success("See you next time", {
      autoClose: 2000,
    });
    navigate(routes.login);
  };
  return (
    <>
      <div
        className="zu_sidebar_bg_overlay"
        onClick={() => document.body.classList.remove("zu_open_sidebar")}
      ></div>
      <div className="zu_sidebar_section zu_admin_sidebar_section">
        <button
          type="button"
          className="zu_top_header_toggal_close_btn"
          onClick={() => document.body.classList.remove("zu_open_sidebar")}
        >
          {closeIcon}
        </button>
        <div className="zu_sidebar_content">
          <div className="zu_sidebar_logo">
            <Link to={routes.admindashboard}>
              {/* className={`zu_sidebar_logo ${pathname.match(/^.*dashboard.*$/) && "active"} `}> */}
              {logo}
            </Link>
          </div>
          <ul className="zu_sidebar_nav">
            {adminSidebarLink.map((i) => {
              return (
                <li className="zu_sidebar_item" key={`zu_sidebar_item${i.id}`}>
                  <button
                    type="button"
                    className={`zu_sidebar_link ${
                      pathname?.match(
                        new RegExp("^.*" + i.linkClassName + ".*$")
                      ) && "active"
                    } `}
                    onClick={() => {
                      document.body.classList.remove("zu_open_sidebar");
                      if (i.linkClassName === "adminmanageusers") {
                        dispatch(clearUserListForAdmin()).then(() =>
                          navigate(i.linkTo)
                        );
                      } else if (i.linkClassName === "adminmanagejobs") {
                        dispatch(clearJobListByUser()).then(() =>
                          navigate(i.linkTo)
                        );
                      } else {
                        navigate(i.linkTo);
                      }
                    }}
                  >
                    {i.icon}
                    <span>{i.title}</span>
                  </button>
                </li>
              );
            })}
            <li className="zu_sidebar_item d-lg-none pb-15">
              <button
                className="zu_sidebar_link null "
                onClick={() => {
                  document.body.classList.remove("zu_open_sidebar");
                  navigate(routes.settings);
                }}
              >
                <span>Settings</span>
              </button>
            </li>
            <li className="zu_sidebar_item d-lg-none pb-0">
              <button
                type="button"
                className="zu_sidebar_link null"
                onClick={() => {
                  document.body.classList.remove("zu_open_sidebar");
                  logoutHandler();
                }}
              >
                <span>Sign out</span>
              </button>
            </li>
            <div className="zu_sidebar_bottom_link">
              <Link to={routes.settings} className="d-lg-block d-none">
                Settings
              </Link>
              <a
                href="https://jivo.chat/zgY5DzoKWO"
                target="_blank"
                rel="noreferrer"
              >
                Need Support?
              </a>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;
