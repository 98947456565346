import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { jobWorkerRoleClassname, jobWorkerRoleName } from "../../constants";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";
import {
  checkedGreenIcon,
  closeIcon,
  locationIcon,
  warningIcon,
} from "../../icons";
import image from "../../images/default-user.jpg";
import { getApplicantProfile } from "../../store/slice/userSlice";
import Loader from "../Common/Loader";
import SpinLoader from "../Common/SpinLoader";
import ApplicantDetailModal from "./ApplicantDetailModal";

const MyJobsColumn = ({
  job,
  hasFindJob,
  showModal,
  showCancelApprovalHandler,
  acceptDeclineModalHandler,
  jobLoading,
  jobLoadingState,
  job_status_for_reject,
}) => {
  const [lessText, setLessText] = useState(false);
  const [ownerDetail, setOwnerDetail] = useState("");
  const [ownerDetailModal, setOwnerDetailModal] = useState(false);
  const dispatch = useDispatch();

  const cancelJobHandler = (e, id) => {
    e.preventDefault();
    showCancelApprovalHandler(id);
  };

  const user = useSelector((state) => state.user.user);
  const loading = useSelector((state) => state.user.loader);

  const userId = user?.user_id;

  let word = "";
  const wordFunction = (id) => {
    for (let i = 0; i < 15; i++) {
      word += " " + id[i];
    }
    return word;
  };

  const readMoreHandler = () => {
    setLessText(!lessText);
  };

  const jobClassname = (status, applicant, userId) => {
    let className = {
      CANCELLED: "zu_btn_pending_red",
      WAITING_FOR_RESPONSE:
        applicant && applicant === userId ? "zu_btn_pending_green" : "",
      OFFER_ACCEPTED:
        applicant && applicant === userId
          ? "zu_btn_green"
          : "zu_btn_pending_red",
      IN_PROGRESS:
        applicant && applicant === userId
          ? "zu_btn_progress_orange"
          : "zu_btn_pending_red",
      COMPLETED:
        applicant && applicant === userId
          ? "zu_btn_purple"
          : "zu_btn_pending_red",
      DONE:
        applicant && applicant === userId
          ? "zu_btn_voilet"
          : "zu_btn_pending_red",
    };
    // let className;
    // if (job_status_for_reject === "REJECTED") {
    //   className = {
    //     REJECTED: "zu_btn_pending_red",
    //   };
    //   return className["REJECTED"];
    // } else {
    //   className = {
    //     CANCELLED: "zu_btn_pending_red",
    //     WAITING_FOR_RESPONSE: "zu_btn_pending_green",
    //     OFFER_ACCEPTED: "zu_btn_green",

    //     IN_PROGRESS: "zu_btn_progress_orange",

    //     COMPLETED: "zu_btn_purple",
    //     DONE: "zu_btn_voilet",
    //   };
    return className[status];
    // }
  };

  const jobIcon = (status, applicant, userId) => {
    let icon = {
      IN_PROGRESS: job_status_for_reject !== "REJECTED" && warningIcon,
      DONE: job_status_for_reject !== "REJECTED" && checkedGreenIcon,
      CANCELLED: closeIcon,
    };
    return icon[status];
  };

  const jobStatusName = (status, applicant, userId) => {
    let statusName = {
      CANCELLED: "Cancelled",
      NEW: "APPLIED",
      WAITING_FOR_RESPONSE:
        applicant && applicant === userId ? "ACCEPT/DECLINE" : "APPLIED",
      OFFER_ACCEPTED:
        applicant && applicant === userId ? "CONFIRMED" : "REJECTED",
      IN_PROGRESS:
        applicant && applicant === userId ? "IN PROGRESS" : "REJECTED",
      COMPLETED: applicant && applicant === userId ? "COMPLETED" : "REJECTED",
      DONE: applicant && applicant === userId ? "DONE" : "REJECTED",
    };
    // let statusName;
    // if (job_status_for_reject === "REJECTED") {
    //   statusName = {
    //     REJECTED: "REJECTED",
    //   };
    //   return statusName["REJECTED"];
    // } else {
    //   statusName = {
    //     CANCELLED: "Cancelled",
    //     NEW: "APPLIED",
    //     WAITING_FOR_RESPONSE: "ACCEPT/DECLINE",
    //     OFFER_ACCEPTED: "CONFIRMED",
    //     IN_PROGRESS: "IN PROGRESS",
    //     COMPLETED: "COMPLETED",
    //     DONE: "DONE",
    //   };
    return statusName[status];
    // }
  };

  const openOwnerModalHandler = () => {
    dispatch(
      getApplicantProfile({
        id: job?.owner,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setOwnerDetailModal(true);
            setOwnerDetail(res);
          }
        },
      })
    );
  };

  const hideOwnerDetailModal = () => {
    setOwnerDetailModal(false);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="zu_jobs_column" key={job?.Id}>
        <div className="zu_jobs_column_left_content">
          <img
            src={
              job?.owner_profile_photo
                ? `${process.env.REACT_APP_FILE_BASE_URL}/${job?.owner_profile_photo}`
                : image
            }
            alt="user profile"
            className="zu_jobs_column_left_user_img"
          />
          <div className="zu_jobs_column_name_description_text">
            <div className="zu_jobs_column_name_row">
              <h3 role="button" onClick={(e) => showModal(job?.id, job?.owner)}>
                {capitalizeFirstLetter(job?.title)}
              </h3>
              <span onClick={openOwnerModalHandler} role="button">
                By {capitalizeFirstLetter(job?.owner_first_name)}{" "}
                {capitalizeFirstLetter(job?.owner_last_name)}
              </span>
              <p>
                {locationIcon} {job?.address}
              </p>
            </div>
            <div className="zu_jobs_column_price_time_text">
              <p>{moment(job?.date).utc().format("DD MMM YYYY")}</p>
              <p>
                {job?.extra_hour
                  ? job?.extra_hour + job?.number_of_hour
                  : job?.number_of_hour}{" "}
                Hours
              </p>
              <p>
                $
                {job?.price_per_hour &&
                  (job?.price_per_hour * 0.98)?.toFixed(2)}{" "}
                Per Hour
              </p>
              <p className="zu_jobs_column_right_price_text">
                $
                {job?.extra_hour
                  ? (
                      (job?.number_of_hour + job?.extra_hour) *
                      job?.price_per_hour *
                      0.98
                    )?.toFixed(2)
                  : job?.custom_amount
                  ? (
                      (job?.custom_amount +
                        job?.number_of_hour * job?.price_per_hour) *
                      0.98
                    )?.toFixed(2)
                  : (job?.number_of_hour * job?.price_per_hour * 0.98)?.toFixed(
                      2
                    )}{" "}
                AUD Total
              </p>
            </div>
            {job?.brief?.split(" ")?.length < 16 ? (
              <pre className="zu_jobs_column_description_text zu_jobs_column_brief_pre">
                {job?.brief}
              </pre>
            ) : (
              <pre className="zu_jobs_column_description_text zu_jobs_column_brief_pre">
                {lessText ? job?.brief : wordFunction(job?.brief?.split(" "))}{" "}
                {job?.brief?.split(" ")?.length > 10 && (
                  <span className="readmore_link" onClick={readMoreHandler}>
                    {lessText ? "Read less..." : "Read more..."}
                  </span>
                )}
              </pre>
            )}
            <div className="zu_jobs_column_label_row">
              {job?.job_worker_roles &&
                JSON.parse(job?.job_worker_roles).map((i, id) => {
                  return (
                    <span
                      className={`zu_common_label ${jobWorkerRoleClassname(
                        i
                      )} `}
                      key={`zu_label${id}`}
                    >
                      {i === "DRONE_OPERATOR" ||
                      i === "PHOTO_EDITOR" ||
                      i === "VIDEO_EDITOR"
                        ? jobWorkerRoleName(i)
                        : i}
                    </span>
                  );
                })}
              <span
                className={`zu_common_label zu_common_label_light`}
                key={`zu_label`}
              >
                JOB ID: {job?.id}
              </span>
            </div>
            <button
              className="zu_jobs_column_price_more_detail_btn zu_find_jobs_column_more_detail"
              onClick={(e) =>
                showModal(
                  job?.id,
                  job?.job_status,
                  job?.applicant_with_offer,
                  job?.owner
                )
              }
            >
              {jobLoading && jobLoadingState === job?.id ? (
                <SpinLoader />
              ) : (
                "More Details"
              )}
            </button>
          </div>
          <div className="zu_jobs_column_price_more_detail">
            <p>{moment(job?.date).utc().format("DD MMM YYYY")}</p>
            <p>
              {job?.extra_hour
                ? job?.extra_hour + job?.number_of_hour
                : job?.number_of_hour}{" "}
              Hours
            </p>
            <p>
              ${job?.price_per_hour && (job?.price_per_hour * 0.98)?.toFixed(2)}{" "}
              Per Hour
            </p>
            <p className="zu_jobs_column_right_price_text">
              $
              {job?.extra_hour
                ? (
                    (job?.number_of_hour + job?.extra_hour) *
                    job?.price_per_hour *
                    0.98
                  )?.toFixed(2)
                : job?.custom_amount
                ? (
                    (job?.custom_amount +
                      job?.number_of_hour * job?.price_per_hour) *
                    0.98
                  )?.toFixed(2)
                : (job?.number_of_hour * job?.price_per_hour * 0.98)?.toFixed(
                    2
                  )}{" "}
              AUD Total
            </p>
          </div>
        </div>
        <div className="zu_jobs_column_right_content">
          {hasFindJob && (
            <div className="zu_find_jobs_column_right_content">
              <button
                className={`zu_common_btn ${jobClassname(
                  job?.job_status,
                  job?.applicant_with_offer,
                  userId
                )}`}
                onClick={() => {
                  if (
                    job?.job_status === "WAITING_FOR_RESPONSE" &&
                    job?.applicant_with_offer === userId
                  ) {
                    acceptDeclineModalHandler(job);
                  } else {
                    showModal(
                      job?.id,
                      job?.job_status,
                      job?.applicant_with_offer,
                      job?.owner
                    );
                  }
                }}
              >
                {jobIcon(job?.job_status, job?.applicant_with_offer, userId)}
                {jobStatusName(
                  job?.job_status,
                  job?.applicant_with_offer,
                  userId
                )}
              </button>
              {(job?.job_status === "NEW" ||
                (job?.job_status === "WAITING_FOR_RESPONSE" &&
                  job?.applicant_with_offer !== userId)) && (
                <button
                  className={`zu_jobs_column_right_payment_text zu_danger_text zu_cancel_link`}
                  onClick={(e) => cancelJobHandler(e, job?.id)}
                >
                  Cancel
                </button>
              )}
              <button
                className="zu_jobs_column_price_more_detail_btn zu_find_jobs_column_more_detail"
                onClick={(e) =>
                  showModal(
                    job?.id,
                    job?.job_status,
                    job?.applicant_with_offer,
                    job?.owner
                  )
                }
              >
                {jobLoading && jobLoadingState === job?.id ? (
                  <SpinLoader />
                ) : (
                  "More Details"
                )}
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={ownerDetailModal}
        onHide={hideOwnerDetailModal}
        centered
        className="zu_common_text_modal zu_job_completed_modal"
      >
        <ApplicantDetailModal
          hideOwnerDetailModal={hideOwnerDetailModal}
          ownerDetail={ownerDetail}
        />
      </Modal>
    </>
  );
};

export default MyJobsColumn;
