import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  routes,
  jobWorkerRoleClassname,
  jobWorkerRoleName,
} from "../../constants";
import CommonHeading from "../../component/Common/CommonHeading";
import {
  locationIcon,
  calendarFillIcon,
  stopWatchFillIcon,
  dollorIcon,
  editLineIcon,
  closeFillIcon,
  totalIcon,
} from "../../icons";
import {
  finalizePaymentOfJob,
  getApplicantList,
  getJobDetail,
  resendJobAlert,
  sendOfferToApplicant,
  updateJob,
} from "../../store/slice/postSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../component/Common/Loader";
import { clearUserProfile, userCardList } from "../../store/slice/userSlice";

import { Modal } from "react-bootstrap";
import { useForm } from "../../hooks/useForm";
import {
  capitalizeFirstLetter,
  manageJobContacts,
} from "../../helpers/commonFunction";
import ApplicantDetailModal from "../../component/Layout/ApplicantDetailModal";
import SendOfferModal from "../../component/Layout/SendOfferModal";
import SendMessageModal from "../../component/Layout/SendMessageModal";
import OfferSentModal from "../../component/Layout/OfferSentModal";
import ReviewBusinessModal from "../../component/Layout/ReviewBusinessModal";
import ManageJobsApplicats from "../../component/Layout/ManageJobsApplicats";
import { useRef } from "react";
import FinalizeModal from "../../component/Layout/FinalizeModal";
import AddExtraHourModal from "../../component/Layout/AddExtraHourModal";

const ManageJobsDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const ref = useRef();
  const id = params.id;
  const jobDetail = useSelector((state) => state.post.jobDetail);
  const applicants = useSelector((state) => state.post.getApplicantList);
  const loading = useSelector((state) => state.user.loader);
  const userLoading = useSelector((state) => state.post.loader);
  const jobLoading = useSelector((state) => state.post.jobLoader);
  const user = useSelector((state) => state.user.user);

  const initialFValues = {
    show: false,
    cancelPostModal: false,
    completePostModal: true,
    jobReviewModal: false,
    applicantDetailModal: false,
    offerSendConfirmModal: false,
    review: "",
    rating: 3,
    applicantModalDetails: "",
    sendOfferModal: false,
    cardData: [],
    creditCardId: "",
    creditCardIdErr: "",
    policy: false,
    policyErr: "",
    sendMessageModal: false,
    errorMessage: "",
    messageContent: "",
    cardNumber: "",
    cardNumberErr: "",
    expiry: "",
    expiryErr: "",
    cvc: "",
    cvcErr: "",
    cardDataErr: "",
    finalizePaymentModal: false,
    cardBlinkClass: false,
    cardBlinkClassTwo: false,
    extraHourModal: false,
    extra_hour: "",
    extra_hour_err: "",
    resendJobAlertModal: false,
    custom_amount: "",
    custom_amount_err: "",
    custom_amount_reason: "",
    custom_amount_reason_err: "",
    showExtraHourField: true,
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const { confirm_at } = useParams();

  useEffect(() => {
    if (!values.cancelPostModal) {
      dispatch(clearUserProfile());
      if (id) {
        dispatch(
          getJobDetail({
            id,
            confirm:
              location.state?.job_status === "OFFER_ACCEPTED" ||
              confirm_at ||
              location.state?.job_status === "IN_PROGRESS" ||
              location.state?.job_status === "COMPLETED" ||
              location.state?.job_status === "DONE" ||
              user?.role === "ROLE_ADMIN"
                ? true
                : "",
          })
        );
        dispatch(getApplicantList({ id }));
      }
    }
  }, [values.cancelPostModal]);

  const cancelJobHandler = async () => {
    let data;
    if (jobDetail.job_status === "WAITING_FOR_RESPONSE") {
      data = {
        applicant_id: jobDetail.shooter_id,
        current_status: "WAITING_FOR_RESPONSE",
        job_status: "CANCELLED",
      };
    } else {
      data = {
        job_status: "CANCELLED",
      };
    }
    await dispatch(updateJob({ data, id }));
    await dispatch(getJobDetail({ id }));
    user?.role == "ROLE_ADMIN"
      ? closeModelHanlder()
      : navigate(`${routes.managejobs}/page/1`);
  };

  // if (loading) {
  //   return <Loader />;
  // }

  const openModelHanlder = () => {
    setValues({ ...values, cancelPostModal: true });
  };

  const closeModelHanlder = () => {
    setValues({
      ...values,
      show: false,
      completePostModal: false,
      cancelPostModal: false,
      review: "",
      rating: 3,
      finalizePaymentModal: false,
    });
  };

  const openInProcessModelHanlder = () => {
    setValues({ ...values, completePostModal: true });
  };

  const completeJobCloseHandler = () => {
    setValues({
      ...values,
      jobReviewModal: false,
    });
    dispatch(getJobDetail({ id, confirm: true }));
  };

  const hideApplicantDetailModal = () => {
    setValues({
      ...values,
      applicantDetailModal: false,
      applicantModalDetails: "",
    });
  };

  const jobOfferCloseHandler = () => {
    setValues({
      ...values,
      sendOfferModal: false,
      policy: false,
      cardBlinkClass: false,
      cardBlinkClassTwo: false,
      cardNumber: "",
      cardNumberErr: "",
      expiry: "",
      expiryErr: "",
      cvc: "",
      cvcErr: "",
      cardDataErr: "",
      creditCardId: "",
      policyErr: "",
      creditCardIdErr: "",
    });
  };

  const validate = () => {
    let valid = false;
    if (!values.creditCardId) {
      setValues({
        ...values,
        creditCardIdErr: "You have to select atleast one card!",
      });
    } else if (!values.policy) {
      setValues({
        ...values,
        policyErr: "Please accept the Privacy Policy and Terms of Service!",
      });
    } else {
      valid = true;
    }
    return valid;
  };

  const extraHourValidate = () => {
    let valid = false;
    if (!values.extra_hour) {
      setValues({
        ...values,
        extra_hour_err: "Please Add Additional Hour!",
      });
    } else if (+values.extra_hour < 1) {
      setValues({
        ...values,
        extra_hour_err: "Please Add Valid Additional Hour!",
      });
    } else {
      valid = true;
    }
    return valid;
  };

  const extraAmountValidation = () => {
    let valid = false;
    if (!values.custom_amount) {
      setValues({
        ...values,
        custom_amount_err: "Please Add Custom Amount!",
      });
    } else if (+values.custom_amount < 1) {
      setValues({
        ...values,
        custom_amount_err: "Please Add Valid Custom Amount!",
      });
    } else {
      valid = true;
    }
    return valid;
  };

  const extraAmountReasonValidation = () => {
    let valid = false;
    if (values.custom_amount_reason === "") {
      setValues({
        ...values,
        custom_amount_reason_err: "Please describe something!",
      });
    } else {
      valid = true;
    }
    return valid;
  };

  const userCardValidate = () => {
    let valid = false;
    if (!values.creditCardId) {
      setValues({
        ...values,
        creditCardIdErr: "You have to select atleast one card!",
      });
    } else {
      valid = true;
    }
    return valid;
  };

  const jobOfferSendHandler = () => {
    if (values.cardData.length === 0) {
      if (ref.current === true) {
        setValues({
          ...values,
          cardBlinkClass: !values.cardBlinkClass,
          cardBlinkClassTwo: !values.cardBlinkClassTwo,
        });
      } else {
        setValues({
          ...values,
          cardBlinkClassTwo: true,
        });
      }
      ref.current = true;
    } else if (validate()) {
      const data = {
        job_id: jobDetail?.id,
        applicant_id: values.applicantModalDetails?.id,
        customer_card_id: values.creditCardId,
        capture: false,
      };

      dispatch(
        sendOfferToApplicant({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              dispatch(
                getJobDetail({
                  id,
                  confirm:
                    location.state?.job_status === "OFFER_ACCEPTED" ||
                    confirm_at ||
                    location.state?.job_status === "IN_PROGRESS" ||
                    location.state?.job_status === "COMPLETED"
                      ? true
                      : "",
                })
              );
              dispatch(getApplicantList({ id }));
              setValues({
                ...values,
                sendOfferModal: false,
                offerSendConfirmModal: true,
                policy: false,
                creditCardId: "",
                cardNumber: "",
                cardNumberErr: "",
                expiry: "",
                expiryErr: "",
                cvc: "",
                cvcErr: "",
                cardDataErr: "",
                policyErr: "",
              });
            }
          },
        })
      );
    }
  };

  const jobOfferConfirmCloseHandler = () => {
    setValues({
      ...values,
      sendOfferModal: false,
      offerSendConfirmModal: false,
    });
  };

  const sendMessageCloseHandler = () => {
    setValues({
      ...values,
      sendMessageModal: false,
      errorMessage: "",
      messageContent: "",
    });
  };

  const openfinalizePaymentModal = () => {
    setValues({ ...values, finalizePaymentModal: true });
  };

  const finalizePaymentHandler = async () => {
    await dispatch(
      finalizePaymentOfJob({
        id: id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getJobDetail({
                id,
                confirm:
                  location.state?.job_status === "OFFER_ACCEPTED" ||
                  confirm_at ||
                  location.state?.job_status === "IN_PROGRESS" ||
                  location.state?.job_status === "COMPLETED" ||
                  location.state?.job_status === "DONE"
                    ? true
                    : "",
              })
            );
          }
        },
      })
    );
    setValues({ ...values, finalizePaymentModal: false });
  };

  const exportBusinessInvoice = (i) => {
    if (i === "admin") {
      navigate(`/adminmanagejobs/${jobDetail?.id}/true`, {
        state: { jobDetail: jobDetail, person: "Business" },
      });
    } else {
      navigate(`/managejobs/${jobDetail?.id}/true`, {
        state: { jobDetail: jobDetail, person: "Business" },
      });
    }
  };

  const exportCrewInvoice = () => {
    navigate(`/adminmanagejobs/${jobDetail?.id}/true`, {
      state: { jobDetail: jobDetail, person: "Crew" },
    });
  };

  const showMessageModal = () => {
    setValues({
      ...values,
      sendMessageModal: true,
    });
  };

  const showExtraHourModal = () => {
    dispatch(
      userCardList({
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setValues({
              ...values,
              cardData: res,
              creditCardId: "",
              extraHourModal: true,
              completePostModal: false,
            });
          }
        },
      })
    );
  };

  const closeExtraHourModal = () => {
    setValues({
      ...values,
      extraHourModal: false,
      extra_hour_err: "",
      extra_hour: "",
      custom_amount: "",
      custom_amount_err: "",
      custom_amount_reason: "",
      custom_amount_reason_err: "",
      cardBlinkClass: false,
      cardBlinkClassTwo: false,
      cardNumber: "",
      cardNumberErr: "",
      expiry: "",
      expiryErr: "",
      cvc: "",
      cvcErr: "",
      cardDataErr: "",
      creditCardId: "",
      creditCardIdErr: "",
    });
  };

  const addExtraHourHandler = () => {
    if (values.cardData.length === 0) {
      if (ref.current === true) {
        setValues({
          ...values,
          cardBlinkClass: !values.cardBlinkClass,
          cardBlinkClassTwo: !values.cardBlinkClassTwo,
        });
      } else {
        setValues({
          ...values,
          cardBlinkClassTwo: true,
        });
      }
      ref.current = true;
    } else if (extraHourValidate() && userCardValidate()) {
      const data = {
        extra_hour: values.extra_hour,
        user_card_id: values.creditCardId,
      };

      dispatch(
        updateJob({
          data: data,
          id: jobDetail?.id,
          cb: (err, res) => {
            if (err) {
              console.log("err.response", err.response.data.message);
            } else {
              dispatch(
                getJobDetail({
                  id,
                  confirm:
                    location.state?.job_status === "OFFER_ACCEPTED" ||
                    confirm_at ||
                    location.state?.job_status === "IN_PROGRESS" ||
                    location.state?.job_status === "COMPLETED"
                      ? true
                      : "",
                })
              );
              setValues({
                ...values,
                extraHourModal: false,
                completePostModal: true,
              });
            }
          },
        })
      );
    }
  };

  const showResendJobModal = () => {
    setValues({
      ...values,
      resendJobAlertModal: true,
    });
  };

  const hideResendJobModal = () => {
    setValues({
      ...values,
      resendJobAlertModal: false,
    });
  };

  const resendJobAlertHandler = () => {
    dispatch(
      resendJobAlert({
        id: id,
        cb: (err, res) => {
          if (err) {
            console.log("err.response", err.response.data.message);
          } else {
            setValues({
              ...values,
              resendJobAlertModal: false,
            });
          }
        },
      })
    );
  };

  const addExtraAmountHandler = () => {
    if (values.cardData.length === 0) {
      if (ref.current === true) {
        setValues({
          ...values,
          cardBlinkClass: !values.cardBlinkClass,
          cardBlinkClassTwo: !values.cardBlinkClassTwo,
        });
      } else {
        setValues({
          ...values,
          cardBlinkClassTwo: true,
        });
      }
      ref.current = true;
    } else if (
      extraAmountValidation() &&
      extraAmountReasonValidation() &&
      userCardValidate()
    ) {
      const data = {
        custom_amount: values.custom_amount,
        custom_amount_reason: values.custom_amount_reason,
        user_card_id: values.creditCardId,
      };
      dispatch(
        updateJob({
          data: data,
          id: jobDetail?.id,
          cb: (err, res) => {
            if (err) {
              console.log("err.response", err.response.data.message);
            } else {
              dispatch(
                getJobDetail({
                  id,
                  confirm:
                    location.state?.job_status === "OFFER_ACCEPTED" ||
                    confirm_at ||
                    location.state?.job_status === "IN_PROGRESS" ||
                    location.state?.job_status === "COMPLETED"
                      ? true
                      : "",
                })
              );
              setValues({
                ...values,
                extraHourModal: false,
                completePostModal: true,
              });
            }
          },
        })
      );
    }
  };

  const navigateToMsgPage = () => {
    navigate(`/messages`, {
      state: {
        job_status: jobDetail?.job_status,
        job_owner_id: jobDetail?.owner,
        job_id: jobDetail?.id,
        job_title: jobDetail?.title,
        job_date: jobDetail.date,
        opp_user_id: jobDetail?.shooter,
        opp_first_name: jobDetail?.crew_first_name,
        opp_last_name: jobDetail?.crew_last_name,
        opp_profile_photo: jobDetail?.crew_profile_photo,
      },
    });
  };

  return (
    <>
      {(loading || userLoading || jobLoading) && <Loader />}
      <main className="zu_all_page_main_content">
        <section className="zu_manage_job_detail_heading_section">
          <div className="zu_manage_job_detail_heading_contant">
            <h1 className="zu_common_heading zu_manage_job_detail_heading">
              Job Overview{" "}
              {jobDetail?.job_status === "POSTED" && (
                <span className="zu_common_heading_processing_label">
                  Pending
                </span>
              )}
            </h1>
            {jobDetail?.job_status === "IN_PROGRESS" &&
            user?.role !== "ROLE_ADMIN" ? (
              <div className="zu_common_heading_job_progress_btn_row">
                {!jobDetail?.extra_hour && !jobDetail?.custom_amount && (
                  <button
                    className="zu_common_heading_extra_hour"
                    onClick={showExtraHourModal}
                  >
                    INCREASE CREW PAYMENT
                  </button>
                )}
                <button
                  className="zu_common_heading_job_progress"
                  onClick={openInProcessModelHanlder}
                >
                  COMPLETE YOUR JOB
                </button>
              </div>
            ) : (
              ""
            )}
            {(jobDetail?.job_status === "NEW" ||
              jobDetail?.job_status === "WAITING_FOR_RESPONSE") &&
            user?.role === "ROLE_ADMIN" ? (
              <div className="zu_common_heading_job_progress_btn_row">
                <button
                  className="zu_common_heading_job_progress"
                  onClick={showResendJobModal}
                >
                  RESEND JOB ALERT
                </button>
              </div>
            ) : (
              ""
            )}

            {user?.role === "ROLE_ADMIN" &&
            jobDetail?.job_status === "COMPLETED" ? (
              <span
                onClick={() => openfinalizePaymentModal()}
                className="zu_common_btn zu_btn_voilet finalize_payment_btn"
              >
                Finalise Payment
              </span>
            ) : (
              ""
            )}
            {jobDetail?.job_status === "DONE" ? (
              <div className="invoice_button_section">
                <button
                  className="zu_common_btn zu_btn_voilet  bussiness_invoice_btn"
                  onClick={(e) =>
                    exportBusinessInvoice(
                      user?.role === "ROLE_ADMIN" && "admin"
                    )
                  }
                >
                  Business Invoice
                </button>
                {user?.role === "ROLE_ADMIN" && (
                  <button
                    className="zu_common_btn zu_btn_voilet crew_invoice_btn"
                    onClick={exportCrewInvoice}
                  >
                    Crew Invoice
                  </button>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </section>

        <section className="zu_manage_job_detail_column_section">
          <div className="zu_manage_job_detail_column_content">
            <div className="zu_manage_job_detail_column_row">
              <div className="zu_manage_job_detail_column zu_manage_job_detail_left_column">
                <div className="zu_manage_job_detail_left_content">
                  <div className="zu_manage_job_detail_project_name_label_row">
                    <div className="zu_manage_job_detail_project_name_text">
                      <CommonHeading
                        heading={capitalizeFirstLetter(jobDetail?.title)}
                      />
                      <div className="zu_manage_job_date">
                        <span>Date of Job</span>
                        <p>
                          {moment(jobDetail?.date).utc().format("DD MMM YYYY")}
                        </p>
                      </div>
                      <span>
                        By {capitalizeFirstLetter(jobDetail?.owner_first_name)}{" "}
                        {capitalizeFirstLetter(jobDetail?.owner_last_name)}
                      </span>
                      <p>
                        {locationIcon} {jobDetail?.address}
                      </p>
                    </div>
                    <div className="zu_manage_job_detail_project_label_text">
                      <span className="zu_common_label zu_manage_job_detail_id_label">
                        JOB ID: {jobDetail?.id}
                      </span>
                      {jobDetail?.job_worker_roles &&
                        JSON.parse(jobDetail?.job_worker_roles).map((i, id) => {
                          return (
                            <span
                              className={`zu_common_label ${jobWorkerRoleClassname(
                                i
                              )}`}
                              key={`zu_label${id}`}
                            >
                              {i === "DRONE_OPERATOR" ||
                              i === "PHOTO_EDITOR" ||
                              i === "VIDEO_EDITOR"
                                ? jobWorkerRoleName(i)
                                : i}
                            </span>
                          );
                        })}
                    </div>
                  </div>
                  <div className="zu_manage_job_detail_paragraph">
                    <pre className="zu_jobs_column_description_text zu_jobs_column_brief_pre">
                      {jobDetail?.brief}
                    </pre>
                  </div>
                </div>
              </div>
              <div className="zu_manage_job_detail_column zu_manage_job_detail_right_column">
                {(jobDetail?.job_status === "NEW" ||
                  jobDetail?.job_status === "WAITING_FOR_RESPONSE" ||
                  jobDetail?.job_status === "POSTED" ||
                  (jobDetail?.job_status === "CANCELLED" &&
                    user?.role === "ROLE_ADMIN") ||
                  (jobDetail?.job_status === "OFFER_ACCEPTED" &&
                    user?.role === "ROLE_ADMIN") ||
                  (jobDetail?.job_status === "DONE" &&
                    user?.role === "ROLE_ADMIN")) && (
                  <ManageJobsApplicats
                    applicants={applicants}
                    jobDetail={jobDetail}
                    values={values}
                    setValues={setValues}
                  />
                )}
                {jobDetail?.job_status === "OFFER_ACCEPTED" ||
                jobDetail?.job_status === "COMPLETED" ||
                jobDetail?.job_status === "IN_PROGRESS"
                  ? manageJobContacts(
                      jobDetail,
                      user,
                      showMessageModal,
                      navigateToMsgPage
                    )
                  : null}
              </div>
              <div className="zu_manage_job_detail_column zu_manage_job_other_detail_column">
                <div className="zu_manage_job_other_detail_row">
                  <div className="zu_manage_job_other_detail_content zu_manage_job_date_column">
                    {calendarFillIcon}
                    <div className="zu_manage_job_other_detail_content_text">
                      <h3>Date of Job</h3>
                      <p>
                        {moment(jobDetail?.date).utc().format("DD MMM YYYY")}
                      </p>
                    </div>
                  </div>
                  <div className="zu_manage_job_other_detail_content">
                    {stopWatchFillIcon}
                    <div className="zu_manage_job_other_detail_content_text">
                      <h3>Hours Required</h3>
                      <p>
                        {jobDetail?.extra_hour
                          ? jobDetail?.number_of_hour + jobDetail?.extra_hour
                          : jobDetail?.number_of_hour}
                      </p>
                    </div>
                  </div>
                  <div className="zu_manage_job_other_detail_content">
                    {dollorIcon}
                    <div className="zu_manage_job_other_detail_content_text">
                      <h3>Price Per Hour</h3>
                      <p>{jobDetail?.price_per_hour?.toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="zu_manage_job_other_detail_content zu_manage_job_other_detail_total_amount">
                    {totalIcon}
                    <div className="zu_manage_job_other_detail_content_text">
                      <h3>Total</h3>
                      <p>
                        $
                        {jobDetail?.extra_hour
                          ? (
                              (jobDetail?.number_of_hour +
                                jobDetail?.extra_hour) *
                              jobDetail?.price_per_hour
                            ).toFixed(2)
                          : jobDetail?.custom_amount
                          ? (
                              jobDetail?.number_of_hour *
                                jobDetail?.price_per_hour +
                              jobDetail?.custom_amount
                            ).toFixed(2)
                          : (
                              jobDetail?.number_of_hour *
                              jobDetail?.price_per_hour
                            ).toFixed(2)}
                        AUD
                      </p>
                      {jobDetail?.custom_amount_reason && (
                        <p className="zu_custom_total_msg">
                          {jobDetail?.custom_amount_reason
                            ? "This total includes an additional custom payment for " +
                              jobDetail?.custom_amount_reason
                            : ""}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="zu_manage_job_btn_content">
          <div className="zu_manage_job_btn_row">
            <button
              onClick={() => {
                user?.role === "ROLE_ADMIN"
                  ? navigate("/adminmanagejobs/page/1")
                  : navigate(-1);
              }}
              className="zu_manage_job_back_btn"
            >
              Back
            </button>
            {jobDetail?.job_status !== "CANCELLED" &&
            (jobDetail?.job_status === "NEW" ||
              (jobDetail?.job_status === "OFFER_ACCEPTED" &&
                user?.role === "ROLE_ADMIN") ||
              (jobDetail?.job_status === "WAITING_FOR_RESPONSE" &&
                user?.role === "ROLE_ADMIN") ||
              (jobDetail?.job_status === "IN_PROGRESS" &&
                user?.role === "ROLE_ADMIN") ||
              jobDetail?.job_status === "POSTED") ? (
              <div className="zu_manage_job_edit_cancel_btn_row">
                {jobDetail?.job_status !== "IN_PROGRESS" && (
                  <Link
                    to={
                      user?.role === "ROLE_ADMIN"
                        ? `/adminmanagejobseditpost/${jobDetail?.id}`
                        : `/managejobseditpost/${jobDetail?.id}`
                    }
                    className="zu_common_btn zu_btn_theme_color zu_manage_job_edit_btn"
                  >
                    {editLineIcon}
                    Edit Job
                  </Link>
                )}
                <button
                  onClick={openModelHanlder}
                  className="zu_common_btn zu_manage_job_cancel_btn"
                >
                  {closeFillIcon}
                  Cancel Job
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </main>
      <Modal
        show={values.cancelPostModal}
        onHide={closeModelHanlder}
        centered
        className="zu_common_text_modal zu_decline_offer_modal"
      >
        <div className="zu_common_modal zu_common_center_modal">
          <div className="zu_common_modal_content">
            <h2 className="zu_common_modal_heading">Are you sure ?</h2>
            <p className="zu_common_modal_paragraph">
              You want to cancel this job!!
            </p>
            <div className="zu_modal_common_btn_row">
              <button
                type="button"
                className="zu_common_btn zu_btn_gray zu_decline_offer_back_btn"
                onClick={closeModelHanlder}
              >
                GO BACK
              </button>
              <button
                type="button"
                className="zu_common_btn zu_danger_btn"
                onClick={cancelJobHandler}
              >
                CANCEL JOB
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {jobDetail?.job_status === "IN_PROGRESS" && (
        <Modal
          show={values.completePostModal && user?.role !== "ROLE_ADMIN"}
          onHide={closeModelHanlder}
          centered
          className="zu_common_text_modal zu_review_complete_modal"
        >
          <ReviewBusinessModal
            jobDetail={jobDetail}
            values={values}
            setValues={setValues}
            handleInputChange={handleInputChange}
            errors={errors}
            closeModelHanlder={closeModelHanlder}
            id={id}
            showExtraHourModal={showExtraHourModal}
          />
        </Modal>
      )}
      {jobDetail?.job_status === "IN_PROGRESS" && (
        <Modal
          show={values.extraHourModal && user?.role !== "ROLE_ADMIN"}
          onHide={closeExtraHourModal}
          centered
          className="zu_common_text_modal zu_review_complete_modal"
        >
          <AddExtraHourModal
            closeExtraHourModal={closeExtraHourModal}
            values={values}
            setValues={setValues}
            addExtraHourHandler={addExtraHourHandler}
            jobDetail={jobDetail}
            addExtraAmountHandler={addExtraAmountHandler}
            cardData={values?.cardData}
          />
        </Modal>
      )}
      <Modal
        show={values.jobReviewModal}
        onHide={completeJobCloseHandler}
        centered
        className="zu_common_text_modal zu_job_completed_modal"
      >
        <div className="zu_common_modal zu_common_center_modal">
          <div className="zu_common_modal_content">
            <h2 className="zu_common_modal_heading">Job Completed</h2>
            <p className="zu_common_modal_paragraph">
              Your crew payment is now being processed and you’ll receive a tax
              invoice for your records once payments are finalised.
            </p>
            <div className="zu_modal_common_btn_row">
              <button
                type="button"
                className="zu_common_btn zu_btn_gray"
                onClick={completeJobCloseHandler}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={values.applicantDetailModal}
        onHide={hideApplicantDetailModal}
        centered
        className="zu_common_text_modal zu_job_completed_modal"
      >
        <ApplicantDetailModal
          hideApplicantDetailModal={hideApplicantDetailModal}
          values={values}
          setValues={setValues}
          jobDetail={jobDetail}
        />
      </Modal>
      <Modal
        show={values.sendOfferModal}
        onHide={() => jobOfferCloseHandler()}
        centered
        className="zu_send_offer_modal"
      >
        <SendOfferModal
          jobOfferCloseHandler={jobOfferCloseHandler}
          applicantDetail={values.applicantModalDetails}
          jobD={jobDetail}
          jobOfferSendHandler={jobOfferSendHandler}
          values={values}
          setValues={setValues}
        />
      </Modal>
      <Modal
        show={values.sendMessageModal}
        onHide={() => sendMessageCloseHandler()}
        centered
        className="zu_send_msg_modal"
      >
        <SendMessageModal
          sendMessageCloseHandler={sendMessageCloseHandler}
          applicantDetail={values.applicantModalDetails}
          messageContent={values.messageContent}
          errorMessage={values.errorMessage}
          values={values}
          setValues={setValues}
          jobDetail={jobDetail}
        />
      </Modal>

      <Modal
        show={values.offerSendConfirmModal}
        onHide={() => jobOfferConfirmCloseHandler()}
        centered
        className="zu_offer_sent_modal"
      >
        <OfferSentModal
          jobOfferConfirmCloseHandler={jobOfferConfirmCloseHandler}
        />
      </Modal>
      <Modal
        show={values.finalizePaymentModal}
        onHide={closeModelHanlder}
        centered
        className="zu_common_text_modal zu_decline_offer_modal"
      >
        <FinalizeModal
          closeModelHanlder={closeModelHanlder}
          finalizePaymentHandler={finalizePaymentHandler}
          jobDetail={jobDetail}
        />
      </Modal>

      <Modal
        show={values.resendJobAlertModal}
        onHide={hideResendJobModal}
        centered
        className="zu_common_text_modal zu_job_completed_modal"
      >
        <div className="zu_common_modal zu_common_center_modal">
          <div className="zu_common_modal_content">
            <h2 className="zu_common_modal_heading">RESEND JOB ALERT</h2>
            <p className="zu_common_modal_paragraph">
              Are you sure you want to resend job alert?
            </p>

            <div className="zu_modal_common_btn_row">
              <button
                type="button"
                className="zu_common_btn zu_btn_gray zu_send_msg_modal_cancel_btn"
                onClick={hideResendJobModal}
              >
                CANCEL
              </button>
              <button
                type="button"
                className="zu_common_btn zu_btn_light_green"
                onClick={resendJobAlertHandler}
              >
                RESEND
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ManageJobsDetailPage;
