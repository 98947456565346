import moment from "moment";
import React from "react";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";

const FinalizeModal = ({
  closeModelHanlder,
  finalizePaymentHandler,
  jobDetail,
}) => {
  return (
    <div className="zu_common_modal zu_common_center_modal">
      <div className="zu_common_modal_content">
        <h2 className="zu_common_modal_heading">Are you sure ?</h2>
        <p className="zu_common_modal_paragraph">
          Here are the details to finalise payment:
        </p>
        <div className="zu_common_modal_other_detail_row_finalize">
          <div className="zu_common_modal_finalize_details">
            <div>
              <p>Crew Name</p>
              <p className="zu_common_modal_finalize_details_text">
                {capitalizeFirstLetter(jobDetail?.crew_first_name)}{" "}
                {capitalizeFirstLetter(jobDetail?.crew_last_name)}
              </p>
            </div>
          </div>
          <div className="zu_common_modal_finalize_details">
            <div>
              <p>Email</p>
              <p className="zu_common_modal_finalize_details_text">
                {jobDetail?.crew_email}
              </p>
            </div>
          </div>
          <div className="zu_common_modal_finalize_details">
            <div>
              <p>Job Id</p>
              <p className="zu_common_modal_finalize_details_text">
                {jobDetail?.id}
              </p>
            </div>
          </div>
          <div className="zu_common_modal_finalize_details">
            <div>
              <p>Job Date</p>
              <p className="zu_common_modal_finalize_details_text">
                {moment(jobDetail?.date).utc().format("DD MMM YYYY")}
              </p>
            </div>
          </div>

          <div className="zu_common_modal_finalize_details">
            <div>
              <p>Name On Bank Account</p>
              <p className="zu_common_modal_finalize_details_text">
                {jobDetail?.name_on_bank_account
                  ? jobDetail?.name_on_bank_account
                  : "Not available"}
              </p>
            </div>
          </div>
          <div className="zu_common_modal_finalize_details">
            <div>
              <p>Bank</p>
              <p className="zu_common_modal_finalize_details_text">
                {jobDetail?.bank_name ? jobDetail?.bank_name : "Not available"}
              </p>
            </div>
          </div>
          <div className="zu_common_modal_finalize_details">
            <div>
              <p>BSB</p>
              <p className="zu_common_modal_finalize_details_text">
                {jobDetail?.bsb ? jobDetail?.bsb : "Not available"}
              </p>
            </div>
          </div>
          <div className="zu_common_modal_finalize_details">
            <div>
              <p>Account Number</p>
              <p className="zu_common_modal_finalize_details_text">
                {jobDetail?.account_number
                  ? jobDetail?.account_number
                  : "Not available"}
              </p>
            </div>
          </div>
          <div>
            <div className="zu_common_modal_finalize_details">
              <p>Crew Payment Amount</p>
              <p className="zu_common_modal_finalize_details_text">
                ${" "}
                {(jobDetail?.extra_hour
                  ? (jobDetail?.extra_hour + jobDetail?.number_of_hour) *
                    jobDetail?.price_per_hour *
                    0.98
                  : jobDetail?.custom_amount
                  ? (jobDetail?.custom_amount +
                      jobDetail?.price_per_hour * jobDetail?.number_of_hour) *
                    0.98
                  : jobDetail?.number_of_hour * jobDetail?.price_per_hour * 0.98
                )?.toFixed(2)}{" "}
                AUD
              </p>
            </div>
          </div>
        </div>

        <div className="zu_modal_common_btn_row">
          <button
            type="button"
            className="zu_common_btn zu_btn_gray zu_decline_offer_back_btn"
            onClick={closeModelHanlder}
          >
            CANCEL
          </button>
          <button
            type="button"
            className="zu_common_btn zu_lighter_blue_btn"
            onClick={finalizePaymentHandler}
          >
            FINALISE PAYMENT
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinalizeModal;
