import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "./helpers/commonFunction";
import {
  dashboardIcon,
  calenderIcon,
  userDocIcon,
  invoiceMailIcon,
  userIcon,
  chatIcon,
} from "./icons";

export const routes = {
  admindashboard: "/admindashboard",
  adminmanageEditPostJob: "/adminmanagejobseditpost/:id",
  adminmanagejobsdetail: "/adminmanagejobsdetail/:id",
  adminmanagejobs: "/adminmanagejobs",
  adminmanagejobsByPage: "/adminmanagejobs/page/:page_number",
  adminmanageusersprofile: "/adminmanageusersprofile",
  adminleadsmanagement: "/adminleadsmanagement",
  adminleadsmanagementByPage: "/adminleadsmanagement/page/:page_number",
  admineditleadmanagement: "/admineditleadmanagement",
  adminmanageusers: "/adminmanageusers",
  adminmanageusersByPage: "/adminmanageusers/page/:page_number",
  adminmanagejobApplicant: "/adminmanagejobsapplicant/:id",
  adminmanageuserApplicant: "/adminmanageusersapplicant/:id",
  adminusersprofilePage: "/adminmanageusers/profileedit",
  adminjobprofilePage: "/adminmanagejobs/profileedit",
  homePage: "/",
  all: "*",
  login: "/login",
  forgotpassword: "/forgotpassword",
  forgotpasswordcheckyouremail: "/forgotpasswordcheckyouremail",
  forgotchangepassword: "/reset-password/:token",
  confirmpassword: "/confirmpassword",
  signup: "/signup",
  confirmcreateaccount: "/confirm/:token",
  signupcheckyouremail: "/signupcheckyouremail",
  dashboard: "/dashboard",
  managenewpostjob: "/managejobsnewpost",
  managejobs: "/managejobs",
  managejobsByPage: "/managejobs/page/:page_number",
  managejobsdetail: "/managejobsdetail/:id",
  managejobsdetail1: "/managejobsdetail/:id/:confirm_at",
  manageEditPostJob: "/managejobseditpost/:id",
  applicantprofile: "/managejobsapplicant/:id",
  profile: "/profile",
  findJobs: "/findjobs",
  jobownerprofile: "/findjobsowner/:id",
  findJobsByPage: "/findjobs/page/:page_number",
  findJobsByPageForMail: "/findjobs/page/:page_number/:id",
  myJobs: "/myjobs",
  myJobsByPage: "/myjobs/page/:page_number",
  myJobsByPageForMail: "/myjobs/page/:page_number/:id",
  invoice: "/invoices",
  invoiceByPage: "/invoices/page/:page_number",
  viewInvoice: "/invoice/:id/:confirm_at",
  manageViewInvoice: "/managejobs/:id/:confirm_at",
  myJobsInvoice: "/myjobs/:id/:confirm_at",
  adminJobsInvoice: "/adminmanagejobs/:id/:confirm_at",
  viewInvoiceForMail: "/invoice/:id/true/:person",
  leads: "/leads",
  leadsByPage: "/leads/page/:page_number",
  leadInvoice: "/leadsinvoic/:id",
  profileedit: "/profileedit",
  notification: "/notification",
  settings: "/settings",
  networkError: "/NetworkError",
  privacypolicy: "/privacypolicy",
  privacypolicyauth: "/privacypolicyauth",
  termsconditions: "/termsconditions",
  termsconditionsauth: "/termsconditionsauth",
  commonleadform: "/postlead",
  messagePage: "/messages",
};

export const sidebarLink = [
  {
    id: 1,
    linkClassName: "dashboard",
    linkTo: routes.dashboard,
    icon: dashboardIcon,
    title: "Dashboard",
  },
  {
    id: 2,
    linkClassName: "message",
    linkTo: routes.messagePage,
    icon: chatIcon,
    title: "Messages",
    msgPanding: true,
  },
  {
    id: 3,
    linkClassName: "findjobs",
    linkTo: `${routes.findJobs}/page/1`,
    icon: calenderIcon,
    title: "Find Jobs",
  },
  {
    id: 4,
    linkClassName: "manage",
    linkTo: `${routes.managejobs}/page/1`,
    icon: dashboardIcon,
    title: "Hire Crew",
  },
  {
    id: 5,
    linkClassName: "myjobs",
    linkTo: `${routes.myJobs}/page/1`,
    icon: calenderIcon,
    title: "My Applications",
  },
  {
    id: 6,
    linkClassName: "leads",
    linkTo: `${routes.leads}/page/1`,
    icon: userDocIcon,
    title: "Leads",
  },
  {
    id: 7,
    linkClassName: "invoice",
    linkTo: `${routes.invoice}/page/1`,
    icon: invoiceMailIcon,
    title: "Invoices",
  },
  {
    id: 8,
    linkClassName: "profile",
    linkTo: routes.profile,
    icon: userIcon,
    title: "Profile",
  },
];

export const adminSidebarLink = [
  {
    id: 1,
    linkClassName: "admindashboard",
    linkTo: routes.admindashboard,
    icon: dashboardIcon,
    title: "Dashboard",
  },
  {
    id: 2,
    linkClassName: "adminmanagejobs",
    linkTo: routes.adminmanagejobs,
    icon: calenderIcon,
    title: "Manage Jobs",
  },
  {
    id: 3,
    linkClassName: "adminmanageusers",
    linkTo: routes.adminmanageusers,
    icon: dashboardIcon,
    title: "Manage Users",
  },
  {
    id: 4,
    linkClassName: "adminleadsmanagement",
    linkTo: `${routes.adminleadsmanagement}/page/1`,
    icon: dashboardIcon,
    title: "Manage Leads",
  },
];

export const leadsColumnLabelList = [
  {
    id: 1,
    text: "PHOTOGRAPHER",
  },
  {
    id: 2,
    text: "VIDEOGRAPHER",
  },
];
export const leadsColumnLaadContactList = [
  {
    id: 1,
    heading: "Client Name",
    name: "client_name",
    paragrapg: "***** **********",
  },
  {
    id: 2,
    heading: "Email Address",
    name: "client_email",
    paragrapg: "*****@**********.com.au",
  },
  {
    id: 3,
    heading: "Phone Number",
    name: "client_phone_number",
    paragrapg: "**** *** ***",
    noteText: "*Mobile number has been verified",
  },
];

export const jobWorkerRoleClassname = (role) => {
  let classname = {
    PHOTO_EDITOR: "zu_common_label_success",
    PHOTOGRAPHER: "zu_common_label_success",
    VIDEO_EDITOR: "zu_common_label_primary",
    VIDEOGRAPHER: "zu_common_label_primary",
    DRONE_OPERATOR: "zu_common_label_other",
    ASSISTANT: "zu_common_label_other",
  };
  return classname[role];
};

export const jobWorkerRoleName = (role) => {
  let workerRole = {
    DRONE_OPERATOR: "DRONE (OPERATOR)",
    PHOTO_EDITOR: "PHOTO (EDITOR)",
    VIDEO_EDITOR: "VIDEO (EDITOR)",
  };
  return workerRole[role];
};

export const marks = [
  {
    value: 100,
    label: "100 Km",
  },
  {
    value: 200,
    label: "200 Km",
  },
  {
    value: 300,
    label: "300 Km",
  },
];

export const ratingTextValue = (values) => {
  return values.rating === 1
    ? "ONE"
    : values.rating === 2
    ? "TWO"
    : values.rating === 3
    ? "THREE"
    : values.rating === 4
    ? "FOUR"
    : values.rating === 5
    ? "FIVE"
    : "";
};

export const ratingTextValueForBusiness = (values) => {
  return values.BusinessRating === 1
    ? "ONE"
    : values.BusinessRating === 2
    ? "TWO"
    : values.BusinessRating === 3
    ? "THREE"
    : values.BusinessRating === 4
    ? "FOUR"
    : values.BusinessRating === 5
    ? "FIVE"
    : "";
};

export const ratingReviewList = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

export const specialtyLabelList = [
  {
    id: 1,
    labelText: "PHOTOGRAPHER",
    label: "Photographer",
  },
  {
    id: 2,
    labelText: "VIDEOGRAPHER",
    label: "Videographer",
  },
  {
    id: 3,
    labelText: "DRONE_OPERATOR",
    label: "Drone Operator",
  },
  {
    id: 4,
    labelText: "PHOTO_EDITOR",
    label: "Editor (Photos)",
  },
  {
    id: 5,
    labelText: "VIDEO_EDITOR",
    label: "Editor (Videos)",
  },
  {
    id: 6,
    labelText: "ASSISTANT",
    label: "Assistant",
  },
];

export const emailNotificationList = [
  {
    id: 1,
    labelText: "Photography Jobs",
    name: "PHOTOGRAPHER",
  },
  {
    id: 2,
    labelText: "Videography Jobs",
    name: "VIDEOGRAPHER",
  },
  // {
  //   id: 3,
  //   labelText: "Drone Operator Jobs",
  //   name: "DRONE_OPERATOR",
  // },
  {
    id: 3,
    labelText: "Editor (Photo) Jobs",
    name: "PHOTO_EDITOR",
  },
  {
    id: 4,
    labelText: "Editor (Video) Jobs",
    name: "VIDEO_EDITOR",
  },
  {
    id: 5,
    labelText: "Assistant Jobs",
    name: "ASSISTANT",
  },
];
export const jobCategoriesNotificationList = [
  {
    id: 1,
    labelText: "Corporate",
    name: "CORPORATE",
  },
  {
    id: 2,
    labelText: "Weddings",
    name: "WEDDINGS",
  },
  {
    id: 3,
    labelText: "Real Estate",
    name: "REAL_ESTATE",
  },
  {
    id: 4,
    labelText: "Events",
    name: "EVENTS",
  },
  {
    id: 5,
    labelText: "Other",
    name: "OTHERS",
  },
];
export const otherNotificationList = [
  {
    id: 1,
    labelText: "New Client Leads in my area",
    name: "NEW_LEAD",
  },
];

export const paymentInputList = [
  {
    id: 1,
    labelText: "Name on Bank Account",
    placeholderText: "Name on Bank Account",
    typeText: "text",
    name: "name_on_bank_account",
  },
  {
    id: 2,
    labelText: "Bank",
    placeholderText: "Bank",
    typeText: "text",
    name: "bank_name",
  },
  {
    id: 3,
    labelText: "BSB",
    placeholderText: "BSB",
    typeText: "tel",
    maxLength: 6,
    name: "bsb",
  },
  {
    id: 4,
    labelText: "Account Number",
    placeholderText: "Account Number",
    typeText: "tel",
    name: "account_number",
  },
];

export const iNeedLabelList = [
  {
    id: 1,
    labelText: "PHOTOGRAPHER",
    label: "Photographer",
  },
  {
    id: 2,
    labelText: "VIDEOGRAPHER",
    label: "Videographer",
  },
  {
    id: 3,
    labelText: "DRONE_OPERATOR",
    label: "Drone Operator",
  },
  {
    id: 4,
    labelText: "PHOTO_EDITOR",
    label: "Editor (Photos)",
  },
  {
    id: 5,
    labelText: "VIDEO_EDITOR",
    label: "Editor (Videos)",
  },
  {
    id: 6,
    labelText: "ASSISTANT",
    label: "Assistant",
  },
];

export const LeadProfessionTypeList = [
  {
    id: 1,
    labelText: "PHOTOGRAPHER",
    label: "Photographer",
  },
  {
    id: 2,
    labelText: "VIDEOGRAPHER",
    label: "Videographer",
  },

  {
    id: 3,
    labelText: "PHOTO_EDITOR",
    label: "Editor (Photos)",
  },
  {
    id: 4,
    labelText: "VIDEO_EDITOR",
    label: "Editor (Videos)",
  },
];

export const leadsStatusLists = [
  {
    id: 1,
    labelText: "PENDING",
    label: "Approval Pending",
  },
  {
    id: 2,
    labelText: "ACTIVE",
    label: "Active Leads",
  },
  {
    id: 3,
    labelText: "ARCHIVED",
    label: "Archived Leads",
  },
  {
    id: 4,
    labelText: "CANCELLED",
    label: "Cancelled Leads",
  },
];

export const jobTypeList = [
  {
    id: 1,
    labelText: "EVENTS",
    label: "Event",
  },
  {
    id: 2,
    labelText: "REAL_ESTATE",
    label: "Real Estate",
  },
  {
    id: 3,
    labelText: "WEDDINGS",
    label: "Wedding",
  },
  {
    id: 4,
    labelText: "CORPORATE",
    label: "Corporate",
  },
  {
    id: 5,
    labelText: "OTHERS",
    label: "Other",
  },
];

export const jobRoleList = [
  {
    id: 1,
    labelText: "PHOTOGRAPHER",
    label: "Photographer",
  },
  {
    id: 2,
    labelText: "VIDEOGRAPHER",
    label: "Videographer",
  },
  {
    id: 3,
    labelText: "DRONE_OPERATOR",
    label: "Drone Operator",
  },
  {
    id: 4,
    labelText: "PHOTO_EDITOR",
    label: "Editor (Photos)",
  },
  {
    id: 5,
    labelText: "VIDEO_EDITOR",
    label: "Editor (Videos)",
  },
  {
    id: 6,
    labelText: "ASSISTANT",
    label: "Assistant",
  },
];

export const jobStatusList = [
  {
    id: 1,
    filterLabel: "Approval pending",
    labelText: "POSTED",
    label: "Posted",
  },
  {
    id: 2,
    filterLabel: "Approved",
    labelText: "NEW",
    label: "New",
  },
  {
    id: 3,
    filterLabel: "Hire pending",
    labelText: "WAITING_FOR_RESPONSE",
    label: "Hire Pending",
  },
  {
    id: 4,
    filterLabel: "Hired",
    labelText: "OFFER_ACCEPTED",
    label: "Hired",
  },
  {
    id: 5,
    filterLabel: "In Progress",
    labelText: "IN_PROGRESS",
    label: "In Progress",
  },
  {
    id: 6,
    filterLabel: "Completed",
    labelText: "COMPLETED_DONE",
    label: "Completed",
  },
  // {
  //   id: 7,
  //   filterLabel: "Done",
  //   labelText: "DONE",
  //   label: "Done",
  // },
  {
    id: 7,
    filterLabel: "Cancelled",
    labelText: "CANCELLED",
    label: "Cancelled",
  },
];

export const myJobStatusList = [
  {
    id: 1,
    filterLabel: "Pending Offers",
    labelText: "WAITING_FOR_RESPONSE",
    label: "Offers",
  },
  {
    id: 2,
    filterLabel: "Confirmed Jobs",
    labelText: "OFFER_ACCEPTED",
    label: "Confirmed",
  },
  {
    id: 3,
    filterLabel: "In Progress Jobs",
    labelText: "IN_PROGRESS",
    label: "In Progress",
  },
  {
    id: 4,
    filterLabel: "Completed Jobs",
    labelText: "COMPLETED_DONE",
    label: "Completed",
  },
  // {
  //   id: 5,
  //   filterLabel: "Done jobs",
  //   labelText: "DONE",
  //   label: "Done",
  // },
  {
    id: 5,
    filterLabel: "Cancelled Jobs",
    labelText: "CANCELLED",
    label: "Cancelled",
  },
  {
    id: 6,
    filterLabel: "Rejected Jobs",
    labelText: "REJECTED",
    label: "Rejected",
  },
];

// starsss

export const starList = [
  {
    id: 1,
    starText: "ONE",
    starNum: 1,
  },
  {
    id: 2,
    starText: "TWO",
    starNum: 2,
  },
  {
    id: 3,
    starText: "THREE",
    starNum: 3,
  },
  {
    id: 4,
    starText: "FOUR",
    starNum: 4,
  },
  {
    id: 5,
    starText: "FIVE",
    starNum: 5,
  },
];

// table data

export const ProcessingLabel = () => (
  <span className="zu_table_processing_button">Processing</span>
);
export const DownloadButton = ({ jobDetail }) => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      className="zu_table_download_button"
      onClick={() =>
        navigate(`/invoice/${jobDetail?.id}/true`, {
          state: {
            jobDetail: jobDetail,
            person: capitalizeFirstLetter(jobDetail.invoice_type),
          },
        })
      }
    >
      View / Download
    </button>
  );
};
export const CrewImageText = ({ imgsrc, text }) => (
  <div className="zu_crew_img_text_row">
    {" "}
    <img
      src={
        imgsrc
          ? `${process.env.REACT_APP_FILE_BASE_URL}/${imgsrc}`
          : "https://my-first-test-bucket-39.s3.ap-south-1.amazonaws.com/9/e3be1129-eafa-47fb-a116-3e7590c57931.png"
      }
      alt="user img"
    />{" "}
    <span className="zu_table_text">{text}</span>{" "}
  </div>
);
export const TableIdText = (props) => (
  <p className="zu_table_label_text">{props.text}</p>
);

export const TableJobDateText = (props) => (
  <p className="zu_table_text">{props.text}</p>
);

export const TableBusinessText = (props) => (
  <p className="zu_table_text">{`${props.text.slice(0, 15)}${
    props.text.length > 15 ? "..." : ""
  }`}</p>
);

export const TableMailText = (props) => (
  <p className="table_text">{props.text}</p>
);
const TableStatusLabel = (props) => {
  return (
    <div className="zu_table_action_btn_row">
      <div
        className={`zu_table_status_label`}
        onClick={() => props.onViewClickHandler()}
      >
        {" "}
        View{" "}
      </div>{" "}
      <div
        className={`zu_table_status_label ${
          props.labelColor
        } zu_table_status_text ${props.btnStyle && "zu_table_status_style"}`}
        onClick={() => props.onClickHandler()}
      >
        {" "}
        {props.text}
        {props.icon}
        {/* <img src={props.icon} />{" "} */}
      </div>
    </div>
  );
};
export const RowLabel = (props) => (
  <div className="zu_table_label_row">
    {" "}
    <TableStatusLabel
      text={props.text}
      labelColor={props.labelColor}
      onClickHandler={() => props.onClickHandler()}
      onViewClickHandler={() => props.onViewClickHandler()}
      icon={props.icon}
      btnStyle={props.btnStyle}
    />{" "}
  </div>
);

export let month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const ActionBtn = (props) => (
  <div className="zu_table_action_btn_row">
    {" "}
    <button
      // to={props?.onViewButtonClick}
      type="button"
      className="zu_table_action_btn zu_table_action_view_btn"
      onClick={props.onViewButtonClick}
    >
      view
    </button>
    {props.userdeleteStatus !== "1" && (
      <>
        <button
          onClick={props.onButtonClick}
          type="button"
          className={`zu_table_action_btn ${props.buttonColor}`}
        >
          {props.buttonText}
        </button>
        {props.optionalButton && (
          <button
            onClick={props.onAnotherButtonClick}
            type="button"
            className={`zu_table_action_btn ${props.anotherButtonColor}`}
          >
            Reject
          </button>
        )}
      </>
    )}
  </div>
);

export const tableStyles = {
  table: {
    style: {
      borderRadius: "6px",
      overflow: "hidden",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#FAFAFA",
      borderBottomWidth: "1px",
      borderBottomColor: "#ECECEC",
      borderBottomStyle: "solid",
    },
  },
  rows: {
    style: {
      borderBottomWidth: "2px",
      borderBottomColor: "#ECECEC",
      borderBottomStyle: "solid",
      "&:not(:last-of-type)": {
        borderBottomWidth: "2px",
        borderBottomColor: "#ECECEC",
      },
    },
  },
  headCells: {
    style: {
      paddingTop: "16px",
      paddingBottom: "16px",
      paddingLeft: "16px",
      paddingRight: "16px",
      fontSize: "18px",
      color: "#5F0166",
      fontFamily: "Proxima-Nova-Bold",
    },
  },
  cells: {
    style: {
      paddingTop: "16px",
      paddingBottom: "16px",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  pagination: {
    style: {
      backgroundColor: "transparent",
      borderTopWidth: "0px",
    },
    pageButtonsStyle: {
      borderRadius: "5px",
      height: "40px",
      width: "40px",
      padding: "8px",
      margin: "0px",
      cursor: "pointer",
      transition: "0.4s",
      color: "#9D9D9D",
      fill: "#9D9D9D",
      backgroundColor: "transparent",
      "&:disabled": {
        cursor: "unset",
        color: "#9D9D9D",
        fill: "#9D9D9D",
      },
      "&:hover:not(:disabled)": {
        backgroundColor: "#5F0166",
        color: "#ffffff",
        fill: "#ffffff",
      },
      "&:focus": {
        outline: "none",
        backgroundColor: "#5F0166",
        color: "#ffffff",
        fill: "#ffffff",
      },
    },
  },
};

export const invoiceTableColums = [
  {
    name: "ID",
    selector: (row) => row.projectId,
    grow: 0.5,
  },
  {
    name: "Job Date",
    selector: (row) => row.date,
    center: true,
  },
  {
    name: "Invoice Type",
    selector: (row) => row.invoice_type,
    center: true,
  },
  {
    name: "Crew / Business",
    selector: (row) => row.crewImgText,
    center: true,
    // grow: 2,
  },
  {
    name: "Invoice",
    selector: (row) => row.invoiceButton,
    center: true,
  },
];

export const adminManageJobTableColums = [
  {
    name: "Job ID",
    selector: (row) => row.jobId,
    center: true,
    grow: 0.3,
  },
  {
    name: "Job Date",
    selector: (row) => row.jobDate,
    center: true,
  },
  {
    name: "Job Title",
    selector: (row) => row.jobTitle,
    grow: 0.8,
    // center: true,
  },
  {
    name: "Job Owner",
    selector: (row) => row.crewImgText,
    // center: true,
    grow: 0.75,
  },
  {
    name: "Status",
    selector: (row) => row.statusLabel,
    center: true,
    grow: 2,
  },
];

export const manageUsersTableStyles = {
  table: {
    style: {
      borderRadius: "6px",
      overflow: "hidden",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#FAFAFA",
      borderBottomWidth: "1px",
      borderBottomColor: "#ECECEC",
      borderBottomStyle: "solid",
    },
  },
  rows: {
    style: {
      borderBottomWidth: "2px",
      borderBottomColor: "#ECECEC",
      borderBottomStyle: "solid",
      "&:not(:last-of-type)": {
        borderBottomWidth: "2px",
        borderBottomColor: "#ECECEC",
      },
    },
  },
  headCells: {
    style: {
      paddingTop: "16px",
      paddingBottom: "16px",
      paddingLeft: "16px",
      paddingRight: "16px",
      fontSize: "18px",
      color: "#5F0166",
      fontFamily: "Proxima-Nova-Bold",
    },
  },
  cells: {
    style: {
      paddingTop: "16px",
      paddingBottom: "16px",
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  pagination: {
    style: {
      backgroundColor: "transparent",
      borderTopWidth: "0px",
    },
    pageButtonsStyle: {
      borderRadius: "5px",
      height: "40px",
      width: "40px",
      padding: "8px",
      margin: "0px",
      cursor: "pointer",
      transition: "0.4s",
      color: "#9D9D9D",
      fill: "#9D9D9D",
      backgroundColor: "transparent",
      "&:disabled": {
        cursor: "unset",
        color: "#9D9D9D",
        fill: "#9D9D9D",
      },
      "&:hover:not(:disabled)": {
        backgroundColor: "#5F0166",
        color: "#ffffff",
        fill: "#ffffff",
      },
      "&:focus": {
        outline: "none",
        backgroundColor: "#5F0166",
        color: "#ffffff",
        fill: "#ffffff",
      },
    },
  },
};

export const manageUsersTableColums = [
  {
    name: "User ID",
    selector: (row) => row.userId,
    grow: 1,
  },
  {
    name: "CREW",
    selector: (row) => row.crewImgText,
    grow: 1.5,
  },
  {
    name: "E-MAIL",
    selector: (row) => row.mail,
    grow: 1.5,
    wrap: true,
  },
  {
    name: "ACTIONS",
    selector: (row) => row.actionBtn,
    grow: 1.9,
  },
];
export const manageApproveUsersTableColums = [
  {
    name: "USER ID",
    selector: (row) => row.id,
    grow: 1,
  },
  {
    name: "CREW",
    selector: (row) => row.crewImgText,
    grow: 1.5,
  },
  {
    name: "E-MAIL",
    selector: (row) => row.mail,
    grow: 1.5,
  },
  {
    name: "ACTIONS",
    selector: (row) => row.actionBtn,
    grow: 1.8,
  },
];
