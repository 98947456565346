import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../css/component.css";
import image from "../../images/default-user.jpg";

import {
  locationIcon,
  warningIcon,
  checkedGreenIcon,
  closeIcon,
} from "../../icons";
import moment from "moment";
import { jobWorkerRoleClassname, jobWorkerRoleName } from "../../constants";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";

const JobsColumn = ({ job, hasUpcommingJob }) => {
  const [lessText, setLessText] = useState(false);

  const jobStatusClassname = (status) => {
    let labelColor = {
      COMPLETED_JOB: "zu_btn_yellow",
      OFFER_ACCEPTED: "zu_btn_green",
      WAITING_FOR_RESPONSE: "zu_btn_pending_green",
      CANCELLED: "zu_btn_pending_red",
      IN_PROGRESS: "zu_btn_progress_orange",
      COMPLETED: "zu_btn_purple",
      DONE: "zu_btn_voilet",
    };
    return labelColor[status];
  };

  const jobStatusIcon = (status) => {
    let jobIcon = {
      IN_PROGRESS: warningIcon,
      DONE: checkedGreenIcon,
      CANCELLED: closeIcon,
    };
    return jobIcon[status];
  };

  const jobStatusName = (status, applicant) => {
    let statusName = {
      POSTED: applicant + " APPLICANTS",
      NEW: applicant + " APPLICANTS",
      WAITING_FOR_RESPONSE: "HIRE PENDING",
      OFFER_ACCEPTED: "HIRED",
      COMPLETED: "COMPLETED",
      CANCELLED: "CANCELLED",
      IN_PROGRESS: "COMPLETE JOB",
      DONE: "DONE",
    };
    return statusName[status];
  };

  let word = "";
  const wordFunction = (id) => {
    for (let i = 0; i < 15; i++) {
      word += " " + id[i];
    }
    return word;
  };

  const readMoreHandler = () => {
    setLessText(!lessText);
  };

  return (
    <div className="zu_jobs_column" key={job?.Id}>
      <div className="zu_jobs_column_left_content">
        <img
          src={
            job?.profile_photo
              ? `${process.env.REACT_APP_FILE_BASE_URL}/${job?.profile_photo}`
              : image
          }
          alt="user profile"
          className="zu_jobs_column_left_user_img"
        />
        <div className="zu_jobs_column_name_description_text">
          <div className="zu_jobs_column_name_row">
            <h3>{capitalizeFirstLetter(job?.title)}</h3>
            <span>
              By {capitalizeFirstLetter(job?.first_name)}{" "}
              {capitalizeFirstLetter(job?.last_name)}
            </span>
            <p>
              {locationIcon} {job?.address}
            </p>
          </div>
          <div className="zu_jobs_column_price_time_text">
            <p>{moment(job?.date).utc().format("DD MMM YYYY")}</p>
            <p>{job?.number_of_hour + " Hours"}</p>
            <p>{"$" + job?.price_per_hour?.toFixed(2) + " Per Hour"}</p>
          </div>
          {job?.brief?.split(" ")?.length < 16 ? (
            <pre className="zu_jobs_column_description_text zu_jobs_column_brief_pre">
              {job?.brief}
            </pre>
          ) : (
            <pre className="zu_jobs_column_description_text zu_jobs_column_brief_pre">
              {lessText ? job?.brief : wordFunction(job?.brief?.split(" "))}{" "}
              {job?.brief?.split(" ")?.length > 10 && (
                <span className="readmore_link" onClick={readMoreHandler}>
                  {lessText ? "Read less..." : "Read more..."}
                </span>
              )}
            </pre>
          )}
          <div className="zu_jobs_column_label_row">
            {job?.job_worker_roles &&
              JSON.parse(job?.job_worker_roles).map((i, id) => {
                return (
                  <span
                    className={`zu_common_label ${jobWorkerRoleClassname(i)} `}
                    key={`zu_label${id}`}
                  >
                    {i === "DRONE_OPERATOR" ||
                    i === "PHOTO_EDITOR" ||
                    i === "VIDEO_EDITOR"
                      ? jobWorkerRoleName(i)
                      : i}
                  </span>
                );
              })}
            <span
              className={`zu_common_label zu_common_label_light`}
              key={`zu_label`}
            >
              JOB ID: {job?.id}
            </span>
          </div>
          <Link
            to={`/managejobsdetail/${job?.id}`}
            state={{ job_status: job?.job_status }}
            className="zu_jobs_column_price_more_detail_btn zu_find_jobs_column_more_detail"
          >
            More Details
          </Link>
        </div>
        <div className="zu_jobs_column_price_more_detail">
          <p>{moment(job?.date).utc().format("DD MMM YYYY")}</p>
          <p>
            {job?.extra_hour
              ? job?.extra_hour + job?.number_of_hour
              : job?.number_of_hour}{" "}
            Hours
          </p>
          <p>${job?.price_per_hour?.toFixed(2)} Per Hour </p>
          {hasUpcommingJob && (
            <Link
              to={`/managejobsdetail/${job?.id}`}
              state={{ job_status: job?.job_status }}
              className="zu_jobs_column_price_more_detail_btn zu_find_jobs_column_more_detail"
            >
              More Details
            </Link>
          )}
        </div>
      </div>
      <div className="zu_jobs_column_right_content">
        {hasUpcommingJob && (
          <>
            <div className="zu_jobs_column_right_text_row">
              <span className="zu_jobs_column_right_payment_text">
                Crew Payment
              </span>
              <p className="zu_jobs_column_right_price_text">
                $
                {job?.extra_hour
                  ? (
                      (job?.extra_hour + job?.number_of_hour) *
                      job?.price_per_hour
                    ).toFixed(2)
                  : (job?.number_of_hour * job?.price_per_hour).toFixed(2)}{" "}
                AUD Total
              </p>
            </div>

            <Link
              to={`/managejobsdetail/${job?.id}`}
              state={{ job_status: job?.job_status }}
              className={`zu_common_btn ${jobStatusClassname(
                job?.job_status
              )} ${
                job?.job_status === "OFFER_ACCEPTED" &&
                "zu_job_status_crew_name"
              }`}
            >
              {jobStatusIcon(job?.job_status)}
              {jobStatusName(job?.job_status, job?.total_applicant_count)}
              {job?.job_status === "OFFER_ACCEPTED" && (
                <span className="zu_job_crew_name">
                  {job?.crew_first_name + " " + job?.crew_last_name}
                </span>
              )}
            </Link>
            {job?.job_status === "CANCELLED" && job?.shooter && (
              <span className="zu_admin_cancel_text">By Admin</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default JobsColumn;
