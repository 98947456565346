import React from "react";
import Input from "../../component/Common/Input";
import AutoComplete from "../Common/AutoComplete";
import DatePicker, { registerLocale } from "react-datepicker";
import { Form } from "react-bootstrap";
import moment from "moment";
import { iNeedLabelList, jobTypeList } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import enGB from "date-fns/locale/en-GB";
import { postNewLead, updateLead } from "../../store/slice/leadsSlice";
import { useEffect } from "react";
import Loader from "../Common/Loader";
import { checkDateIsMonday } from "../../helpers/commonFunction";

const LeadForm = ({
  leadDetail,
  setLeadSubmitModal,
  blurForm,
  contactNumber,
  directPost,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  registerLocale("enGB", enGB);

  const leadLoading = useSelector((state) => state.leads.loader);

  const initialFValues = {
    client_email: leadDetail?.client_email ? leadDetail?.client_email : "",
    client_name: leadDetail?.client_name ? leadDetail?.client_name : "",
    client_phone_number: leadDetail?.client_phone_number
      ? leadDetail?.client_phone_number
      : contactNumber
      ? contactNumber
      : "",
    date_of_event: leadDetail?.date_of_event
      ? new Date(leadDetail?.date_of_event)
      : moment().add(1, "day").toDate(),
    address: leadDetail ? leadDetail?.address : "",
    details: leadDetail?.details ? leadDetail?.details : "",
    num_of_hours: leadDetail?.num_of_hours ? leadDetail?.num_of_hours : "",
    lead_type: leadDetail?.lead_type ? leadDetail?.lead_type : "EVENTS",
    roles: leadDetail?.roles ? JSON.parse(leadDetail?.roles) : ["PHOTOGRAPHER"],
    budget: leadDetail?.budget ? leadDetail?.budget : "",
  };

  useEffect(() => {
    setValues({
      ...values,
      client_phone_number: leadDetail?.client_phone_number
        ? leadDetail?.client_phone_number
        : contactNumber
        ? contactNumber
        : "",
    });
  }, [contactNumber]);

  // Validation
  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    if ("client_name" in fieldValues) {
      temp.client_name =
        fieldValues.client_name && fieldValues.client_name.trim()
          ? ""
          : "Please enter Full Name";
    }
    if ("client_email" in fieldValues) {
      temp.client_email =
        fieldValues.client_email && fieldValues.client_email.trim()
          ? ""
          : "Please enter Email Address";
    }
    if ("client_phone_number" in fieldValues) {
      temp.client_phone_number =
        fieldValues.client_phone_number &&
        fieldValues.client_phone_number.trim()
          ? ""
          : "Please enter Contact Number";
    }

    if ("address" in fieldValues) {
      temp.address =
        fieldValues.address && fieldValues.address.trim()
          ? ""
          : "You must enter a location for this Job";
    }
    if ("date_of_event" in fieldValues) {
      temp.date_of_event = fieldValues.date_of_event
        ? ""
        : "Please select a date!";
    }

    if ("roles" in fieldValues) {
      temp.roles =
        fieldValues.roles?.length !== 0 ? "" : "What kind of crew you need?";
    }

    if ("lead_type" in fieldValues) {
      temp.lead_type =
        fieldValues.lead_type?.length !== 0 ? "" : "What type of job is this?";
    }

    if ("details" in fieldValues) {
      temp.details =
        fieldValues.details && fieldValues.details.trim()
          ? ""
          : "A detailed job information is required";
    }
    if ("num_of_hours" in fieldValues) {
      temp.num_of_hours =
        fieldValues.num_of_hours && fieldValues.num_of_hours
          ? ""
          : "The number of hours is required";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  var dateString = moment(values.date_of_event).format().split("T")[0];

  const updateLeadHandler = () => {
    if (validate()) {
      const data = {
        date_of_event: dateString,
        roles: values.roles,
        client_name: values.client_name,
        client_email: values.client_email,
        client_phone_number: values.client_phone_number,
        address: values.address,
        lead_type: values.lead_type,
        num_of_hours: +values.num_of_hours,
        details: values.details,
      };

      if (leadDetail) {
        if (values?.budget) {
          data.budget = +values.budget;
        } else {
          data.budget = null;
        }
      } else if (values?.budget) {
        data.budget = +values.budget;
      }

      if (leadDetail) {
        dispatch(
          updateLead({ data, id: leadDetail?.id, navigate, edit: true })
        );
      } else {
        dispatch(
          postNewLead({
            data,
            cb: (err, res) => {
              if (err) {
              } else {
                if (directPost) {
                  setLeadSubmitModal(true);
                } else {
                  setLeadSubmitModal(true);
                }
              }
            },
          })
        );
      }
    }
  };

  const MyContainer = ({ className, children }) => {
    return (
      <div
        className={`zu_common_custom_date_picker ${
          checkDateIsMonday(values.date_of_event) && `zu_color_disable_mon`
        }`}
      >
        <div className={className}>
          <h2 className="zu_common_custom_date_picker_heading">
            Select Job Date
          </h2>
          <div className="zu_common_custom_date_picker_content">{children}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      {leadLoading && <Loader />}
      <div
        className={`zu_manage_new_job_post_content ${
          directPost && !blurForm && "zu_blur_disable_form"
        }`}
      >
        <div className="zu_manage_new_job_post_row">
          <div className="zu_manage_new_job_post_column">
            <div className="zu_manage_new_job_post_left_input">
              <div className="zu_common_form_input_content">
                <Input
                  labelClassName="zu_common_form_input_label"
                  inputClassName="zu_common_form_input"
                  errorClassName="err_text"
                  label="Full Name"
                  placeholder="Enter Full Name"
                  type="text"
                  id="client_name"
                  name="client_name"
                  onChange={handleInputChange}
                  value={values.client_name}
                />
                {errors?.client_name ? (
                  <span className="err_text">{errors?.client_name}</span>
                ) : null}
              </div>
              <div className="zu_common_form_input_content">
                <Input
                  labelClassName="zu_common_form_input_label"
                  inputClassName="zu_common_form_input"
                  errorClassName="err_text"
                  placeholder="Enter Email"
                  label="Enter Email"
                  type="email"
                  id="client_email"
                  name="client_email"
                  onChange={handleInputChange}
                  value={values.client_email}
                />
                {errors?.client_email ? (
                  <span className="err_text">{errors?.client_email}</span>
                ) : null}
              </div>
              <div className="zu_common_form_input_content">
                <Input
                  labelClassName="zu_common_form_input_label"
                  inputClassName="zu_common_form_input"
                  errorClassName="err_text"
                  label="Contact Number"
                  placeholder="Enter Contact Number"
                  type="tel"
                  maxLength={10}
                  id="client_phone_number"
                  name="client_phone_number"
                  onChange={handleInputChange}
                  value={values.client_phone_number}
                  disabled={directPost}
                />
                {errors?.client_phone_number ? (
                  <span className="err_text">
                    {errors?.client_phone_number}
                  </span>
                ) : null}
              </div>

              <div className="zu_common_form_input_content">
                <AutoComplete
                  label="Location of Lead"
                  placeholder="Town / City"
                  id="address"
                  name="address"
                  handleInputChange={handleInputChange}
                  values={values}
                  setValues={setValues}
                />
                {errors?.address ? (
                  <span className="err_text">{errors?.address}</span>
                ) : null}
              </div>
              <div className="zu_common_form_input_content">
                <label
                  htmlFor="dateJobInput"
                  className="zu_common_form_input_label"
                >
                  Date Required
                </label>
                <DatePicker
                  id="date_of_event"
                  className="zu_common_form_input"
                  placeholderText="MM/DD/YYYY"
                  dateFormat="dd/MM/yyyy"
                  name="date_of_event"
                  locale="enGB"
                  selected={values.date_of_event}
                  dateFormatCalendar={"MMM yyyy"}
                  formatWeekDay={(nameOfDay) =>
                    nameOfDay.toString().substr(0, 3).toUpperCase()
                  }
                  onChange={(date) => {
                    setValues({ ...values, date_of_event: date });
                  }}
                  calendarContainer={MyContainer}
                  minDate={moment().add(1, "day").toDate()}
                />
                {errors?.date_of_event ? (
                  <span className="err_text">{errors?.date_of_event}</span>
                ) : null}
              </div>
            </div>
            <div className="zu_manage_new_job_post_left_label_content">
              <h4 className="zu_manage_new_job_post_left_label_heading">
                I Need A ...
              </h4>
              <div className="zu_specialty_label_row">
                {iNeedLabelList.map((i) => {
                  return (
                    <div
                      key={i.id}
                      className="zu_specialty_label_column zu_select_label_box"
                    >
                      <input
                        id={`zu_I_need_label${i.id}`}
                        type="checkbox"
                        className="d-none"
                        name="roles"
                        value={i.labelText}
                        //   checked={values.roles === i.labelText}
                        checked={values.roles?.includes(i.labelText)}
                        onChange={(e) => handleInputChange(e, null, true)}
                        //   onChange={workerInputChange}
                      />
                      <label htmlFor={`zu_I_need_label${i.id}`}>
                        {i.label}
                      </label>
                    </div>
                  );
                })}
              </div>
              {errors?.roles ? (
                <span className="err_text">{errors?.roles}</span>
              ) : null}
            </div>
          </div>
          <div className="zu_manage_new_job_post_column">
            <div className="zu_manage_new_job_post_discription_content">
              <div className="zu_manage_new_job_post_left_label_content">
                <h4 className="zu_manage_new_job_post_left_label_heading">
                  Job Type
                </h4>
                <div className="zu_specialty_label_row">
                  {jobTypeList.map((i) => {
                    return (
                      <div
                        key={i.id}
                        className="zu_specialty_label_column zu_select_label_box"
                      >
                        <input
                          id={`zu_job_type_label${i.id}`}
                          type="radio"
                          className="d-none"
                          name="lead_type"
                          value={i.labelText}
                          checked={values.lead_type?.includes(i.labelText)}
                          onChange={handleInputChange}
                        />
                        <label htmlFor={`zu_job_type_label${i.id}`}>
                          {i.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
                {errors?.lead_type ? (
                  <span className="err_text">{errors?.lead_type}</span>
                ) : null}
              </div>
              <h2 className>Job Brief</h2>
              <p>
                Please add as much detail as you can, so businesses know exactly
                what you're needing for this job and can provide a quote
                accordingly.
              </p>
              <Form.Control
                as="textarea"
                rows={10}
                id="details"
                placeholder="Be specific about this job"
                className="zu_manage_new_job_post_discription_input"
                onChange={handleInputChange}
                name="details"
                value={values.details}
              />
              {errors?.details ? (
                <span className="err_text">{errors?.details}</span>
              ) : null}
            </div>

            <div className="zu_manage_new_job_post_hours_column mb-4">
              <Input
                className="zu_common_form_input_content"
                labelClassName="zu_common_form_input_label"
                inputClassName="zu_common_form_input"
                errorClassName="err_text"
                label="Hours Required"
                placeholder="Number of Hours"
                type="tel"
                id="num_of_hours"
                name="num_of_hours"
                onChange={handleInputChange}
                value={values.num_of_hours}
              />
              {errors?.num_of_hours ? (
                <span className="err_text">{errors?.num_of_hours}</span>
              ) : null}
            </div>
            <div className="zu_manage_new_job_post_price_column">
              <Input
                className="zu_common_form_input_content"
                labelClassName="zu_common_form_input_label"
                inputClassName="zu_common_form_input"
                errorClassName="err_text"
                label="Budget (Optional)"
                placeholder="Enter Budget for job"
                type="tel"
                id="budget"
                name="budget"
                onChange={handleInputChange}
                value={values.budget}
              />
            </div>
          </div>
        </div>
        <div className="zu_manage_new_job_btn_content">
          <button
            type="button"
            className="zu_common_btn zu_btn_light_green"
            onClick={updateLeadHandler}
          >
            {directPost
              ? "GET QUOTES"
              : leadDetail
              ? "Update Lead"
              : "Post Lead"}
          </button>
          {!directPost && (
            <button
              type="button"
              className="zu_manage_new_job_back_btn"
              onClick={() => navigate(-1)}
            >
              Cancel - Go Back
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default LeadForm;
