import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../css/component.css";
import {
  clearUserProfile,
  getProfile,
  logout,
} from "../../store/slice/userSlice";
import { useLocation } from "react-router-dom";
import { routes, sidebarLink } from "../../constants";
import { closeIcon, logo } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getChatUsers } from "../../store/slice/chatSlice";

const Sidebar = ({ setMsgCount, msgCount }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [chatUsers, setChatUsers] = useState();

  const user = useSelector((state) => state.user.user);
  const id = user?.user_id;

  const count = () => {
    let msgCount = 0;
    for (let i = 0; i < chatUsers?.length; i++) {
      if (chatUsers[i]?.unread_count !== 0) {
        msgCount = msgCount + chatUsers[i]?.unread_count;
      }
    }
    return msgCount;
  };

  const totalCount = count();

  useEffect(() => {
    setMsgCount(totalCount);
  }, [chatUsers]);

  useEffect(() => {
    dispatch(
      getChatUsers({
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setChatUsers(res);
          }
        },
      })
    );
  }, [navigate]);

  useEffect(() => {
    dispatch(getProfile({ id }));
  }, [dispatch, id]);

  const logoutHandler = () => {
    dispatch(logout());
    toast.success("See you next time", {
      autoClose: 2000,
    });
    navigate(routes.login);
    // clearInterval(interval);
  };

  return (
    <>
      <div
        className="zu_sidebar_bg_overlay"
        onClick={() => document.body.classList.remove("zu_open_sidebar")}
      ></div>
      <div className="zu_sidebar_section">
        <button
          type="button"
          className="zu_top_header_toggal_close_btn"
          onClick={() => document.body.classList.remove("zu_open_sidebar")}
        >
          {closeIcon}
        </button>
        <div className="zu_sidebar_content">
          <div className="zu_sidebar_logo">
            <Link to={routes.dashboard}>{logo}</Link>
          </div>
          <ul className="zu_sidebar_nav">
            {sidebarLink.map((i) => {
              return (
                <li className="zu_sidebar_item" key={`zu_sidebar_item${i.id}`}>
                  <button
                    type="button"
                    className={`zu_sidebar_link ${
                      pathname?.match(
                        new RegExp("^.*" + i.linkClassName + ".*$")
                      ) && "active"
                    } `}
                    onClick={() => {
                      if (i.title === "Profile") {
                        dispatch(clearUserProfile());
                      }
                      document.body.classList.remove("zu_open_sidebar");
                      navigate(i.linkTo);
                    }}
                  >
                    {i.msgPanding && msgCount !== 0 && (
                      <b className="zu_sidebar_link_pandding_msg">{msgCount}</b>
                    )}
                    {i.icon}
                    <span>{i.title}</span>
                  </button>
                </li>
              );
            })}
            <li className="zu_sidebar_item d-lg-none">
              <button
                type="button"
                className="zu_sidebar_link null"
                onClick={() => {
                  document.body.classList.remove("zu_open_sidebar");
                  navigate(routes.settings);
                }}
              >
                <span>Settings</span>
              </button>
            </li>
            <li className="zu_sidebar_item d-lg-none">
              <button
                type="button"
                className="zu_sidebar_link null "
                onClick={(e) => {
                  e.preventDefault();
                  document.body.classList.remove("zu_open_sidebar");
                  navigate(routes.notification);
                }}
              >
                <span>notifications</span>
              </button>
            </li>
            <li className="zu_sidebar_item d-lg-none pb-0">
              <button
                type="button"
                className="zu_sidebar_link null "
                onClick={() => {
                  document.body.classList.remove("zu_open_sidebar");
                  logoutHandler();
                }}
              >
                <span>Sign out</span>
              </button>
            </li>
            <div className="zu_sidebar_bottom_link">
              <Link
                to={routes.settings}
                className={`d-lg-block d-none ${
                  pathname?.match(new RegExp("^.*" + "settings" + ".*$")) &&
                  "active"
                }`}
              >
                Settings
              </Link>
              <a
                href="mailto:support@shootzu.com"
                target="_blank"
                rel="noreferrer"
              >
                Need Support?
              </a>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
