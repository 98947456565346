import { Navigate } from "react-router-dom";
import AuthPage from "./component/Layout/AuthPage";
import Layout from "./component/Layout/MainLayout";
import LoginPage from "./pages/Login/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPasswordPage";
import ForgotPasswordCheckYourEmailPage from "./pages/ForgotPassword/ForgotPasswordCheckYourEmailPage";
import ForgotChangePasswordPage from "./pages/ForgotPassword/ForgotChangePasswordPage";
import ConfirmPasswordPage from "./pages/ForgotPassword/ConfirmPasswordPage";
import SignUpPage from "./pages/SignUp/SignUpPage";
import ConfirmCreateAccountPage from "./pages/SignUp/ConfirmCreateAccountPage";
import SignUpCheckYourEmailPage from "./pages/SignUp/SignUpCheckYourEmailPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import AdminDashboardPage from "./pages/Dashboard/AdminDashboardPage";
import FindJobsPage from "./pages/FindJobs/FindJobsPage";
import InvoicePage from "./pages/Invoice/InvoicePage";
import ManageJobsPage from "./pages/ManageJobs/ManageJobsPage";
import ManageJobsDetailPage from "./pages/ManageJobs/ManageJobsDetailPage";
import ManageNewPostJobPage from "./pages/ManageJobs/ManageNewPostJobPage";
import AdminManageJobsPage from "./pages/ManageJobs/AdminManageJobsPage";
import LeadsPage from "./pages/Leads/LeadsPage";
import AdminLeadsManagementPage from "./pages/Leads/AdminLeadsManagementPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import ProfileEditPage from "./pages/Profile/ProfileEditPage";
import ApplicantProfilePage from "./pages/Profile/ApplicantProfilePage";
import AdminManageUsersProfilePage from "./pages/Profile/AdminManageUsersProfilePage";
import NotificationPage from "./pages/Notification/NotificationPage";
import PaymentPage from "./pages/Payment/PaymentPage";
import AdminManageUsersPage from "./pages/ManageUsers/AdminManageUsersPage";
import Page404 from "./pages/Page404/Page404";
import PrivacyPolicyPage from "./pages/PrivacyTerms/PrivacyPolicyPage";
import TermsConditionsPage from "./pages/PrivacyTerms/TermsConditionsPage";
import { routes } from "../src/constants";
import ManageEditPostPage from "./pages/ManageJobs/ManageEditPostPage";
import MyJobsPage from "./pages/MyJobs/MyJobsPage";
import BusinessInvoice from "./pages/Invoice/BusinessInvoice";
import BusinessMailInvoice from "./pages/Invoice/BusinessMailInvoice";
import AdminEditLeads from "./pages/Leads/AdminEditLeads";
import LeadsInvoicePage from "./pages/Leads/LeadsInvoicePage";
import CommonLeadForm from "./pages/Leads/CommonLeadForm";
import UnderMaintenancePage from "./pages/Login/UnderMaintenancePage";
import MessagePage from "./pages/Messages/MessagePage";
// import NetworkError from "./pages/NetworkError/NetworkError";

const getRoutes = (user, err) => {
  // if (err !== undefined && err !== null) {
  //   return [
  //     {
  //       path: "",
  //       element:
  //         err === "Network Error"
  //           ?
  //            <NetworkError />
  //           :
  //           < Page404 />
  //           ,
  //       children: [
  //         { path: "*", element: <NetworkError /> },
  //       ],
  //     },
  //   ];
  // }
  // else {

  return [
    {
      path: routes.homePage,
      element: !user ? (
        <AuthPage />
      ) : (
        <Navigate
          to={
            user && user?.role === "ROLE_ADMIN"
              ? routes.admindashboard
              : user?.user_status === "VERIFICATION_SUCCESS"
              ? routes.dashboard
              : routes.profileedit
          }
        />
      ),
      children: [
        { path: routes.login, element: <LoginPage /> },
        // { path: routes.maintanance, element: <UnderMaintenancePage /> },
        { path: routes.forgotpassword, element: <ForgotPasswordPage /> },
        {
          path: routes.forgotpasswordcheckyouremail,
          element: <ForgotPasswordCheckYourEmailPage />,
        },
        {
          path: routes.forgotchangepassword,
          element: <ForgotChangePasswordPage />,
        },
        { path: routes.confirmpassword, element: <ConfirmPasswordPage /> },
        { path: routes.signup, element: <SignUpPage /> },
        {
          path: routes.confirmcreateaccount,
          element: <ConfirmCreateAccountPage />,
        },
        {
          path: routes.signupcheckyouremail,
          element: <SignUpCheckYourEmailPage />,
        },
        { path: routes.homePage, element: <Navigate to={routes.login} /> },
        { path: routes.privacypolicy, element: <PrivacyPolicyPage /> },
        { path: routes.termsconditions, element: <TermsConditionsPage /> },
        { path: routes.commonleadform, element: <CommonLeadForm /> },
      ],
    },
    // {
    //   path: routes.homePage,
    //   element:
    //     user && user.role === "ROLE_ADMIN" ? (
    //       <Layout />
    //     ) : (
    //       <Navigate to={routes.homePage} />
    //     ),
    //   children: [
    //     { path: routes.admindashboard, element: <AdminDashboardPage /> },
    //     { path: routes.adminmanagejobs, element: <AdminManageJobsPage /> },
    //     { path: routes.adminmanageusers, element: <AdminManageUsersPage /> },
    //     {
    //       path: routes.adminleadsmanagement,
    //       element: <AdminLeadsManagementPage />,
    //     },
    //     {
    //       path: routes.adminmanageusersprofile,
    //       element: <AdminManageUsersProfilePage />,
    //     },
    //     // { path: routes.notification, element: <NotificationPage /> },
    //     // { path: routes.profile, element: <ProfilePage /> },
    //     // { path: routes.profileedit, element: <ProfileEditPage /> },
    //     // { path: routes.settings, element: <PaymentPage /> },

    //     // { path: routes.homePage,
    //     //   element: (
    //     //     <Navigate
    //     //       to={
    //     //           routes.AdminDashboardPage

    //     //       }
    //     //     />
    //     //   ),
    //     // },
    //   ],
    // },
    {
      path: routes.homePage,
      element: user ? <Layout /> : <Navigate to={routes.login} />,
      children:
        user && user?.role === "ROLE_ADMIN"
          ? [
              { path: routes.admindashboard, element: <AdminDashboardPage /> },
              {
                path: routes.adminmanagejobs,
                element: <AdminManageJobsPage />,
              },
              {
                path: routes.adminmanagejobsByPage,
                element: <AdminManageJobsPage />,
              },
              {
                path: routes.adminmanageusers,
                element: <AdminManageUsersPage />,
              },
              {
                path: routes.adminmanageusersByPage,
                element: <AdminManageUsersPage />,
              },
              {
                path: routes.adminleadsmanagement,
                element: <AdminLeadsManagementPage />,
              },
              {
                path: routes.adminleadsmanagementByPage,
                element: <AdminLeadsManagementPage />,
              },
              {
                path: routes.admineditleadmanagement,
                element: <AdminEditLeads />,
              },
              { path: routes.profile, element: <ProfilePage /> },
              { path: routes.profileedit, element: <ProfileEditPage /> },
              { path: routes.settings, element: <PaymentPage /> },
              { path: routes.notification, element: <NotificationPage /> },
              {
                path: routes.adminmanageusersprofile,
                element: <AdminManageUsersProfilePage />,
              },
              {
                path: routes.adminmanagejobsByPage,
                element: <AdminManageJobsPage />,
              },
              {
                path: routes.adminmanageusersByPage,
                element: <AdminManageUsersPage />,
              },
              {
                path: routes.managejobsdetail,
                element: <ManageJobsDetailPage />,
              },
              { path: routes.viewInvoice, element: <BusinessInvoice /> },
              { path: routes.manageViewInvoice, element: <BusinessInvoice /> },
              { path: routes.myJobsInvoice, element: <BusinessInvoice /> },
              { path: routes.adminJobsInvoice, element: <BusinessInvoice /> },
              {
                path: routes.viewInvoiceForMail,
                element: <BusinessMailInvoice />,
              },
              {
                path: routes.leadInvoice,
                element: <LeadsInvoicePage />,
              },
              {
                path: routes.adminmanagejobsdetail,
                element: <ManageJobsDetailPage />,
              },
              {
                path: routes.adminmanageEditPostJob,
                element: <ManageEditPostPage />,
              },
              {
                path: routes.applicantprofile,
                element: <ApplicantProfilePage />,
              },
              {
                path: routes.jobownerprofile,
                element: <ApplicantProfilePage />,
              },
              {
                path: routes.adminmanagejobApplicant,
                element: <ApplicantProfilePage />,
              },
              {
                path: routes.adminmanageuserApplicant,
                element: <ApplicantProfilePage />,
              },
              {
                path: routes.adminjobprofilePage,
                element: <ProfileEditPage />,
              },
              {
                path: routes.adminusersprofilePage,
                element: <ProfileEditPage />,
              },
            ]
          : [
              // {
              //   path: routes.adminmanageusersprofile,
              //   element: <AdminManageUsersProfilePage />,
              // },
              { path: routes.dashboard, element: <DashboardPage /> },
              {
                path: routes.privacypolicyauth,
                element: <PrivacyPolicyPage />,
              },
              {
                path: routes.termsconditionsauth,
                element: <TermsConditionsPage />,
              },
              // { path: routes.admindashboard, element: <AdminDashboardPage /> },

              { path: routes.findJobsByPage, element: <FindJobsPage /> },
              { path: routes.findJobsByPageForMail, element: <FindJobsPage /> },
              { path: routes.myJobsByPage, element: <MyJobsPage /> },
              { path: routes.myJobsByPageForMail, element: <MyJobsPage /> },

              { path: routes.invoice, element: <InvoicePage /> },
              { path: routes.invoiceByPage, element: <InvoicePage /> },
              { path: routes.viewInvoice, element: <BusinessInvoice /> },
              { path: routes.manageViewInvoice, element: <BusinessInvoice /> },
              { path: routes.myJobsInvoice, element: <BusinessInvoice /> },
              // { path: routes.adminJobsInvoice, element: <BusinessInvoice /> },
              {
                path: routes.viewInvoiceForMail,
                element: <BusinessMailInvoice />,
              },
              {
                path: routes.leadInvoice,
                element: <LeadsInvoicePage />,
              },
              { path: routes.managejobsByPage, element: <ManageJobsPage /> },
              {
                path: routes.managejobsdetail,
                element: <ManageJobsDetailPage />,
              },
              {
                path: routes.managejobsdetail1,
                element: <ManageJobsDetailPage />,
              },
              {
                path: routes.managenewpostjob,
                element: <ManageNewPostJobPage />,
              },
              {
                path: routes.manageEditPostJob,
                element: <ManageEditPostPage />,
              },
              // {
              //   path: routes.adminmanagejobs,
              //   element: <AdminManageJobsPage />,
              // },

              { path: routes.leads, element: <LeadsPage /> },
              { path: routes.leadsByPage, element: <LeadsPage /> },
              // {
              //   path: routes.adminleadsmanagement,
              //   element: <AdminLeadsManagementPage />,
              // },
              { path: routes.profile, element: <ProfilePage /> },
              { path: routes.profileedit, element: <ProfileEditPage /> },
              {
                path: routes.applicantprofile,
                element: <ApplicantProfilePage />,
              },
              {
                path: routes.jobownerprofile,
                element: <ApplicantProfilePage />,
              },
              { path: routes.notification, element: <NotificationPage /> },
              { path: routes.settings, element: <PaymentPage /> },
              // {
              //   path: routes.adminmanageusers,
              //   element: <AdminManageUsersPage />,
              // },
              { path: routes.messagePage, element: <MessagePage /> },
              {
                path: routes.homePage,
                element: (
                  <Navigate
                    to={
                      user?.user_status === "VERIFICATION_SUCCESS"
                        ? routes.dashboard
                        : routes.profileedit
                    }
                  />
                ),
              },
            ],
    },
    {
      path: "",
      element: !user ? <Navigate to={routes.login} /> : <Page404 />,
      children: [
        { path: routes.login, element: <LoginPage /> },
        { path: "*", element: <LoginPage /> },
      ],
    },
  ];
};

export default getRoutes;
