import React from "react";

const OfferDeclineModal = ({
  GoBackHandler,
  jobModalDetail,
  DeclineOfferHandler,
}) => {
  return (
    <div className="zu_common_modal zu_common_center_modal zu_common_text_modal zu_decline_offer_modal">
      <div className="zu_common_modal_content">
        <h2 className="zu_common_modal_heading">Decline Offer Are you sure?</h2>
        <p className="zu_common_modal_paragraph">
          This job is yours if you want it! If you decline this offer, the job
          will be offered to another applicant.
        </p>
        <div className="zu_modal_common_btn_row">
          <button
            type="button"
            className="zu_common_btn zu_btn_gray zu_decline_offer_back_btn"
            onClick={() => GoBackHandler(jobModalDetail)}
          >
            Go Back
          </button>
          <button
            type="button"
            className="zu_common_btn zu_danger_btn"
            onClick={() => DeclineOfferHandler(jobModalDetail)}
          >
            DECLINE OFFER
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferDeclineModal;
