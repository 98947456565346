import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import { getJobDetail } from "../../store/slice/postSlice";

const BusinessMailInvoice = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, person } = params;

  const [businessLoad, setBusinessLoad] = useState(false);

  const jobDetail = useSelector((state) => state.post.jobDetail);

  useEffect(() => {
    if (id && !jobDetail) {
      dispatch(
        getJobDetail({
          id,
          confirm: true,
        })
      );
    } else {
      setBusinessLoad(true);
      navigate(`/invoice/${id}/true`, {
        state: {
          jobDetail: jobDetail,
          person: person === "business" ? "Business" : "Crew",
        },
      });
    }
  }, [dispatch, id, jobDetail]);

  if (!businessLoad) {
    return <Loader />;
  }

  return <div>BusinessMailInvoice</div>;
};

export default BusinessMailInvoice;
