import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

import { routes } from "../../constants";

import CommonHeading from "../../component/Common/CommonHeading";

import { useSelector } from "react-redux";
import Loader from "../../component/Common/Loader";

import LeadForm from "../../component/Layout/LeadForm";

const AdminEditLeads = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const leadsLoading = useSelector((state) => state.leads.loader);

  const leadDetail = location?.state?.lead;

  const [leadSubmitModal, setLeadSubmitModal] = useState(false);

  const closeSubmitModalHandler = () => {
    setLeadSubmitModal(false);
    navigate(`${routes.adminleadsmanagement}/page/1`);
  };

  return (
    <>
      {leadsLoading && <Loader />}
      <main className="zu_all_page_main_content">
        <div className="zu_manage_new_job_post_page">
          <section className="zu_manage_new_job_post_section">
            <CommonHeading heading={leadDetail ? "Edit Lead" : "Post Lead"} />

            <LeadForm
              leadDetail={leadDetail}
              setLeadSubmitModal={setLeadSubmitModal}
            />
          </section>
        </div>
      </main>
      <Modal
        show={leadSubmitModal}
        onHide={closeSubmitModalHandler}
        centered
        className="zu_common_heading_paragraph_modal zu_job_submitted_modal"
      >
        <div className="zu_common_modal zu_common_center_modal">
          <div className="zu_common_modal_content">
            <div className="zu_common_heading_paragraph_modal_text">
              <h2 className="zu_common_modal_heading">LEAD POSTED</h2>
              <p className="zu_common_modal_paragraph">
                New lead has been posted. please Approve lead for further
                process.
              </p>
            </div>
            <div className="zu_modal_common_btn_row">
              <button
                type="button"
                className="zu_common_btn zu_btn_gray"
                onClick={closeSubmitModalHandler}
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdminEditLeads;
