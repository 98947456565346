import * as React from "react";

import loaderGif from "../../images/shootzu-loader.gif";

const Loader = () => {
  return (
    <div className="spinner">
      <img src={loaderGif} alt="shootzu loader" />
    </div>
  );
};

export default Loader;
