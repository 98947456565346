import React from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { getNotificationsDetails } from "../../store/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import SpinLoader from "../../component/Common/SpinLoader";
import { linksetup } from "../../helpers/commonFunction";

const NotificationPage = () => {
  const dispatch = useDispatch();

  const [limit, setLimit] = useState(10);

  const notificationList = useSelector(
    (state) => state.user.getNotificationsDetails?.data
  );
  const totalCount = useSelector(
    (state) => state.user.getNotificationsDetails?.totalCount
  );
  const loading = useSelector((state) => state.user.loader);

  const findHrefLink = (notification, status, type) => {
    if (status === "OFFER_ACCEPTED" && type === "NEW_APPLICANTS") {
      return `${
        linksetup(notification)[1]?.split('<a href="')[1]?.split('">job</a>')[0]
      }/true`;
    } else {
      return linksetup(notification)[1]
        ?.split('<a href="')[1]
        ?.split('">job</a>')[0];
    }
  };

  useEffect(() => {
    dispatch(getNotificationsDetails({ start: 0, limit: limit }));
  }, [limit]);

  return (
    <>
      <main className="zu_all_page_main_content">
        <div className="zu_notification_page">
          <section className="zu_notification_section">
            <CommonHeading heading="Notifications List " />
          </section>
        </div>
        <div className="zu_notifications_list_view_main_div">
          <div className="zu_notifications_list_view_main_content">
            {notificationList?.map((notification, id) => {
              return (
                <>
                  <div
                    className={`zu_notifications_list_view  ${
                      notification?.notification_type === "NEW_APPLICANTS"
                        ? "zu_left_border_voilet"
                        : notification?.notification_type === "OFFER_ACCEPTED"
                        ? "zu_left_border_blue"
                        : notification?.notification_type === "OFFER_SENT"
                        ? "zu_left_border_green"
                        : notification?.notification_type === "JOB_EDITED"
                        ? "zu_left_border_orange"
                        : notification?.notification_type === "PROFILE_APPROVED"
                        ? "zu_left_border_light_blue"
                        : ""
                    }`}
                    key={notification?.id}
                  >
                    <div className="zu_notifications_list_view_title">
                      <h4>{notification?.title}</h4>
                      <span className="zu_notifications_list_view_date">
                        {moment(notification?.event_date)
                          .utc()
                          .format("DD MMM YYYY")}
                      </span>
                    </div>
                    <p className="zu_notifications_list_view_msg">
                      {linksetup(notification)[0]}
                      {linksetup(notification)?.length > 1 &&
                        linksetup(notification)[1] !== "" && (
                          <a
                            href={findHrefLink(
                              notification,
                              notification?.job_status,
                              notification?.notification_type
                            )}
                          >
                            Job
                          </a>
                        )}
                      {linksetup(notification)?.length > 2 &&
                        linksetup(notification)[2] !== "" &&
                        linksetup(notification)[2]}
                    </p>
                  </div>
                </>
              );
            })}
            {+totalCount !== +notificationList?.length ? (
              <div className="zu_job_column_view_more_btn">
                <button onClick={() => setLimit((prevLimit) => prevLimit + 10)}>
                  {loading ? <SpinLoader /> : "View More"}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default NotificationPage;
