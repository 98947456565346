import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";
import { messageIcon, ratingFillStarIcon, ratingStarIcon } from "../../icons";
import image from "../../images/default-user.jpg";

const ManageJobsApplicats = ({ applicants, jobDetail, values, setValues }) => {
  const navigate = useNavigate();
  const showApplicantDetailModal = (applicant) => {
    setValues({
      ...values,
      applicantDetailModal: true,
      applicantModalDetails: applicant,
    });
  };
  const user = useSelector((state) => state.user.user);

  const msgPagehandler = (applicant) => {
    navigate(`/messages`, {
      state: {
        job_status: jobDetail?.job_status,
        job_owner_id: jobDetail?.owner,
        job_id: jobDetail?.id,
        job_title: jobDetail?.title,
        job_date: jobDetail?.date,
        opp_user_id: applicant?.id,
        opp_first_name: applicant?.first_name,
        opp_last_name: applicant?.last_name,
        opp_profile_photo: applicant?.profile_photo,
      },
    });
  };

  const getRatingStars = (rating) => {
    let ratingStars = Array.from(
      {
        length: rating ? rating?.toFixed(1) : 0,
      },
      (_, i) => i + 1
    );
    return ratingStars;
  };

  const getEmptyRatingStars = (rating) => {
    let emptyRatingStars = Array.from(
      { length: 5 - getRatingStars(rating)?.length },
      (_, i) => i + 1
    );
    return emptyRatingStars;
  };

  return (
    <div className="zu_manage_job_applicant_list_column">
      <div className="zu_manage_job_applicant_list_heading">
        <h3> Applicant List</h3>
        <span className="zu_manage_job_applicant_list_number">
          {applicants?.length}
        </span>
      </div>
      <div
        className={`zu_manage_job_applicant_list ${
          jobDetail?.job_status === "OFFER_ACCEPTED" &&
          user?.role === "ROLE_ADMIN" &&
          "height_minimize"
        }`}
      >
        {applicants?.map((applicant, i) => {
          return (
            <>
              {user?.role === "ROLE_ADMIN" ? (
                <div key={i}>
                  <Link
                    to={`/adminmanagejobsapplicant/${applicant.id}`}
                    state={{
                      id: applicant?.id,
                      offer_id: applicant?.offer_id,
                      jobId: jobDetail?.id,
                      title: jobDetail?.title,
                      date: jobDetail?.date,
                      jobHour: jobDetail?.number_of_hour,
                      jobPerHour: jobDetail?.price_per_hour,
                      jobStatus: jobDetail?.job_status,
                    }}
                    className="zu_manage_job_applicant_list_link"
                    key={`zu_applicant${applicant?.id}`}
                  >
                    <img
                      src={
                        applicant?.profile_photo
                          ? `${process.env.REACT_APP_FILE_BASE_URL}/${applicant?.profile_photo}`
                          : image
                      }
                      alt="user img"
                      className="zu_manage_job_applicant_list_img"
                    />
                    <span className="zu_manage_job_applicant_list_name">
                      {capitalizeFirstLetter(applicant?.first_name)}{" "}
                      {capitalizeFirstLetter(applicant?.last_name)}
                      {jobDetail?.job_status === "WAITING_FOR_RESPONSE" &&
                        applicant.id === jobDetail?.shooter_id && (
                          <sup> Offer Sent</sup>
                        )}
                      <span className="zu_manage_job_applicant_list_star_rating">
                        {getRatingStars(applicant?.avg_ratings).map(
                          (i, index) => ratingFillStarIcon
                        )}
                        {getEmptyRatingStars(applicant?.avg_ratings).map(
                          (i, index) => ratingStarIcon
                        )}
                      </span>
                      {applicant?.delete_status === "1" && <sup> Deleted</sup>}
                    </span>
                  </Link>
                </div>
              ) : (
                <div className="zu_applicant_btn_div">
                  <button
                    className="zu_manage_job_applicant_list_link"
                    onClick={(e) => showApplicantDetailModal(applicant)}
                    key={`zu_applicant${applicant?.id}`}
                    disabled={applicant?.delete_status === "1"}
                  >
                    <img
                      src={
                        applicant?.profile_photo
                          ? `${process.env.REACT_APP_FILE_BASE_URL}/${applicant?.profile_photo}`
                          : image
                      }
                      alt="user img"
                      className="zu_manage_job_applicant_list_img"
                    />
                    <div className="zu_manage_job_applicant_list_name zu_manage_job_applicant_list_with_rating">
                      {capitalizeFirstLetter(applicant?.first_name)}{" "}
                      {capitalizeFirstLetter(applicant?.last_name)}
                      <span className="zu_manage_job_applicant_list_star_rating">
                        {getRatingStars(applicant?.avg_ratings).map(
                          (i, index) => ratingFillStarIcon
                        )}
                        {getEmptyRatingStars(applicant?.avg_ratings).map(
                          (i, index) => ratingStarIcon
                        )}
                      </span>
                    </div>
                    {jobDetail?.job_status === "WAITING_FOR_RESPONSE" &&
                      applicant.id === jobDetail?.shooter_id && (
                        <sup>Offer Sent</sup>
                      )}
                    {applicant?.delete_status === "1" && <sup> Deleted</sup>}
                  </button>
                  <button
                    className="zu_applicant_msg_icon"
                    onClick={() => msgPagehandler(applicant)}
                  >
                    {messageIcon}
                  </button>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ManageJobsApplicats;
