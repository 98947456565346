import React from "react";
import { Form } from "react-bootstrap";

const Select = (props) => {
  return (
    <div
      className={`zu_common_select_box ${props.hasIcon}`}
      onChange={(e) => {
        props.onChange(e);
      }}
    >
      {props.icon}
      <Form.Select>
        <option value="" disabled selected>
          {props.defaultText}
        </option>
        <option value="">All</option>
        <option value="POSTED">Approval Pending</option>
        <option value="NEW">New</option>
        <option value="WAITING_FOR_RESPONSE">Waiting for response</option>
        <option value="OFFER_ACCEPTED">Offer accepted</option>
        <option value="IN_PROGRESS">In progress</option>
        <option value="DONE">Completed</option>
        <option value="CANCELLED">Cancelled</option>
      </Form.Select>
    </div>
  );
};

export default Select;
