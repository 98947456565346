import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../css/ConfirmBox.css";
import { routes } from "../../constants";

import { logo } from "../../icons";
import { useDispatch } from "react-redux";
import { confirmUser } from "../../store/slice/userSlice";

const ConfirmCreateAccountPage = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const confirmUserHandler = () => {
    const data = {
      token: token,
    };
    dispatch(confirmUser({ data, navigate }));
  };

  return (
    <div className="zu_confirm_box_page">
      <div className="zu_confirm_box">
        <div className="zu_confirm_box_content">
          <Link to={routes.login} className="zu_confirm_box_logo">
            {logo}
          </Link>
          <h2 className="zu_confirm_box_heading">Hello! Welcome to Shootzu</h2>
          <button onClick={confirmUserHandler} className="zu_common_btn">
            Create Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmCreateAccountPage;
