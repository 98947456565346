import React from "react";
import { deleteIcon } from "../../icons";

const CardDeleteModal = ({ hideModal, deleteCardHandler }) => {
  return (
    <div className="zu_common_modal zu_common_center_modal">
      <div className="zu_common_modal_content">
        <div className="delete_container">{deleteIcon}</div>
        <h2 className="zu_common_modal_heading">Delete Card</h2>
        <p className="zu_common_modal_paragraph">
          Are you sure you wish to delete this credit card record?
        </p>
        <div className="zu_modal_common_btn_row">
          <button
            onClick={() => hideModal()}
            type="button"
            className="zu_common_btn zu_btn_gray"
          >
            Cancel
          </button>
          <button
            onClick={() => deleteCardHandler()}
            type="button"
            className="zu_common_btn zu_danger_btn"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardDeleteModal;
