import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  clearUserProfile,
  getNotificationsDetails,
  getProfile,
  logout,
} from "../../store/slice/userSlice";
import "../../css/component.css";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import {
  headerNotificationIcon,
  logo,
  userRoundIcon,
  settingLineIcon,
  mailLineIcon,
  logoutLineIcon,
} from "../../icons";
import userImage from "../../images/149071.png";

import { Dropdown } from "react-bootstrap";
import { capitalizeFirstLetter, linksetup } from "../../helpers/commonFunction";
import { useState } from "react";
import moment from "moment";

const Navbar = () => {
  const loginUserToken =
    localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"));
  const [loginState, setLoginState] = useState(
    loginUserToken?.role === "ROLE_ADMIN" ? "admin" : "user"
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.user);

  const loginAsUser =
    localStorage.getItem("admin_login_as_user_token") &&
    JSON.parse(localStorage.getItem("admin_login_as_user_token"));

  const notificationList = useSelector(
    (state) => state.user.getNotificationsDetails?.data
  );
  const id = user?.user_id;

  useEffect(() => {
    dispatch(getProfile({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getNotificationsDetails({ start: 0 }));
  }, [dispatch, navigate]);

  const profile = useSelector((state) => state.user.getProfile);

  const logoutHandler = async () => {
    if (loginAsUser && loginUserToken?.role === "ROLE_ADMIN") {
      localStorage.removeItem("token");
      localStorage.removeItem("admin_login_as_user_token");
      localStorage.removeItem("token1");
      window.location.href = `${process.env.REACT_APP_LOAD_URL}${routes.login}`;
    } else if (loginAsUser && loginUserToken?.role !== "ROLE_ADMIN") {
      const token = localStorage.getItem("token1");
      localStorage.removeItem("admin_login_as_user_token");
      localStorage.setItem("token", token);
      localStorage.removeItem("token1");
      window.location.href = `${process.env.REACT_APP_LOAD_URL}${routes.adminmanageusers}`;
    } else {
      dispatch(logout());
      toast.success("See you next time", {
        autoClose: 2000,
      });
      navigate(routes.login);
    }
  };

  const findHrefLink = (notification, status, type) => {
    if (status === "OFFER_ACCEPTED" && type === "NEW_APPLICANTS") {
      return `${
        linksetup(notification)[1]?.split('<a href="')[1]?.split('">job</a>')[0]
      }/true`;
    } else {
      return linksetup(notification)[1]
        ?.split('<a href="')[1]
        ?.split('">job</a>')[0];
    }
  };

  const notificationNavigate = () => {
    navigate(routes.notification);
  };

  const loginAsUserHandler = () => {
    const token1 = localStorage.getItem("token");
    const token = localStorage.getItem("token1");
    localStorage.setItem("token", token);
    localStorage.setItem("token1", token1);
    window.location.href = `${process.env.REACT_APP_LOAD_URL}${routes.dashboard}`;
  };

  const loginAsAdminHandler = () => {
    const token1 = localStorage.getItem("token");
    const token = localStorage.getItem("token1");
    localStorage.setItem("token", token);
    localStorage.setItem("token1", token1);
    window.location.href = `${process.env.REACT_APP_LOAD_URL}${routes.admindashboard}`;
  };

  return (
    <header className="zu_top_header_section">
      <Link
        to={
          user?.role == "ROLE_ADMIN" ? routes.admindashboard : routes.dashboard
        }
        className="zu_top_header_logo"
      >
        {logo}
      </Link>
      <button
        type="button"
        className="zu_top_header_toggal_btn"
        onClick={() => document.body.classList.add("zu_open_sidebar")}
      >
        <span></span>
      </button>
      <ul className="zu_top_header_icon_menu">
        {loginAsUser && (
          <div>
            <button
              className="zu_loginas-btn zu_login_btn"
              onClick={loginAsAdminHandler}
              disabled={loginState === "admin"}
            >
              Login as admin
            </button>
            <button
              className="zu_loginas-btn zu_login_btn"
              onClick={loginAsUserHandler}
              disabled={loginState === "user"}
            >
              Login as user
            </button>
          </div>
        )}

        <li className="zu_top_header_icon_menu_item">
          <Dropdown className="zu_top_header_icon_menu_dropdown" align="end">
            <Dropdown.Toggle
              id="notificationDropdown"
              className="zu_top_header_icon_menu_link"
            >
              {headerNotificationIcon}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <>
                <div className="zu_notification_dropdown_header"></div>
              </>
              {notificationList?.length === 0 ? (
                <p className="zu_no_notification_found_content">
                  No Notifications Found
                </p>
              ) : (
                <>
                  {notificationList?.slice(0, 3)?.map((notification, index) => {
                    return (
                      <div
                        className="zu_top_header_icon_menu_dropdown_content"
                        key={notification?.id}
                      >
                        <div
                          className={`zu_notification_dropdown_item ${
                            index % 2 === 0 ? "zu_notification_unread" : ""
                          }`}
                        >
                          <h4>{notification?.title}</h4>
                          <p>
                            {linksetup(notification)[0]}
                            {linksetup(notification)?.length > 1 &&
                              linksetup(notification)[1] !== "" && (
                                <a
                                  href={findHrefLink(
                                    notification,
                                    notification?.job_status,
                                    notification?.notification_type
                                  )}
                                >
                                  Job
                                </a>
                              )}

                            {linksetup(notification)?.length > 2 &&
                              linksetup(notification)[2] !== "" &&
                              linksetup(notification)[2]}
                          </p>
                          <span>
                            {moment(notification?.event_date)
                              .utc()
                              .format("DD MMM YYYY")}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
              {notificationList?.length !== 0 && (
                <div className="zu_notification_dropdown_footer">
                  <span onClick={notificationNavigate}>
                    <Dropdown.Item
                      className="zu_notification_dropdown_footer_link"
                      href="javascript:void(0)"
                    >
                      See All
                    </Dropdown.Item>
                  </span>
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
      <Dropdown className="zu_top_header_user_dropdown_menu" align="end">
        <Dropdown.Toggle className="zu_top_header_user_dropdown">
          <p>
            Hello,{" "}
            <span>{capitalizeFirstLetter(profile?.first_name || "")}</span>
          </p>
          <img
            src={
              profile?.profile_photo
                ? `${process.env.REACT_APP_FILE_BASE_URL}/${profile?.profile_photo}`
                : userImage
            }
            alt="user img"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="zu_top_header_user_dropdown_nav_menu">
          <ul className="zu_top_header_user_dropdown_nav">
            <li className="zu_top_header_user_dropdown_list">
              <Link
                to={routes.profile}
                onClick={() => dispatch(clearUserProfile())}
                className="zu_top_header_user_dropdown_link"
              >
                {userRoundIcon}
                <span>View / Edit Profile</span>
              </Link>
            </li>

            <li className="zu_top_header_user_dropdown_list">
              <Link
                to={routes.settings}
                className="zu_top_header_user_dropdown_link"
              >
                {settingLineIcon}
                <span>Settings</span>
              </Link>
            </li>
            <li className="zu_top_header_user_dropdown_list">
              <a
                className="zu_top_header_user_dropdown_link"
                href="https://jivo.chat/zgY5DzoKWO"
                target="_blank"
                rel="noreferrer"
              >
                {mailLineIcon} Contact Support
              </a>
            </li>
            <li className="zu_top_header_user_dropdown_list">
              <button
                className="zu_top_header_user_dropdown_link"
                onClick={logoutHandler}
              >
                {logoutLineIcon}
                <span>Sign out</span>
              </button>
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </header>
  );
};

export default Navbar;
