import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes, starList } from "../../constants";
import image from "../../images/default-user.jpg";
import CommonHeading from "../../component/Common/CommonHeading";
import ProfileUserDetaile from "../../component/Layout/ProfileUserDetaile";
import ShowcaseGallery from "../../component/Layout/ShowcaseGallery";
import { ratingFillStarIcon, ratingStarIcon } from "../../icons";

import { useDispatch, useSelector } from "react-redux";
import {
  adminUpdateUser,
  getApplicantProfile,
  getProfile,
  getReviewList,
  loginAsUser,
} from "../../store/slice/userSlice";
import Loader from "../../component/Common/Loader";
import { Modal } from "react-bootstrap";
import { webaddressValidation } from "../../helpers/commonFunction";
import SpinLoader from "../../component/Common/SpinLoader";
import DeleteUserModal from "../../component/Layout/DeleteUserModal";

const ProfilePage = ({ applicantDetail, jobD, applicantId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const profile = useSelector((state) => state.user.getProfile);
  const loading = useSelector((state) => state.user.loader);
  const reviewLoading = useSelector((state) => state.user.extraLoader);
  const user = useSelector((state) => state.user.user);
  const id = user?.user_id;
  const [blockModal, setBlockModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);

  const reviewList = useSelector((state) => state.user.getReviewList?.data);
  const loginUserLoader = useSelector((state) => state.user.loginAsLoader);
  const totalReview = useSelector(
    (state) => state.user.getReviewList?.totalCount
  );

  const [limit, setLimit] = useState(4);

  useEffect(() => {
    dispatch(
      getReviewList({
        user_id: applicantDetail ? applicantId : "",
        start: 0,
        limit: limit,
      })
    );
  }, [dispatch, limit]);

  const onClickBlockBtn = (user) => {
    let data = { blocked: user?.blocked == "0" ? "1" : "0", user_id: user.id };
    dispatch(adminUpdateUser({ data: data })).then(() => {
      dispatch(getApplicantProfile({ id: user?.id }));
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      dispatch(getProfile({ id }));
    }
    if (applicantDetail?.id) {
      dispatch(getApplicantProfile({ id: applicantDetail?.id }));
    }
  }, [dispatch, id]);

  if ((loading && !applicantDetail) || loginUserLoader) {
    return <Loader />;
  }
  if (!profile) return null;

  const ratedReview = (review) => {
    return starList.filter((star, i) => {
      return star.starText === review;
    })[0].starNum;
  };

  const adminToken = user?.token;

  const loginAsHandler = (e) => {
    e.preventDefault();

    const data = {
      token: adminToken,
      email: applicantDetail?.email,
    };

    dispatch(loginAsUser({ data }));
  };

  const deleteUserHandler = () => {
    const data = {
      delete_status: "1",
      user_id: applicantDetail?.id,
    };
    dispatch(
      adminUpdateUser({
        data: data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            navigate("/adminmanageusers");
          }
        },
      })
    );
  };

  const hideDeleteUserModalHandler = () => {
    setDeleteUserModal(false);
  };

  return (
    <>
      {}
      <main className="zu_all_page_main_content">
        <div
          className={`zu_profile_page ${
            profile.role === "ROLE_ADMIN"
              ? "zu_admin_manage_user_profile_page"
              : ""
          }`}
        >
          <div className="zu_profile_heading_row">
            <CommonHeading
              heading={
                location?.pathname.includes("findjobsowner")
                  ? "Business Profile"
                  : applicantDetail
                  ? "Applicant Profile "
                  : "Shootzu Profile"
              }
            />
            {applicantDetail &&
              profile.role === "ROLE_ADMIN" &&
              applicantDetail?.delete_status !== "1" && (
                <div className="zu_profile_heading_btn">
                  {applicantDetail?.blocked == "0" && (
                    <button
                      className="zu_common_btn zu_lighter_blue_btn"
                      onClick={loginAsHandler}
                      disabled={loginUserLoader}
                    >
                      LOGIN AS…
                    </button>
                  )}
                  <Link
                    to={
                      user?.role == "ROLE_ADMIN"
                        ? location.pathname.includes(
                            "/adminmanageusersapplicant"
                          )
                          ? routes.adminusersprofilePage
                          : routes.adminjobprofilePage
                        : routes.profileedit
                    }
                    className="zu_common_btn zu_btn_light_green"
                    state={{
                      applicantId: applicantDetail?.id,
                      jobId: jobD?.jobId,
                    }}
                  >
                    EDIT PROFILE
                  </Link>

                  <button
                    className={
                      applicantDetail?.blocked == "0"
                        ? "zu_common_btn zu_action_warning_btn"
                        : "zu_common_btn zu_action_warning_btn"
                    }
                    onClick={(e) => {
                      setBlockModal(true);
                    }}
                  >
                    {applicantDetail?.blocked == "0"
                      ? `BLOCK USER`
                      : `UNBLOCK USER`}
                  </button>
                  <button
                    className="zu_common_btn zu_danger_btn"
                    onClick={(e) => {
                      setDeleteUserModal(true);
                    }}
                  >
                    DELETE USER
                  </button>
                  <Modal
                    show={blockModal}
                    onHide={() => setBlockModal(false)}
                    centered
                    className="zu_purchase_lead_msg_modal"
                  >
                    <div className="zu_common_modal zu_common_center_modal">
                      <div className="zu_common_modal_content">
                        <div className="zu_common_modal_heading_paragraph">
                          <h2 className="zu_common_modal_heading">
                            Block User Confirmation
                          </h2>
                          <p className="zu_common_modal_paragraph">
                            Are you sure you want to{" "}
                            {applicantDetail?.blocked == "0"
                              ? "block"
                              : "unblock"}{" "}
                            this user?
                          </p>
                        </div>
                        <div className="zu_modal_common_btn_row">
                          <button
                            type="button"
                            className="zu_common_btn zu_btn_gray zu_send_msg_modal_cancel_btn"
                            onClick={() => {
                              setBlockModal(false);
                            }}
                          >
                            CANCEL
                          </button>
                          <button
                            type="button"
                            className="zu_common_btn zu_btn_light_green zu_send_msg_modal_approve_btn"
                            onClick={async () => {
                              await onClickBlockBtn(applicantDetail);
                              setBlockModal(false);
                            }}
                          >
                            {applicantDetail?.blocked == "0"
                              ? "BLOCK"
                              : "UNBLOCK"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              )}

            {profile.role !== "ROLE_ADMIN" &&
            !applicantDetail &&
            profile?.status === "PENDING" ? (
              <span className="zu_common_heading_processing_label">
                Approval Pending
              </span>
            ) : (
              ""
            )}
            {applicantDetail ? (
              ""
            ) : (
              <>
                {" "}
                <Link
                  to={routes.profileedit}
                  className="d-lg-none zu_mobile_edit_btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </Link>
                {!applicantDetail && (
                  <div className="zu_profile_heading_btn d-lg-block d-none">
                    <Link
                      to={routes.profileedit}
                      className="zu_common_btn zu_btn_theme_color"
                    >
                      Edit Profile
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>

          <ProfileUserDetaile
            applicantDetail={applicantDetail}
            jobD={jobD}
            profile={profile}
            hasButton
            hasAboutMe
            viewProfile
            aboutHeading={applicantDetail ? "OVERVIEW" : "About Me"}
          />
          {(applicantDetail
            ? applicantDetail?.images &&
              JSON.parse(applicantDetail?.images).length > 0
            : profile?.images && JSON.parse(profile?.images).length > 0) && (
            <ShowcaseGallery
              galleryPhotos={
                applicantDetail
                  ? applicantDetail?.images &&
                    JSON.parse(applicantDetail?.images)
                  : profile?.images && JSON.parse(profile?.images)
              }
              heading="Showcase Photos"
            />
          )}
          {(applicantDetail
            ? applicantDetail?.videos &&
              JSON.parse(applicantDetail?.videos).length > 0
            : profile?.videos && JSON.parse(profile?.videos).length > 0) && (
            <section className="zu_showcase_links_section">
              <div className="zu_showcase_links_content">
                <div className="zu_profile_common_heading">
                  <h2>Showcase Videos</h2>
                </div>
                <ul className="zu_showcase_links_nav">
                  {(applicantDetail
                    ? applicantDetail?.videos
                    : profile?.videos) &&
                    JSON.parse(
                      applicantDetail
                        ? applicantDetail?.videos
                        : profile?.videos
                    )?.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={item} target="_blank" rel="noreferrer">
                            {item}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </section>
          )}
          {(applicantDetail
            ? applicantDetail?.resources &&
              JSON.parse(applicantDetail?.resources).length > 0
            : profile?.resources &&
              JSON.parse(profile?.resources).length > 0) && (
            <section className="zu_showcase_links_section">
              <div className="zu_showcase_links_content">
                <div className="zu_profile_common_heading">
                  <h2>Showcase Links</h2>
                </div>
                <ul className="zu_showcase_links_nav">
                  {(applicantDetail
                    ? applicantDetail?.resources
                    : profile?.resources) &&
                    JSON.parse(
                      applicantDetail
                        ? applicantDetail?.resources
                        : profile?.resources
                    )?.map((item, index) => {
                      return (
                        <li key={index}>
                          <a
                            href={webaddressValidation(item)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </section>
          )}
          {reviewList?.length > 0 && (
            <section className="zu_profile_rating_review_section">
              <div className="zu_profile_rating_review_content">
                <div className="zu_profile_common_heading">
                  <h2>Ratings & Reviews</h2>
                </div>
                <div className="zu_profile_rating_review_row">
                  {reviewList.map((review, index) => {
                    return (
                      <div
                        className="zu_profile_rating_review_column"
                        key={index}
                      >
                        <div className="zu_profile_rating_review_img_row">
                          <img
                            src={
                              review?.profile_photo
                                ? `${process.env.REACT_APP_FILE_BASE_URL}/${review?.profile_photo}`
                                : image
                            }
                            alt="user img"
                          />
                          <div className="zu_profile_rating_review_user_name">
                            <h3>
                              {review?.first_name + " " + review?.last_name}
                            </h3>
                            <div className="zu_profile_rating_review_user_rating">
                              {Array.from(
                                {
                                  length: ratedReview(review?.star),
                                },
                                (_, i) => i + 1
                              )?.map((i) => ratingFillStarIcon)}

                              {Array.from(
                                { length: 5 - ratedReview(review?.star) },
                                (_, i) => i + 1
                              )?.map((i) => ratingStarIcon)}
                            </div>
                          </div>
                        </div>
                        <p className="zu_profile_rating_review_user_paragraph">
                          {review?.review}
                        </p>
                      </div>
                    );
                  })}
                </div>
                {reviewList?.length < +totalReview && (
                  <div className="zu_job_column_view_more_btn">
                    <button
                      onClick={() => setLimit((prevLimit) => prevLimit + 4)}
                    >
                      {reviewLoading ? <SpinLoader /> : "View More"}
                    </button>
                  </div>
                )}
              </div>
            </section>
          )}
          {applicantDetail && (
            <button
              onClick={() => {
                user?.role == "ROLE_ADMIN"
                  ? location.pathname.includes("adminmanageusers")
                    ? navigate("/adminmanageusers")
                    : navigate(
                        `/adminmanagejobsdetail/${
                          jobD?.jobId ? jobD?.jobId : location?.state?.jobId
                        }`
                      )
                  : navigate(-1);
              }}
              className="zu_profile_job_back_btn"
            >
              Go Back
            </button>
          )}
        </div>
      </main>
      <Modal
        show={deleteUserModal}
        onHide={hideDeleteUserModalHandler}
        centered
        className="zu_common_text_modal"
      >
        <DeleteUserModal
          hideDeleteUserModalHandler={hideDeleteUserModalHandler}
          deleteUserHandler={deleteUserHandler}
        />
      </Modal>
    </>
  );
};

export default ProfilePage;
