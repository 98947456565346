import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import FindJobSideMenu from "../../component/Layout/FindJobSideMenu";
import ImageTextContent from "../../component/Layout/ImageTextContent";
import MyJobsColumn from "../../component/Layout/MyJobsColumn";
import { jobTypeList, myJobStatusList, routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import findJobsGraphicImage from "../../images/FindJobsGraphicImage.jpg";
import {
  acceptDeclineOffer,
  cancelJobByApplicant,
  getjobListbyUser,
} from "../../store/slice/postSlice";

import { Form, Modal } from "react-bootstrap";
import ProfileAttentionModal from "../../component/Layout/ProfileAttentionModal";
import CommonHeading from "../../component/Common/CommonHeading";
import Input from "../../component/Common/Input";
import JobOfferModal from "../../component/Layout/JobOfferModal";
import OfferAcceptModal from "../../component/Layout/OfferAcceptModal";
import OfferDeclineModal from "../../component/Layout/OfferDeclineModal";
import Pagination from "../../component/Pagination/Pagination";
import AskQuestionModal from "../../component/Layout/AskQuestionModal";

const MyJobsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number, id } = params;

  const myJobs = useSelector((state) => state.post.getjobListbyUser);
  const totalCount = useSelector((state) => state.post.totalCount);
  const loading = useSelector((state) => state.post.loader);
  const jobLoading = useSelector((state) => state.post.jobLoader);

  const user = useSelector((state) => state.user.user);
  const userId = user?.user_id;

  const initialFValues = {
    show: false,
    findJobModal: null,
    jobStatusApplicant: "",
    ownerId: null,
    finCancelModal: false,
    cancelModalId: null,
    profileAttentionModal: false,
    askQuestionModal: false,
    question: "",
    openFilter: false,
    job_type: "",
    job_status: "ALL",
    searchData: "",
    jobModalDetail: "",
    acceptDeclineModal: false,
    acceptModal: false,
    declineModal: false,
    policyErr: "",
    offer_id: "",
    jobLoadingState: "",
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     dispatch(
  //       getjobListbyUser({
  //         confirm: "my_jobs",
  //         job_type: values.job_type,
  //         job_status: values.job_status,
  //         search_key: values.searchData,
  //       })
  //     );
  //   }, 500);
  //   return () => clearTimeout(timer);
  // }, [dispatch, values.job_status, values.job_type, values.searchData]);

  useEffect(() => {
    if (!id) {
      navigate(`${routes.myJobs}/page/1`);
    }
    const timer = setTimeout(() => {
      if (+page_number === 1)
        dispatch(
          getjobListbyUser({
            confirm: "my_jobs",
            job_type: values.job_type,
            job_status: values.job_status,
            search_key: values.searchData,
            order_by: values.job_status === "COMPLETED_DONE" && "DESC",
          })
        );
    }, 500);
    return () => clearTimeout(timer);
  }, [dispatch, values.job_status, values.job_type, values.searchData]);

  useEffect(() => {
    if (id) {
      setValues({
        ...values,
        show: true,
        offer_id: userId,
        findJobModal: id,
        jobStatusApplicant: "OFFER_ACCEPTED",
      });
    }
  }, [id, setValues]);

  const showModal = (id, status, offerId) => {
    setValues({
      ...values,
      show: true,
      jobStatusApplicant: status,
      findJobModal: id,
      offer_id: offerId,
      jobLoadingState: id,
    });
  };

  const hideModal = () => {
    setValues({ ...values, show: false, findJobModal: null });
  };

  const showProfileModal = () => {
    setValues({ ...values, profileAttentionModal: true, show: false });
  };

  const hideProfileModal = () => {
    setValues({ ...values, profileAttentionModal: false });
  };

  const hideJobOfferModal = () => {
    setValues({
      ...values,
      acceptDeclineModal: false,
    });
  };

  const hideOfferAcceptModal = () => {
    setValues({
      ...values,
      acceptModal: false,
    });
  };

  const hideOfferDeclineModal = () => {
    setValues({
      ...values,
      declineModal: false,
    });
  };

  const showQuestionModal = (job_id, owner_id) => {
    setValues({
      ...values,
      askQuestionModal: true,
      show: false,
      ownerId: owner_id,
      findJobModal: job_id,
    });
  };

  const hideQuestionModal = () => {
    setValues({ ...values, askQuestionModal: false, question: "" });
  };

  const showCancelApprovalHandler = (id) => {
    setValues({ ...values, finCancelModal: true, cancelModalId: id });
  };

  const hideCancelApprovalHandler = () => {
    setValues({ ...values, finCancelModal: false });
  };

  const searchChangeHandler = (e) => {
    e.preventDefault();
    setValues({ ...values, searchData: e.target.value });
  };

  const cancelledHandler = (id) => {
    dispatch(cancelJobByApplicant({ id: values.cancelModalId }));
    setValues({ ...values, finCancelModal: false });
  };

  const acceptDeclineModalHandler = (job) => {
    setValues({
      ...values,
      acceptDeclineModal: true,
      jobModalDetail: job,
    });
  };

  const acceptModalHandler = (job, policy) => {
    if (policy) {
      const data = { job_id: job?.id, accepted: true };
      dispatch(
        acceptDeclineOffer({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              setValues({
                ...values,
                acceptDeclineModal: false,
                acceptModal: true,
              });
              dispatch(
                getjobListbyUser({
                  confirm: "my_jobs",
                  job_type: values.job_type,
                  job_status: values.job_status,
                  search_key: values.searchData,
                })
              );
            }
          },
        })
      );
    } else {
      setValues({
        ...values,
        policyErr: "Please accept the Privacy Policy and Terms of Service!",
      });
    }
  };

  const DeclineModalHandler = (jobModalDetail) => {
    setValues({
      ...values,
      acceptDeclineModal: false,
      declineModal: true,
      jobModalDetail: jobModalDetail,
    });
  };

  const GoBackHandler = (jobModalDetail) => {
    setValues({
      ...values,
      declineModal: false,
      acceptDeclineModal: true,
      jobModalDetail: jobModalDetail,
    });
  };

  const DeclineOfferHandler = (jobModalDetail) => {
    const data = { job_id: jobModalDetail?.id, accepted: false };
    dispatch(
      acceptDeclineOffer({
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setValues({
              ...values,
              declineModal: false,
            });
            dispatch(
              getjobListbyUser({
                confirm: "my_jobs",
                job_type: values.job_type,
                job_status: values.job_status,
                search_key: values.searchData,
              })
            );
          }
        },
      })
    );
  };

  const onPageChange = (page_number) => {
    dispatch(
      getjobListbyUser({
        start: page_number,
        limit: 10,
        confirm: "my_jobs",
        job_type: values.job_type,
        job_status: values.job_status,
        search_key: values.searchData,
        order_by: values.job_status === "COMPLETED_DONE" && "DESC",
      })
    );
  };

  return (
    <>
      {loading && <Loader />}
      <main className="zu_all_page_main_content">
        <div className="zu_find_page">
          <section className="zu_find_job_section">
            <div className="zu_find_job_content">
              <ImageTextContent
                imageSrc={findJobsGraphicImage}
                heading="My Applications"
              />
            </div>
          </section>
          <section className="zu_upcomming_job_section">
            <div
              className={`d-lg-none zu_common_job_heading ${
                values.openFilter && "zu_common_job_heading_filter_open"
              }`}
            >
              <CommonHeading heading="My Applications" />
              <button
                type="button"
                className="zl_common_job_show_filter"
                onClick={() =>
                  setValues({ ...values, openFilter: !values.openFilter })
                }
              >
                {values.openFilter ? "Hide Filters" : "Show Filters"}
              </button>
            </div>
            <div className="zu_upcomming_job_content">
              <div
                className={`zu_common_filter_input_row ${
                  values.openFilter && "zu_common_filter_open"
                }`}
              >
                <div className="zu_common_filter_input_two ">
                  <Input
                    className="zu_common_filter_input_box"
                    labelClassName="zu_common_form_input_label"
                    inputClassName="zu_common_form_input"
                    errorClassName="err_text"
                    placeholder={"Search..."}
                    type="text"
                    id="searchData"
                    name="searchData"
                    value={values.searchData}
                    onChange={searchChangeHandler}
                  />
                </div>
                <div className="zu_common_filter_input_one">
                  <div className="zu_common_select_box">
                    {/* <Select defaultText="Filter Job Status" /> */}
                    <Form.Select
                      onChange={handleInputChange}
                      id="job_status"
                      className="zu_common_filter_input_box"
                      name="job_status"
                    >
                      <option disabled selected>
                        Job Status
                      </option>
                      <option value="ALL">All</option>

                      {myJobStatusList.map((jobStatus, i) => {
                        return (
                          <option
                            key={i}
                            selected={
                              values?.job_status === jobStatus.labelText
                            }
                            value={jobStatus.labelText}
                          >
                            {jobStatus.filterLabel}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </div>
                <div className="zu_common_filter_input_two">
                  <div className="zu_common_select_box">
                    <Form.Select
                      onChange={handleInputChange}
                      id="job_type"
                      className="zu_common_filter_input_box"
                      name="job_type"
                    >
                      <option disabled selected>
                        Job Type
                      </option>
                      <option value="">All</option>

                      {jobTypeList.map((jobType, i) => {
                        return (
                          <option
                            key={i}
                            selected={values?.job_type === jobType.labelText}
                            value={jobType.labelText}
                          >
                            {jobType.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </div>
              </div>
              <div className="zu_upcomming_job_column_content">
                {myJobs?.length === 0 ? (
                  <h1 className="zu_no_jobs_found_content">No Jobs Found</h1>
                ) : (
                  myJobs?.map((job, i) => {
                    return (
                      <MyJobsColumn
                        key={i}
                        job={job}
                        hasFindJob
                        showModal={showModal}
                        showCancelApprovalHandler={showCancelApprovalHandler}
                        acceptDeclineModalHandler={acceptDeclineModalHandler}
                        jobLoading={jobLoading}
                        jobLoadingState={values.jobLoadingState}
                        job_status_for_reject={values.job_status}
                      />
                    );
                  })
                )}
              </div>
            </div>
          </section>
        </div>
        {totalCount > 0 && (
          <Pagination totalRecords={totalCount} onPageChange={onPageChange} />
        )}
      </main>
      <Modal
        show={values.show}
        onHide={hideModal}
        centered
        className="zu_sidemenu_modal"
      >
        <FindJobSideMenu
          showProfileModal={showProfileModal}
          showQuestionModal={showQuestionModal}
          hideModal={hideModal}
          findJobModal={values.findJobModal}
          jobStatusApplicantModal={values.jobStatusApplicant}
          offerId={values.offer_id}
          jobLoading={jobLoading}
          paramsId={id}
        />
      </Modal>
      <Modal
        show={values.askQuestionModal}
        onHide={() => hideQuestionModal()}
        centered
        className="zu_common_text_modal"
      >
        <AskQuestionModal
          values={values}
          handleInputChange={handleInputChange}
          errors={errors}
          hideQuestionModal={hideQuestionModal}
          setValues={setValues}
          setErrors={setErrors}
        />
      </Modal>
      <Modal
        show={values.profileAttentionModal}
        onHide={hideProfileModal}
        centered
        className="zu_common_text_modal"
      >
        <ProfileAttentionModal hideProfileModal={hideProfileModal} />
      </Modal>
      <Modal
        show={values.finCancelModal}
        onHide={hideCancelApprovalHandler}
        centered
        className="zu_common_text_modal zu_decline_offer_modal"
      >
        <div className="zu_common_modal zu_common_center_modal zu_common_text_modal zu_decline_offer_modal">
          <div className="zu_common_modal_content">
            <h2 className="zu_common_modal_heading">Are you sure ?</h2>
            <p className="zu_common_modal_paragraph">
              You want to cancel this application
            </p>
            <div className="zu_modal_common_btn_row">
              <button
                type="button"
                className="zu_common_btn zu_btn_gray zu_decline_offer_back_btn"
                onClick={hideCancelApprovalHandler}
              >
                GO BACK
              </button>
              <button
                type="button"
                className="zu_common_btn zu_danger_btn"
                onClick={cancelledHandler}
              >
                CANCEL APPLICATION
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={values.acceptDeclineModal}
        onHide={hideJobOfferModal}
        centered
        className="zu_common_text_modal"
      >
        <JobOfferModal
          hideJobOfferModal={hideJobOfferModal}
          jobModalDetail={values.jobModalDetail}
          acceptModalHandler={acceptModalHandler}
          DeclineModalHandler={DeclineModalHandler}
          policyErr={values.policyErr}
          setValues={setValues}
          values={values}
        />
      </Modal>
      <Modal
        show={values.acceptModal}
        onHide={hideOfferAcceptModal}
        centered
        className="zu_common_text_modal"
      >
        <OfferAcceptModal hideOfferAcceptModal={hideOfferAcceptModal} />
      </Modal>
      <Modal
        show={values.declineModal}
        onHide={hideOfferDeclineModal}
        centered
        className="zu_common_text_modal"
      >
        <OfferDeclineModal
          hideOfferDeclineModal={hideOfferDeclineModal}
          GoBackHandler={GoBackHandler}
          jobModalDetail={values.jobModalDetail}
          DeclineOfferHandler={DeclineOfferHandler}
        />
      </Modal>
    </>
  );
};

export default MyJobsPage;
