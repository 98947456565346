import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "../../css/component.css";
import AdminSidebar from "./AdminSidebar";
import { useSelector } from "react-redux";

const Layout = () => {
  const [msgCount, setMsgCount] = useState(0);
  const user = useSelector((state) => state.user.user);

  return (
    <div className="Layout">
      {user?.role === "ROLE_ADMIN" ? (
        <AdminSidebar />
      ) : (
        <Sidebar setMsgCount={setMsgCount} msgCount={msgCount} />
      )}
      <main className="main_content">
        <Navbar user={user} />
        <Outlet context={setMsgCount} />
      </main>
    </div>
  );
};

export default Layout;
