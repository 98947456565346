import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getJobDetail } from "../../store/slice/postSlice";
import ManageNewPostJobPage from "./ManageNewPostJobPage";

const ManageEditPostPage = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const id = params.id;

  const [jobDataLoaded, setJobDataLoaded] = useState(false);

  const job = useSelector((state) => state.post.jobDetail);
  useEffect(() => {
    if (id && !job) {
      dispatch(getJobDetail({ id }));
    } else {
      setJobDataLoaded(true);
    }
  }, [dispatch, id, job]);

  const loading = useSelector((state) => state.post.loader);

  if (!jobDataLoaded) {
    return <div />;
  }

  return (
    <>
      <ManageNewPostJobPage job={job} />
    </>
  );
};

export default ManageEditPostPage;
