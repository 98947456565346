import React from "react";
import { warningMaintenanceIcon } from "../../icons";

const UnderMaintenancePage = () => {
  return (
    <article className="zu_body-container">
      <div>
        <span>{warningMaintenanceIcon}</span>
        <h1 className="zu_body-heading">We&rsquo;ll be back soon!</h1>
        <div>
          <p>
            Sorry for the inconvenience. We&rsquo;re performing some maintenance
            at the moment. we&rsquo;ll be back up shortly!
          </p>
          <p>&mdash; The Shootzu Team</p>
        </div>
      </div>
    </article>
  );
};

export default UnderMaintenancePage;
