import React from "react";
import { useLocation, useParams } from "react-router-dom";
import privacyTermsImage from "../../images/PrivacyTermsImage.jpeg";

const TermsConditionsPage = () => {
  const params = useLocation();
  return (
    <>
      <main
        className={`${
          params.pathname.includes("termsconditionsauth") &&
          "zu_all_page_main_content"
        } zu_privacy_terms_page`}
      >
        <div className="zu_privacy_terms_banner_img_text">
          <img
            src={privacyTermsImage}
            alt="img"
            className="zu_privacy_terms_banner_img"
          />
          <div className="zu_privacy_terms_banner_text">
            <h2 className="zu_privacy_terms_banner_heading">
              Membership Terms & Conditions
            </h2>
            <span className="zu_privacy_terms_banner_updat_date">
              Updated May 2021
            </span>
          </div>
        </div>
        <section className="zu_privacy_terms_section">
          <div className="container">
            <div className="zu_privacy_terms_content">
              <div className="zu_privacy_terms_covid_content">
                <h2 className="zu_privacy_terms_covid_heading">
                  COVID DISRUPTIONS
                </h2>
                <h3 className="zu_privacy_terms_common_heading mb-4">
                  Important Information:
                </h3>
                <h3 className="zu_privacy_terms_common_heading mb-4">
                  What happens if my Shootzu job is impacted by COVID-19
                  disruptions?
                </h3>
                <p className="zu_privacy_terms_common_paragraph mb-4">
                  In these days of un-certainty and snap lockdowns and
                  restrictions by government officials – both CREW and
                  BUSINESSES agree that there is potential for postponements and
                  cancellations on jobs due to circumstances beyond anyone’s
                  control relating to COVID-19.
                </p>
                <p className="zu_privacy_terms_common_paragraph mb-4">
                  Should your job via Shootzu be impacted by COVID related
                  lockdowns or restrictions, the following actions can be taken:
                </p>
                <ul className="zu_privacy_terms_common_list mb-4">
                  <li>
                    <p className="zu_privacy_terms_common_paragraph">
                      <span className="zu_privacy_terms_common_paragraph_bold">
                        Postpone the date:
                      </span>{" "}
                      Simply let Shootzu Support & your Crew know via email that
                      the job is impacted and will be postponed to a future
                      date. We can move this in the booking calendar which will
                      reflect in both the CREW and BUSINESS accounts. In the
                      case that your crew member is not available on the
                      rescheduled date, you can choose another applicant on the
                      job or request option 2, cancellation;
                    </p>
                  </li>
                  <li>
                    <p className="zu_privacy_terms_common_paragraph">
                      <span className="zu_privacy_terms_common_paragraph_bold">
                        Cancellation:
                      </span>{" "}
                      Should a job be completely cancelled due to a COVID
                      related disruption, email Shootzu Support & your Crew to
                      explain the situation. A refund of the Crew Payment (minus
                      admin / transaction fees) will be processed to the hiring
                      business.
                    </p>
                  </li>
                </ul>
                <p className="zu_privacy_terms_common_paragraph">
                  Always ensure that you communicate with your hired crew at all
                  times, and you can keep us in the loop by CC’ing us regarding
                  the potential disruption.
                </p>
                <a
                  href="https://www.shootzu.com/job-distruption/"
                  target="_blank"
                  rel="noreferrer"
                  className="zu_privacy_terms_btn"
                >
                  Distruption Form
                </a>
              </div>
              <div className="zu_privacy_terms_condition_pdf_content">
                <h2 className="zu_privacy_terms_common_heading">
                  Terms + Conditions
                </h2>
                <a
                  href="https://www.shootzu.com/Shootzu-TC-Master.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="zu_privacy_terms_btn"
                >
                  View Now
                </a>
              </div>
              <h2 className="zu_privacy_terms_common_heading mb-4">
                Shootzu Service Fee and Payment Gateway Processing Fees:
              </h2>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                As Members on the Platform (a Business User hiring Crew on the
                Platform, or a Crew Member accepting a Job), you opt into
                Shootzu Protect when a Job is Posted and Hired on the Platform.
                Under Shootzu Protect, you agree that Crew Payments through the
                Shootzu Platform are through the third party service payment
                gateways integrated into the Platform: Stripe and Transferwise.
              </p>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                For Hiring Businesses, the Shootzu Service Fee is 5% of the
                value of the Crew Payment, and includes the Payment Gateway
                Processing Fees, and any applicable taxes. For Business Users,
                this Service Fee will be added to your total payable amount when
                processing a Crew Payment (Crew Payment plus Shootzu Business
                User Service Fee).
              </p>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                For Crew, the Shootzu Service Fee is 2% of the value of the Crew
                Payment, and includes any applicable taxes. For Crew, the
                Shootzu Crew Service Fee is already incorporated into the Job
                Brief advertised rate: the hourly rate advertised will be the
                amount budgeted by the Business User minus the Shootzu Crew
                Service Fee.
              </p>
              <h2 className="zu_privacy_terms_common_heading mb-4">
                The Shootzu Service Fee facilitates the following Features:
              </h2>
              <ul className="zu_privacy_terms_common_list mb-4">
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    on-time, secure payments through established third party
                    payment gateway(s)
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    assisted communication between Members
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    Push Notifications and emails
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    facilitating invoicing, payment release, and additional
                    Member checks after each Job
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    Crew Agreement, and
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    Crew Payments in the event of cancellation through no fault
                    of the Crew
                  </p>
                </li>
              </ul>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                If applying for a job in a country different to your stated home
                country, you warrant:
              </p>
              <ul className="zu_privacy_terms_common_list mb-4">
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    You have the relevant working visas and permissions required
                    to work in this country.
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    You have a local bank account in this country. Crew payments
                    will only be processed to local bank accounts in the same
                    currency as the job.
                  </p>
                </li>
              </ul>
              <div className="zu_privacy_second_terms_condition_pdf_content">
                <h2 className="zu_privacy_terms_common_heading">
                  Terms + Conditions
                </h2>
                <a
                  href="https://www.shootzu.com/Shootzu-TC-Master.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="zu_privacy_terms_btn"
                >
                  View Now
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default TermsConditionsPage;
