import React from "react";
import { useLocation } from "react-router-dom";
import privacyTermsImage from "../../images/PrivacyTermsImage.jpeg";

const PrivacyPolicyPage = () => {
  const params = useLocation();
  return (
    <>
      <main
        className={`${
          params.pathname.includes("privacypolicyauth") &&
          "zu_all_page_main_content"
        } zu_privacy_terms_page`}
      >
        <div className="zu_privacy_terms_banner_img_text">
          <img
            src={privacyTermsImage}
            alt="img"
            className="zu_privacy_terms_banner_img"
          />
          <div className="zu_privacy_terms_banner_text">
            <h2 className="zu_privacy_terms_banner_heading">Privacy Policy</h2>
            <p className="zu_privacy_terms_banner_paragraph">
              The boring, but important stuff
            </p>
            <span className="zu_privacy_terms_banner_updat_date">
              Updated May 2021
            </span>
          </div>
        </div>
        <section className="zu_privacy_terms_section">
          <div className="container">
            <div className="zu_privacy_terms_content">
              <h2 className="zu_privacy_terms_common_heading mb-4">
                Privacy Policy
              </h2>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                This is the privacy policy of Shootzu, owned and operated by
                Turbo Productions Pty Ltd, ABN 18 150 556 102 referred to as
                ‘we’, ‘us’ or ‘our’. The purpose of this privacy policy is to
                provide information about how we deal with and manage personal
                information.
              </p>
              <h2 className="zu_privacy_terms_common_heading mb-4">
                What is personal information?
              </h2>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                Personal information is defined as: information or an opinion
                about an identified individual, or an individual who is
                reasonably identifiable: whether the information or opinion is
                true or not; and whether the information or opinion is recorded
                in a material form or not.
              </p>
              <h2 className="zu_privacy_terms_common_heading mb-4">
                Why do we collect personal information?
              </h2>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                There are few, if any, organisations that can function without
                personal information. We are no exception. We collect personal
                information where it is reasonably necessary for our functions
                or activities. In addition to our core business activity of
                offering a networking Platform for Photography and Videography
                businesses to find Crew for relevant freelance work in their
                local area, and to verify, validate Members’ work and facilitate
                payments, services and support in fulfilling these job posts,
                our functions and activities include:
              </p>
              <ul className="zu_privacy_terms_common_list mb-4">
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    marketing our business;
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    undertaking research to improve our business;
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    obtaining goods and services from other businesses;
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    employing staff; and
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    complying with legal and regulatory obligations.
                  </p>
                </li>
              </ul>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                Some common examples of personal information that we may collect
                include an individual’s name, contact details, location,
                speciality, interests, contact details, payment details,
                financial information, and information about your skills and
                qualifications, and details of products and services that they
                obtain from us.
              </p>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                We recognise that certain types of personal information are
                sensitive.
              </p>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                Examples of sensitive information about an individual may
                include information about the individual’s:
              </p>
              <ul className="zu_privacy_terms_common_list mb-4">
                <li>
                  <p className="zu_privacy_terms_common_paragraph">health;</p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    racial or ethnic origin;
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    political opinions;
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    membership of a political association, professional or trade
                    association or trade union;
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    religious beliefs or affiliations;
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    philosophical beliefs;
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    sexual orientation or practices; and
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    criminal record.
                  </p>
                </li>
              </ul>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                We do not normally collect sensitive information from our
                customers. Any sensitive information which we do collect will
                either be collected with the individual’s consent or as
                permitted by law.
              </p>
              <h2 className="zu_privacy_terms_common_heading mb-4">
                How do we collect and keep personal information?
              </h2>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                We receive personal information in different ways and through a
                number of different media including:
              </p>
              <ul className="zu_privacy_terms_common_list mb-4">
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    via online sources (including email);
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    by telephone;
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    through face to face communications; and
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    by hard copy correspondence and documentation.
                  </p>
                </li>
              </ul>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                We keep different types of records that include personal
                information.
              </p>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                These include records stored electronically on data bases and
                also hard copy files.
              </p>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                When do we use or disclose personal information? If we collect
                personal information for a particular purpose, we may use or
                disclose that personal information for that purpose. For
                example, if a customer obtains a product or service from us, we
                may use and/or disclose their personal information for the
                purpose of providing the product or service to them.
              </p>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                We may also use or disclose personal information for other
                secondary purposes including the following:
              </p>
              <ul className="zu_privacy_terms_common_list mb-4">
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    where the individual has consented to the use or disclosure
                    for the
                  </p>
                </li>
              </ul>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                secondary purpose;
              </p>
              <ul className="zu_privacy_terms_common_list mb-4">
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    the secondary purpose is related to (or in the case of
                    sensitive information directly related to) the purpose for
                    which the personal information was collected and the
                    individual concerned would reasonably expect us to use or
                    disclose the information – for example providing marketing
                    information to existing customers (unless the customer has
                    requested not to receive marketing information from us);
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    the use or disclosure is required or authorised under any
                    law or a court or tribunal order;
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    a permitted general or health situation exists as defined in
                    the Australian Privacy Act 1988; or
                  </p>
                </li>
                <li>
                  <p className="zu_privacy_terms_common_paragraph">
                    we reasonably believe that the use or disclosure of the
                    personal information is reasonably necessary for one or more
                    enforcement related activities conducted by, or on behalf
                    of, an enforcement body.
                  </p>
                </li>
              </ul>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                We may store personal information using services provided to us
                by third parties that are located outside your jurisdiction or
                that use servers that are located outside your jurisdiction.
                This may involve personal information that we collect being
                stored in Germany, Ireland, the US, and the UK.
              </p>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                How can you access your personal information that we hold? You
                may request access to your personal information held by us by
                emailing us at hello@shootzu.com. We will provide access where
                we are required to do so under law. In other cases, we reserve
                our right not to provide access. We also reserve the right to
                recover our reasonable costs of providing you with access to
                your personal information held by us.
              </p>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                What should you do if you believe we hold personal information
                about you that is wrong or you wish to complain about how we
                have handled your personal information?
              </p>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                If you believe that we hold personal information about you that
                is wrong (which includes inaccurate, out of date, incomplete,
                irrelevant or misleading), or you wish to complain about how we
                have handled your personal information, you should contact us at{" "}
                <a
                  href="mailto:hello@shootzu.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  hello@shootzu.com
                </a>
                .
              </p>
              <p className="zu_privacy_terms_common_paragraph mb-4">
                If you have complained to us about how we have handled your
                personal information, and you believe that we have not
                satisfactorily resolved your complaint, you may wish to contact
                the privacy regulator in your jurisdiction, including the Office
                of the Australian Information Commissioner (
                <a
                  href="https://www.oaic.gov.au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.oaic.gov.au/
                </a>
                ) (in Australia)
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default PrivacyPolicyPage;
