import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CommonHeading from "../../component/Common/CommonHeading";
import Input from "../../component/Common/Input";

import { useDispatch, useSelector } from "react-redux";

import { sendOtp, verifyOtp } from "../../store/slice/authSlice";
import LeadForm from "../../component/Layout/LeadForm";
import SpinLoader from "../../component/Common/SpinLoader";

const CommonLeadForm = () => {
  const dispatch = useDispatch();

  const [leadSubmitModal, setLeadSubmitModal] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [numberErr, setnumberErr] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const [otpRequestId, setOtpRequestId] = useState("");
  const [blurForm, setblurForm] = useState(false);
  const [hideOtpInput, setHideOtpInput] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [otp, setOtp] = useState("");
  const directPost = "true";

  const authLoading = useSelector((state) => state.auth.loader);

  const sendOtpHandler = () => {
    const data = {
      country_code: "61",
      phone_number: contactNumber,
    };

    if (contactNumber.trim() === "") {
      setnumberErr("Please Enter Valid Contact number");
    } else {
      dispatch(
        sendOtp({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              setShowVerifyOtp(true);
              setOtpRequestId(res.request_id);
            }
          },
        })
      );
      setnumberErr("");
    }
  };

  const verityOtpHandler = () => {
    const data = {
      otp: otp,
      request_id: otpRequestId,
    };
    if (otp.trim() === "") {
      setOtpErr("Please Enter Verification Code");
    } else {
      dispatch(
        verifyOtp({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
              setOtpErr("Invalid verification code");
            } else {
              setblurForm(true);
              setHideOtpInput(true);
              setContactNumber(contactNumber);
            }
          },
        })
      );
    }
  };

  const numberChangeHandler = (e) => {
    setContactNumber(e.target.value);
    setnumberErr("");
  };

  const otpChangeHandler = (e) => {
    setOtp(e.target.value);
    setOtpErr("");
  };

  return (
    <>
      <main className="zu_all_page_main_content" style={{ margin: "auto" }}>
        <div className="zu_manage_new_job_post_page">
          <section className="zu_manage_new_job_post_section">
            <CommonHeading heading="Get Quotes" />
            {!hideOtpInput && (
              <div className="zu_manage_new_job_post_content zu_phone_verify">
                <div className="zu_common_form_input_content zu_common_form_phone">
                  {!showVerifyOtp && (
                    <>
                      <Input
                        className="zu_common_form_send_otp"
                        labelClassName="zu_common_form_input_label"
                        inputClassName="zu_common_form_input"
                        errorClassName="err_text"
                        label="Get Started - Enter Mobile Number"
                        placeholder="Enter Contact Number"
                        type="number"
                        //   maxLength={10}
                        id="contact_number"
                        name="contact_number"
                        onChange={numberChangeHandler}
                        value={contactNumber}
                      />
                      {numberErr !== "" ? (
                        <span className="err_text sm-err-text">
                          {numberErr}
                        </span>
                      ) : null}
                      <button
                        className="zu_common_btn send_otp_btn"
                        onClick={sendOtpHandler}
                        disabled={authLoading}
                      >
                        {authLoading ? <SpinLoader style /> : "Verify Mobile"}
                      </button>
                    </>
                  )}
                  {showVerifyOtp && (
                    <>
                      <Input
                        labelClassName="zu_common_form_input_label"
                        inputClassName="zu_common_form_input"
                        errorClassName="err_text"
                        label="Verification Code"
                        placeholder="Enter your SMS code..."
                        type="number"
                        id="otp"
                        name="otp"
                        onChange={otpChangeHandler}
                        value={otp}
                      />
                      {otpErr !== "" ? (
                        <span className="err_text sm-err-text">{otpErr}</span>
                      ) : null}
                      <button
                        className="zu_common_btn send_otp_btn"
                        onClick={verityOtpHandler}
                        disabled={authLoading}
                      >
                        {authLoading ? <SpinLoader style /> : "Verify Code"}
                      </button>
                    </>
                  )}
                </div>
                {!showVerifyOtp && numberErr !== "" ? (
                  <span className="err_text md-err-text">{numberErr}</span>
                ) : null}
                {showVerifyOtp && otpErr !== "" ? (
                  <span className="err_text md-err-text">{otpErr}</span>
                ) : null}
              </div>
            )}
            <LeadForm
              blurForm={blurForm}
              contactNumber={contactNumber}
              directPost={directPost}
              setLeadSubmitModal={setLeadSubmitModal}
            />
          </section>
        </div>
      </main>
      <Modal
        show={leadSubmitModal}
        centered
        className="zu_common_heading_paragraph_modal zu_job_submitted_modal"
      >
        <div className="zu_common_modal zu_common_center_modal">
          <div className="zu_common_modal_content">
            <div className="zu_common_heading_paragraph_modal_text">
              <h2 className="zu_common_modal_heading">JOB POSTED</h2>
              <p className="zu_common_modal_paragraph">
                Your job has been posted successfully.
              </p>
            </div>
            <div className="zu_modal_common_btn_row">
              <a
                className="zu_common_btn zu_btn_gray"
                href="https://www.shootzu.com/thanks/"
              >
                CLOSE
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CommonLeadForm;
