import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const MsgHeader = ({ setOpenChat, chatValues, loggedInId, jobD }) => {
  const navigate = useNavigate();

  const jobNavigateHandler = () => {
    if (jobD?.job_owner_id === loggedInId) {
      navigate(`/managejobsdetail/${chatValues?.job_id}`, {
        state: {
          job_status: jobD.job_status,
        },
      });
    } else if (jobD?.job_status === "NEW") {
      navigate(`/findjobs/page/1/${chatValues?.job_id}`);
    } else {
      navigate(`/myjobs/page/1/${chatValues?.job_id}`);
    }
  };

  return (
    <div className="zu_chat_msg_header">
      <button
        type="button"
        className="zu_chat_msg_content_back_btn"
        onClick={() => setOpenChat(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
          />
        </svg>
      </button>
      <div className="zu_chat_msg_header_name_content online">
        <img
          src={`${process.env.REACT_APP_FILE_BASE_URL}/${chatValues?.opp_profile_photo}`}
          alt="user img"
          className="zu_chat_msg_header_img"
        />
        {/* <span className="zu_chat_msg_header_img">VP</span> */}
        <div className="zu_chat_msg_header_name_text">
          <h4>{chatValues.opp_first_name + " " + chatValues.opp_last_name}</h4>
          <p>
            <span className="zu_chat_msg_header_job_title">
              {chatValues.job_title + " |"}
            </span>
            <span className="zu_chat_msg_header_job_date">
              {moment(chatValues?.job_date).utc().format("DD MMM YYYY")}
            </span>
          </p>
          <span className="zu_chat_msg_header_name_text_job_id">
            Job ID: {chatValues.job_id}
          </span>
        </div>
      </div>
      <button
        type="button"
        className="zu_chat_msg_header_view_job"
        onClick={() => jobNavigateHandler()}
      >
        View Job
      </button>
    </div>
  );
};

// to={`/myjobs/page/1/${jobModalDetail?.job_id}`}
// to = {`/managejobsdetail/${job?.id}`}

export default MsgHeader;
