import React from "react";

import CardList from "./CardList";

const PurchaseLeadModal = ({
  handleCardRadio,
  closeLeadModalHandler,
  purchaseLeadHandler,
  setValues,
  values,
}) => {
  return (
    <div className="zu_common_modal zu_common_center_modal zu_purchase_lead_msg_modal">
      <div className="zu_common_modal_content zu_common_lead_purchase">
        <div className="zu_common_modal_heading_paragraph">
          <h2 className="zu_common_modal_heading">Purchase Lead</h2>
          <p className="zu_common_modal_paragraph">
            By purchasing this lead, you will be one of only six Shootzu users
            given the direct contact details for this potential client, so you
            can present your services and proposal.
          </p>
          <p className="zu_common_modal_paragraph">
            Contact details will be revealed upon payment, and you'll also get
            an email with all the details.
          </p>
        </div>
        <CardList
          cardData={values.cardData}
          creditCardId={values.creditCardId}
          handleCardRadio={handleCardRadio}
          creditCardIdErr={values.creditCardIdErr}
          cardBlinkClass={values.cardBlinkClass}
          cardBlinkClassTwo={values.cardBlinkClassTwo}
          cardNumber={values.cardNumber}
          expiry={values.expiry}
          cvc={values.cvc}
          cardNumberErr={values.cardNumberErr}
          expiryErr={values.expiryErr}
          cvcErr={values.cvcErr}
          cardDataErr={values.cardDataErr}
          setValues={setValues}
          values={values}
        />
        <div className="zu_modal_common_btn_row">
          <button
            type="button"
            className="zu_common_btn zu_btn_gray zu_send_msg_modal_cancel_btn"
            onClick={closeLeadModalHandler}
          >
            Cancel
          </button>

          <button
            type="button"
            className="zu_common_btn zu_btn_light_green zu_lead_btn"
            onClick={purchaseLeadHandler}
          >
            <span>${process.env.REACT_APP_LEAD_CHARGE} - PAY NOW</span>
            <span className="zu_charge">
              Lead fee: $
              {(
                process.env.REACT_APP_LEAD_CHARGE -
                process.env.REACT_APP_LEAD_CHARGE / 11
              ).toFixed(2)}{" "}
              + GST
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseLeadModal;
