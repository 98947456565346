import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../css/LoginPage.css";
import { routes } from "../../constants";
import { Form, FloatingLabel } from "react-bootstrap";

import { eyeIcon, hideEyeIcon, logo, rightArrow } from "../../icons";
import loginImage from "../../images/login-image.png";
import { useForm } from "../../hooks/useForm";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../store/slice/userSlice";

const ForgotChangePasswordPage = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialFValues = {
    password: "",
    inputTypePassword: "password",
    confirm_password: "",
    inputTypeConfirmPassword: "password",
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    if ("password" in fieldValues) {
      if (!fieldValues.password) {
        temp.password = "Password is required!";
      } else {
        temp.password =
          fieldValues.password.length > 5
            ? ""
            : "Password must contain at least 6 characters!";
      }
    }
    if ("confirm_password" in fieldValues) {
      if (fieldValues.password !== fieldValues.confirm_password) {
        temp.confirm_password = "The password confirmation does not match!";
      } else {
        temp.confirm_password = fieldValues.confirm_password
          ? ""
          : "Confirm password is required!";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    if (values.confirm_password) {
      validate(values);
    }
  }, [values.password, values.confirm_password]);

  const showPasswordHandler = (e) => {
    e.preventDefault();
    if (values.inputTypePassword === "password") {
      setValues({
        ...values,
        inputTypePassword: "text",
      });
    } else {
      setValues({
        ...values,
        inputTypePassword: "password",
      });
    }
  };

  const showConfirmPasswordHandler = (e) => {
    e.preventDefault();
    if (values.inputTypeConfirmPassword === "password") {
      setValues({
        ...values,
        inputTypeConfirmPassword: "text",
      });
    } else {
      setValues({
        ...values,
        inputTypeConfirmPassword: "password",
      });
    }
  };

  const resetPasswordHandler = (e) => {
    e.preventDefault();

    if (validate()) {
      const data = {
        user_password: values.password,
        token: token,
      };

      dispatch(resetPassword({ data, navigate }));
    }
  };

  return (
    <div className="zu_forgot_password_page_content">
      <div className="zu_auth_page_mobile_logo">
        <Link to={routes.dashboard}>{logo}</Link>
      </div>
      <section className="zu_login_section">
        <div className="zu_login_content">
          <div className="zu_login_form_content">
            <div className="zu_login_form">
              <Link to={routes.dashboard} className="zu_login_form_logo">
                {logo}
              </Link>
              <h2 className="zu_login_form_heading">Reset your password</h2>
              <form className="zu_login_form_input_content">
                <FloatingLabel
                  label="New Password"
                  className="zu_floting_input_content"
                >
                  <Form.Control
                    type={values.inputTypePassword}
                    placeholder="New Password"
                    className="zu_floting_input"
                    name="password"
                    id="password"
                    onChange={handleInputChange}
                    value={values.password}
                  />
                  <button
                    onClick={showPasswordHandler}
                    className="zu_password_eye_btn"
                  >
                    {values.inputTypePassword === "password"
                      ? eyeIcon
                      : hideEyeIcon}
                  </button>
                  {errors?.password && (
                    <span className="err_text">{errors?.password}</span>
                  )}
                </FloatingLabel>
                <FloatingLabel
                  label="Confirm Password "
                  className="zu_floting_input_content"
                >
                  <Form.Control
                    type={values.inputTypeConfirmPassword}
                    placeholder="Confirm Password "
                    className="zu_floting_input"
                    name="confirm_password"
                    id="confirm_password"
                    onChange={handleInputChange}
                    value={values.confirm_password}
                  />
                  <button
                    onClick={showConfirmPasswordHandler}
                    className="zu_password_eye_btn"
                  >
                    {values.inputTypeConfirmPassword === "password"
                      ? eyeIcon
                      : hideEyeIcon}
                  </button>
                  {errors?.confirm_password && (
                    <span className="err_text">{errors?.confirm_password}</span>
                  )}
                </FloatingLabel>
                <button
                  onClick={resetPasswordHandler}
                  className="zu_auth_form_submit_btn"
                >
                  RESET PASSWORD
                  {rightArrow}
                </button>
              </form>
            </div>
          </div>
          <div className="zu_login_image_content">
            <img src={loginImage} alt="img" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgotChangePasswordPage;
