import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getApplicantProfile } from "../../store/slice/userSlice";
import ProfilePage from "./ProfilePage";

const ApplicantProfilePage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const [jobD, setjobD] = useState(location.state);
  const [applicantLoaded, setApplicantLoaded] = useState(false);

  const applicantDetail = useSelector(
    (state) => state.user.getApplicantProfile
  );

  useEffect(() => {
    if (params.id && !applicantDetail) {
      dispatch(getApplicantProfile({ id: params.id }));
    } else {
      setApplicantLoaded(true);
    }
  }, [dispatch, params.id, applicantDetail]);

  if (!applicantLoaded) return <div />;

  return (
    <>
      <ProfilePage
        applicantDetail={applicantDetail}
        applicantId={params.id}
        jobD={jobD}
      />
    </>
  );
};

export default ApplicantProfilePage;
