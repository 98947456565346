import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

// GET LEADLIST
export const getLeadsList = createAsyncThunk(
  "getLeadsList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/leads/lead-list?start=${data.start || 0}&limit=${
          data.limit || 1000000
        }&lead_type=${data.lead_type || ""}&location=${
          data.location || ""
        }&km=${data.km || 100}&roles=${data.roles || ""}&my_leads=${
          data.my_leads || "0"
        }&lead_status=${data.lead_status || ""}`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// PURCHASE LEAD
export const purchaseLead = createAsyncThunk(
  "purchaseLead",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/leads/purchase-lead",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Great! Lead Purchased", {
          autoClose: 3000,
        });
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

export const updateLead = createAsyncThunk(
  "updateLead",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/leads/update-lead/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Lead updated!", {
          autoClose: 3000,
        });
        if (data.edit) {
          data.navigate(`${routes.adminleadsmanagement}/page/1`);
        }
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// POST NEW JOB
export const postNewLead = createAsyncThunk(
  "postNewLead",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post("/leads/add-lead", data.data);
      if (response.status === 200 || response.status === 201) {
        toast.success("Great! Lead has been Posted", {
          autoClose: 3000,
        });
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return isRejectedWithValue(error.response.data.message);
    }
  }
);

const leadsSlice = createSlice({
  name: "leads",
  initialState: {
    getLeadsList: [],
    error: null,
    loader: false,
  },

  // GET LEADS LIST
  extraReducers: (builder) => {
    builder.addCase(getLeadsList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getLeadsList.fulfilled, (state, action) => {
      state.loader = false;
      state.getLeadsList = action.payload;
    });
    builder.addCase(getLeadsList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    builder.addCase(purchaseLead.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(purchaseLead.fulfilled, (state, action) => {
      state.loader = false;
      state.purchaseLead = action.payload;
    });
    builder.addCase(purchaseLead.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    builder.addCase(updateLead.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateLead.fulfilled, (state, action) => {
      state.loader = false;
      state.updateLead = action.payload;
    });
    builder.addCase(updateLead.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    builder.addCase(postNewLead.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(postNewLead.fulfilled, (state, action) => {
      state.loader = false;
      state.postNewLead = action.payload;
    });
    builder.addCase(postNewLead.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default leadsSlice.reducer;
