import { toast } from "react-toastify";
import { box, randomBytes } from "tweetnacl";
import { decodeUTF8, encodeBase64, decodeBase64 } from "tweetnacl-util";
import { Slider } from "@mui/material";
import { styled } from "@mui/material/styles";
import image from "../../src/images/default-user.jpg";
import { useNavigate } from "react-router-dom";

const toastList = new Set();
const MAX_TOAST = 1;

export function capitalizeFirstLetter(string = "") {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function notify(test, message) {
  if (toastList.size < MAX_TOAST) {
    let id;
    if (message === "success") {
      id = toast.success(test, {
        // I'm using the onClose hook here to remove the id
        // from the set
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    } else {
      id = toast.error(test, {
        // I'm using the onClose hook here to remove the id
        // from the set
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    }
    toastList.add(id);
  }
}

export function creditCardHandler(cardNumber, expiry, cvc) {
  const obj = {
    credit_card: cardNumber.split(" ").join(""),
    expiry_date: expiry.split(" ").join(""),
    cvv: cvc,
  };

  const sharedA = box.before(
    decodeBase64(process.env.REACT_APP_PUBLIC_KEY),
    decodeBase64(process.env.REACT_APP_SECRET_KEY)
  );

  const newNonce = () => randomBytes(box.nonceLength);

  const encrypt = (secretOrSharedKey, json) => {
    const nonce = newNonce();
    const messageUint8 = decodeUTF8(JSON.stringify(json));
    const encrypted = box.after(messageUint8, nonce, secretOrSharedKey);

    const fullMessage = new Uint8Array(nonce.length + encrypted.length);
    fullMessage.set(nonce);
    fullMessage.set(encrypted, nonce.length);

    const base64FullMessage = encodeBase64(fullMessage);
    return base64FullMessage;
  };

  const encrypted = encrypt(sharedA, obj);

  const data = {
    card_info: encrypted,
    public_key: "hurKuHuhPQ4hw7rNjbbRjpSTh95xTRnO7WDCFMaGPzk=",
  };
  return data;
}

export const CustomSlider = styled(Slider)({
  width: "100%",
  color: "#5f0166",
  height: 6,
  margin: "10px 10px",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#5f0166",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 0,
    fontSize: 10,
    background: "unset",
    padding: 0,
    width: 25,
    height: 25,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#5f0166",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export const webaddressValidation = (address) => {
  if (address?.includes("https://")) {
    return address;
  } else {
    return "https://" + address;
  }
};

export const manageJobContacts = (
  jobDetail,
  user,
  showMessageModal,
  navigateToMsgPage
) => {
  return (
    <>
      <div className="zu_manage_job_contact_column">
        <h3 className="zu_manage_job_contact_column_heading">
          Business Contact
        </h3>
        <div className="zu_manage_job_contact_details_row">
          <img
            src={
              jobDetail?.owner_profile_photo
                ? `${process.env.REACT_APP_FILE_BASE_URL}/${jobDetail?.owner_profile_photo}`
                : image
            }
            alt="user img"
            className="zu_manage_job_contact_details_user_img"
          />
          <div className="zu_manage_job_contact_details">
            <h3 className="zu_manage_job_contact_details_heading">
              {capitalizeFirstLetter(jobDetail?.owner_first_name) +
                " " +
                capitalizeFirstLetter(jobDetail?.owner_last_name)}
            </h3>
            <p className="zu_manage_job_contact_details_number">
              {jobDetail?.owner_phone}
            </p>
            <p className="zu_manage_job_contact_details_email">
              {jobDetail?.owner_email}
            </p>
            <a
              href={webaddressValidation(jobDetail?.owner_web_address)}
              target="_blank"
              className="zu_manage_job_contact_details_web_link"
              rel="noreferrer"
            >
              Visit Website
            </a>
          </div>
        </div>
      </div>
      <div className="zu_manage_job_contact_column">
        <h3 className="zu_manage_job_contact_column_heading">Crew Contact</h3>
        <div className="zu_manage_job_contact_details_row">
          <img
            src={
              jobDetail?.crew_profile_photo
                ? `${process.env.REACT_APP_FILE_BASE_URL}/${jobDetail?.crew_profile_photo}`
                : image
            }
            alt="user img"
            className="zu_manage_job_contact_details_user_img"
          />
          <div className="zu_manage_job_contact_details">
            <h3 className="zu_manage_job_contact_details_heading">
              {capitalizeFirstLetter(jobDetail?.crew_first_name) +
                " " +
                capitalizeFirstLetter(jobDetail?.crew_last_name)}
            </h3>
            <p className="zu_manage_job_contact_details_number">
              {jobDetail?.crew_phone}
            </p>
            <p className="zu_manage_job_contact_details_email">
              {jobDetail?.crew_email}
            </p>

            {user?.role == "ROLE_ADMIN" && jobDetail?.bank_name !== null && (
              <p className="zu_manage_job_contact_details_email">
                Bank Name: {jobDetail?.bank_name}
              </p>
            )}
            {user?.role == "ROLE_ADMIN" &&
              jobDetail?.account_number !== null && (
                <p className="zu_manage_job_contact_details_email">
                  Account Number: {jobDetail?.account_number}
                </p>
              )}
            {user?.role == "ROLE_ADMIN" && jobDetail?.bsb !== null && (
              <p className="zu_manage_job_contact_details_email">
                BSB: {jobDetail?.bsb}
              </p>
            )}

            <a
              href={webaddressValidation(jobDetail?.crew_web_address)}
              target="_blank"
              className="zu_manage_job_contact_details_web_link"
              rel="noreferrer"
            >
              Visit Website
            </a>
            {user?.role !== "ROLE_ADMIN" && (
              <button
                type="button"
                className="zu_common_btn zu_btn_light_purple_color crew_send_msg_btn"
                // onClick={showMessageModal}
                onClick={() => navigateToMsgPage()}
              >
                Send Message
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const linksetup = (notification) => {
  let tempArray = notification?.message?.split("[[url]]") || [];
  if (tempArray.length === 1) {
    return [tempArray[0], "", ""];
  }
  let textLaterString = tempArray.length > 1 && tempArray[1];
  let tempArray1 = tempArray.length > 0 && tempArray[0].split("[[url=");
  let beforeString = tempArray1.length > 0 && tempArray1[0];
  let link = tempArray1.length > 1 ? tempArray1[1].split("]]") : "";
  let actualLink = link.length > 0 && link[0];
  let link12 = link.length > 1 ? link[1].link(actualLink) : "";
  let string = [beforeString, link12, textLaterString];
  return string;
};

export const messagesArray = [
  {
    msg_id: 1,
    text: "hello Yash...",
    sendBy: 1,
    from: "Vansh patel",
    to: 29,
    createdAt: "2023-02-14T10:02:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 2,
    text: "How are you?",
    sendBy: 1,
    from: "Vansh patel",
    to: 29,
    createdAt: "2023-02-14T10:02:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 3,
    text: "hello Vansh..",
    sendBy: 29,
    from: "Yash patel",
    to: 1,
    createdAt: "2023-02-14T10:03:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 4,
    text: "I am good.. How are you ?",
    sendBy: 29,
    from: "Yash patel",
    to: 1,
    createdAt: "2023-02-14T10:03:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 5,
    text: "I m Good...",
    sendBy: 1,
    from: "Vansh patel",
    to: 29,
    createdAt: "2023-02-14T10:03:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 6,
    text: "Vansh, I have work for you.. just see image",
    sendBy: 29,
    from: "Yash Patel",
    to: 1,
    createdAt: "2023-02-14T10:03:43.000Z",
    media:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    mediaType: "img",
    mediaName: "",
  },
  {
    msg_id: 7,
    text: "OHH That's Nice... We will meet soon!",
    sendBy: 1,
    from: "Vansh patel",
    to: 29,
    createdAt: "2023-02-14T10:04:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 8,
    text: "Great 🔥 You can Shot tomorrow. 😍",
    sendBy: 1,
    from: "Vansh patel",
    to: 29,
    createdAt: "2023-02-14T10:04:43.000Z",
    media: "https://cdn.pixabay.com/photo/2012/06/19/10/32/owl-50267__340.jpg",
    mediaType: "img",
    mediaName: "",
  },
  {
    msg_id: 9,
    text: "Okay then see you soon!!!",
    sendBy: 29,
    from: "Yash patel",
    to: 1,
    createdAt: "2023-02-14T10:05:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 10,
    text: "hey Yash wait I have something to tell you..",
    sendBy: 1,
    from: "Vansh patel",
    to: 29,
    createdAt: "2023-02-15T12:02:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 11,
    text: "yash Your marrige will be coming next month so what about the bachlor party ?",
    sendBy: 1,
    from: "Vansh patel",
    to: 29,
    createdAt: "2023-02-15T12:03:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 12,
    text: "🍸",
    sendBy: 1,
    from: "Vansh patel",
    to: 29,
    createdAt: "2023-02-15T12:03:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 13,
    text: "Oh bratha... Sure.. i will arrrange that.",
    sendBy: 29,
    from: "Yash patel",
    to: 1,
    createdAt: "2023-02-15T12:04:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 14,
    text: "That's Awsome bro..",
    sendBy: 29,
    from: "Yash patel",
    to: 1,
    createdAt: "2023-02-15T12:04:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 15,
    text: "Okay bro.. Bye",
    sendBy: 1,
    from: "Vansh patel",
    to: 29,
    createdAt: "2023-02-15T12:04:43.000Z",
    media: "",
    mediaType: "",
    mediaName: "",
  },
  {
    msg_id: 16,
    text: "",
    sendBy: 1,
    from: "Vansh patel",
    to: 29,
    createdAt: "2023-02-15T12:05:43.000Z",
    media:
      "https://cdn.pixabay.com/photo/2023/01/12/18/08/beach-7714610__340.jpg",
    mediaType: "img",
    mediaName: "",
  },
  {
    msg_id: 17,
    text: "",
    sendBy: 29,
    from: "Yash patel",
    to: 1,
    createdAt: "2023-02-15T12:05:44.000Z",
    media: "https://www.africau.edu/images/default/sample.pdf",
    mediaType: "pdf",
    mediaName: "yashp.pdf",
  },
  {
    msg_id: 18,
    text: "",
    sendBy: 1,
    from: "Vansh patel",
    to: 29,
    createdAt: "2023-02-15T12:05:44.000Z",
    media:
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    mediaType: "pdf",
    mediaName: "shootzu.pdf",
  },
];

export const checkDateIsMonday = (dateValue) => {
  return dateValue && dateValue?.toString().substr(0, 3) === "Mon"
    ? false
    : true;
};
