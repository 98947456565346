import React from "react";
import "../../css/component.css";
import DropBoxImage from "./DropBoxImage";

const ShowImage = ({ images, setValues }) => {
  const removeImageHandler = (image, i) => {
    setValues((prevState) => {
      let tempImages = [...prevState.images].filter((im, ix) => i !== ix);
      let tempPreviewes = [...prevState.images_preview].filter(
        (im, ix) => i !== ix
      );

      return {
        ...prevState,
        images: tempImages,
        images_preview: tempPreviewes,
      };
    });
  };
  const show = (image, i) => {
    return (
      <DropBoxImage
        key={i}
        image={image}
        removeImageHandler={() => removeImageHandler(image, i)}
      />
    );
  };
  return (
    <>
      <div
        className={`zu_upload_image_priview_row ${
          images?.map(show)?.length === 0
            ? "zu_upload_image_priview_no_data"
            : ""
        }`}
      >
        {images?.map(show)}
      </div>
    </>
  );
};

export default ShowImage;
