import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { jobStatusList, marks, routes } from "../../constants";
import ImageTextContent from "../../component/Layout/ImageTextContent";
import JobsColumn from "../../component/Layout/JobsColumn";
import CommonHeading from "../../component/Common/CommonHeading";
import managejobsGraphicImage from "../../images/ManagejobsGraphicImage.jpg";
import { locationFillIcon } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import { getjobListbyUser } from "../../store/slice/postSlice";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { useForm } from "../../hooks/useForm";
import { Form } from "react-bootstrap";
import ProfileAttentionModal from "../../component/Layout/ProfileAttentionModal";
import { checkUserProfile } from "../../store/slice/userSlice";
import { Modal } from "react-bootstrap";
import { CustomSlider } from "../../helpers/commonFunction";
import AutoComplete from "../../component/Common/AutoComplete";

const ManageJobsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const params = useParams();
  const { page_number } = params;
  const manageJobs = useSelector((state) => state.post.getjobListbyUser);
  const loading = useSelector((state) => state.post.loader);
  const totalCount = useSelector((state) => state.post.totalCount);

  const initialFValues = {
    job_status: "",
    location: "",
    distance: 100,
    profileModalShow: false,
    approvalStatus: "",
    order_by: "",
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    navigate(`${routes.managejobs}/page/1`);
    if (+page_number === 1)
      dispatch(
        getjobListbyUser({
          job_status: values.job_status,
          location: values.location,
          km: values.distance,
          order_by: values.job_status === "COMPLETED_DONE" && "DESC",
        })
      );
  }, [dispatch, values.distance, values.job_status, values.location]);

  const locationChangeHandler = (e) => {
    setValues({ ...values, location: "" });
  };

  function valuetext(value) {
    return value;
  }

  function valueTextSlider(e, value) {
    setValues({ ...values, distance: value });
  }

  const postJobHandler = () => {
    dispatch(
      checkUserProfile({
        cb: (res, err) => {
          if (err) {
            console.log("err", err);
          } else {
            if (res.user_status === "VERIFICATION_SUCCESS") {
              navigate(routes.managenewpostjob);
            } else {
              setValues({
                ...values,
                profileModalShow: true,
                approvalStatus: res.user_status,
              });
            }
          }
        },
      })
    );
  };

  const hideProfileModal = () => {
    setValues({ ...values, profileModalShow: false });
  };

  const onPageChange = (page_number) => {
    dispatch(
      getjobListbyUser({
        start: page_number,
        limit: 10,
        job_status: values.job_status,
        location: values.location,
        km: values.distance,
        order_by: values.job_status === "COMPLETED_DONE" && "DESC",
      })
    );
  };

  return (
    <>
      {loading && <Loader />}
      <main className="zu_all_page_main_content">
        <div className="zu_manage_job_page">
          <section className="zu_manage_job_banner_section">
            <div className="zu_manage_job_banner_content">
              <ImageTextContent
                imageSrc={managejobsGraphicImage}
                heading="Hire Crew"
              />
            </div>
          </section>
          <section className="zu_manage_job_section">
            <div
              className={`d-lg-none zu_common_job_heading ${
                openFilter && "zu_common_job_heading_filter_open"
              }`}
            >
              <CommonHeading heading="Hire Crew" />
              <button
                type="button"
                className="zl_common_job_show_filter"
                onClick={() => setOpenFilter(!openFilter)}
              >
                {openFilter ? "Hide Filters" : "Show Filters"}
              </button>
            </div>
            <div className="zu_manage_job_content">
              <div className="zu_manage_job_filter_row">
                <div
                  className={`zu_common_filter_input_row ${
                    openFilter && "zu_common_filter_open"
                  }`}
                >
                  <div className="zu_common_filter_input_two zu_common_select_location_icon">
                    {locationFillIcon}

                    <AutoComplete
                      id="location"
                      name="location"
                      placeholder="Location"
                      values={values}
                      setValues={setValues}
                      handleInputChange={(e) => locationChangeHandler(e)}
                    />
                  </div>
                  <div className="zu_common_filter_input_one">
                    <div className="zu_common_select_box">
                      <Form.Select
                        onChange={handleInputChange}
                        id="job_status"
                        className="zu_common_filter_input_box"
                        name="job_status"
                      >
                        <option disabled selected>
                          Job Status
                        </option>
                        <option value="">All</option>

                        {jobStatusList.map((jobStatus, i) => {
                          return (
                            <option
                              key={i}
                              selected={
                                values?.job_status === jobStatus.labelText
                              }
                              value={jobStatus.labelText}
                            >
                              {jobStatus.filterLabel}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </div>

                  <div className="zu_common_filter_input_three">
                    <CustomSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      step={100}
                      defaultValue={100}
                      min={100}
                      marks={marks}
                      max={300}
                      valueLabelFormat={"KM"}
                      value={values.distance}
                      getAriaValueText={valuetext}
                      onChangeCommitted={valueTextSlider}
                    />
                  </div>
                </div>
                <button
                  // to={routes.managenewpostjob}
                  onClick={postJobHandler}
                  className="zu_common_btn zu_btn_lighter_green"
                >
                  post new job
                </button>
              </div>
              <div className="zu_manage_job_column_content">
                {manageJobs?.length === 0 ? (
                  <h1 className="zu_no_jobs_found_content">No Jobs Found</h1>
                ) : (
                  manageJobs?.map((job, i) => {
                    return (
                      <JobsColumn
                        key={`zu_manage_job${i}`}
                        job={job}
                        hasUpcommingJob
                        hasBookMark
                      />
                    );
                  })
                )}
              </div>
            </div>
          </section>
        </div>
        {totalCount > 0 && (
          <Pagination totalRecords={totalCount} onPageChange={onPageChange} />
        )}
      </main>
      <Modal
        show={values.profileModalShow}
        onHide={hideProfileModal}
        centered
        className="zu_common_text_modal"
      >
        <ProfileAttentionModal
          hideProfileModal={hideProfileModal}
          approvalStatus={values.approvalStatus}
        />
      </Modal>
    </>
  );
};

export default ManageJobsPage;
