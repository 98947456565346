import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useRoutes } from "react-router-dom";
import getRoutes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./css/style.css";
import { checkAuth } from "./helpers/checkAuth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useLocation } from "react-router-dom";
import { getChatUsers } from "./store/slice/chatSlice";

toast.configure();
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [err, setErr] = useState(localStorage.getItem("error"));

  useEffect(() => {
    user && checkAuth(dispatch, setErr, navigate);
  }, [dispatch, err, navigate]);

  const routing = useRoutes(getRoutes(user, null));

  return <>{routing}</>;
}

export default App;
