import React from "react";
import ReactStars from "react-rating-stars-component";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";
import { closeIcon, ratingFillStarIcon, ratingLineStarIcon } from "../../icons";

const BusinessReviewModal = ({
  reviewMDetailsForBusinessPerson,
  hideBusinessReviewModal,
  values,
  BusinessRatingChanged,
  handleInputChange,
  submitBusinessReviewHandler,
}) => {
  return (
    <div className="zu_common_modal zu_common_center_modal zu_leave_review_modal">
      <div className="zu_common_modal_content">
        <button
          type="button"
          className="zu_modal_close_btn"
          onClick={() => hideBusinessReviewModal()}
        >
          {closeIcon}
        </button>
        <div className="zu_common_modal_heading_paragraph">
          <h2 className="zu_common_modal_heading">Leave a Review</h2>
          <p className="zu_common_modal_paragraph">
            Reviews will be viewable on Shootzu profiles.
          </p>
        </div>
        <h2 className="zu_common_modal_second_heading">
          {capitalizeFirstLetter(
            reviewMDetailsForBusinessPerson?.shooter_first_name
          ) +
            " " +
            capitalizeFirstLetter(
              reviewMDetailsForBusinessPerson?.shooter_last_name
            )}
        </h2>
        <span className="zu_common_modal_sub_heading">
          {capitalizeFirstLetter(reviewMDetailsForBusinessPerson?.job_title)}
        </span>
        <div className="zu_common_modal_rating_star_row">
          <ReactStars
            count={5}
            size={40}
            emptyIcon={ratingLineStarIcon}
            filledIcon={ratingFillStarIcon}
            value={values.BusinessRating}
            onChange={BusinessRatingChanged}
          />
        </div>
        <div className="zu_common_modal_text_input">
          <textarea
            name="BuisnessReview"
            placeholder="write your review"
            className="zu_common_modal_textarea"
            id="BuisnessReview"
            value={values.BuisnessReview}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <div className="zu_modal_common_btn_row">
          <button
            type="button"
            className="zu_common_link_btn"
            onClick={() => hideBusinessReviewModal()}
          >
            Later
          </button>
          <button
            type="button"
            className="zu_common_btn zu_btn_light_green zu_modal_common_submit_btn"
            onClick={() => submitBusinessReviewHandler()}
          >
            Submit Review
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessReviewModal;
