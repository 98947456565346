import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";
import { closeIcon } from "../../icons";

const JobOfferModal = ({
  hideJobOfferModal,
  jobModalDetail,
  acceptModalHandler,
  DeclineModalHandler,
  policyErr,
  setValues,
  values,
  viewJobLink,
}) => {
  const [policy, setPolicy] = useState(false);

  const policyHandler = () => {
    setValues({ ...values, policyErr: "" });
    setPolicy(!policy);
  };

  return (
    <>
      <div className="zu_common_modal zu_common_center_modal zu_job_offer_modal">
        <div className="zu_common_modal_content">
          <button
            type="button"
            className="zu_modal_close_btn"
            onClick={() => hideJobOfferModal()}
          >
            {closeIcon}
          </button>
          <h2 className="zu_common_modal_heading">
            You have a new job offer from{" "}
            {jobModalDetail &&
              capitalizeFirstLetter(jobModalDetail?.owner_first_name) +
                " " +
                capitalizeFirstLetter(jobModalDetail?.owner_last_name)}
          </h2>
          {jobModalDetail?.job_worker_roles &&
            JSON.parse(jobModalDetail?.job_worker_roles).map((i, id) => {
              return (
                <span
                  className={`zu_common_label ${
                    i === "PHOTO_EDITOR" || i === "PHOTOGRAPHER"
                      ? " zu_common_label_success"
                      : i === "VIDEO_EDITOR" || i === "VIDEOGRAPHER"
                      ? "zu_common_label_primary"
                      : i === "DRONE_OPERATOR" || i === "ASSISTANT"
                      ? "zu_common_label_other"
                      : ""
                  }`}
                  key={`zu_label${id}`}
                >
                  {i === "DRONE_OPERATOR"
                    ? "DRONE(OPERATOR)"
                    : i === "PHOTO_EDITOR"
                    ? "PHOTO(EDITOR)"
                    : i === "VIDEO_EDITOR"
                    ? "VIDEO(EDITOR)"
                    : i}
                </span>
              );
            })}
          <h2 className="zu_common_modal_second_heading">
            {jobModalDetail?.title
              ? jobModalDetail?.title
              : jobModalDetail?.job_title}
            (
            {jobModalDetail?.job_type === "EVENTS"
              ? "event"
              : jobModalDetail?.job_type === "REAL_ESTATE"
              ? "real estate"
              : jobModalDetail?.job_type === "WEDDINGS"
              ? "wedding"
              : jobModalDetail?.job_type === "CORPORATE"
              ? "corporate"
              : jobModalDetail?.job_type === "OTHERS"
              ? "other"
              : ""}
            )
          </h2>
          <span className="zu_common_modal_sub_heading">
            {jobModalDetail?.address}
          </span>
          {viewJobLink && (
            <Link
              to={`/myjobs/page/1/${jobModalDetail?.job_id}`}
              className="zu_common_modal_sub_heading"
              style={{ marginTop: "1rem" }}
            >
              View Full Job Details
            </Link>
          )}
          <div className="zu_common_modal_other_detail_row">
            <div className="zu_common_modal_other_detail_column">
              <div className="zu_common_modal_other_detail_text">
                <h3>Job Date</h3>
                <p>
                  {moment(
                    jobModalDetail?.date
                      ? jobModalDetail?.date
                      : jobModalDetail?.job_date
                  )
                    .utc()
                    .format("DD MMM YYYY")}
                </p>
              </div>
            </div>
            <div className="zu_common_modal_other_detail_column">
              <div className="zu_common_modal_other_detail_text">
                <h3>Crew Payment</h3>
                <p>
                  ${" "}
                  {(
                    jobModalDetail &&
                    jobModalDetail?.number_of_hour *
                      (jobModalDetail?.price_per_hour &&
                        jobModalDetail?.price_per_hour * 0.98)
                  )?.toFixed(2)}{" "}
                  AUD
                </p>
              </div>
            </div>
          </div>
          <div className="zu_common_modal_checkbox">
            <input
              type="checkbox"
              id="zu_job_offer_checkbox"
              name="policy"
              value={policy}
              onChange={policyHandler}
            />
            <label htmlFor="zu_job_offer_checkbox">
              By accepting this job offer, you are accepting the{" "}
              <a href="https://www.shootzu.com/terms/" target="_blank">
                terms and conditions
              </a>
              , including crew contract.
              {policyErr && <span className="err_text">{policyErr}</span>}
            </label>
          </div>

          <div className="zu_modal_common_btn_row">
            <button
              type="button"
              className="zu_common_btn zu_btn_light_green zu_modal_common_accetp_offer_btn"
              onClick={() => {
                acceptModalHandler(jobModalDetail, policy);
              }}
            >
              Accept Offer
            </button>
            <button
              type="button"
              className="zu_common_btn zu_danger_btn zu_modal_common_accetp_decline_btn"
              onClick={() => {
                DeclineModalHandler(jobModalDetail);
              }}
            >
              Decline Offer
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobOfferModal;
