import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Axios from "../../helpers/axios";

export const sendOtp = createAsyncThunk("sendOtp", async (data, thunkAPI) => {
  try {
    const response = await Axios.post("/leads/send-otp", data.data);
    if (response.status === 200) {
      toast.success(response.data.message, {
        autoClose: 3000,
      });
      return data.cb(null, response.data);
    }
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return isRejectedWithValue(error.response.data.message);
  }
});

export const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post("/leads/verify-otp", data.data);
      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 3000,
        });
        return data.cb(null, response.data);
      }
    } catch (error) {
      return data.cb(error.response);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    error: null,
    loader: false,
  },

  extraReducers: (builder) => {
    builder.addCase(sendOtp.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      state.loader = false;
      state.sendOtp = action.payload;
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    builder.addCase(verifyOtp.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.loader = false;
      state.verifyOtp = action.payload;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default authSlice.reducer;
