import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetter,
  webaddressValidation,
} from "../../helpers/commonFunction";
import {
  modalCloseIcon,
  ratingFillStarIcon,
  ratingStarIcon,
} from "../../icons";
import image from "../../images/default-user.jpg";
import { userCardList } from "../../store/slice/userSlice";

const ApplicantDetailModal = ({
  hideApplicantDetailModal,
  jobDetail,
  values,
  setValues,
  ownerDetail,
  hideOwnerDetailModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const applicantDetail = values?.applicantModalDetails;

  const applicantBio = applicantDetail
    ? applicantDetail?.public_bio &&
      JSON.parse(applicantDetail?.public_bio)?.text
    : ownerDetail?.public_bio && JSON.parse(ownerDetail?.public_bio)?.text;

  const showMessageModal = () => {
    setValues({
      ...values,
      sendMessageModal: true,
      applicantDetailModal: false,
    });
  };

  const showSendModalHandler = (e) => {
    e.preventDefault();

    dispatch(
      userCardList({
        cb: (err, res) => {
          if (err) {
          } else {
            setValues({
              ...values,
              sendOfferModal: true,
              applicantDetailModal: false,
              cardData: res,
              creditCardId: "",
            });
          }
        },
      })
    );
  };

  const getRatingStars = (rating) => {
    let ratingStars = Array.from(
      {
        length: rating ? rating?.toFixed(1) : 0,
      },
      (_, i) => i + 1
    );
    return ratingStars;
  };

  const getEmptyRatingStars = (rating) => {
    let emptyRatingStars = Array.from(
      { length: 5 - getRatingStars(rating)?.length },
      (_, i) => i + 1
    );
    return emptyRatingStars;
  };

  return (
    <>
      <div className="zu_applicant_detail_modal">
        <button
          type="button"
          className="zu_modal_close_btn"
          onClick={
            applicantDetail
              ? () => hideApplicantDetailModal()
              : () => hideOwnerDetailModal()
          }
        >
          {modalCloseIcon}
        </button>
        <div className="zu_applicant_detail_content">
          <div className="zu_applicant_detail_img_name_content">
            <img
              src={
                applicantDetail
                  ? applicantDetail?.profile_photo
                    ? `${process.env.REACT_APP_FILE_BASE_URL}/${applicantDetail?.profile_photo}`
                    : image
                  : ownerDetail?.profile_photo
                  ? `${process.env.REACT_APP_FILE_BASE_URL}/${ownerDetail?.profile_photo}`
                  : image
              }
              alt="user img"
              className="zu_applicant_detail_user_img"
            />
            <div className="zu_applicant_detail_user_name_btn">
              <div>
                <span className="zu_applicant_detail_business_name">
                  {applicantDetail
                    ? applicantDetail?.business_name
                    : ownerDetail?.business_name}
                </span>
                {applicantDetail && (
                  <h2 className="zu_applicant_detail_user_name">
                    {capitalizeFirstLetter(applicantDetail?.first_name)}{" "}
                    {capitalizeFirstLetter(applicantDetail?.last_name)}
                  </h2>
                )}
                {ownerDetail && (
                  <h2 className="zu_applicant_detail_user_name">
                    {capitalizeFirstLetter(ownerDetail?.first_name)}{" "}
                    {capitalizeFirstLetter(ownerDetail?.last_name)}
                  </h2>
                )}

                {applicantDetail && applicantDetail?.web_address && (
                  <a
                    href={webaddressValidation(applicantDetail?.web_address)}
                    target="_blank"
                    className="zu_applicant_detail_view_website_link"
                    rel="noreferrer"
                  >
                    View Website
                  </a>
                )}
                {ownerDetail && ownerDetail?.web_address && (
                  <a
                    href={webaddressValidation(ownerDetail?.web_address)}
                    target="_blank"
                    className="zu_applicant_detail_view_website_link"
                    rel="noreferrer"
                  >
                    View Website
                  </a>
                )}
              </div>
              <div className="zu_applicant_detail_star_rating_content">
                <span className="zu_applicant_detail_star_ratings">
                  {getRatingStars(applicantDetail?.avg_ratings).map(
                    (i, index) => ratingFillStarIcon
                  )}
                  {getEmptyRatingStars(applicantDetail?.avg_ratings).map(
                    (i, index) => ratingStarIcon
                  )}
                </span>
                <span className="zu_applicant_detail_star_rating_display">
                  {applicantDetail?.avg_ratings
                    ? applicantDetail?.avg_ratings.toFixed(1)
                    : "0"}{" "}
                  Stars
                </span>
              </div>
            </div>
          </div>
          {applicantDetail && applicantDetail?.message && (
            <div className="zu_applicant_detail_user_about_text">
              <h2 className="zu_applicant_detail_user_message_heading">
                Message
              </h2>
              <pre className="zu_applicant_detail_user_message">
                {applicantDetail?.message}
              </pre>
            </div>
          )}
          <div className="zu_applicant_detail_user_about_text">
            <h2 className="zu_applicant_detail_user_about_heading">About</h2>
            <pre
              className="zu_applicant_detail_user_about_paragraph"
              dangerouslySetInnerHTML={{
                __html: applicantBio,
              }}
            />
          </div>
          <div className="zu_applicant_detail_user_more_detail_link">
            {applicantDetail && (
              <Link
                to={
                  // user?.role == "ROLE_ADMIN"
                  //   ? `/adminmanagejobsapplicant/${values.applicantModalDetails.id}`
                  //   :
                  `/managejobsapplicant/${applicantDetail.id}`
                }
                state={{
                  id: values.applicantModalDetails?.id,
                  offer_id: values.applicantModalDetails?.offer_id,
                  jobId: jobDetail?.id,
                  title: jobDetail?.title,
                  date: jobDetail?.date,
                  jobHour: jobDetail?.number_of_hour,
                  jobPerHour: jobDetail?.price_per_hour,
                  jobStatus: jobDetail?.job_status,
                  jobOwnerId: jobDetail?.owner,
                }}
                className=""
              >
                View More
              </Link>
            )}
            {ownerDetail && (
              <Link
                to={
                  // user?.role == "ROLE_ADMIN"
                  //   ? `/adminmanagejobsapplicant/${values.applicantModalDetails.id}`
                  //   :
                  `/findjobsowner/${ownerDetail?.id}`
                }
                className=""
              >
                View More
              </Link>
            )}
          </div>
          {applicantDetail && (
            <div className="zu_applicant_detail_bottom_btn">
              {!applicantDetail?.offer_id && (
                <button
                  type="button"
                  className="zu_common_btn zu_btn_light_green zu_modal_common_submit_btn"
                  onClick={showSendModalHandler}
                >
                  SEND JOB OFFER
                </button>
              )}
              {/* <button
                type="button"
                className="zu_common_btn zu_modal_common_submit_btn"
                // onClick={showMessageModal}

              >
                SEND MESSAGE
              </button> */}
              <button
                className="zu_common_btn zu_modal_common_submit_btn"
                onClick={() =>
                  navigate(`/messages`, {
                    state: {
                      job_status: jobDetail?.job_status,
                      job_owner_id: jobDetail?.owner,
                      job_id: jobDetail?.id,
                      job_title: jobDetail?.title,
                      job_date: jobDetail?.date,
                      opp_user_id: applicantDetail?.id,
                      opp_first_name: applicantDetail?.first_name,
                      opp_last_name: applicantDetail?.last_name,
                      opp_profile_photo: applicantDetail?.profile_photo,
                    },
                  })
                }
              >
                SEND MESSAGE
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ApplicantDetailModal;
