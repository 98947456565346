import visalogo from "../../images/Visa_Brandmark_Blue.png";
import americanLogo from "../../images/american-express.jpg";
import masterCardLogo from "../../images/mc_symbol_opt.png";
import jcbLogo from "../../images/jcb-co-ltd.png";
import discoverLogo from "../../images/DGN_AcceptanceMark.jpg";
import unionPayLogo from "../../images/unionpay.png";
import defaultCardLogo from "../../images/default_credit_card.png";
import React from "react";
import CreditCardInput from "react-credit-card-input";
import { useDispatch } from "react-redux";
import { creditCardHandler } from "../../helpers/commonFunction";
import {
  createStripeCustomer,
  userCardList,
} from "../../store/slice/userSlice";
import { plusIcon } from "../../icons";

const CardList = ({
  cardData,
  creditCardId,
  handleCardRadio,
  creditCardIdErr,
  cardBlinkClass,
  cardBlinkClassTwo,
  cardNumber,
  expiry,
  cvc,
  cardNumberErr,
  expiryErr,
  cvcErr,
  cardDataErr,
  setValues,
  values,
}) => {
  const dispatch = useDispatch();

  const validateCards = () => {
    let isValid = false;

    if (!values.cardNumber && !values.expiry && !values.cvc) {
      setValues({
        ...values,
        cardDataErr: "You have to first add details of credit card",
      });
    } else {
      isValid = true;
    }
    return isValid;
  };

  const cvcValidateHandler = () => {
    let isValid = false;

    if (!values.cardNumber) {
      setValues({
        ...values,
        cardDataErr: "Card number is required",
      });
    } else if (!values.expiry) {
      setValues({
        ...values,
        cardDataErr: "Expiry date is required",
      });
    } else if (!values.cvc || +values.cvc.length < 3) {
      setValues({
        ...values,
        cardDataErr: "Cvc is required",
      });
    } else {
      isValid = true;
    }
    return isValid;
  };

  const sendDataHandler = (e) => {
    e.preventDefault();
    if (
      values.cardNumberErr ||
      values.expiryErr ||
      values.cvcErr ||
      validateCards() === false ||
      cvcValidateHandler() === false
    ) {
    } else {
      const data = creditCardHandler(
        values.cardNumber,
        values.expiry,
        values.cvc
      );

      dispatch(
        createStripeCustomer({
          data,
          cb: (err, res) => {
            if (err) {
              setValues({ ...values, policy: false });
            } else {
              dispatch(
                userCardList({
                  cb: (err, res) => {
                    if (err) {
                      console.log("err", err);
                    } else {
                      setValues({
                        ...values,
                        cardData: res,

                        creditCardId: "",
                        expiryErr: "",
                        cvcErr: "",
                        cardDataErr: "",
                        cardNumberErr: "",
                        expiry: "",
                        cvc: "",
                        cardNumber: "",
                        policy: false,
                      });
                    }
                  },
                })
              );
            }
          },
        })
      );
    }
  };

  const handleCardNumberChange = (e) => {
    setValues({
      ...values,
      cardNumberErr: "",
      cardDataErr: "",
      cardNumber: e.target.value,
    });
  };

  const handleCardExpiryChange = (e) => {
    setValues({
      ...values,
      expiryErr: "",
      cardDataErr: "",
      expiry: e.target.value,
    });
  };

  const handleCardCVCChange = (e) => {
    setValues({
      ...values,
      cvcErr: "",
      cardDataErr: "",
      cvc: e.target.value,
    });
  };

  return (
    <div className="zu_common_modal_card_info_content">
      <div className="zu_common_modal_send_offer_card">
        <h2 className="zu_common_modal_send_offer_heading">
          Credit Card Information
        </h2>
      </div>
      <div>
        <div className="zu_credit_card_container">
          <div className="zu_credit_card_input zu_card_input">
            <CreditCardInput
              invalidStyle={{ border: "1px solid black" }}
              cardImageStyle={!cardNumber && !expiry && !cvc && { opacity: 0 }}
              fieldClassName="zu_common_card_input"
              customTextLabels={{
                cardNumberPlaceholder: "Card Number",
              }}
              cardNumberInputProps={{
                value: cardNumber,
                onChange: handleCardNumberChange,
                onError: (err) => {
                  console.log("err1", err);
                  setTimeout(() => {
                    setValues((prevState) => {
                      return {
                        ...prevState,
                        cardNumberErr: !prevState.creditCardId ? err : "",
                      };
                    });
                  }, 100);
                },
              }}
              cardExpiryInputProps={{
                value: expiry,
                onChange: handleCardExpiryChange,
                onError: (err) => {
                  console.log("err2", err);
                  setTimeout(() => {
                    setValues((prevState) => {
                      return {
                        ...prevState,
                        expiryErr: !prevState.creditCardId ? err : "",
                      };
                    });
                  }, 100);
                },
              }}
              cardCVCInputProps={{
                value: cvc,
                onChange: handleCardCVCChange,
                onError: (err) => {
                  console.log("err3", err);
                  setTimeout(() => {
                    setValues((prevState) => {
                      return {
                        ...prevState,
                        cvcErr: !prevState.creditCardId ? err : "",
                      };
                    });
                  }, 100);
                },
              }}
            />
            {(cardNumberErr || expiryErr || cvcErr || cardDataErr) && (
              <div className="err_text">
                {cardDataErr
                  ? cardDataErr
                  : cardNumberErr
                  ? cardNumberErr
                  : expiryErr
                  ? expiryErr
                  : cvcErr
                  ? cvcErr
                  : ""}
              </div>
            )}
          </div>
          <button
            type="button"
            className="zu_send_offer_add_card_btn"
            onClick={sendDataHandler}
          >
            {plusIcon}
          </button>
        </div>
        {values?.cardData?.length === 0 && (
          <div
            className={`zu_warning_message alert alert-warning alert-dismissible mt-2 fade show ${
              cardBlinkClass && "blink_me"
            } ${cardBlinkClassTwo && "blink_me_two"}`}
            role="alert"
          >
            <strong>ALERT!</strong> To proceed further, please add your credit
            card.
          </div>
        )}
      </div>

      <div className="creditcard-detail_container">
        {cardData?.map((item, index) => {
          return (
            <label
              htmlFor={item.card_profile_id}
              className="creditcard_detail"
              key={index}
              // defaultChecked={index[0]}
            >
              <div className="creditcard_detail_img">
                <img
                  alt="creditcard_logo"
                  src={
                    item.card_type === "Visa"
                      ? visalogo
                      : item.card_type === "American Express"
                      ? americanLogo
                      : item.card_type === "MasterCard"
                      ? masterCardLogo
                      : item.card_type === "Japan Credit Bureau"
                      ? jcbLogo
                      : item.card_type === "China UnionPay"
                      ? unionPayLogo
                      : item.card_type === "Discover & Diners"
                      ? discoverLogo
                      : defaultCardLogo
                  }
                />
              </div>
              <div className="creditcard_detail_number_date">
                <h4>{`**** **** **** ${item.last_4}`}</h4>
                <p>
                  {item.exp_month}/{item.exp_year}
                </p>
              </div>
              <input
                type="radio"
                name="crd_card"
                className="creditcard_detail-radio"
                value={creditCardId}
                onChange={(e) => handleCardRadio(item.card_profile_id)}
                id={item.card_profile_id}
                // checked={item.card_profile_id === creditCardId}
              />
            </label>
          );
        })}
      </div>
      {creditCardIdErr && <span className="err_text">{creditCardIdErr}</span>}
    </div>
  );
};

export default CardList;
