import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes, specialtyLabelList } from "../../constants";
import CommonHeading from "../../component/Common/CommonHeading";
import ProfileUserDetaile from "../../component/Layout/ProfileUserDetaile";
import Input from "../../component/Common/Input";
import { Form } from "react-bootstrap";
import { useForm } from "../../hooks/useForm";
import ButtonCom from "../../component/Common/Button";
import DropBox from "../../component/Layout/DropBox";
import ShowImage from "../../component/Layout/ShowImage";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import {
  editProfile,
  getApplicantProfile,
  getProfile,
  profileForApproval,
} from "../../store/slice/userSlice";
import { fileUpload } from "../../helpers/fileUpload";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import Loader from "../../component/Common/Loader";
import draftToHtml from "draftjs-to-html";
import { notify } from "../../helpers/commonFunction";
import image from "../../images/default-user.jpg";
import AutoComplete from "../../component/Common/AutoComplete";

const ProfileEditPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useRef();
  const user = useSelector((state) => state.user.user);
  const id = user?.user_id;
  const [content, setContent] = useState("");
  const [contentErr, setContentErr] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (location?.state?.applicantId) {
      dispatch(getApplicantProfile({ id: location?.state?.applicantId }));
    } else if (id) {
      dispatch(getProfile({ id }));
    }
  }, [dispatch, id, location?.state?.applicantId]);

  const profile = useSelector((state) => state.user.getApplicantProfile);
  const profile1 = useSelector((state) => state.user.getProfile);

  const loading = useSelector((state) => state.user.loader);
  const profileloader = useSelector((state) => state.user.proloader);

  const initialFValues = useCallback(() => {
    return getInitialProfileValues(profile ? profile : profile1);
  }, [profile, profile1]);

  let initialCompanyAwardPreview;

  const getInitialProfileValues = (profileData) => {
    let tempContent =
      profileData?.public_bio && JSON.parse(profileData?.public_bio).text;
    const contentBlock = tempContent && htmlToDraft(tempContent);
    const contentState =
      contentBlock &&
      ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState1 =
      contentState && EditorState.createWithContent(contentState);
    setContent(
      (profileData?.public_bio && JSON.parse(profileData?.public_bio).text) ||
        ""
    );

    if (profileData?.images) {
      initialCompanyAwardPreview = JSON.parse(profileData?.images).map((a) => {
        return `${process.env.REACT_APP_FILE_BASE_URL}/${a}`;
      });
    }
    return {
      user_worker_roles: profileData?.user_worker_roles
        ? JSON.parse(profileData?.user_worker_roles)
        : [],
      address: profileData?.address ? profileData?.address : "",
      business_name: profileData?.business_name
        ? profileData?.business_name
        : "",
      public_bio_editor_state: editorState1 ? editorState1 : editorState,
      email: profileData?.email ? profileData?.email : "",
      web_address: profileData?.web_address ? profileData?.web_address : "",
      abn: profileData?.abn ? profileData?.abn : "",
      profile_photo: profileData?.profile_photo
        ? profileData?.profile_photo
        : "",
      gst: profileData?.gst ? profileData?.gst : "0",
      experience: profileData?.experience ? profileData?.experience : "",
      postal_address: profileData?.postal_address
        ? profileData?.postal_address
        : "",
      phone: profileData?.phone ? profileData?.phone : "",
      images: profileData?.images ? JSON.parse(profileData?.images) : [],
      images_preview: profileData?.images ? initialCompanyAwardPreview : [],
      images_error: "",
      videos:
        profileData?.videos && JSON.parse(profileData?.videos).length > 0
          ? JSON.parse(profileData?.videos)
          : [""],
      videos_error: [],
      resources:
        profileData?.resources && JSON.parse(profileData?.resources).length > 0
          ? JSON.parse(profileData?.resources)
          : [""],
      resources_error: [],
      stateLoad: false,
      file: "",
      filePreview: "",
      loadingApproval: false,
    };
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };
    var expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    var regex = new RegExp(expression);

    function checkABN() {
      if (!fieldValues.abn || fieldValues.abn.length !== 11) {
        return false;
      }
      var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19],
        checksum = fieldValues.abn
          .split("")
          .map(Number)
          .reduce(function (total, digit, index) {
            if (!index) {
              digit--;
            }
            return total + digit * weights[index];
          }, 0);

      if (!checksum || checksum % 89 !== 0) {
        return false;
      }
      return true;
    }

    if ("user_worker_roles" in fieldValues) {
      temp.user_worker_roles =
        fieldValues.user_worker_roles?.length > 0
          ? ""
          : "Please select at least one category!";
    }
    if ("address" in fieldValues) {
      temp.address = fieldValues.address?.trim() ? "" : "Address is required!";
    }
    if ("business_name" in fieldValues) {
      temp.business_name = fieldValues.business_name?.trim()
        ? ""
        : "Business name is required!";
    }
    if ("public_bio_editor_state" in fieldValues) {
      temp.public_bio_editor_state =
        wordCount !== 0 ? "" : "Biography is required!";
    }
    if ("email" in fieldValues) {
      temp.email = !fieldValues.email ? "Email is required!" : "";
    }
    if ("web_address" in fieldValues) {
      if (!fieldValues.web_address) {
        temp.web_address = "";
      } else if (!fieldValues.web_address.match(regex)) {
        temp.web_address = "Please enter a valid web address!";
      } else {
        temp.web_address = "";
      }
    }
    if ("abn" in fieldValues) {
      temp.abn =
        fieldValues.abn !== "" && !checkABN()
          ? "Please enter a valid ABN number!"
          : fieldValues.abn
          ? ""
          : "ABN is required!";
    }
    if ("profile_photo" in fieldValues) {
      temp.profile_photo = fieldValues.profile_photo
        ? ""
        : "Profile photo is required !";
    }
    if ("gst" in fieldValues) {
      temp.gst = fieldValues.gst ? "" : "GST is required!";
    }
    if ("experience" in fieldValues) {
      temp.experience = fieldValues.experience
        ? ""
        : "Please select your Experience";
    }
    if ("postal_address" in fieldValues) {
      temp.postal_address = fieldValues.postal_address?.trim()
        ? ""
        : "Postal address is required!";
    }
    if ("phone" in fieldValues) {
      if (!fieldValues.phone) {
        temp.phone = "Phone number is required!";
      } else {
        temp.phone =
          fieldValues.phone.length === 10 ? "" : "Phone number is not valid!";
      }
    }

    if (
      fieldValues?.user_worker_roles?.includes("PHOTOGRAPHER") ||
      fieldValues?.user_worker_roles?.includes("PHOTO_EDITOR")
    ) {
      if ("images" in fieldValues) {
        temp.images =
          fieldValues.images?.length < 10
            ? "As a Photographer, you need at least 10 images to showcase your work"
            : "";

        setValues({ ...values, images_error: "" });
      }
    } else {
      temp.images = "";
    }
    if (
      fieldValues?.user_worker_roles?.includes("VIDEOGRAPHER") ||
      fieldValues?.user_worker_roles?.includes("VIDEO_EDITOR")
    ) {
      if ("videos" in fieldValues) {
        temp.videos =
          fieldValues?.videos?.filter((i) => i.trim() !== "")?.length < 5
            ? "As a Videographer you need to list at least 5 video links (YouTube, Vimeo etc)"
            : "";
      }
    } else {
      temp.videos = "";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const emailValidate = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
    var mailReg = new RegExp(mailformat);
    if (!values.email.match(mailReg)) {
      setErrors({ ...errors, email: "Please enter a valid email!" });
    } else {
      setErrors({ ...errors, email: "" });
      return true;
    }
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate,
    profile,
    profile1
  );

  useEffect(() => {
    if (values.file) {
      const preview = URL.createObjectURL(values.file);
      setValues({ ...values, filePreview: preview });
    }
  }, [values.file]);

  const profileCloseHandler = () => {
    setValues({ ...values, file: "", filePreview: image, profile_photo: "" });
  };

  const handleImage = (e) => {
    setValues({
      ...values,
      profile_photo: e.target.files[0],
      file: e.target.files[0],
    });
    setErrors({
      ...errors,
      profile_photo: "",
    });
  };

  const extractContent = (s) => {
    const span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  var wordCount =
    extractContent(content).trim().length > 0
      ? extractContent(content).trim().split(/\s+/).length
      : 0;

  useEffect(() => {
    if (ref.current === true) {
      setErrors({ ...errors, public_bio_editor_state: "" });
      if (wordCount < 30 && wordCount > 0) {
        setContentErr(
          "Tell us a bit more about yourself. we need a minimum of 30 words. "
        );
      } else {
        setContentErr("");
      }
    }
    ref.current = true;
  }, [wordCount]);

  const videoLinkChangeHandler = (e, index) => {
    setErrors({ ...errors, videos: "" });
    var expression =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    var regex = new RegExp(expression);
    const { value } = e.target;
    const list = [...values.videos];
    const list_error = [...values.videos_error];
    list[index] = value;
    list_error[index] =
      value && !value.match(regex)
        ? "Please enter a valid video url"
        : undefined;
    setValues({
      ...values,
      videos: list,
      videos_error: list_error,
    });
  };

  const videoHandleRemoveClick = (index) => {
    setErrors({ ...errors, videos: "" });
    const list = [...values.videos];
    const list_error = [...values.videos_error];
    list.splice(index, 1);
    list_error.splice(index, 1);
    setValues({
      ...values,
      videos: list,
      videos_error: list_error,
    });
  };

  const videoHandleAddClick = () => {
    setErrors({ ...errors, videos: "" });
    setValues({
      ...values,
      videos: [...values.videos, ""],
    });
  };

  const resourcesLinkChangeHandler = (e, index) => {
    var expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    var regex = new RegExp(expression);
    const { value } = e.target;
    const list = [...values.resources];
    const list_error = [...values.resources_error];
    list[index] = value;
    list_error[index] =
      value && !value.match(regex)
        ? "Please enter a valid showcase url"
        : undefined;
    setValues({
      ...values,
      resources: list,
      resources_error: list_error,
    });
  };

  const resourcesHandleRemoveClick = (index) => {
    const list = [...values.resources];
    const list_error = [...values.videos_error];
    list.splice(index, 1);
    list_error.splice(index, 1);

    setValues({
      ...values,
      resources: list,
      resources_error: list_error,
    });
  };

  const resourcesHandleAddClick = () => {
    setValues({
      ...values,
      resources: [...values.resources, ""],
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setErrors({ ...errors, images: "" });

    setValues((prevState) => {
      let tempImagesErr = "";
      let images1 = [];
      const temp = [];
      for (const key in acceptedFiles) {
        if (key !== "length" && key !== "item") {
          if (!acceptedFiles[key].name.match(/.(jpg|jpeg|png|gif)$/i)) {
            tempImagesErr = "Please add valid image (png/gif/jpeg/jpg)";
            continue;
          }
          if (acceptedFiles[key].size > 50000000) {
            tempImagesErr = "Image size should not be greater than 50MB";
            continue;
          }
          images1.push(acceptedFiles[key]);
          temp.push(URL.createObjectURL(acceptedFiles[key]));
        }
      }

      return {
        ...prevState,
        images: [...prevState.images, ...images1],
        images_preview: [...prevState.images_preview, ...temp],
        images_error: tempImagesErr
          ? tempImagesErr
          : [...prevState.images_preview, ...temp].length < 10 &&
            values?.user_worker_roles?.includes(
              "PHOTOGRAPHER" || "PHOTO_EDITOR"
            )
          ? "A minimum of 10 images are required to showcase your work"
          : "",
      };
    });
  }, []);

  const saveProfileHandler = async () => {
    if (
      validate() &&
      emailValidate() &&
      values.videos_error.filter((i) => i !== undefined).length === 0 &&
      values.resources_error.filter((i) => i !== undefined).length === 0 &&
      !contentErr
    ) {
      setValues({ ...values, stateLoad: true });
      const formData = new FormData();
      let attachmentUrl;
      if (values.profile_photo && typeof values.profile_photo !== "string") {
        formData.append("file", values.profile_photo);
        attachmentUrl = await fileUpload(formData);
        formData.delete("file");
      } else {
        attachmentUrl = values.profile_photo;
      }

      let tempImage = [];

      for (let i = 0; i < values.images.length; i++) {
        if (typeof values.images[i] !== "string") {
          formData.append("file", values.images[i]);
          let temp = await fileUpload(formData);
          if (temp) {
            tempImage.push(temp);
          }
          formData.delete("file");
        } else {
          tempImage.push(values.images[i]);
        }
      }
      const preLoader =
        profile1?.status === "NEW" || profile1?.status === "VERIFICATION_FAILED"
          ? true
          : false;
      const data = {
        user_worker_roles: values.user_worker_roles,
        address: values.address,
        business_name: values.business_name,
        public_bio: { text: content },
        email: values.email,
        web_address: values.web_address,
        abn: values.abn,
        profile_photo: attachmentUrl,
        gst: values.gst,
        experience: values.experience,
        postal_address: values.postal_address,
        resources: values.resources.filter((i) => i.trim() !== ""),
        phone: values.phone,
        images: tempImage,
        videos: values.videos.filter((i) => i.trim() !== ""),
      };
      if (user?.role == "ROLE_ADMIN") {
        data.admin_with_user_id = profile ? profile.id : profile1.id;
      }
      setValues({ ...values, stateLoad: false });

      dispatch(
        editProfile({
          data,
          preLoader,
          // navigate,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              if (user?.role == "ROLE_ADMIN") {
                // user?.role == "ROLE_ADMIN"
                //   ?

                location.pathname.includes("/adminmanageusers")
                  ? navigate(`/adminmanageusersapplicant/${profile.id}`)
                  : location.pathname.includes("/adminmanagejobs")
                  ? navigate(`/adminmanagejobsapplicant/${profile.id}`, {
                      state: { jobId: location?.state?.jobId },
                    })
                  : navigate(routes.profile);
                // : navigate(`/managejobsapplicant/${profile.id}`);
              } else {
                if (
                  profile1?.status === "NEW" ||
                  profile1?.status === "VERIFICATION_FAILED"
                ) {
                  dispatch(profileForApproval({ id, navigate }));
                } else {
                  navigate(routes.profile);
                }
              }
            }
          },
        })
      );
    } else {
      notify("Some information is missing, please check your profile", "error");
    }
  };

  if (loading || values.stateLoad || profileloader) {
    return <Loader />;
  }

  return (
    <>
      <main className="zu_all_page_main_content">
        <div className="zu_profile_edit_page">
          <div className="zu_profile_heading_row">
            <CommonHeading heading="Profile / Profile Edit" />
          </div>
          <ProfileUserDetaile
            profile={profile ? profile : profile1}
            errorsPro={errors}
            hasProfileEdit
            handleImage={handleImage}
            profileCloseHandler={profileCloseHandler}
            file={values.file}
            filePreview={values.filePreview}
            profile_photo={values.profile_photo}
          />
          <section className="zu_specialty_label_section">
            <div className="zu_specialty_label_content">
              <div className="zu_profile_common_heading">
                <h2>What is your specialty? 'Tick all that apply'</h2>
              </div>
              <div className="zu_specialty_label_row">
                {specialtyLabelList.map((i) => {
                  return (
                    <div
                      key={i.id}
                      className="zu_specialty_label_column zu_select_label_box"
                    >
                      <input
                        id={`zu_specialty_label${i.id}`}
                        type="checkbox"
                        className="d-none"
                        name="user_worker_roles"
                        value={i.labelText}
                        checked={values.user_worker_roles?.includes(
                          i.labelText
                        )}
                        onChange={(e) => handleInputChange(e, null, true)}
                      />
                      <label htmlFor={`zu_specialty_label${i.id}`}>
                        {i.label}
                      </label>
                    </div>
                  );
                })}
              </div>
              {errors?.user_worker_roles ? (
                <span className="err_text">{errors?.user_worker_roles}</span>
              ) : null}
            </div>
          </section>
          <section className="zu_profile_edit_input_section">
            <div className="zu_profile_edit_input_content">
              <div className="zu_profile_edit_input_row">
                <AutoComplete
                  id="address"
                  name="address"
                  className="zu_common_form_input_content"
                  placeholder="Town/City"
                  values={values}
                  handleInputChange={handleInputChange}
                  label="List your home town/city"
                  setValues={setValues}
                  error={errors?.address || ""}
                  errors={errors}
                />
                <Input
                  className="zu_common_form_input_content"
                  labelClassName="zu_common_form_input_label"
                  inputClassName="zu_common_form_input"
                  errorClassName="err_text"
                  label="Business Name"
                  placeholder="Business Name"
                  type="text"
                  id="business_name"
                  name="business_name"
                  value={values.business_name}
                  onChange={handleInputChange}
                  error={errors?.business_name || ""}
                />
                <div className="zu_common_form_input_content zu_common_form_textarea_content">
                  <label
                    className="zu_common_form_input_label"
                    htmlFor="biographyInput"
                  >
                    Biography
                  </label>
                  <Editor
                    editorState={values.public_bio_editor_state}
                    wrapperClassName="card"
                    editorClassName="card-body"
                    toolbarClassName="toolbarClassName"
                    onEditorStateChange={(newState) => {
                      setValues({
                        ...values,
                        public_bio_editor_state: newState,
                      });
                      setContent(
                        draftToHtml(convertToRaw(newState.getCurrentContent()))
                      );
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "textAlign",
                        "emoji",
                      ],
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                    }}
                  />
                  {contentErr && <span className="err_text">{contentErr}</span>}
                  {errors?.public_bio_editor_state && (
                    <span className="err_text">
                      {errors?.public_bio_editor_state}
                    </span>
                  )}
                </div>
                <Input
                  className="zu_common_form_input_content "
                  labelClassName="zu_common_form_input_label"
                  inputClassName={`zu_common_form_input ${
                    user?.role !== "ROLE_ADMIN" && "zu_email_text"
                  }`}
                  errorClassName="err_text"
                  label="Email Address"
                  placeholder="Email Address"
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors?.email || ""}
                  disabled={user?.role !== "ROLE_ADMIN"}
                />
                <Input
                  className="zu_common_form_input_content"
                  labelClassName="zu_common_form_input_label"
                  inputClassName="zu_common_form_input"
                  errorClassName="err_text"
                  label="Website Address"
                  placeholder="Website Address"
                  type="text"
                  id="web_address"
                  name="web_address"
                  value={values.web_address}
                  onChange={handleInputChange}
                  error={errors?.web_address || ""}
                />
                <Input
                  className="zu_common_form_input_content"
                  labelClassName="zu_common_form_input_label"
                  inputClassName="zu_common_form_input"
                  errorClassName="err_text"
                  label="ABN Number"
                  placeholder="ABN Number"
                  type="tel"
                  maxLength={11}
                  id="abn"
                  name="abn"
                  value={values.abn}
                  onChange={handleInputChange}
                  error={errors?.abn || ""}
                />
                <div className="zu_common_form_input_content">
                  <label className="zu_common_form_input_label">
                    Are you registered for GST?
                  </label>
                  <div className="zu_common_form_input_check_box_row">
                    <div className="zu_common_form_check_box">
                      <input
                        type="radio"
                        id="yes"
                        className="d-none"
                        name="gst"
                        onChange={handleInputChange}
                        value="1"
                        checked={values.gst === "1"}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="zu_common_form_check_box">
                      <input
                        type="radio"
                        id="no"
                        className="d-none"
                        name="gst"
                        onChange={handleInputChange}
                        value="0"
                        checked={values.gst === "0"}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                </div>
                <AutoComplete
                  id="postal_address"
                  name="postal_address"
                  className="zu_common_form_input_content"
                  values={values}
                  handleInputChange={handleInputChange}
                  label="What is your postal address?"
                  placeholder="Only visible on official invoices"
                  setValues={setValues}
                  error={errors?.address || ""}
                  errors={errors}
                />

                <div className="zu_common_form_input_content">
                  <label
                    className="zu_common_form_input_label"
                    htmlFor="experience"
                  >
                    Years of experience
                  </label>
                  <Form.Select
                    onChange={handleInputChange}
                    id="experience"
                    className="zu_common_form_input"
                    name="experience"
                  >
                    <option disabled selected>
                      Years of experience
                    </option>

                    <option
                      selected={values?.experience === "BEGINNER"}
                      value="BEGINNER"
                    >
                      0 - 1 Year
                    </option>
                    <option
                      selected={values?.experience === "INTERMEDIATE"}
                      value="INTERMEDIATE"
                    >
                      1 - 2 Years
                    </option>
                    <option
                      selected={values?.experience === "SOLID"}
                      value="SOLID"
                    >
                      2 - 5 Years
                    </option>
                    <option
                      selected={values?.experience === "ADVANCE"}
                      value="ADVANCE"
                    >
                      5 - 10 Years
                    </option>
                    <option
                      selected={values?.experience === "MASTER"}
                      value="MASTER"
                    >
                      10+ Years
                    </option>
                  </Form.Select>
                  {errors?.experience ? (
                    <span className="err_text">{errors?.experience}</span>
                  ) : null}
                </div>

                <div className="zu_common_form_input_content">
                  <label
                    htmlFor="showcaseUrlInput"
                    className="zu_common_form_input_label undefined undefined"
                  >
                    Video Links
                  </label>
                  {values.videos &&
                    values.videos?.map((x, i) => {
                      return (
                        <div key={i} className="project_edit_detail_divider">
                          <Input
                            className="position-relative"
                            inputClassName="zu_common_form_input undefined"
                            type="text"
                            placeholder="Add Video URL (YouTube / Vimeo etc)"
                            id="videos"
                            name="videos"
                            value={x}
                            onChange={(e) => videoLinkChangeHandler(e, i)}
                          />
                          {values.videos_error[i] && (
                            <span className="err_text">
                              {values.videos_error[i]}
                            </span>
                          )}
                          <div className="zu_edit_profile_add_remove_btn_row">
                            {values.videos?.length - 1 === i && (
                              <ButtonCom
                                buttonClassName="zu_edit_profile_add_remove_btn zu_edit_profile_add_btn"
                                onClick={() => videoHandleAddClick(i)}
                                text="Add Link"
                              />
                            )}
                            {values.videos?.length !== 1 && (
                              <ButtonCom
                                className="zu_edit_profile_input_remove_btn"
                                buttonClassName="zu_edit_profile_add_remove_btn zu_edit_profile_add_btn"
                                onClick={() => videoHandleRemoveClick(i)}
                                text="Remove"
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {errors?.videos && (
                    <span className="err_text">{errors?.videos}</span>
                  )}
                </div>
                <div className="zu_common_form_input_content">
                  <label
                    htmlFor="showcaseUrlInput"
                    className="zu_common_form_input_label undefined undefined"
                  >
                    Showcase Links
                  </label>
                  {values.resources &&
                    values.resources.map((x, i) => {
                      return (
                        <div key={i} className="project_edit_detail_divider">
                          <Input
                            className="position-relative"
                            inputClassName="zu_common_form_input undefined"
                            type="text"
                            placeholder="Enter other website links (Gallery / Portfolio etc)"
                            id="resources"
                            name="resources"
                            value={x}
                            onChange={(e) => resourcesLinkChangeHandler(e, i)}
                          />
                          {values.resources_error[i] && (
                            <span className="err_text">
                              {values.resources_error[i]}
                            </span>
                          )}
                          <div className="zu_edit_profile_add_remove_btn_row">
                            {values.resources?.length - 1 === i && (
                              <ButtonCom
                                buttonClassName="zu_edit_profile_add_remove_btn zu_edit_profile_add_btn"
                                onClick={() => resourcesHandleAddClick(i)}
                                text="Add Link"
                              />
                            )}
                            {values.resources?.length !== 1 && (
                              <ButtonCom
                                className="zu_edit_profile_input_remove_btn"
                                buttonClassName="zu_edit_profile_add_remove_btn zu_edit_profile_add_btn"
                                onClick={() => resourcesHandleRemoveClick(i)}
                                text="Remove"
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <Input
                  className="zu_common_form_input_content"
                  labelClassName="zu_common_form_input_label"
                  inputClassName="zu_common_form_input"
                  errorClassName="err_text"
                  label="Phone Number (Mobile)"
                  placeholder="Mobile Phone Number"
                  type="tel"
                  maxLength={10}
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleInputChange}
                  error={errors?.phone || ""}
                />
              </div>
            </div>
          </section>
          <section className="zu_upload_image_section">
            <div className="zu_upload_image_content">
              <h2 className="zu_upload_image_heading">
                Upload Showcase Images
              </h2>
              <div className="zu_upload_image_dropbox">
                <DropBox onDrop={onDrop} />
                <ShowImage images={values.images} setValues={setValues} />
              </div>
              {values?.images_error || errors?.images ? (
                <span className="err_text">
                  {values?.images_error}
                  {errors?.images}
                </span>
              ) : null}
            </div>
          </section>
          <div className="zu_profile_edit_bottom_btns">
            <div className="zu_profile_edit_bottom_btn_row">
              <button
                type="button"
                className="zu_profile_edit_bottom_back_btn"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
              {user?.role == "ROLE_ADMIN" && location?.state?.applicantId ? (
                <div className="zu_profile_edit_bottom_btn_right_content">
                  <button
                    type="button"
                    className="zu_common_btn zu_btn_theme_color"
                    onClick={() => saveProfileHandler()}
                  >
                    SAVE PROFILE
                  </button>
                </div>
              ) : (
                <div className="zu_profile_edit_bottom_btn_right_content">
                  {profile1?.status !== "NEW" &&
                    profile1?.status !== "VERIFICATION_FAILED" && (
                      <button
                        type="button"
                        className="zu_common_btn zu_btn_theme_color"
                        onClick={() => saveProfileHandler()}
                      >
                        SAVE PROFILE
                      </button>
                    )}
                  {profile1?.status !== "PENDING" &&
                    profile1?.status !== "VERIFICATION_SUCCESS" && (
                      <button
                        type="button"
                        className="zu_common_btn zu_btn_light_green"
                        // onClick={() => submitProfileHandler()}
                        onClick={() => saveProfileHandler()}
                      >
                        SUBMIT FOR APPROVAL
                      </button>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ProfileEditPage;
