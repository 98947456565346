import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ImageTextContent from "../../component/Layout/ImageTextContent";
import MyJobsColumn from "../../component/Layout/MyJobsColumn";
import dashboardGraphicImage from "../../images/DashboardGraphicImage.jpg";
import { findJobSearchIcon, manageJobuserIcon } from "../../icons";
import {
  checkJobPendingReview,
  checkPendingReviewForBuesiness,
  checkUserJobWait,
  getCountForDashboard,
} from "../../store/slice/userSlice";
import OfferDeclineModal from "../../component/Layout/OfferDeclineModal";
import OfferAcceptModal from "../../component/Layout/OfferAcceptModal";
import JobOfferModal from "../../component/Layout/JobOfferModal";
import { useForm } from "../../hooks/useForm";
import { Modal } from "react-bootstrap";
import {
  acceptDeclineOffer,
  getjobListbyUser,
  getJobsConfirmedBusiness,
  jobReview,
} from "../../store/slice/postSlice";
import Loader from "../../component/Common/Loader";
import CrewReviewModal from "../../component/Layout/CrewReviewModal";
import FindJobSideMenu from "../../component/Layout/FindJobSideMenu";
import CommonHeading from "../../component/Common/CommonHeading";
import AskQuestionModal from "../../component/Layout/AskQuestionModal";
import {
  ratingTextValue,
  ratingTextValueForBusiness,
  routes,
} from "../../constants";
import JobsColumn from "../../component/Layout/JobsColumn";
import BusinessReviewModal from "../../component/Layout/BusinessReviewModal";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const jobModalDetail = useSelector((state) => state.user.checkUserJobWait);
  const dashboardCount = useSelector(
    (state) => state.user.getCountForDashboard
  );
  const jobLoading = useSelector((state) => state.post.jobLoader);
  const reviewModalDetails = useSelector(
    (state) => state.user.checkJobPendingReview
  );
  const reviewMDetailsForBusinessPerson = useSelector(
    (state) => state.user.checkPendingReviewForBuesiness
  );

  const myJobs = useSelector((state) => state.post.getjobListbyUser);
  const businessJobs = useSelector(
    (state) => state.post.getJobsConfirmedBusiness
  );

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  let jobAmount =
    dashboardCount?.total_job_amount &&
    numberWithCommas((+dashboardCount?.total_job_amount)?.toFixed(2));
  let users =
    dashboardCount?.total_user && numberWithCommas(+dashboardCount?.total_user);

  const loading = useSelector((state) => state.post.loader);
  const loginLoader = useSelector((state) => state.user.loginAsLoader);

  const initialFValues = {
    show: false,
    findJobModal: null,
    acceptDeclineModal: false,
    acceptModal: false,
    declineModal: false,
    askQuestionModal: false,
    policyErr: "",
    reviewModal: false,
    job_status: "OFFER_ACCEPTED",
    question: "",
    review: "",
    rating: 3,
    jobLoadingState: "",
    businessReviewModal: false,
    BusinessRating: 3,
    BuisnessReview: "",
  };

  useEffect(() => {
    dispatch(
      getjobListbyUser({
        confirm: "my_jobs",
        job_status: values.job_status,
      })
    );
    dispatch(getCountForDashboard());
    dispatch(
      getJobsConfirmedBusiness({
        job_status: "OFFER_ACCEPTED",
      })
    );

    dispatch(checkUserJobWait());
    dispatch(checkJobPendingReview());
    dispatch(checkPendingReviewForBuesiness());
  }, [dispatch]);

  useEffect(() => {
    setValues({
      ...values,
      acceptDeclineModal: jobModalDetail ? true : false,
      reviewModal: reviewModalDetails ? true : false,
      businessReviewModal: reviewMDetailsForBusinessPerson ? true : false,
    });
  }, [jobModalDetail, reviewModalDetails, reviewMDetailsForBusinessPerson]);

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const hideOfferAcceptModal = () => {
    setValues({
      ...values,
      acceptModal: false,
    });
  };

  const hideOfferDeclineModal = () => {
    setValues({
      ...values,
      declineModal: false,
    });
  };

  const hideJobOfferModal = () => {
    setValues({
      ...values,
      acceptDeclineModal: false,
    });
  };

  const hideCrewReviewModal = () => {
    setValues({
      ...values,
      reviewModal: false,
      rating: 3,
      review: "",
    });
  };

  const DeclineModalHandler = (jobModalDetail) => {
    setValues({
      ...values,
      acceptDeclineModal: false,
      declineModal: true,
      jobModalDetail: jobModalDetail,
    });
  };

  const GoBackHandler = (jobModalDetail) => {
    setValues({
      ...values,
      declineModal: false,
      acceptDeclineModal: true,
      jobModalDetail: jobModalDetail,
    });
  };

  const acceptModalHandler = (jobModalDetail, policy) => {
    if (policy) {
      const data = { job_id: jobModalDetail?.job_id, accepted: true };
      dispatch(
        acceptDeclineOffer({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              navigate(`/myjobs/page/1/${jobModalDetail?.job_id}`);
              setValues({
                ...values,
                acceptDeclineModal: false,
                acceptModal: true,
              });
            }
          },
        })
      );
    } else {
      setValues({
        ...values,
        policyErr: "Please accept the Privacy Policy and Terms of Service!",
      });
    }
  };

  const DeclineOfferHandler = (jobModalDetail) => {
    const data = { job_id: jobModalDetail?.job_id, accepted: false };
    dispatch(
      acceptDeclineOffer({
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setValues({
              ...values,
              declineModal: false,
            });
          }
        },
      })
    );
  };

  const ratingChanged = (rating) => {
    setValues({ ...values, rating });
  };
  const BusinessRatingChanged = (BusinessRating) => {
    setValues({ ...values, BusinessRating });
  };

  const submitReviewHandler = () => {
    const data = {
      job: reviewModalDetails?.job_id,
      feedback_type: "AS_CREW",
      receiver: reviewModalDetails?.owner_id,
      star: ratingTextValue(values),
      review: values.review,
    };

    dispatch(
      jobReview({
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err.response", err.response.data.message);
          } else {
            setValues({
              ...values,
              reviewModal: false,
            });
          }
        },
      })
    );
  };

  const hideModal = () => {
    setValues({ ...values, show: false, findJobModal: null });
  };

  const showModal = (id, status, offerId) => {
    setValues({
      ...values,
      show: true,
      jobStatusApplicant: status,
      findJobModal: id,
      offer_id: offerId,
      jobLoadingState: id,
    });
  };

  const showQuestionModal = (job_id, owner_id) => {
    setValues({
      ...values,
      askQuestionModal: true,
      show: false,
      ownerId: owner_id,
      findJobModal: job_id,
    });
  };

  const hideQuestionModal = () => {
    setValues({ ...values, askQuestionModal: false, question: "" });
  };

  const hideBusinessReviewModal = () => {
    setValues({
      ...values,
      businessReviewModal: false,
      BusinessRating: 3,
      BuisnessReview: "",
    });
  };

  const submitBusinessReviewHandler = () => {
    const data = {
      job: reviewMDetailsForBusinessPerson?.job_id,
      feedback_type: "AS_BUSINESS",
      receiver: reviewMDetailsForBusinessPerson?.shooter_id,
      star: ratingTextValueForBusiness(values),
      review: values.BuisnessReview,
    };

    dispatch(
      jobReview({
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err.response", err.response.data.message);
          } else {
            setValues({
              ...values,
              businessReviewModal: false,
            });
          }
        },
      })
    );
  };

  return (
    <>
      {(loading || loginLoader) && <Loader />}
      <main className="zu_all_page_main_content">
        <div className="zu_dashboard_page">
          <section className="zu_dashboard_job_section">
            <div className="zu_dashboard_job_content">
              <ImageTextContent
                imageSrc={dashboardGraphicImage}
                heading="Find Crew, Be Crew"
              />
              <div className="zu_dashboard_job_content_row">
                <Link to={`${routes.findJobs}/page/1`}>
                  <div className="zu_dashboard_job_content_column">
                    <div className="zu_dashboard_job_content_column_icon">
                      {findJobSearchIcon}
                    </div>
                    <div className="zu_dashboard_job_content_column_text">
                      <h2>FIND JOBS</h2>
                      <p>Search and apply for jobs</p>
                    </div>
                    <div className="zu_dashboard_job_content_column_right_icon">
                      {findJobSearchIcon}
                    </div>
                  </div>
                </Link>
                <Link to={`${routes.managejobs}/page/1`}>
                  <div className="zu_dashboard_job_content_column zu_dashboard_manage_job_content_column">
                    <div className="zu_dashboard_job_content_column_icon">
                      {manageJobuserIcon}
                    </div>
                    <div className="zu_dashboard_job_content_column_text">
                      <h2>HIRE CREW</h2>
                      <p>Find and book crew</p>
                    </div>
                    <div className="zu_dashboard_job_content_column_right_icon">
                      {manageJobuserIcon}
                    </div>
                  </div>
                </Link>
                <div className="zu_dashboard_crew_payment_column">
                  <h2>Crew Payments</h2>
                  <span>Amount of jobs posted since 2019</span>
                  <p>${jobAmount}</p>
                </div>
                <div className="zu_dashboard_crew_payment_column">
                  <h2>Find Crew</h2>
                  <span>The number of crew in our network</span>
                  <p>{users} Members</p>
                </div>
              </div>
            </div>
          </section>
          <section className="zu_upcomming_job_section">
            {(businessJobs?.length !== 0 || myJobs?.length !== 0) && (
              <CommonHeading heading="Confirmed Upcoming Jobs" />
            )}
            {myJobs?.length !== 0 && (
              <>
                <CommonHeading
                  heading="- My Confirmed Jobs"
                  className="dashboard_heading"
                />

                <div className="zu_upcomming_job_column_content">
                  {myJobs?.map((job, i) => {
                    return (
                      <MyJobsColumn
                        key={i}
                        job={job}
                        hasFindJob
                        showModal={showModal}
                        jobLoadingState={values.jobLoadingState}
                        jobLoading={jobLoading}
                      />
                    );
                  })}
                </div>
              </>
            )}
            {businessJobs?.length !== 0 && (
              <>
                <CommonHeading
                  heading="- Hired Crew Jobs"
                  className="dashboard_heading"
                />
                <div className="zu_upcomming_job_column_content">
                  {businessJobs?.map((job, i) => {
                    return (
                      <JobsColumn
                        key={`zu_manage_job${i}`}
                        job={job}
                        hasUpcommingJob
                        hasBookMark
                      />
                    );
                  })}
                </div>
              </>
            )}
          </section>
        </div>
      </main>
      <Modal
        show={values.show}
        onHide={hideModal}
        centered
        className="zu_sidemenu_modal"
      >
        <FindJobSideMenu
          showQuestionModal={showQuestionModal}
          hideModal={hideModal}
          findJobModal={values.findJobModal}
          jobStatusApplicantModal={values.jobStatusApplicant}
          offerId={values.offer_id}
          jobLoading={jobLoading}
        />
      </Modal>
      <Modal
        show={values.askQuestionModal}
        onHide={() => hideQuestionModal()}
        centered
        className="zu_common_text_modal"
      >
        <AskQuestionModal
          values={values}
          handleInputChange={handleInputChange}
          errors={errors}
          hideQuestionModal={hideQuestionModal}
          setValues={setValues}
          setErrors={setErrors}
        />
      </Modal>
      <Modal
        show={values.acceptDeclineModal}
        onHide={hideJobOfferModal}
        centered
        className="zu_common_text_modal"
      >
        <JobOfferModal
          viewJobLink
          hideJobOfferModal={hideJobOfferModal}
          jobModalDetail={jobModalDetail}
          acceptModalHandler={acceptModalHandler}
          DeclineModalHandler={DeclineModalHandler}
          policyErr={values.policyErr}
          setValues={setValues}
          values={values}
        />
      </Modal>
      <Modal
        show={values.acceptModal}
        onHide={hideOfferAcceptModal}
        centered
        className="zu_common_text_modal"
      >
        <OfferAcceptModal hideOfferAcceptModal={hideOfferAcceptModal} />
      </Modal>
      <Modal
        show={values.declineModal}
        onHide={hideOfferDeclineModal}
        centered
        className="zu_common_text_modal"
      >
        <OfferDeclineModal
          GoBackHandler={GoBackHandler}
          jobModalDetail={jobModalDetail}
          DeclineOfferHandler={DeclineOfferHandler}
        />
      </Modal>
      <Modal
        show={values.reviewModal}
        onHide={hideCrewReviewModal}
        centered
        className="zu_common_text_modal"
      >
        <CrewReviewModal
          hideCrewReviewModal={hideCrewReviewModal}
          reviewModalDetails={reviewModalDetails}
          values={values}
          ratingChanged={ratingChanged}
          handleInputChange={handleInputChange}
          submitReviewHandler={submitReviewHandler}
        />
      </Modal>

      <Modal
        show={values.businessReviewModal}
        onHide={hideBusinessReviewModal}
        centered
        className="zu_common_text_modal"
      >
        <BusinessReviewModal
          hideBusinessReviewModal={hideBusinessReviewModal}
          reviewMDetailsForBusinessPerson={reviewMDetailsForBusinessPerson}
          values={values}
          BusinessRatingChanged={BusinessRatingChanged}
          handleInputChange={handleInputChange}
          submitBusinessReviewHandler={submitBusinessReviewHandler}
        />
      </Modal>
    </>
  );
};

export default DashboardPage;
