import React from "react";
import { closeIcon } from "../../icons";
import moment from "moment";
import CardList from "./CardList";

const SendOfferModal = ({
  jobOfferCloseHandler,
  applicantDetail,
  jobD,
  jobOfferSendHandler,
  values,
  setValues,
}) => {
  const totalPayment = jobD?.number_of_hour
    ? jobD?.number_of_hour * jobD?.price_per_hour
    : jobD?.jobPerHour * jobD?.jobHour;

  const shootzuTotal = totalPayment * 0.05;

  const today14Date = new Date(Date.now() + 14 * 24 * 60 * 60 * 1000);
  const modalDate = new Date(jobD?.date) < today14Date;

  const handleCardRadio = (id) => {
    setValues({
      ...values,
      creditCardIdErr: "",
      cardDataErr: "",
      cardNumberErr: "",
      expiryErr: "",
      cvcErr: "",
      creditCardId: id,
    });
  };

  const policyHandler = () => {
    setValues({
      ...values,
      policyErr: "",
      cardDataErr: values.policy && "",
      cardNumberErr: values.policy && "",
      expiryErr: values.policy && "",
      cvcErr: values.policy && "",
      policy: !values.policy,
    });
  };

  return (
    <div className="zu_common_modal zu_common_center_modal">
      <div className="zu_common_modal_content">
        <button
          type="button"
          className="zu_modal_close_btn"
          onClick={() => jobOfferCloseHandler()}
        >
          {closeIcon}
        </button>
        <div className="zu_common_modal_heading_paragraph">
          <h2 className="zu_common_modal_heading">
            SEND OFFER TO{" "}
            {applicantDetail?.first_name?.toUpperCase() +
              " " +
              applicantDetail?.last_name?.toUpperCase()}
          </h2>
          <p className="zu_common_modal_paragraph zu_send_offer_paragraph">
            By funding the job with Shootzu, you will be protected by{" "}
            <a
              href={`https://www.shootzu.com/shootzu-protect/`}
              target="_blank"
              rel="noreferrer"
            >
              Shootzu Protect
            </a>
          </p>
        </div>
        <div className="zu_common_modal_send_offer_row">
          <div className="zu_common_modal_send_offer_column zu_common_modal_how_work_column">
            <div className="zu_common_modal_how_work_content">
              <h2 className="zu_common_modal_send_offer_heading">
                How it works:
              </h2>
              <ul className="zu_common_modal_send_offer_step_list">
                <li className="zu_common_modal_send_offer_step_item">
                  <p className="zu_common_modal_send_offer_step_text">
                    <span>Step 1. </span>
                    50% of your job total will be processed now as a Booking
                    Retainer.
                  </p>
                </li>
                <li className="zu_common_modal_send_offer_step_item">
                  <p className="zu_common_modal_send_offer_step_text">
                    <span>Step 2. </span>
                    The Remaining Balance is payable 14 Days prior to the Job
                    Date.
                  </p>
                </li>
                <li className="zu_common_modal_send_offer_step_item">
                  <p className="zu_common_modal_send_offer_step_text">
                    <span>Step 3. </span>
                    When the job has been completed, we'll release payment to
                    your crew.
                  </p>
                </li>
              </ul>
            </div>

            <CardList
              cardData={values.cardData}
              creditCardId={values.creditCardId}
              handleCardRadio={handleCardRadio}
              creditCardIdErr={values.creditCardIdErr}
              cardBlinkClass={values.cardBlinkClass}
              cardBlinkClassTwo={values.cardBlinkClassTwo}
              cardNumber={values.cardNumber}
              expiry={values.expiry}
              cvc={values.cvc}
              cardNumberErr={values.cardNumberErr}
              expiryErr={values.expiryErr}
              cvcErr={values.cvcErr}
              cardDataErr={values.cardDataErr}
              setValues={setValues}
              values={values}
            />

            <div className="zu_common_modal_checkbox">
              <input
                type="checkbox"
                id="zu_job_offer_checkbox"
                name="policy"
                value={values.policy}
                onChange={policyHandler}
              />
              <label htmlFor="zu_job_offer_checkbox">
                By sending this job offer, you are accepting the{" "}
                <a href="https://www.shootzu.com/terms/" target="_blank">
                  terms and conditions
                </a>
                , including crew contract.
              </label>
            </div>
            {values.policyErr && (
              <span className="err_text">{values.policyErr}</span>
            )}
          </div>
          <div className="zu_common_modal_send_offer_column zu_common_modal_the_offer_column">
            <h2 className="zu_common_modal_send_offer_heading">The Offer:</h2>
            <div className="zu_common_modal_the_offer_content">
              <div className="zu_common_modal_the_offer_text">
                <h3>job title</h3>
                <p>{jobD.title}</p>
              </div>
              <div className="zu_common_modal_the_offer_text">
                <h3>job date</h3>
                <p>{moment(jobD?.date).utc().format("DD MMM YYYY")}</p>
              </div>
              <div className="zu_common_modal_the_offer_text">
                <h3>total crew payment</h3>
                <p>${totalPayment.toFixed(2)}</p>
              </div>
              <div className="zu_common_modal_the_offer_text">
                <h3>shootzu protect</h3>
                <p>${shootzuTotal.toFixed(2)}</p>
              </div>
              <div className="zu_common_modal_the_offer_text">
                <h3>charged today</h3>
                <p>
                  $
                  {(modalDate
                    ? totalPayment + shootzuTotal
                    : totalPayment + shootzuTotal - totalPayment / 2
                  ).toFixed(2)}
                </p>
              </div>
              {!modalDate && (
                <div className="zu_common_modal_the_offer_text zu_common_modal_the_offer_disabled_text">
                  <h3>payable later</h3>
                  <p>${(totalPayment / 2).toFixed(2)} </p>
                </div>
              )}
              <p className="zu_common_modal_the_offer_note">
                <span>Note:</span> For jobs starting in 14 days or less, the
                full job amount will be charged.
              </p>
            </div>
          </div>
        </div>
        <div className="zu_modal_common_btn_row">
          <button
            type="button"
            className="zu_common_btn zu_btn_gray zu_send_offer_modal_cancel_btn"
            onClick={jobOfferCloseHandler}
          >
            Cancel
          </button>

          <button
            type="button"
            className="zu_common_btn zu_btn_light_green"
            onClick={jobOfferSendHandler}
          >
            Send Job Offer
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendOfferModal;
