import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import { Form } from "react-bootstrap";
import Select from "react-select";
import enGB from "date-fns/locale/en-GB";

import { iNeedLabelList, jobTypeList, routes } from "../../constants";

import CommonHeading from "../../component/Common/CommonHeading";
import { cardIcon } from "../../icons";
import Input from "../../component/Common/Input";

import { useForm } from "../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getjobListForBrief,
  postNewJob,
  updateJob,
} from "../../store/slice/postSlice";
import Loader from "../../component/Common/Loader";
import moment from "moment";

import { Modal } from "react-bootstrap";
import AutoComplete from "../../component/Common/AutoComplete";
import { checkDateIsMonday } from "../../helpers/commonFunction";

const ManageNewPostJobPage = ({ job }) => {
  const navigate = useNavigate();
  const ref = useRef();
  registerLocale("enGB", enGB);

  const dispatch = useDispatch();
  const [submitModal, setSubmitModal] = useState(false);
  const loading = useSelector((state) => state.post.loader);
  const user = useSelector((state) => state.user.user);

  const myPostedJobs = useSelector((state) => state.post.jobListForBrief);

  const [selectedBrief, setSelectedBrief] = useState("");

  const myPostedJobsOptions =
    myPostedJobs &&
    myPostedJobs.length > 0 &&
    myPostedJobs.map((job, i) => {
      return {
        id: job?.id,
        label: job?.title,
        value: job?.brief,
      };
    });

  useEffect(() => {
    dispatch(getjobListForBrief({}));
  }, []);

  const initialFValues = {
    owner_type: job?.owner_type ? job?.owner_type : "BUSINESS_NAME",
    title: job?.title ? job?.title : "",
    date: job?.date ? new Date(job?.date) : "",
    address: job ? job?.address : "",
    brief: job?.brief ? job?.brief : "",
    price_per_hour: job?.price_per_hour ? job?.price_per_hour : "",
    number_of_hour: job?.number_of_hour ? job?.number_of_hour : "",
    job_status: job?.job_status ? job?.job_status : "POSTED",
    last_action: job?.last_action ? new Date(job?.last_action) : "",
    job_type: job?.job_type ? job?.job_type : [],
    job_worker_roles: job?.job_worker_roles
      ? JSON.parse(job?.job_worker_roles)[0]
      : [],
    equipment: job?.equipment ? job?.equipment : [],
    attachment: job?.attachment ? job?.attachment : [],
    isProfessionErr: false,
    worker_roles: "",
  };
  // Validation
  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    if ("owner_type" in fieldValues) {
      temp.owner_type =
        fieldValues.owner_type?.length !== 0
          ? ""
          : "Please select a work type!";
    }
    if ("title" in fieldValues) {
      temp.title =
        fieldValues.title && fieldValues.title.trim()
          ? ""
          : "Job title is required";
    }

    if ("address" in fieldValues) {
      temp.address =
        fieldValues.address && fieldValues.address.trim()
          ? ""
          : "You must enter a location for this job";
    }
    if ("date" in fieldValues) {
      temp.date = fieldValues.date ? "" : "Please select a job date!";
    }

    if ("job_worker_roles" in fieldValues) {
      temp.worker_roles =
        fieldValues.job_worker_roles?.length !== 0
          ? ""
          : "What kind of crew do you need?";
    }

    if ("job_type" in fieldValues) {
      temp.job_type =
        fieldValues.job_type?.length !== 0 ? "" : "What type of job is this?";
    }
    if ("brief" in fieldValues) {
      temp.brief =
        fieldValues.brief && fieldValues.brief.trim()
          ? ""
          : "A detailed job brief is required";
    }
    if ("price_per_hour" in fieldValues) {
      temp.price_per_hour =
        fieldValues.price_per_hour && fieldValues.price_per_hour
          ? ""
          : "A per hour pay rate is required";
    }
    if ("number_of_hour" in fieldValues) {
      temp.number_of_hour =
        fieldValues.number_of_hour && fieldValues.number_of_hour
          ? ""
          : "The number of hours is required";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  var LastActionDateString = moment().format().split("T")[0];

  var dateString = moment(values.date).format().split("T")[0];

  const postNewJobHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        owner_type: values.owner_type,
        title: values.title,
        date: dateString,
        address: values.address,
        brief: values.brief,
        price_per_hour: (+values.price_per_hour).toFixed(2) * 1,
        number_of_hour: +values.number_of_hour,
        job_status: values.job_status,
        last_action: LastActionDateString,
        job_type: values.job_type,
        job_worker_roles: [values.job_worker_roles],
        equipment: values.equipment,
        attachment: values.attachment,
      };
      if (job?.id) {
        data.job_status = dispatch(
          updateJob({
            data,
            id: job?.id,
            cb: (err, res) => {
              if (err) {
                console.log("err", err);
              } else {
                user?.role == "ROLE_ADMIN"
                  ? navigate(`/adminmanagejobsdetail/${job?.id}`, {
                      state: { job_status: values.job_status },
                    })
                  : navigate(`${routes.managejobs}/page/1`);
              }
            },
          })
        );
      } else {
        dispatch(
          postNewJob({
            data,
            cb: (err, res) => {
              if (err) {
                console.log("err", err);
              } else {
                setSubmitModal(true);
              }
            },
          })
        );
      }
    }
  };

  const closeModalHandler = () => {
    setSubmitModal(false);
    navigate(`${routes.managejobs}/page/1`);
  };

  const handleSelectBrief = (event) => {
    setSelectedBrief(event);
    setValues({
      ...values,
      brief: event.value,
    });
    setErrors({
      ...errors,
      brief: "",
    });
  };

  const workerInputChange = (e) => {
    if (ref.current == true) {
      setValues({
        ...values,
        isProfessionErr: true,
        job_worker_roles: e.target.value,
      });
    } else {
      setValues({
        ...values,
        job_worker_roles: e.target.value,
      });
    }
    setErrors({ ...errors, worker_roles: "" });
    ref.current = true;
  };

  const MyContainer = ({ className, children }) => {
    return (
      <div
        className={`zu_common_custom_date_picker ${
          checkDateIsMonday(values.date) && `zu_color_disable_mon`
        }`}
      >
        <div className={className}>
          <h2 className="zu_common_custom_date_picker_heading">
            Select Job Date
          </h2>
          <div className="zu_common_custom_date_picker_content">{children}</div>
        </div>
      </div>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <main className="zu_all_page_main_content">
        <div className="zu_manage_new_job_post_page">
          <section className="zu_manage_new_job_post_section">
            {!job?.id ? (
              <CommonHeading heading="Post New Job" />
            ) : (
              <CommonHeading heading="Update Job" />
            )}

            <div className="zu_manage_new_job_post_content">
              <div className="zu_manage_new_job_post_row">
                <div className="zu_manage_new_job_post_column">
                  <div className="zu_manage_new_job_post_left_input">
                    <div className="zu_common_form_input_content">
                      <Input
                        labelClassName="zu_common_form_input_label"
                        inputClassName="zu_common_form_input"
                        errorClassName="err_text"
                        placeholder="Create a title for this job"
                        label="Job Title"
                        type="text"
                        id="jobTitleInput"
                        name="title"
                        onChange={handleInputChange}
                        value={values.title}
                      />
                      {errors?.title ? (
                        <span className="err_text">{errors?.title}</span>
                      ) : null}
                    </div>

                    <div className="zu_common_form_input_content">
                      <AutoComplete
                        label="Location of Job"
                        placeholder="Town / City"
                        id="address"
                        name="address"
                        handleInputChange={handleInputChange}
                        values={values}
                        setValues={setValues}
                      />

                      {errors?.address ? (
                        <span className="err_text">{errors?.address}</span>
                      ) : null}
                    </div>
                    <div className="zu_common_form_input_content">
                      <label
                        htmlFor="dateJobInput"
                        className="zu_common_form_input_label"
                      >
                        Date of Job
                      </label>
                      <DatePicker
                        id="dateJobInput"
                        className="zu_common_form_input"
                        placeholderText="MM/DD/YYYY"
                        dateFormat="dd/MM/yyyy"
                        name="date"
                        locale="enGB"
                        selected={values.date}
                        dateFormatCalendar={"MMM yyyy"}
                        onChange={(date) => {
                          setValues({ ...values, date: date });
                          setErrors({ ...errors, date: "" });
                        }}
                        formatWeekDay={(nameOfDay) =>
                          nameOfDay.toString().substr(0, 3).toUpperCase()
                        }
                        calendarContainer={MyContainer}
                        minDate={moment().add(0, "day").toDate()}
                      />
                      {errors?.date ? (
                        <span className="err_text">{errors?.date}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="zu_manage_new_job_post_left_label_content">
                    <h4 className="zu_manage_new_job_post_left_label_heading">
                      I Need A ...
                    </h4>
                    {values.isProfessionErr && (
                      <div
                        className="zu_warning_message alert alert-warning alert-dismissible fade show "
                        role="alert"
                      >
                        <strong>NOTE!</strong> Shootzu binds a contract between
                        you and a crew member for a single job. If you need to
                        hire more than one person for this job, please post
                        another job to hire the second crew member.
                      </div>
                    )}
                    <div className="zu_specialty_label_row">
                      {iNeedLabelList.map((i) => {
                        return (
                          <div
                            key={i.id}
                            className="zu_specialty_label_column zu_select_label_box"
                          >
                            <input
                              id={`zu_I_need_label${i.id}`}
                              type="radio"
                              className="d-none"
                              name="job_worker_roles"
                              value={i.labelText}
                              checked={values.job_worker_roles === i.labelText}
                              // onChange={(e) => handleInputChange(e, null, true)}
                              onChange={workerInputChange}
                            />
                            <label htmlFor={`zu_I_need_label${i.id}`}>
                              {i.label}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    {errors?.worker_roles ? (
                      <span className="err_text">{errors?.worker_roles}</span>
                    ) : null}
                  </div>
                  <div className="zu_manage_new_job_post_left_label_content">
                    <h4 className="zu_manage_new_job_post_left_label_heading">
                      Job Type
                    </h4>
                    <div className="zu_specialty_label_row">
                      {jobTypeList.map((i) => {
                        return (
                          <div
                            key={i.id}
                            className="zu_specialty_label_column zu_select_label_box"
                          >
                            <input
                              id={`zu_job_type_label${i.id}`}
                              type="radio"
                              className="d-none"
                              name="job_type"
                              value={i.labelText}
                              checked={values.job_type?.includes(i.labelText)}
                              onChange={handleInputChange}
                            />
                            <label htmlFor={`zu_job_type_label${i.id}`}>
                              {i.label}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    {errors?.job_type ? (
                      <span className="err_text">{errors?.job_type}</span>
                    ) : null}
                  </div>
                </div>
                <div className="zu_manage_new_job_post_column">
                  <div className="zu_manage_new_job_post_discription_content">
                    <div className="zu_manage_new_job_post_discription_heading">
                      <h2>Job Brief</h2>
                      {myPostedJobsOptions && myPostedJobsOptions.length > 0 && (
                        <Select
                          name="option"
                          value={selectedBrief ? selectedBrief : null}
                          onChange={(e, { action }) => {
                            if (action === "clear") {
                              setValues({
                                ...values,
                                brief: "",
                              });
                            }
                            handleSelectBrief(e);
                          }}
                          options={myPostedJobsOptions}
                          className="zu-custom-select-2"
                          classNamePrefix="select"
                          placeholder="DUPLICATE JOB BRIEF..."
                          isClearable
                        />
                      )}
                    </div>
                    <p>
                      Be specific about your job requirements and expectations.
                      The more details the better, as this will form the
                      foundation of your contract with the hired crew member.
                    </p>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      id="jobBrif"
                      className="zu_manage_new_job_post_discription_input"
                      onChange={handleInputChange}
                      name="brief"
                      value={values.brief}
                    />
                    {errors?.brief ? (
                      <span className="err_text">{errors?.brief}</span>
                    ) : null}
                  </div>
                  <div className="zu_manage_new_job_post_hour_price_row">
                    <div className="zu_manage_new_job_post_price_column">
                      <Input
                        className="zu_common_form_input_content"
                        labelClassName="zu_common_form_input_label"
                        inputClassName="zu_common_form_input"
                        errorClassName="err_text"
                        label="Pay Per Hour"
                        placeholder="Price"
                        type="text"
                        id="payPerHourInput"
                        name="price_per_hour"
                        onChange={handleInputChange}
                        value={values.price_per_hour}
                        disabled={
                          job &&
                          (job?.job_status === "WAITING_FOR_RESPONSE" ||
                            job?.job_status === "OFFER_ACCEPTED")
                        }
                      />
                      {errors?.price_per_hour ? (
                        <span className="err_text">
                          {errors?.price_per_hour}
                        </span>
                      ) : null}
                    </div>
                    <div className="zu_manage_new_job_post_hours_column">
                      <Input
                        className="zu_common_form_input_content"
                        labelClassName="zu_common_form_input_label"
                        inputClassName="zu_common_form_input"
                        errorClassName="err_text"
                        label="Hours Required"
                        placeholder="Number of Hours"
                        type="number"
                        id="hourInput"
                        name="number_of_hour"
                        onChange={handleInputChange}
                        value={values.number_of_hour}
                        disabled={
                          job &&
                          (job?.job_status === "WAITING_FOR_RESPONSE" ||
                            job?.job_status === "OFFER_ACCEPTED")
                        }
                      />
                      {errors?.number_of_hour ? (
                        <span className="err_text">
                          {errors?.number_of_hour}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="zu_manage_new_job_total_payment_text">
                    <span>TOTAL CREW PAYMENT</span>
                    <p>
                      ${" "}
                      {(
                        +values.number_of_hour * +values.price_per_hour
                      ).toFixed(2)}
                      {values?.address?.includes("USA")
                        ? " USD"
                        : values?.address?.includes("Australia")
                        ? " AUD"
                        : values?.address?.includes("New Zealand")
                        ? " NZD"
                        : null}
                    </p>
                    <div className="zu_manage_new_job_total_payment_card_icon">
                      {cardIcon}
                    </div>
                  </div>
                </div>
              </div>
              <div className="zu_manage_new_job_btn_content">
                <button
                  type="button"
                  className="zu_common_btn zu_btn_light_green"
                  onClick={postNewJobHandler}
                >
                  {!job?.id ? "Post Job" : "Update Job"}
                </button>
                <button
                  type="button"
                  className="zu_manage_new_job_back_btn"
                  onClick={() => navigate(-1)}
                >
                  Cancel - Go Back
                </button>
              </div>
            </div>
            {/* {submitModal && ( */}
            <Modal
              show={submitModal}
              onHide={closeModalHandler}
              centered
              className="zu_common_heading_paragraph_modal zu_job_submitted_modal"
            >
              <div className="zu_common_modal zu_common_center_modal">
                <div className="zu_common_modal_content">
                  <div className="zu_common_heading_paragraph_modal_text">
                    <h2 className="zu_common_modal_heading">JOB SUBMITTED</h2>
                    <p className="zu_common_modal_paragraph">
                      Your job post has been submitted for admin approval and
                      will be pushed out to crew in the area shortly.
                    </p>
                    <p className="zu_common_modal_paragraph">
                      You will be notified via email when available applicants
                      express their interest in this job.
                    </p>
                  </div>
                  <div className="zu_modal_common_btn_row">
                    <button
                      type="button"
                      className="zu_common_btn zu_btn_gray"
                      onClick={closeModalHandler}
                    >
                      CLOSE
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </section>
        </div>
      </main>
    </>
  );
};

export default ManageNewPostJobPage;
