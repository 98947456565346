import moment from "moment";
import React, { useState, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { modalCloseIcon, msgSearchIcon } from "../../icons";

const MsgUserList = ({
  chatUsers,
  newUser,
  onSelectUser,
  chatValues,
  pinnedMsgs,
  pinnedMsgId,
  loggedInUserName,
  loggedInId,
}) => {
  const searchInputRef = useRef("");
  const [searchValue, setSearchValue] = useState("");
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [searchedPinnedUsers, setSearchedPinnedUsers] = useState([]);
  const [AllMsgTab, setALLMsgTab] = useState(true);

  const searchInputChange = (e) => {
    let sValue = e.target.value;
    setSearchValue(sValue);
    let exp = new RegExp("^" + sValue + ".*$", "i");

    if (sValue.length > 0) {
      if (AllMsgTab) {
        let nUsers = [newUser].filter((user) => {
          return (
            exp.test(user.job_title) ||
            exp.test(user.job_id) ||
            exp.test(user.opp_first_name) ||
            exp.test(user.opp_last_name) ||
            exp.test(user.opp_first_name + " " + user.opp_last_name)
          );
        });

        let tempUsers = chatUsers.filter((user) => {
          return (
            exp.test(user.job_title) ||
            exp.test(user.job_id) ||
            exp.test(user.opp_first_name) ||
            exp.test(user.opp_last_name) ||
            exp.test(user.opp_first_name + " " + user.opp_last_name)
          );
        });

        setSearchedUsers([...nUsers, ...tempUsers]);
      } else {
        let tempUsers = pinnedMsgs.filter((user) => {
          return (
            exp.test(user.job_title) ||
            exp.test(user.job_id) ||
            exp.test(user.opp_first_name) ||
            exp.test(user.opp_last_name) ||
            exp.test(user.opp_first_name + " " + user.opp_last_name)
          );
        });
        setSearchedPinnedUsers([...tempUsers]);
      }
    } else {
      setSearchValue("");
      setSearchedUsers([]);
    }
  };

  const clearSearchInput = () => {
    searchInputRef.current.valueOf = "";
    setSearchValue("");
    setSearchedUsers([]);
  };

  const listUsers = ({ users }) => {
    const listItems = users.map((chatUser) => (
      <li
        className={`${
          chatValues.opp_user_id === chatUser.opp_user_id &&
          chatValues.job_id === chatUser.job_id &&
          "active"
        } "online"`}
        key={`${chatUser.job_id}-${chatUser.opp_user_id}`}
        role="button"
        onClick={() => onSelectUser(chatUser)}
      >
        <img
          src={`${process.env.REACT_APP_FILE_BASE_URL}/${chatUser?.opp_profile_photo}`}
          alt="user image"
          className="zu_chat_user_list_img"
        />
        <div className="zu_chat_user_list_text">
          <div className="zu_chat_user_list_text_name">
            <h3>{chatUser.opp_first_name + " " + chatUser.opp_last_name}</h3>
            <span>
              {chatUser.created_at
                ? moment(chatUser.created_at).fromNow()
                : moment().format("LT")}
            </span>
          </div>
          <div className="zu_chat_user_list_text_paragraph">
            <p>{chatUser.job_title}</p>
            {chatUser.unread_count > 0 && <span>{chatUser.unread_count}</span>}
          </div>
          <div className="zu_chat_user_list_text_last_msg_container">
            {chatUser.message_text !== "" && (
              <>
                <p className="zu_chat_user_list_text_last_msg_name">
                  {chatUser.sender_id === loggedInId
                    ? loggedInUserName + ":"
                    : chatUser.opp_first_name +
                      " " +
                      chatUser.opp_last_name +
                      ":"}
                </p>
                <pre
                  className="zu_chat_user_list_text_last_msg"
                  dangerouslySetInnerHTML={{
                    __html: chatUser.message_text,
                  }}
                />
              </>
            )}
          </div>
        </div>
      </li>
    ));
    return listItems;
  };

  const pinnedUsersList = ({ pinnedMessages }) => {
    const pinnedItems = pinnedMessages.map((pinnedMsg) => (
      <li
        className={`${
          chatValues.opp_user_id === pinnedMsg.opp_user_id &&
          chatValues.job_id === pinnedMsg.job_id &&
          pinnedMsgId === pinnedMsg.pin_id &&
          "active"
        } "online"`}
        role="button"
        onClick={() => onSelectUser(pinnedMsg)}
      >
        <img
          src={`${process.env.REACT_APP_FILE_BASE_URL}/${pinnedMsg?.opp_profile_photo}`}
          alt="user image"
          className="zu_chat_user_list_img"
        />
        <div className="zu_chat_user_list_text">
          <div className="zu_chat_user_list_text_name">
            <h3>{pinnedMsg.opp_first_name + " " + pinnedMsg.opp_last_name}</h3>
            <span>{moment(pinnedMsg.created_at).fromNow()}</span>
          </div>
          <div className="zu_chat_user_list_text_paragraph">
            <p>{pinnedMsg.job_title}</p>
          </div>

          <pre
            className="zu_chat_user_list_text_last_msg"
            dangerouslySetInnerHTML={{
              __html: pinnedMsg.message_text,
            }}
          />
        </div>
      </li>
    ));
    return pinnedItems;
  };

  const tabChangeHandler = (e) => {
    setSearchValue("");
    if (e === "AllMessage") {
      setALLMsgTab(true);
    } else {
      setALLMsgTab(false);
    }
  };

  return (
    <div className="zu_chat_list_content">
      <h2 className="zu_chat_list_content_heading">Messages</h2>
      <div className="zu_chat_list_search">
        {/* {msgSearchIcon} */}

        {/* <div className="input-group"> */}
        <div className="zu_chat_list_search_icon">{msgSearchIcon}</div>
        <input
          placeholder="Search by Name or Job Title"
          type="text"
          name="search_user"
          value={searchValue}
          onChange={searchInputChange}
          ref={searchInputRef}
          className="form-control"
        />
        {searchValue.length > 0 && (
          <button
            type="button"
            className="zu_chat_list_search_remove_btn"
            onClick={() => clearSearchInput()}
          >
            {modalCloseIcon}
          </button>
        )}
        {/* </div> */}
      </div>
      <Tabs
        defaultActiveKey="AllMessage"
        id="uncontrolled-tab-example"
        className="zu_chat_list_tab_row"
        onSelect={(e) => tabChangeHandler(e)}
      >
        <Tab eventKey="AllMessage" title="All Message">
          <div className="zu_chat_user_list">
            <ul className="zu_chat_user_list_nav">
              {searchValue.length === 0 &&
                newUser?.length !== 0 &&
                listUsers({ users: [newUser] })}
              {searchValue.length === 0 &&
                chatUsers &&
                listUsers({ users: chatUsers })}
              {searchValue.length !== 0 && listUsers({ users: searchedUsers })}
            </ul>
          </div>
        </Tab>
        <Tab eventKey="PinnedMessage" title="Pinned Message">
          <div className="zu_chat_user_list">
            {pinnedMsgs?.length === 0 ? (
              <div className="zu_pin_msg_icon_no_pinned_msg_container">
                <p>No Pinned Messages</p>
              </div>
            ) : (
              <ul className="zu_chat_user_list_nav">
                {searchValue.length === 0 && pinnedMsgs
                  ? pinnedUsersList({ pinnedMessages: pinnedMsgs })
                  : pinnedUsersList({ pinnedMessages: searchedPinnedUsers })}
              </ul>
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default MsgUserList;
