import React from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../css/ConfirmBox.css";
import { routes } from "../../constants";

import { logo } from "../../icons";

const ForgotPasswordCheckYourEmailPage = () => {
  return (
    <div className="zu_confirm_box_page">
      <div className="zu_confirm_box">
        <div className="zu_confirm_box_content">
          <Link to={routes.login} className="zu_confirm_box_logo">
            {logo}
          </Link>
          <h2 className="zu_confirm_box_heading">Check your email</h2>
          <p className="zu_confirm_box_paragraph">
            To reset your password, tap the button in the email we sent to you.
          </p>
          <div style={{ textAlign: "center" }}>Open email app</div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordCheckYourEmailPage;
