import React, { useState, useEffect } from "react";
import { jobRoleList, jobTypeList, marks, routes } from "../../constants";
import ImageTextContent from "../../component/Layout/ImageTextContent";
import { useNavigate, useParams } from "react-router-dom";
import CommonHeading from "../../component/Common/CommonHeading";
import findJobsGraphicImage from "../../images/FindJobsGraphicImage.jpg";
import { locationFillIcon } from "../../icons";
import FindJobSideMenu from "../../component/Layout/FindJobSideMenu";

import { applyForJob, getAllJob } from "../../store/slice/postSlice";
import { useDispatch, useSelector } from "react-redux";
import FindJobsColumn from "../../component/Layout/FindJobsColumn";
import { useForm } from "../../hooks/useForm";
import { Form } from "react-bootstrap";
import Pagination from "../../component/Pagination/Pagination";
import ProfileAttentionModal from "../../component/Layout/ProfileAttentionModal";
import Loader from "../../component/Common/Loader";

import { Modal } from "react-bootstrap";
import AskQuestionModal from "../../component/Layout/AskQuestionModal";
import ApplyNowModal from "../../component/Layout/ApplyNowModal";
import { CustomSlider } from "../../helpers/commonFunction";
import AutoComplete from "../../component/Common/AutoComplete";
import { checkUserProfile } from "../../store/slice/userSlice";

const FindJobsPage = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number, id } = params;

  const findJobs = useSelector((state) => state.post.getAllJob?.data);
  const loading = useSelector((state) => state.post.loader);
  const jobLoading = useSelector((state) => state.post.jobLoader);
  const totalCount = useSelector((state) => state.post.getAllJob?.totalCount);

  const user = useSelector((state) => state.user.user);

  const authToken = user.user_status;

  const initialFValues = {
    show: false,
    findJobModal: null,
    ownerId: null,
    profileAttentionModal: false,
    applyJobModal: false,
    confirmApplyJobModal: false,
    askQuestionModal: false,
    message: "",
    question: "",
    location: "",
    job_type: "",
    job_role: "ALL",
    distance: 100,
    approvalStatus: "",
    jobLoadingState: "",
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    if (!id) {
      navigate(`${routes.findJobs}/page/1`);
    }
    if (+page_number === 1)
      dispatch(
        getAllJob({
          job_type: values.job_type,
          job_worker_roles: values.job_role,
          location: values.location,
          km: values.distance,
        })
      );
  }, [
    dispatch,
    values.distance,
    values.job_type,
    values.location,
    values.job_role,
  ]);

  useEffect(() => {
    if (id) {
      setValues({
        ...values,
        show: true,
        findJobModal: id,
        jobLoadingState: id,
      });
    }
  }, [id, setValues]);

  const showModal = (id) => {
    setValues({
      ...values,
      show: true,
      findJobModal: id,
      jobLoadingState: id,
    });
  };

  const hideModal = () => {
    setValues({ ...values, show: false, findJobModal: null });
  };

  const findJobShowModal = (id, owner_id) => {
    setValues({
      ...values,
      applyJobModal: true,
      show: false,
      ownerId: owner_id,
      findJobModal: id,
    });
  };

  const continueJobModalHandler = () => {
    setValues({
      ...values,
      applyJobModal: false,
      confirmApplyJobModal: true,
      message: "",
    });
    const data = {
      job_id: values.findJobModal,
      owner: values.ownerId,
      message: values.message,
    };
    dispatch(applyForJob({ data, navigate }));
  };

  const cancelJobModalHandler = () => {
    setValues({ ...values, applyJobModal: false, message: "" });
  };

  const closeConfirmJobModal = () => {
    setValues({ ...values, confirmApplyJobModal: false });
  };

  const showProfileModal = (status) => {
    setValues({
      ...values,
      profileAttentionModal: true,
      show: false,
      approvalStatus: status,
    });
  };

  const hideProfileModal = () => {
    setValues({ ...values, profileAttentionModal: false });
  };

  const showQuestionModal = (job_id, owner_id) => {
    setValues({
      ...values,
      askQuestionModal: true,
      show: false,
      ownerId: owner_id,
      findJobModal: job_id,
    });
  };

  const hideQuestionModal = () => {
    setValues({ ...values, askQuestionModal: false, question: "" });
    setErrors({ ...errors, question: "" });
  };

  const locationChangeHandler = (e) => {
    setValues({ ...values, location: "" });
  };

  function valuetext(value) {
    return value;
  }

  function valueTextSlider(e, value) {
    setValues({ ...values, distance: value });
  }

  const onPageChange = (page_number) => {
    dispatch(
      getAllJob({
        start: page_number,
        limit: 10,
        job_type: values.job_type,
        job_worker_roles: values.job_role,
        location: values.location,
        km: values.distance,
      })
    );
  };

  const applyBtnHandler = (id, owner) => {
    dispatch(
      checkUserProfile({
        cb: (res, err) => {
          if (err) {
            console.log("err", err);
          } else {
            if (res.user_status === "VERIFICATION_SUCCESS") {
              findJobShowModal(id, owner);
            } else {
              showProfileModal(res.user_status);
            }
          }
        },
      })
    );
  };

  return (
    <>
      {loading && <Loader />}
      <main className="zu_all_page_main_content">
        <div className="zu_find_page">
          <section className="zu_find_job_section">
            <div className="zu_find_job_content">
              <ImageTextContent
                imageSrc={findJobsGraphicImage}
                heading="Find Jobs"
              />
            </div>
          </section>
          <section className="zu_upcomming_job_section">
            <div
              className={`d-lg-none zu_common_job_heading ${
                openFilter && "zu_common_job_heading_filter_open"
              }`}
            >
              <CommonHeading heading="Find Job" />
              <button
                type="button"
                className="zl_common_job_show_filter"
                onClick={() => setOpenFilter(!openFilter)}
              >
                {openFilter ? "Hide Filters" : "Show Filters"}
              </button>
            </div>
            <div className="zu_upcomming_job_content">
              <div
                className={`zu_common_filter_input_row four_filter_container ${
                  openFilter && "zu_common_filter_open"
                }`}
              >
                <div className="zu_common_filter_input_one zu_common_select_location_icon">
                  {locationFillIcon}

                  <AutoComplete
                    id="location"
                    name="location"
                    values={values}
                    setValues={setValues}
                    placeholder="Location"
                    handleInputChange={(e) => locationChangeHandler(e)}
                  />
                </div>
                <div className="zu_common_filter_input_two">
                  <div className="zu_common_select_box">
                    <Form.Select
                      onChange={handleInputChange}
                      id="job_type"
                      className="zu_common_filter_input_box"
                      name="job_type"
                    >
                      <option disabled selected>
                        Job Type
                      </option>
                      <option value="">All</option>

                      {jobTypeList.map((jobType, i) => {
                        return (
                          <option
                            key={i}
                            selected={values?.job_type === jobType.labelText}
                            value={jobType.labelText}
                          >
                            {jobType.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </div>
                <div className="zu_common_filter_input_two">
                  <div className="zu_common_select_box">
                    <Form.Select
                      onChange={handleInputChange}
                      id="job_role"
                      className="zu_common_filter_input_box"
                      name="job_role"
                    >
                      <option disabled selected value="ALL">
                        Job Role
                      </option>
                      <option value="ALL">All</option>

                      {jobRoleList.map((jobRole, i) => {
                        return (
                          <option
                            key={i}
                            selected={values?.job_type === jobRole.labelText}
                            value={jobRole.labelText}
                          >
                            {jobRole.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </div>
                <div className="zu_common_filter_input_class_three">
                  <CustomSlider
                    valueLabelDisplay="auto"
                    aria-label="pretto slider"
                    step={100}
                    defaultValue={100}
                    min={100}
                    marks={marks}
                    max={300}
                    valueLabelFormat={"KM"}
                    value={values.distance}
                    getAriaValueText={valuetext}
                    onChangeCommitted={valueTextSlider}
                  />
                </div>
              </div>
              <div className="zu_upcomming_job_column_content">
                {findJobs?.length === 0 ? (
                  <h1 className="zu_no_jobs_found_content">No Jobs Found</h1>
                ) : (
                  findJobs?.map((i, id) => {
                    return (
                      <FindJobsColumn
                        key={id}
                        job={i}
                        hasFindJob
                        showModal={showModal}
                        abcd={values.abcd}
                        authToken={authToken}
                        jobLoading={jobLoading}
                        jobLoadingState={values.jobLoadingState}
                        applyBtnHandler={applyBtnHandler}
                      />
                    );
                  })
                )}
              </div>
              {totalCount > 0 && (
                <Pagination
                  totalRecords={totalCount}
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </section>
        </div>
      </main>

      <Modal
        show={values.show}
        onHide={hideModal}
        centered
        className="zu_sidemenu_modal"
      >
        <FindJobSideMenu
          btnForApply
          hideModal={hideModal}
          findJobModal={values.findJobModal}
          findJobShowModal={findJobShowModal}
          authToken={authToken}
          showProfileModal={showProfileModal}
          showQuestionModal={showQuestionModal}
          jobLoading={jobLoading}
          applyBtnHandler={applyBtnHandler}
        />
      </Modal>
      <Modal
        show={values.profileAttentionModal}
        onHide={hideProfileModal}
        centered
        className="zu_common_text_modal"
      >
        <ProfileAttentionModal
          hideProfileModal={hideProfileModal}
          approvalStatus={values.approvalStatus}
        />
      </Modal>
      <Modal
        show={values.askQuestionModal}
        onHide={() => hideQuestionModal()}
        centered
        className="zu_common_text_modal"
      >
        <AskQuestionModal
          values={values}
          handleInputChange={handleInputChange}
          errors={errors}
          hideQuestionModal={hideQuestionModal}
          setValues={setValues}
          setErrors={setErrors}
          // questionHandler={questionHandler}
        />
      </Modal>
      <Modal
        show={values.applyJobModal}
        onHide={cancelJobModalHandler}
        centered
      >
        <ApplyNowModal
          values={values}
          handleInputChange={handleInputChange}
          cancelJobModalHandler={cancelJobModalHandler}
          continueJobModalHandler={continueJobModalHandler}
        />
      </Modal>
      <Modal
        show={values.confirmApplyJobModal}
        onHide={closeConfirmJobModal}
        centered
        className="zu_common_text_modal"
      >
        <div className="zu_common_modal zu_common_center_modal">
          <div className="zu_common_modal_content">
            <h2 className="zu_common_modal_heading">Good Luck!</h2>
            <p className="zu_common_modal_paragraph">
              Your application has been submitted.
            </p>
            <div className="zu_modal_common_btn_row">
              <button
                type="button"
                className="zu_common_btn zu_btn_gray"
                onClick={closeConfirmJobModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FindJobsPage;
