import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { deleteMsgIcon, editMsgIcon, msgPinnedIcon } from "../../icons";

const MsgToggle = ({
  msg,
  editMsgHandler,
  pinMsgHandler,
  deleteMsgHandler,
  onlyText,
  onlyPin,
}) => {
  return (
    <>
      {onlyPin ? (
        <DropdownButton id="togglebtn" title="">
          <Dropdown.Item as="button" onClick={() => pinMsgHandler(msg)}>
            {msgPinnedIcon}
            {msg.pin_id ? "Unpinned Message" : "Pin Message"}
          </Dropdown.Item>
        </DropdownButton>
      ) : (
        <DropdownButton id="togglebtn" title="">
          <Dropdown.Item as="button" onClick={() => pinMsgHandler(msg)}>
            {msgPinnedIcon}
            {msg.pin_id ? "Unpinned Message" : "Pin Message"}
          </Dropdown.Item>
          {onlyText && (
            <Dropdown.Item as="button" onClick={() => editMsgHandler(msg)}>
              {editMsgIcon}
              Edit
            </Dropdown.Item>
          )}
          <Dropdown.Item as="button" onClick={() => deleteMsgHandler(msg)}>
            {deleteMsgIcon}
            Delete
          </Dropdown.Item>
        </DropdownButton>
      )}
    </>
  );
};

export default MsgToggle;
