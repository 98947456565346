import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes, ratingReviewList } from "../../constants";
import CommonHeading from "../../component/Common/CommonHeading";
import ProfileUserDetaile from "../../component/Layout/ProfileUserDetaile";
import ShowcaseGallery from "../../component/Layout/ShowcaseGallery";
import { ratingStarIcon } from "../../icons";

const AdminManageUsersProfilePage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <main className="zu_all_page_main_content">
        <div className="zu_profile_page zu_admin_manage_user_profile_page">
          <div className="zu_profile_heading_row">
            <CommonHeading heading="Applicant Profile" />
            <div className="zu_profile_heading_btn">
              <Link
                to={routes.profile}
                className="zu_common_btn zu_lighter_blue_btn"
              >
                LOGIN AS…
              </Link>
              <Link
                to={routes.profileedit}
                className="zu_common_btn zu_btn_light_green"
              >
                EDIT PROFILE
              </Link>
              <Link
                to={routes.profileedit}
                className="zu_common_btn zu_danger_btn"
              >
                BLOCK USER
              </Link>
            </div>
          </div>
          <ProfileUserDetaile
            applicantDetail={location.state.user}
            // userPhoto={user2}
            hasButton
            hasAboutMe
            // userName="David Bowie"
            aboutHeading="OVERVIEW"
            // buttonText="View Website"
          />
          <ShowcaseGallery heading="Showcase Photos" />
          <section className="zu_showcase_links_section">
            <div className="zu_showcase_links_content">
              <div className="zu_profile_common_heading">
                <h2>Patryk Bowie Showcase Links</h2>
                <Link
                  to={routes.adminmanageusersprofile}
                  className="zu_profile_common_heading_view_all_btn"
                >
                  VIEW ALL
                </Link>
              </div>
              <ul className="zu_showcase_links_nav">
                <li>
                  <a
                    href="https://www.shootzu.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.shootzu.com
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.shootzu.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.shootzu.com
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.site2.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.site2.com
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.site3.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.site3.com
                  </a>
                </li>
              </ul>
            </div>
          </section>
          <section className="zu_profile_rating_review_section">
            <div className="zu_profile_rating_review_content">
              <div className="zu_profile_common_heading">
                <h2>Ratings & Reviews</h2>
              </div>
              <div className="zu_profile_rating_review_row">
                {ratingReviewList.map((i) => {
                  return (
                    <div className="zu_profile_rating_review_column">
                      <div className="zu_profile_rating_review_img_row">
                        <div className="zu_profile_rating_review_user_name">
                          <h3>Lorem Ipsum</h3>
                          <div className="zu_profile_rating_review_user_rating">
                            {ratingStarIcon}
                            {ratingStarIcon}
                            {ratingStarIcon}
                            {ratingStarIcon}
                          </div>
                        </div>
                      </div>
                      <p className="zu_profile_rating_review_user_paragraph">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <button
            onClick={() => navigate(-1)}
            className="zu_profile_job_back_btn"
          >
            Go Back
          </button>
        </div>
      </main>
    </>
  );
};

export default AdminManageUsersProfilePage;
