import React from "react";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";
import moment from "moment";

const LeadTaxInvoice = ({ lead }) => {
  return (
    <div className="invoice_container">
      <h1 className="invoic_text">
        <span className="invoic_text_crew">LEAD PURCHASE</span> | TAX INVOICE
      </h1>
      <h3 className="issued_date">
        <strong>Issued: </strong>
        {moment(lead?.purchase_date).utc().format("DD MMM YYYY")}
      </h3>
      <div className="first_grid_table">
        <table>
          <thead>
            <tr>
              <th className="first_grid_column">
                <h3
                  className="first_grid_column_heading"
                  style={{ textAlign: "left" }}
                >
                  <strong>Invoice to:</strong>
                </h3>
                <p
                  className="first_grid_column_paragraph"
                  style={{ textAlign: "left" }}
                >
                  {capitalizeFirstLetter(lead?.business_name)}
                </p>
                <h3
                  className="first_grid_column_heading"
                  style={{ textAlign: "left" }}
                >
                  <strong>Contact:</strong>
                </h3>
                <p
                  className="first_grid_column_paragraph"
                  style={{ textAlign: "left" }}
                >
                  {capitalizeFirstLetter(lead?.first_name) +
                    " " +
                    capitalizeFirstLetter(lead?.last_name)}
                </p>
                <h3
                  className="first_grid_column_heading"
                  style={{ textAlign: "left" }}
                >
                  <strong>ABN:</strong>
                </h3>
                <p
                  className="first_grid_column_paragraph"
                  style={{ textAlign: "left" }}
                >
                  {lead?.abn}
                </p>
              </th>
              <th className="first_grid_column">
                <h3
                  className="first_grid_column_heading"
                  style={{ textAlign: "left" }}
                >
                  <strong>Invoice from:</strong>
                </h3>
                <p
                  className="first_grid_column_paragraph "
                  style={{ textAlign: "left" }}
                >
                  Shootzu (Turbo Productions PTY LTD)
                  <br /> 366 / 585 Little Collins Street <br />
                  Melbourne VIC 3000
                </p>
                <p
                  className="first_grid_column_abn_number"
                  style={{ textAlign: "left" }}
                >
                  <span>ABN:</span> 18 150 556 102
                </p>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="job_detail_table_heading_content">
        <h3 className="job_detail_table_heading">LEAD DETAILS: </h3>
        <p className="job_detail_table_job_date">
          <strong> DATE OF JOB: </strong>{" "}
          {moment(lead?.date_of_event).utc().format("DD MMM YYYY")}
        </p>
        <p className="job_detail_table_job_number">
          <strong>LEAD ID: </strong> LEAD-00{lead?.id}
        </p>
      </div>
      <div className="job_detail_table">
        <table>
          <thead>
            <tr></tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h3 className="job_table_data_heading">
                  {" "}
                  Total Fees Paid to Shootzu
                </h3>
              </td>
              <td>
                <p className="job_table_data_paragraph">${lead?.lead_amount}</p>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p className="job_table_data_paragraph lead_text_center">
                  Lead Reveal Fee includes $
                  {(lead?.lead_amount / 11)?.toFixed(2)} GST
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="paid_full_date_text">
        <h2>PAID IN FULL</h2>
        <p>{moment(lead?.purchase_date).utc().format("DD MMM YYYY")}</p>
      </div>
      <p className="note_text lead_last_info">
        Shootzu is a division of Turbo Productions PTY LTD (ABN: 18150556102){" "}
        <br />
        Need Help? Contact support@shootzu.com
      </p>
    </div>
  );
};

export default LeadTaxInvoice;
