import React from "react";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";
import moment from "moment";

const CrewTaxInvoice = ({ jobDetail, person }) => {
  const jobTotal = jobDetail?.number_of_hour * jobDetail?.price_per_hour;
  const jobTotalForExtraHour =
    jobDetail?.extra_hour * jobDetail?.price_per_hour;
  const jobTotalForCustomAmount = jobDetail?.custom_amount;

  let finalJobTotal;
  if (jobTotalForExtraHour) {
    finalJobTotal = jobTotal + jobTotalForExtraHour;
  } else if (jobTotalForCustomAmount) {
    finalJobTotal = jobTotal + jobTotalForCustomAmount;
  } else {
    finalJobTotal = jobTotal;
  }

  const finalJobTotalGST = finalJobTotal / 11;

  const finalShootzuCrewFees = finalJobTotal * 0.02;
  const finalShootzuBuisnessFees =
    jobDetail?.id === 2012 ? 100 : finalJobTotal * 0.05;

  const finalShootzuCrewFeesGST = finalShootzuCrewFees / 11;
  const finalShootzuBuisnessFeesGST =
    jobDetail?.id === 2012 ? 9.09 : finalShootzuBuisnessFees / 11;

  return (
    <div className="invoice_container">
      <h1 className="invoic_text">
        <span
          className={
            person === "Crew" ? "invoic_text_crew" : "invoic_text_business"
          }
        >
          {person}
        </span>{" "}
        | TAX INVOICE
      </h1>
      <h3 className="issued_date">
        <strong>Issued: </strong>
        {moment(jobDetail?.modified_at).utc().format("DD MMM YYYY")}
      </h3>
      <div className="first_grid_table">
        <table>
          <thead>
            <tr>
              <th className="first_grid_column">
                <h3
                  className="first_grid_column_heading"
                  style={{ textAlign: "left" }}
                >
                  <strong>Invoice To Business:</strong>
                </h3>
                <p
                  className="first_grid_column_paragraph"
                  style={{ textAlign: "left" }}
                >
                  {jobDetail?.owner_business_name}
                </p>
                <h3
                  className="first_grid_column_heading"
                  style={{ textAlign: "left" }}
                >
                  <strong>Contact:</strong>
                </h3>
                <p
                  className="first_grid_column_paragraph"
                  style={{ textAlign: "left" }}
                >
                  {capitalizeFirstLetter(jobDetail?.owner_first_name) +
                    " " +
                    capitalizeFirstLetter(jobDetail?.owner_last_name)}
                </p>
                <p
                  className="first_grid_column_abn_number"
                  style={{ textAlign: "left" }}
                >
                  <span>ABN:</span> {jobDetail?.owner_abn}
                </p>
              </th>
              <th className="first_grid_column">
                <h3
                  className="first_grid_column_heading"
                  style={{ textAlign: "left" }}
                >
                  <strong>Invoice From Crew:</strong>
                </h3>
                <p
                  className="first_grid_column_paragraph"
                  style={{ textAlign: "left" }}
                >
                  {jobDetail?.crew_business_name}
                </p>
                <h3
                  className="first_grid_column_heading"
                  style={{ textAlign: "left" }}
                >
                  <strong>Contact:</strong>
                </h3>
                <p
                  className="first_grid_column_paragraph"
                  style={{ textAlign: "left" }}
                >
                  {capitalizeFirstLetter(jobDetail?.crew_first_name) +
                    " " +
                    capitalizeFirstLetter(jobDetail?.crew_last_name)}
                </p>
                <p
                  className="first_grid_column_abn_number"
                  style={{ textAlign: "left" }}
                >
                  <span>ABN:</span> {jobDetail?.crew_abn}
                </p>

                <p
                  className="first_grid_column_abn_number"
                  style={{ textAlign: "left" }}
                >
                  <span>Registered for GST?: </span>
                  {jobDetail?.crew_gst_status === "1" ? "Yes" : "No"}
                </p>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="job_detail_table_heading_content">
        <h3 className="job_detail_table_heading">JOB DETAILS: </h3>
        <p className="job_detail_table_job_date">
          <strong> TITLE OF JOB: </strong>
          {jobDetail?.title}
        </p>
        <p className="job_detail_table_job_date">
          <strong> DATE OF JOB: </strong>
          {moment(jobDetail?.date).utc().format("DD MMM YYYY")}
        </p>
        <p className="job_detail_table_job_number">
          <strong>SHOOTZU JOB ID: </strong>
          {jobDetail?.id}
        </p>
      </div>
      <div className="job_detail_table">
        <table>
          <thead>
            <tr></tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h3 className="job_table_data_heading">
                  {person === "Crew"
                    ? "Job Total"
                    : "Total Amount Paid to Crew"}
                </h3>
              </td>
              <td>
                <p className="job_table_data_paragraph">
                  ${finalJobTotal.toFixed(2)}
                </p>
              </td>
            </tr>
            {jobDetail?.custom_amount && (
              <tr>
                <td colSpan={2}>
                  {/* <h3 className="job_table_data_heading">
                    {person === "Crew"
                      ? "Additional Job Amount"
                      : "Additional Amount Paid to Crew"}
                  </h3>
                </td>
                <td> */}
                  <p className="zu_custom_amount_line">
                    {/* ${additionalJobTotal.toFixed(2)} */}
                    This payment includes an additional custom payment for{" "}
                    {jobDetail?.custom_amount_reason}
                  </p>
                </td>
              </tr>
            )}
            <tr>
              <td>
                <i className="job_table_data_paragraph">
                  Amount of GST on Job Total:{" "}
                </i>
              </td>
              <td>
                <p className="job_table_data_paragraph">
                  {jobDetail?.crew_gst_status === "1"
                    ? `$${finalJobTotalGST.toFixed(2)}`
                    : "Not Applicable"}
                </p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <h3 className="job_table_data_heading">
                  Shootzu Service Fee:{" "}
                </h3>
              </td>
              <td>
                <p className="job_table_data_paragraph">
                  $
                  {person === "Crew"
                    ? finalShootzuCrewFees.toFixed(2)
                    : finalShootzuBuisnessFees.toFixed(2)}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <i className="job_table_data_paragraph">
                  Amount of GST on Service Fee:{" "}
                </i>
              </td>
              <td>
                <p className="job_table_data_paragraph">
                  {`$${
                    person === "Crew"
                      ? finalShootzuCrewFeesGST.toFixed(2)
                      : finalShootzuBuisnessFeesGST.toFixed(2)
                  }`}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <h3 className="job_table_data_heading">
                  {person === "Crew"
                    ? "You Have Received:"
                    : "Total Paid by the Hiring Company:"}
                </h3>
                <p className="job_table_data_paragraph">
                  (including Shootzu Service Fee)
                </p>
              </td>
              <td>
                <h3 className="job_table_data_heading">
                  $
                  {person === "Crew"
                    ? finalJobTotal - finalShootzuCrewFees.toFixed(2)
                    : (finalJobTotal + finalShootzuBuisnessFees).toFixed(2)}
                </h3>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="paid_full_date_text">
        <h2>PAID IN FULL</h2>
        <p>{moment(jobDetail?.modified_at).utc().format("DD MMM YYYY")}</p>
      </div>
      <p className="note_text">
        <span>Terms & Conditions:</span> Shootzu acts as a payment facilitator
        and is neither Business User nor Crew within this invoice. By accepting
        a Job Offer, as Crew you acknowledge you are entering into a direct
        agreement with the Business User. Your Crew Payment processed by Shootzu
        pays the invoice for the Job Offer. Shootzu disclaims any responsibility
        for any damage or loss that you may suffer or incur as a result of
        accepting a Job or hiring Crew, except for damage or loss, if any, that
        you might have a right to recover if something that Shootzu has done or
        omitted to do breaches a consumer guarantee set out in the Australian
        Consumer Law. Crew Payment Made Through: Shootzu, a division of Turbo
        Productions PTY LTD (ABN: 18150556102)
      </p>
    </div>
  );
};

export default CrewTaxInvoice;
