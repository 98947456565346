import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";

// GET CHAT USERS
export const getChatUsers = createAsyncThunk(
  "getChatUsers",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/chat/get-chat-users`,
        data,
        authHeaders()
      );
      if (response.status === 200) {
        return data.cb(null, response.data);
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// GET NEW CHAT USER
export const getNewChatUser = createAsyncThunk(
  "getNewChatUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/chat/get-new-chat-user`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// GET MESSAGES
export const getMessages = createAsyncThunk(
  "getMessages",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/chat/get-messages`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// SEND MSG
export const sendMessage = createAsyncThunk(
  "sendMessage",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/chat/send-message",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// UPDATE MSG
export const updateMessage = createAsyncThunk(
  "updateMessage",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/chat/update-message/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// DELETE MSG
export const deleteMessage = createAsyncThunk(
  "deleteMessage",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.delete(
        `/chat/delete-message?id=${data.id}`,
        authHeaders()
      );
      if (response.status === 200) {
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// PIN UNPIN MSG
export const pinOrUnpinMessage = createAsyncThunk(
  "pinOrUnpinMessage",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/chat/pin-or-unpin-message",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        if (data.cb) {
          return data.cb(null, response.data);
        }
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

// GET PINNED MESSAGES
export const getPinnedMessages = createAsyncThunk(
  "getPinnedMessages",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/chat/get-pinned-messages`,
        data,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

const chatSlice = createSlice({
  name: "messages",
  initialState: {
    error: null,
    loader: false,
    getChatUsers: [],
    getMessages: [],
  },

  // GET LEADS LIST
  extraReducers: (builder) => {
    //getChatUsers
    builder.addCase(getChatUsers.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getChatUsers.fulfilled, (state, action) => {
      state.loader = false;
      state.getChatUsers = action.payload;
    });
    builder.addCase(getChatUsers.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getNewChatUser
    builder.addCase(getNewChatUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getNewChatUser.fulfilled, (state, action) => {
      state.loader = false;
      state.getNewChatUser = action.payload;
    });
    builder.addCase(getNewChatUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getMessages
    builder.addCase(getMessages.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      state.loader = false;
      state.getMessages = action.payload;
    });
    builder.addCase(getMessages.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //sendMessage
    builder.addCase(sendMessage.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.loader = false;
      state.sendMessage = action.payload;
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //updateMessage
    builder.addCase(updateMessage.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateMessage.fulfilled, (state, action) => {
      state.loader = false;
      state.updateMessage = action.payload;
    });
    builder.addCase(updateMessage.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //deleteMessage
    builder.addCase(deleteMessage.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(deleteMessage.fulfilled, (state, action) => {
      state.loader = false;
      state.deleteMessage = action.payload;
    });
    builder.addCase(deleteMessage.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //pinOrUnpinMessage
    builder.addCase(pinOrUnpinMessage.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(pinOrUnpinMessage.fulfilled, (state, action) => {
      state.loader = false;
      state.pinOrUnpinMessage = action.payload;
    });
    builder.addCase(pinOrUnpinMessage.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //getPinnedMessages
    builder.addCase(getPinnedMessages.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getPinnedMessages.fulfilled, (state, action) => {
      state.loader = false;
      state.getPinnedMessages = action.payload;
    });
    builder.addCase(getPinnedMessages.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default chatSlice.reducer;
