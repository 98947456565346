import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../css/LoginPage.css";
import { routes } from "../../constants";
import { Form, FloatingLabel } from "react-bootstrap";
import { logo, rightArrow } from "../../icons";
import loginImage from "../../images/login-image.png";
import { useForm } from "../../hooks/useForm";
import ButtonCom from "../../component/Common/Button";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../store/slice/userSlice";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialFValues = {
    email: "",
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    if ("email" in fieldValues) {
      if (!fieldValues.email) {
        temp.email = "Email is required!";
      } else {
        temp.email = "";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const emailValidate = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
    var mailReg = new RegExp(mailformat);
    if (!values.email.match(mailReg)) {
      setErrors({ ...errors, email: "Please enter a valid email!" });
    } else {
      setErrors({ ...errors, email: "" });
      return true;
    }
  };

  const { errors, setErrors, values, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate() && emailValidate()) {
      const data = {
        email: values.email,
      };

      dispatch(forgotPassword({ data, navigate }));
    }
  };

  return (
    <div className="zu_forgot_password_page_content">
      <div className="zu_auth_page_mobile_logo">
        <Link to={routes.dashboard}>{logo}</Link>
      </div>
      <section className="zu_login_section">
        <div className="zu_login_content">
          <div className="zu_login_form_content">
            <div className="zu_login_form">
              <Link to={routes.dashboard} className="zu_login_form_logo">
                {logo}
              </Link>
              <h2 className="zu_login_form_heading">Forgot Password?</h2>
              <form className="zu_login_form_input_content">
                <FloatingLabel
                  label="Email"
                  className="zu_floting_input_content"
                >
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    className="zu_floting_input"
                    name="email"
                    id="email"
                    onChange={handleInputChange}
                    value={values.email}
                  />
                  {errors?.email && (
                    <span className="err_text">{errors?.email}</span>
                  )}
                </FloatingLabel>
                <ButtonCom
                  buttonClassName="zu_auth_form_submit_btn"
                  onClick={handleSubmit}
                  text="RESET PASSWORD"
                  other={rightArrow}
                />
                <div className="zu_login_bottom_text">
                  <Link
                    to={routes.login}
                    className="zu_login_bottom_password_text"
                  >
                    Login
                  </Link>
                  <p className="zu_login_bottom_join_text">
                    Not a member yet? <Link to={routes.signup}>JOIN FREE</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="zu_login_image_content">
            <img src={loginImage} alt="img" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgotPasswordPage;
