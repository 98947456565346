import React, { useState } from "react";
import CommonHeading from "../../component/Common/CommonHeading";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteCreditCard, userCardList } from "../../store/slice/userSlice";
import Loader from "../../component/Common/Loader";

import CardDeleteModal from "../../component/Layout/CardDeleteModal";
import { Suspense } from "react";
const NotificationSettings = React.lazy(() =>
  import("../../component/Layout/NotificationSettings")
);
const PayoutSettings = React.lazy(() =>
  import("../../component/Layout/PayoutSettings")
);
const CreditcardSettings = React.lazy(() =>
  import("../../component/Layout/CreditcardSettings")
);

const PaymentPage = () => {
  const dispatch = useDispatch();

  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState("");
  const [cardDataState, setCardDataState] = useState(false);

  const loading = useSelector((state) => state.user.loader);

  const hideModal = () => {
    setDeletePopup(false);
  };

  const deleteCardHandler = () => {
    dispatch(
      deleteCreditCard({
        id: deleteCardId,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              userCardList({
                cb: (err, res) => {
                  if (err) {
                    console.log("err", err);
                  } else {
                    setCardDataState(true);
                  }
                },
              })
            );
          }
        },
      })
    );
    setDeletePopup(false);
  };

  return (
    <>
      {loading && <Loader />}
      <main className="zu_all_page_main_content">
        <div className="zu_payment_page">
          <section className="zu_payment_section">
            <CommonHeading heading="Settings" />
            <div className="zu_common_tab_menu">
              <Suspense fallback={<div>Loading...</div>}>
                <Tabs defaultActiveKey="notificationSetiingTab">
                  <Tab
                    eventKey="notificationSetiingTab"
                    title="Notification Settings"
                  >
                    <NotificationSettings />
                  </Tab>
                  <Tab eventKey="crewPaymentTab" title="Payout Details">
                    <PayoutSettings />
                  </Tab>
                  <Tab eventKey="creditCardTab" title="Credit Card Details">
                    <CreditcardSettings
                      deletePopup={deletePopup}
                      setDeletePopup={setDeletePopup}
                      deleteCardId={deleteCardId}
                      setDeleteCardId={setDeleteCardId}
                      cardDataState={cardDataState}
                      setCardDataState={setCardDataState}
                    />
                  </Tab>
                </Tabs>
              </Suspense>
            </div>
          </section>
        </div>
      </main>
      <Modal
        show={deletePopup}
        onHide={hideModal}
        centered
        className="zu_common_text_modal"
      >
        <CardDeleteModal
          hideModal={hideModal}
          deleteCardHandler={deleteCardHandler}
        />
      </Modal>
    </>
  );
};

export default PaymentPage;
