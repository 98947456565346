import React from "react";
import { useDispatch } from "react-redux";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";
import { closeIcon } from "../../icons";
import { askQuestionForJob } from "../../store/slice/postSlice";

const SendMessageModal = ({
  sendMessageCloseHandler,
  applicantDetail,
  messageContent,
  errorMessage,
  values,
  setValues,
  jobDetail,
  sendMessageFlag,
}) => {
  const dispatch = useDispatch();

  const messageSendHandler = () => {
    if (!values.messageContent.trim()) {
      setValues({
        ...values,
        errorMessage: "Please enter something!",
      });
    } else {
      const data = {
        job_id: sendMessageFlag ? jobDetail?.jobId : jobDetail?.id,
        message: values.messageContent,
        applicant_id: applicantDetail?.id
          ? applicantDetail?.id
          : jobDetail?.shooter,
      };

      dispatch(
        askQuestionForJob({ data, business_user: "0", crew_member: "1" })
      );

      setValues({
        ...values,
        sendMessageModal: false,
        messageContent: "",
        errorMessage: "",
      });
    }
  };

  const messageOnchangeHandler = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      errorMessage: "",
      messageContent: e.target.value,
    });
  };

  return (
    <div className="zu_common_modal zu_common_center_modal ">
      <div className="zu_common_modal_content">
        <button
          type="button"
          className="zu_modal_close_btn"
          onClick={() => sendMessageCloseHandler()}
        >
          {closeIcon}
        </button>
        <h2 className="zu_common_modal_heading">
          Send a message to{" "}
          <span>
            {capitalizeFirstLetter(applicantDetail?.first_name) +
              " " +
              capitalizeFirstLetter(applicantDetail?.last_name)}
          </span>
        </h2>
        <p className="zu_common_modal_paragraph">
          This message will be sent via direct email.
        </p>
        <div className="zu_common_modal_text_input">
          <textarea
            placeholder="Type Your Message Here....."
            className="zu_common_modal_textarea"
            onChange={(e) => messageOnchangeHandler(e)}
            value={messageContent}
          />
          {errorMessage !== "" && (
            <span className="err_text">{errorMessage}</span>
          )}
        </div>
        <div className="zu_modal_common_btn_row">
          <button
            type="button"
            className="zu_common_btn zu_btn_gray zu_send_msg_modal_cancel_btn"
            onClick={() => sendMessageCloseHandler()}
          >
            Cancel
          </button>
          <button
            type="button"
            className="zu_common_btn zu_btn_light_purple_color "
            onClick={() => messageSendHandler()}
          >
            Send Message
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendMessageModal;
