import React from "react";

const ApplyNowModal = ({
  values,
  handleInputChange,
  cancelJobModalHandler,
  continueJobModalHandler,
}) => {
  return (
    <div className="zu_common_modal zu_common_center_modal zu_apply_modal">
      <div className="zu_common_modal_content">
        <h2 className="zu_common_modal_heading">Apply Now</h2>
        <p className="zu_common_modal_paragraph">
          Would you like to include a message with your application? This is
          optional but is a good way to introduce yourself.
        </p>
        <div className="zu_common_modal_text_input">
          <textarea
            placeholder="Add your message here (optional)"
            className="zu_common_modal_textarea"
            name="message"
            id="message"
            value={values.message}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <div className="zu_modal_common_btn_row zu_find_job_modal_btn_each">
          <button
            type="button"
            className="zu_common_btn zu_btn_gray zu_send_msg_modal_cancel_btn"
            onClick={cancelJobModalHandler}
          >
            CANCEL
          </button>
          <button
            type="button"
            className="zu_common_btn zu_btn_light_green "
            onClick={continueJobModalHandler}
          >
            CONTINUE
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApplyNowModal;
