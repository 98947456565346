import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { jobWorkerRoleClassname, jobWorkerRoleName } from "../../constants";
import "../../css/component.css";
import { capitalizeFirstLetter } from "../../helpers/commonFunction";
import {
  calendarFillIcon,
  dollorIcon,
  modalCloseIcon,
  stopWatchFillIcon,
  totalIcon,
} from "../../icons";
import image from "../../images/default-user.jpg";
import { getJobDetail } from "../../store/slice/postSlice";
import { checkUserProfile } from "../../store/slice/userSlice";

const FindJobSideMenu = ({
  hideModal,
  findJobModal,
  btnForApply,
  showProfileModal,
  showQuestionModal,
  jobStatusApplicantModal,
  jobLoading,
  offerId,
  applyBtnHandler,
  paramsId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.user);

  const userId = user?.user_id;

  useEffect(() => {
    dispatch(
      getJobDetail({
        id: findJobModal,
        confirm:
          jobStatusApplicantModal === "OFFER_ACCEPTED" ||
          jobStatusApplicantModal === "IN_PROGRESS" ||
          jobStatusApplicantModal === "COMPLETED" ||
          jobStatusApplicantModal === "DONE"
            ? true
            : "",
      })
    );
  }, [dispatch]);

  const askQuestionHandler = (id, owner) => {
    dispatch(
      checkUserProfile({
        cb: (res, err) => {
          if (err) {
            console.log("err", err);
          } else {
            if (res.user_status === "VERIFICATION_SUCCESS") {
              showQuestionModal(id, owner);
            } else {
              showProfileModal(res.user_status);
            }
          }
        },
      })
    );
  };

  const jobDetail = useSelector((state) => state.post.jobDetail);

  const navigateHanlder = () => {
    if (btnForApply) {
      hideModal();
      navigate("/findjobs/page/1");
    } else {
      navigate("/findjobs/page/1");
    }
  };

  return (
    <>
      {!jobLoading && (
        <div className="zu_sidemenu_section">
          <div className="zu_sidemenu_content">
            <button
              type="button"
              className="zu_modal_close_btn"
              onClick={hideModal}
            >
              {modalCloseIcon}
            </button>
            <div className="zu_sidemenu_user_img">
              <img
                src={
                  jobDetail?.owner_profile_photo
                    ? `${process.env.REACT_APP_FILE_BASE_URL}/${jobDetail?.owner_profile_photo}`
                    : image
                }
                alt="user img"
                className="job_detail-image"
              />
            </div>
            <div className="zu_sidemenu_label_other_name_row">
              <span className="zu_sidemenu_label_text">
                Job ID: {jobDetail?.id}
              </span>
              <p className="zu_sidemenu_other_name_text">
                By {capitalizeFirstLetter(jobDetail?.owner_first_name)}{" "}
                {capitalizeFirstLetter(jobDetail?.owner_last_name)}
              </p>
            </div>
            <h2 className="zu_sidemenu_heading">{jobDetail?.title}</h2>
            <pre className="zu_jobs_column_description_text zu_jobs_column_brief_pre">
              {jobDetail?.brief}
            </pre>
            {jobDetail?.job_worker_roles &&
              JSON.parse(jobDetail?.job_worker_roles).map((i, id) => {
                return (
                  <span
                    className={`zu_common_label ${jobWorkerRoleClassname(i)}`}
                    key={`zu_label${id}`}
                  >
                    {i === "DRONE_OPERATOR" ||
                    i === "PHOTO_EDITOR" ||
                    i === "VIDEO_EDITOR"
                      ? jobWorkerRoleName(i)
                      : i}
                  </span>
                );
              })}
            {(jobDetail?.job_status === "OFFER_ACCEPTED" ||
              jobDetail?.job_status === "IN_PROGRESS" ||
              jobDetail?.job_status === "COMPLETED") &&
              offerId === userId && (
                <div className="zu_manage_job_contact_column">
                  <h3 className="zu_manage_job_contact_column_heading">
                    Business Contact
                  </h3>
                  <div className="zu_manage_job_contact_details_row">
                    <img
                      src={
                        jobDetail?.owner_profile_photo
                          ? `${process.env.REACT_APP_FILE_BASE_URL}/${jobDetail?.owner_profile_photo}`
                          : image
                      }
                      alt="user img"
                      className="zu_manage_job_contact_details_user_img"
                    />
                    <div className="zu_manage_job_contact_details">
                      <h3 className="zu_manage_job_contact_details_heading">
                        {capitalizeFirstLetter(jobDetail?.owner_first_name) +
                          " " +
                          capitalizeFirstLetter(jobDetail?.owner_last_name)}
                      </h3>
                      <p className="zu_manage_job_contact_details_number">
                        {jobDetail?.owner_phone}
                      </p>
                      <p className="zu_manage_job_contact_details_email">
                        {jobDetail?.owner_email}
                      </p>
                      <a
                        href={`https://${jobDetail?.owner_web_address}`}
                        target="_blank"
                        className="zu_manage_job_contact_details_web_link"
                        rel="noreferrer"
                      >
                        Visit Website
                      </a>
                    </div>
                  </div>
                </div>
              )}
            <div className="zu_sidemenu_other_detail_row">
              <div className="zu_sidemenu_other_detail_column">
                <div className="zu_sidemenu_other_detail_icon">
                  {calendarFillIcon}
                </div>
                <div className="zu_sidemenu_other_detail_text">
                  <h3>Date of Job</h3>
                  <p>{moment(jobDetail?.date).utc().format("DD MMM YYYY")}</p>
                </div>
              </div>
              <div className="zu_sidemenu_other_detail_column">
                <div className="zu_sidemenu_other_detail_icon">
                  {stopWatchFillIcon}
                </div>
                <div className="zu_sidemenu_other_detail_text">
                  <h3>Hours Required</h3>
                  <p>
                    {jobDetail?.extra_hour
                      ? jobDetail?.extra_hour + jobDetail?.number_of_hour
                      : jobDetail?.number_of_hour}
                  </p>
                </div>
              </div>
              <div className="zu_sidemenu_other_detail_column">
                <div className="zu_sidemenu_other_detail_icon">
                  {dollorIcon}
                </div>
                <div className="zu_sidemenu_other_detail_text">
                  <h3>Price Per Hour</h3>
                  <p>
                    ${" "}
                    {jobDetail?.price_per_hour &&
                      (jobDetail?.price_per_hour * 0.98).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="zu_sidemenu_other_detail_column">
                <div className="zu_sidemenu_other_detail_icon">{totalIcon}</div>
                <div className="zu_sidemenu_other_detail_text">
                  <h3>Total</h3>
                  <p>
                    ${" "}
                    {jobDetail?.extra_hour
                      ? (
                          (jobDetail?.extra_hour + jobDetail?.number_of_hour) *
                          (jobDetail?.price_per_hour &&
                            jobDetail?.price_per_hour * 0.98)
                        )?.toFixed(2)
                      : jobDetail?.custom_amount
                      ? (
                          (jobDetail?.custom_amount +
                            jobDetail?.number_of_hour *
                              jobDetail?.price_per_hour) *
                          0.98
                        )?.toFixed(2)
                      : (
                          jobDetail?.number_of_hour *
                          (jobDetail?.price_per_hour &&
                            jobDetail?.price_per_hour * 0.98)
                        ).toFixed(2)}{" "}
                    AUD
                  </p>
                </div>
              </div>
            </div>

            <div className="zu_sidemenu_bottom_btn">
              {btnForApply &&
                jobDetail?.job_status !== "CANCELLED" &&
                jobDetail?.job_status !== "OFFER_ACCEPTED" && (
                  <button
                    type="button"
                    className="zu_common_btn zu_btn_light_green zu_modal_common_submit_btn"
                    onClick={(e) =>
                      applyBtnHandler(jobDetail?.id, jobDetail?.owner)
                    }
                  >
                    Apply For Job
                  </button>
                )}

              {jobDetail?.job_status !== "CANCELLED" && (
                <button
                  className="zu_common_btn zu_modal_common_submit_btn"
                  onClick={() =>
                    navigate(`/messages`, {
                      state: {
                        job_status: jobDetail?.job_status,
                        job_owner_id: jobDetail?.owner,
                        job_id: jobDetail?.id,
                        job_title: jobDetail?.title,
                        job_date: jobDetail.date,
                        opp_user_id: jobDetail?.owner,
                        opp_first_name: jobDetail?.owner_first_name,
                        opp_last_name: jobDetail?.owner_last_name,
                        opp_profile_photo: jobDetail?.owner_profile_photo,
                      },
                    })
                  }
                >
                  Ask A Question
                </button>
              )}
              {jobDetail?.job_status === "DONE" && (
                <button
                  type="button"
                  className="zu_common_btn zu_modal_common_submit_btn"
                  onClick={() =>
                    navigate(`/myjobs/${jobDetail?.id}/true`, {
                      state: { jobDetail: jobDetail, person: "Crew" },
                    })
                  }
                >
                  Crew Invoice
                </button>
              )}
            </div>
            {((btnForApply &&
              jobDetail?.job_status !== "NEW" &&
              jobDetail?.job_status !== "WAITING_FOR_RESPONSE") ||
              (paramsId &&
                ((jobDetail && jobDetail.job_status === "CANCELLED") ||
                  (jobDetail &&
                    jobDetail.shooter &&
                    jobDetail.shooter !== userId)))) && (
              <div className="zu_my_jobs_no_longer_msg_content">
                <div className="zu_my_jobs_no_longer_msg">
                  <span>
                    Sorry, this position has been filled and is no longer
                    available.
                  </span>
                  <span>
                    Please click below to search for available positions.
                  </span>
                </div>
                <button
                  className="zu_common_btn find-available-job-btn"
                  onClick={() => navigateHanlder()}
                >
                  SEARCH AVAILABLE JOBS
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FindJobSideMenu;
