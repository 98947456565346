import { Editor } from "react-draft-wysiwyg";
import React, { useCallback, useEffect, useRef, useState } from "react";
import draftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { useLocation, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import htmlToDraft from "html-to-draftjs";
import { io } from "socket.io-client";

import emojiCustom from "./emoji.json";
import boldIcon from "../../images/BoldIcon.png";
import italicIcon from "../../images/ItalicIcon.png";
import strikeIcon from "../../images/StrikeIcon.png";
import orderListIcon from "../../images/OrderListIcon.png";
import bulletListIcon from "../../images/BulletListIcon.png";
import linkIcon from "../../images/LinkIcon.png";
import emojiPickerPngIcon from "../../images/EmojiPickerIcon.png";
import {
  attachFilesIcon,
  emojiPickerIcon,
  sendIcon,
  showFormatingIcon,
  deleteIcon,
  msgFileIcon,
  chatCloseIcon,
  shootzuIconLogo,
} from "../../icons";
import MsgUserList from "../../component/Layout/MsgUserList";
import { messagesArray } from "../../helpers/commonFunction";
import ImgShowMsgModal from "../../component/Layout/ImgShowMsgModal";
import MsgChatBox from "../../component/Layout/MsgChatBox";
import MsgHeader from "../../component/Layout/MsgHeader";
import {
  deleteMessage,
  getChatUsers,
  getMessages,
  getNewChatUser,
  getPinnedMessages,
  pinOrUnpinMessage,
  sendMessage,
  updateMessage,
} from "../../store/slice/chatSlice";
import { chatFileUpload } from "../../helpers/fileUpload";
import bottomArrow from "../../images/BottomArrow.svg";

const MessagePage = () => {
  const setMsgCount = useOutletContext();

  const dispatch = useDispatch();
  const socket = useRef();
  const location = useLocation();

  const bottomRef = useRef(null);
  const bottomRef2 = useRef(null);
  const messageRef = useRef(null);

  const [chatValues, setChatValues] = useState({
    opp_user_id: location?.state?.opp_user_id
      ? location?.state?.opp_user_id
      : "",
    job_id: location?.state?.job_id ? location?.state?.job_id : "",
    job_title: location?.state?.job_title ? location?.state?.job_title : "",
    job_date: location?.state?.job_date ? location?.state?.job_date : "",
    opp_first_name: location?.state?.opp_first_name
      ? location?.state?.opp_first_name
      : "",
    opp_last_name: location?.state?.opp_last_name
      ? location?.state?.opp_last_name
      : "",
    opp_profile_photo: location?.state?.opp_profile_photo
      ? location?.state?.opp_profile_photo
      : "",
    specificPinnedMsg: "",
  });

  const [chatUsers, setChatUsers] = useState([]);
  const [messagesArr, setMessagesArr] = useState([]);
  const [newUser, setNewUser] = useState([]);
  const pinnedMsgs = useSelector((state) => state.chat.getPinnedMessages);

  const loading = useSelector((state) => state.chat.loader);

  const user = useSelector((state) => state.user.user);
  const loggedInId = user?.user_id;
  const profile = useSelector((state) => state.user.getProfile);
  const loggedInUserName = profile?.first_name + " " + profile?.last_name;

  const [isOpenOption, setIsOpenOption] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [file, setFile] = useState([]);

  const [filePrev, setFilePrev] = useState([]);

  const [fileErrors, setFileErrors] = useState(false);
  const [text, setText] = useState("a");

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState("");
  const [openChat, setOpenChat] = useState(false);
  const [stateLoader, setStateLoader] = useState(false);
  const [editImg, setEditImg] = useState(false);
  const [editMsg, setEditMsg] = useState(false);
  const [editMsgId, setEditMsgId] = useState("");
  const [editMsgMediaName, setEditMsgMediaName] = useState("");
  const [editMsgMediaURL, setEditMsgMediaURL] = useState("");
  const [pinnedMsgId, setPinnedMsgId] = useState("");
  const [argument, setArgument] = useState("");
  const [updateArgument, setUpdateArgument] = useState("");
  const [lastMsgId, setLastMsgId] = useState("");

  const [jobD, setJobD] = useState(
    location.state && {
      job_status: location?.state?.job_status,
      job_owner_id: location?.state?.job_owner_id,
    }
  );

  useEffect(() => {
    const getCount = chatUsers?.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue.unread_count;
    }, 0);
    setMsgCount(getCount);
  }, [chatUsers]);

  const isNewUser = (arr, opp_user_id, job_id) => {
    let isNewUser = true;
    if (arr?.length === 0) {
      return isNewUser;
    }
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i].opp_user_id === opp_user_id && arr[i].job_id === job_id) {
        isNewUser = false;
      }
    }
    return isNewUser;
  };

  useEffect(() => {
    // console.log("in first uf");
    // socket.connect();
    socket.current = io(process.env.REACT_APP_BASE_URL, {
      query: {
        token:
          localStorage.getItem("token") &&
          JSON.parse(localStorage.getItem("token")).token,
      },
      // path:'/chat',
      // auth: {token: (localStorage.getItem("token") && JSON.parse(localStorage.getItem("token")).token)},
      // xdomain: true,
      // withCredentials: true,
      // extraHeaders: {
      //   origin: process.env.REACT_APP_LOAD_URL,
      //   "Access-Control-Allow-Credentials": true,
      // },
    });

    socket.current.on("connection", function (socket) {
      // Connection now authenticated to receive further events
      // console.log("socket connection done");
    });

    socket.current.on("new-message", (arg) => {
      // {job_id, user_id, opp_user_id}
      setArgument(arg);
    });

    socket.current.on("msg-updated", (arg) => {
      // {job_id, user_id, opp_user_id}
      setUpdateArgument(arg);
    });

    getChatUsersFromDB();

    return () => {
      socket.current.off("connection");
      socket.current.off("new-message");
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (
      argument.job_id === chatValues.job_id &&
      argument.opp_user_id === chatValues.opp_user_id
    ) {
      const data = {
        job_id: argument.job_id,
        opp_user_id: argument.opp_user_id,
        recent_msg_id: lastMsgId ? lastMsgId : 0,
      };
      getMesssageFromDB(data);
    } else {
      dispatch(
        getChatUsers({
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              setChatUsers(res);
            }
          },
        })
      );
    }
  }, [argument]);

  useEffect(() => {
    if (
      updateArgument.job_id === chatValues.job_id &&
      updateArgument.opp_user_id === chatValues.opp_user_id
    ) {
      const data = {
        job_id: updateArgument.job_id,
        opp_user_id: updateArgument.opp_user_id,
        recent_msg_id: 0,
      };
      getMesssageFromDB(data);
    }
    getChatUsersFromDB();
  }, [updateArgument]);

  const getChatUsersFromDB = () => {
    dispatch(
      getChatUsers({
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setChatUsers(res);
            if (location?.state) {
              if (
                isNewUser(
                  res,
                  location?.state?.opp_user_id,
                  location?.state?.job_id
                )
              ) {
                dispatch(
                  getNewChatUser({
                    data: {
                      job_id: location?.state?.job_id,
                      opp_user_id: location?.state?.opp_user_id,
                    },
                    cb: (err, res) => {
                      if (err) {
                        console.log("err", err);
                      } else {
                        console.log("res", res);
                        setNewUser(res);
                      }
                    },
                  })
                );
              } else {
                setNewUser([]);
              }
            }
          }
        },
      })
    );
  };

  const getMesssageFromDB = (data) => {
    dispatch(
      getMessages({
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setMessagesArr((previousArr) => previousArr.concat(res));
            dispatch(
              getChatUsers({
                cb: (err, res) => {
                  if (err) {
                    console.log("err", err);
                  } else {
                    setChatUsers(res);
                    if (location?.state) {
                      if (
                        isNewUser(
                          res,
                          location?.state?.opp_user_id,
                          location?.state?.job_id
                        )
                      ) {
                        dispatch(
                          getNewChatUser({
                            data: {
                              job_id: location?.state?.job_id,
                              opp_user_id: location?.state?.opp_user_id,
                            },
                            cb: (err, res) => {
                              if (err) {
                                console.log("err", err);
                              } else {
                                setNewUser(res);
                              }
                            },
                          })
                        );
                      } else {
                        setNewUser([]);
                      }
                    }
                  }
                },
              })
            );
          }
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getPinnedMessages());
  }, [dispatch]);

  useEffect(() => {
    const data = {
      job_id: chatValues.job_id,
      opp_user_id: chatValues.opp_user_id,
      recent_msg_id: 0,
    };
    dispatch(
      getMessages({
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setMessagesArr(res);
            dispatch(
              getChatUsers({
                cb: (err, res) => {
                  if (err) {
                    console.log("err", err);
                  } else {
                    setChatUsers(res);
                  }
                },
              })
            );
          }
        },
      })
    );
  }, [chatValues.job_id, chatValues.opp_user_id]);

  // MULTI FILES CHANGE HANDLER //
  const multipleHandleInputChange = (e) => {
    let fileErr = "";
    const { files } = e.target;

    if (files.length > 0) {
      let teFiles = [];
      for (let key in files) {
        if (key !== "length" && key !== "item") {
          // if (!files[key].name.match(/.(jpg|jpeg|png)$/i)) {
          //   fileErr = "Please add valid image (png/jpeg/jpg)";
          //   continue;
          // }
          if (files[key].size > 50000000) {
            fileErr = "File size should not be greater than 50MB";
            continue;
          }
          teFiles.push(files[key]);
        }
      }

      const tempPrev = [...filePrev];
      for (let i = 0; i < teFiles.length; i++) {
        tempPrev.push({
          name: teFiles[i].name,
          type: teFiles[i].type,
          URL: URL.createObjectURL(teFiles[i]),
        });
      }

      setFile([...file, ...teFiles]);
      setFilePrev([...tempPrev]);
      setFileErrors(fileErr);
    }
  };
  //

  const removePreviewFileHandler = (i) => {
    const fileArr = [...file];
    const fileArrPreview = [...filePrev];
    fileArr.splice(i, 1);
    fileArrPreview.splice(i, 1);

    setFile(fileArr);
    setFilePrev(fileArrPreview);
  };

  const downloadFilehandler = (url, media_name) => {
    // fetch(
    //   `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2O_K7eqRmIPu5HbtEDD81am9pY89-9xYYxw&usqp=CAU`
    // )
    fetch(`${process.env.REACT_APP_CHAT_FILE_BASE_URL}/${url}`)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = media_name;
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  const showImgModalHandler = (url) => {
    setShowImg(true);
    setImgUrl(url);
  };

  //Check and Disable send Button if only enter and spaces
  let empty = true;
  for (var i = 0, len = text?.blocks?.length; i < len; i++) {
    var obj = text.blocks[i];
    if (obj?.text?.trim() === "") {
      empty = true;
    } else {
      empty = false;
      break;
    }
  }
  //

  // select USer for chat
  const onSelectUser = (user) => {
    setPinnedMsgId(user.pin_id ? user.pin_id : "");
    setOpenChat(true);
    setContent("");
    setFile([]);
    setFilePrev([]);
    setEditorState(EditorState.createEmpty());
    setText("");
    setChatValues({
      ...chatValues,
      job_id: user.job_id,
      opp_user_id: user.opp_user_id,
      opp_first_name: user.opp_first_name,
      opp_last_name: user.opp_last_name,
      job_title: user.job_title,
      job_date: user.job_date,
      opp_profile_photo: user.opp_profile_photo,
      specificPinnedMsg: user.pin_id ? user.pin_id : "",
    });
    setJobD({
      job_owner_id: user.job_owner_id,
      job_status: user.job_status,
    });
  };
  //

  ///// ADD FILES OR IMAGES Handler //////
  const sendMsgAndFilesHandler = async () => {
    setContent("");
    setFile([]);
    setFilePrev([]);
    setEditorState(EditorState.createEmpty());
    setText("");
    const formData = new FormData();

    let tempFile = [];
    setStateLoader(true);
    for (let i = 0; i < file.length; i++) {
      if (typeof file[i] !== "string") {
        formData.append("file", file[i]);
        let temp = await chatFileUpload(formData);
        if (temp) {
          tempFile.push(temp);
        }
        formData.delete("file");
      } else {
        tempFile.push(file[i]);
      }
    }

    const data = {
      images: tempFile,
      message_text: content,
      job_id: chatValues.job_id,
      receiver_id: chatValues.opp_user_id,
    };
    setStateLoader(false);
    dispatch(
      sendMessage({
        data,
      })
    );
  };
  // //

  const editMsgHandler = (msg) => {
    setEditMsg(true);
    setEditMsgId(msg.id);
    if (msg?.message_text) {
      let msgContent = msg && msg?.message_text;
      const msgContentBlock = msgContent && htmlToDraft(msgContent);
      const msgContentState =
        msgContentBlock &&
        ContentState.createFromBlockArray(msgContentBlock.contentBlocks);
      const editorState1 =
        msgContentState && EditorState.createWithContent(msgContentState);
      setEditorState(editorState1);
      setContent(msg?.message_text || "");
    }
    if (msg.media_url.match(/\.(jpeg|jpg|gif|png)$/) !== null) {
      setEditMsgMediaURL(msg.media_url);
      setEditMsgMediaName(msg.media_name);
      setEditImg(true);
      setFilePrev([
        `${process.env.REACT_APP_CHAT_FILE_BASE_URL}/${msg.media_url}`,
      ]);
    }
  };

  const EditMsgSendHandler = async () => {
    let data = {
      message_text: content,
      media_url: "",
      media_name: "",
    };
    if (filePrev.length !== 0) {
      if (typeof filePrev[0] === "string") {
        data.media_name = editMsgMediaName;
        data.media_url = editMsgMediaURL;
      }
      if (typeof filePrev[0] !== "string") {
        const formData = new FormData();
        let tempFile = [];
        setStateLoader(true);
        if (typeof file[i] !== "string") {
          formData.append("file", file[i]);
          let temp = await chatFileUpload(formData);
          data.media_name = temp.originalname;
          data.media_url = temp.key;
        }
        setStateLoader(false);
      }
    }
    dispatch(
      updateMessage({
        id: editMsgId,
        data: data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            setEditMsg("");
            setEditMsg(false);
            setEditImg(false);
            setContent("");
            setFile([]);
            setFilePrev([]);
            setEditorState(EditorState.createEmpty());
            setText("");
          }
        },
      })
    );
  };

  const closeEditMode = () => {
    setEditMsg(false);
    setEditImg(false);
    setEditorState(EditorState.createEmpty());
    setContent("");
    setFilePrev([]);
    setFile([]);
  };

  const deleteMsgHandler = (msg) => {
    dispatch(
      deleteMessage({
        id: msg.id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getMessages({
                data: {
                  job_id: chatValues.job_id,
                  opp_user_id: chatValues.opp_user_id,
                  recent_msg_id: 0,
                },
                cb: (err, res) => {
                  if (err) {
                    console.log("err", err);
                  } else {
                    setMessagesArr(res);
                    dispatch(
                      getChatUsers({
                        cb: (err, res) => {
                          if (err) {
                            console.log("err", err);
                          } else {
                            setChatUsers(res);
                            if (location?.state) {
                              if (
                                isNewUser(
                                  res,
                                  location?.state?.opp_user_id,
                                  location?.state?.job_id
                                )
                              ) {
                                dispatch(
                                  getNewChatUser({
                                    data: {
                                      job_id: location?.state?.job_id,
                                      opp_user_id: location?.state?.opp_user_id,
                                    },
                                    cb: (err, res) => {
                                      if (err) {
                                        console.log("err", err);
                                      } else {
                                        setNewUser(res);
                                      }
                                    },
                                  })
                                );
                              } else {
                                setNewUser([]);
                              }
                            }
                          }
                        },
                      })
                    );
                  }
                },
              })
            );
            if (msg.pin_id) {
              dispatch(getPinnedMessages());
            }
          }
        },
      })
    );
  };

  const pinMsgHandler = (msg) => {
    const data = {
      pin_or_unpin: msg?.pin_id ? "UNPIN" : "PIN",
      msg_id: msg.id,
    };
    dispatch(
      pinOrUnpinMessage({
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(
              getMessages({
                data: {
                  job_id: chatValues.job_id,
                  opp_user_id: chatValues.opp_user_id,
                  recent_msg_id: 0,
                },
                cb: (err, res) => {
                  if (err) {
                    console.log("err", err);
                  } else {
                    setMessagesArr(res);
                  }
                },
              })
            );
            dispatch(getPinnedMessages());
          }
        },
      })
    );
  };

  const handleBottomScroll = () => {
    if (chatValues.specificPinnedMsg) {
      bottomRef2.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <section className="zu_all_page_main_content">
        <div className="zu_chat_container">
          <div className={`zu_chat_content ${openChat && "zu_open_chat"}`}>
            <MsgUserList
              chatUsers={chatUsers}
              newUser={newUser}
              onSelectUser={onSelectUser}
              chatValues={chatValues}
              pinnedMsgs={pinnedMsgs}
              pinnedMsgId={pinnedMsgId}
              loggedInUserName={loggedInUserName}
              loggedInId={loggedInId}
            />
            {chatValues.opp_user_id !== "" && (
              <div className="zu_chat_msg_content">
                <MsgHeader
                  chatValues={chatValues}
                  setOpenChat={setOpenChat}
                  loggedInId={loggedInId}
                  jobD={jobD}
                />
                <MsgChatBox
                  bottomRef={bottomRef}
                  bottomRef2={bottomRef2}
                  messageRef={messageRef}
                  editMsgHandler={editMsgHandler}
                  deleteMsgHandler={deleteMsgHandler}
                  pinMsgHandler={pinMsgHandler}
                  messagesArray={messagesArray}
                  showImgModalHandler={showImgModalHandler}
                  downloadFilehandler={downloadFilehandler}
                  messagesArr={messagesArr}
                  loggedInId={loggedInId}
                  loading={loading}
                  stateLoader={stateLoader}
                  editMsg={editMsg}
                  specificPinnedMsg={chatValues.specificPinnedMsg}
                  setLastMsgId={setLastMsgId}
                />
                {editMsg && (
                  <div className="zu_chat_edit_mode_on_text">Edit message </div>
                )}
                <div
                  className={`zu_chat_msg_write_content ${
                    isOpenOption && "is-open"
                  }
                  ${editMsg ? filePrev?.length === 0 && "is-edit" : ""}
                `}
                >
                  {filePrev?.length !== 0 && (
                    <div className="zu_chat_img_upload_row">
                      {filePrev?.map((file, i) => {
                        return (
                          <div key={i}>
                            {file.type?.split("/")[0] === "image" || editImg ? (
                              <div className="zu_chat_img_upload_col">
                                <img
                                  src={
                                    editImg && typeof file === "string"
                                      ? `${file}`
                                      : file.URL
                                  }
                                  alt="img"
                                />
                                <div
                                  className="zu_chat_img_upload_col_delete"
                                  onClick={() => removePreviewFileHandler(i)}
                                >
                                  {deleteIcon}
                                </div>
                              </div>
                            ) : (
                              <div className="zu_chat_img_upload_col">
                                {msgFileIcon}
                                <p>{file.name?.split(".")[1]}</p>
                                <div
                                  className="zu_chat_img_upload_col_delete"
                                  onClick={() => removePreviewFileHandler(i)}
                                >
                                  {deleteIcon}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="zu_chat_msg_write_btn_row">
                    {editMsg && (
                      <button
                        type="button"
                        className="zu_chat_msg_write_icon_btn"
                        onClick={() => closeEditMode()}
                        title="Cancel Edit"
                      >
                        {chatCloseIcon}
                      </button>
                    )}
                    <button
                      type="button"
                      className="zu_chat_msg_write_icon_btn"
                      onClick={() => setIsOpenOption(!isOpenOption)}
                      disabled={loading || stateLoader}
                      title="Show Formatting"
                    >
                      {showFormatingIcon}
                    </button>
                    {(editMsg && !editImg) ||
                    (editMsg && editImg && filePrev.length === 1) ? (
                      ""
                    ) : (
                      <label
                        htmlFor="msg_file"
                        className="zu_chat_msg_write_icon_btn"
                        title="Attach Files"
                      >
                        {attachFilesIcon}
                        <input
                          type="file"
                          multiple={!editMsg}
                          id="msg_file"
                          className="d-none"
                          name="msg_file"
                          onClick={(e) => (e.target.value = null)}
                          onChange={multipleHandleInputChange}
                          disabled={
                            (editMsg && !editImg) || loading || stateLoader
                          }
                        />
                      </label>
                    )}

                    <button
                      type="button"
                      className="zu_chat_msg_write_icon_btn zu_chat_msg_write_emoji_icon_btn"
                      disabled={loading || stateLoader}
                    >
                      {emojiPickerIcon}
                    </button>
                    <button
                      type="button"
                      className="zu_chat_msg_write_send_btn"
                      onClick={
                        editMsg
                          ? () => EditMsgSendHandler()
                          : () => sendMsgAndFilesHandler()
                      }
                      disabled={
                        (!editMsg &&
                          (empty && file.length === 0 ? true : false)) ||
                        (editMsg && filePrev.length === 0 && empty
                          ? true
                          : false) ||
                        loading ||
                        stateLoader
                      }
                    >
                      {sendIcon}
                    </button>
                    {
                      <button
                        className="zu_chat_msg_top_bottom_arrow"
                        type="button"
                        onClick={handleBottomScroll}
                      >
                        <img src={bottomArrow} alt="bottom arrow" />
                      </button>
                    }
                  </div>
                  {fileErrors && <span className="err_text">{fileErrors}</span>}

                  <Editor
                    toolbar={{
                      options: ["inline", "list", "link", "emoji"],
                      inline: {
                        inDropdown: false,
                        options: ["bold", "italic", "strikethrough"],
                        bold: { icon: boldIcon },
                        italic: { icon: italicIcon },
                        strikethrough: { icon: strikeIcon },
                      },
                      list: {
                        inDropdown: false,
                        options: ["ordered", "unordered"],
                        unordered: { icon: bulletListIcon },
                        ordered: { icon: orderListIcon },
                      },
                      link: {
                        inDropdown: false,
                        defaultTargetOption: "_self",
                        options: ["link"],
                        link: { icon: linkIcon },
                      },
                      emoji: {
                        icon: emojiPickerPngIcon,
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,

                        emojis: emojiCustom.map((e, i) => {
                          return e;
                        }),
                      },
                    }}
                    placeholder="Type a message..."
                    editorState={editorState}
                    onChange={(e) => setText(e)}
                    onEditorStateChange={(newState) => {
                      setEditorState(newState);
                      setContent(
                        draftToHtml(convertToRaw(newState.getCurrentContent()))
                      );
                    }}
                  />
                </div>
              </div>
            )}
            {chatValues.opp_user_id === "" && (
              <div className="zu_msg_not_open_content">
                <div className="zu_msg_not_open_top_content">
                  {shootzuIconLogo}
                  <h2>Welcome to Shootzu Chat</h2>
                  {/* <p>
                    A place where the people come together and communicate
                    efficiently. Let’s get the conversation started
                  </p> */}
                </div>
                <div className="zu_msg_not_open_bottom_content">
                  <img
                    src={`${process.env.REACT_APP_FILE_BASE_URL}/${profile?.profile_photo}`}
                    alt="user img"
                  />
                  <p>{profile?.first_name + " " + profile?.last_name}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <ImgShowMsgModal
        showImg={showImg}
        downloadFilehandler={downloadFilehandler}
        setShowImg={setShowImg}
        imgUrl={imgUrl}
      />
    </>
  );
};

export default MessagePage;
