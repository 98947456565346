import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  jobWorkerRoleName,
  leadsColumnLaadContactList,
  routes,
} from "../../constants";
import "../../css/component.css";
import Loader from "../Common/Loader";

const LeadsColumn = ({
  lead,
  showLeadModalHandler,
  modalForArchiveActiveHandler,
  values,
  showLeadDeleteModal,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const loggedUserRole = user?.role;
  const leadsLoading = useSelector((state) => state.leads.loader);

  const [lessText, setLessText] = useState(false);

  let word = "";
  const wordFunction = (id) => {
    for (let i = 0; i < 30; i++) {
      word += " " + id[i];
    }
    return word;
  };

  const readMoreHandler = () => {
    setLessText(!lessText);
  };

  const leadInvoiceHandler = (id) => {
    navigate(`/leadsinvoic/${id}`, {
      state: { lead: lead },
    });
  };

  return (
    <>
      {leadsLoading && <Loader />}
      <div className="zu_leads_data_column" key={lead?.id}>
        <div className="zu_leads_data_column_left_text">
          <div className="zu_leads_data_column_date_text">
            <p>Date of Event</p>

            <span>
              {moment(lead?.date_of_event).utc().format("DD MMM YYYY")}
            </span>
          </div>
          <div className="zu_leads_data_column_type_text">
            {lead?.roles &&
              JSON.parse(lead?.roles).map((i) => {
                return (
                  <p key={`zu_leads_data_column_type${i}`}>
                    {" "}
                    {i === "DRONE_OPERATOR" ||
                    i === "PHOTO_EDITOR" ||
                    i === "VIDEO_EDITOR"
                      ? jobWorkerRoleName(i)
                      : i}
                  </p>
                );
              })}
          </div>
          <div className="zu_leads_data_column_lead_contact">
            <h4>Lead Contact</h4>
            {leadsColumnLaadContactList.map((i) => {
              return (
                <div
                  className="zu_leads_data_column_lead_contact_text"
                  key={`zu_leads_data_column_lead_contact${i.id}`}
                >
                  <p>{i.heading}</p>
                  <span>
                    {lead?.client_name &&
                    lead?.client_email &&
                    lead?.client_phone_number
                      ? lead[i.name]
                      : i.paragrapg}
                  </span>
                  {i.noteText && loggedUserRole !== "ROLE_ADMIN" && (
                    <div className="zu_leads_data_column_lead_note_text">
                      {i.noteText}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {values?.leadsData === "1" &&
            lead?.client_name &&
            loggedUserRole !== "ROLE_ADMIN" && (
              <button
                className="zu_common_btn zu_lead_invoice_btn zu_lead_max"
                onClick={(e) => leadInvoiceHandler(lead?.id)}
              >
                Invoice
              </button>
            )}
        </div>
        <div className="zu_leads_data_column_right_text">
          <div className="zu_leads_data_column_event_info">
            <h3 className="zu_leads_data_column_event_info_heading d-lg-block d-none">
              Event Information
            </h3>
            <div className="zu_leads_data_column_event_info_row">
              <div className="zu_leads_data_column_event_info_left_text">
                <div className="zu_leads_data_column_event_info_left_text_content">
                  <h4>Location of Event</h4>
                  <p>{lead?.address}</p>
                </div>
                <div className="zu_leads_data_column_event_info_left_text_content">
                  <h4>Type of Event</h4>
                  <p>
                    {lead?.lead_type === "REAL_ESTATE"
                      ? "REAL ESTATE"
                      : lead?.lead_type}
                  </p>
                </div>
                <div className="zu_leads_data_column_event_info_left_text_content">
                  <h4>Hours Required</h4>
                  <p>{lead?.num_of_hours}</p>
                </div>
                {lead?.budget && (
                  <div className="zu_leads_data_column_event_info_left_text_content">
                    <h4>Budget</h4>
                    <p>${lead?.budget}</p>
                  </div>
                )}
              </div>
              <div className="zu_leads_data_column_event_info_detail_text">
                <h4>Details </h4>
                {lead?.details?.trim().split(" ")?.length < 31 ? (
                  <pre>{lead?.details}</pre>
                ) : (
                  <pre>
                    {lessText
                      ? lead?.details
                      : wordFunction(lead?.details.trim().split(" "))}{" "}
                    {lead?.details?.trim().split(" ")?.length > 10 && (
                      <span
                        className="readmore_link2"
                        onClick={readMoreHandler}
                      >
                        {lessText ? "Read less..." : "Read more..."}
                      </span>
                    )}
                  </pre>
                )}
              </div>
            </div>
          </div>
          <div className="zu_leads_data_column_btn_content">
            <h4 className="zu_leads_data_column_btn_heading d-lg-block">
              Posted {moment(lead?.created_at).fromNow()}
            </h4>
            {loggedUserRole === "ROLE_ADMIN" && (
              <div className="zu_leads_data_column_edit_archiv_btn">
                {lead?.lead_status === "PENDING" && (
                  <button
                    type="button"
                    className="zu_common_btn zu_btn_progress_orange"
                    onClick={(e) =>
                      modalForArchiveActiveHandler(lead?.id, lead?.lead_status)
                    }
                  >
                    Approve
                  </button>
                )}
                {lead?.lead_status !== "CANCELLED" && (
                  <Link
                    type="button"
                    className="zu_common_btn zu_btn_light_green"
                    to={routes.admineditleadmanagement}
                    state={{ lead: lead }}
                  >
                    EDIT LEAD
                  </Link>
                )}
                {(lead?.lead_status === "ACTIVE" ||
                  lead?.lead_status === "ARCHIVED") && (
                  <button
                    type="button"
                    className={`zu_common_btn  ${
                      lead?.lead_status === "ACTIVE"
                        ? "zu_btn_yellow"
                        : "zu_btn_progress_orange"
                    }`}
                    onClick={(e) =>
                      modalForArchiveActiveHandler(
                        lead?.id,
                        lead?.lead_status,
                        true
                      )
                    }
                  >
                    {lead?.lead_status === "ARCHIVED" ? "ACTIVE" : "ARCHIVE"}
                  </button>
                )}

                {lead?.lead_status === "ACTIVE" && (
                  <button
                    type="button"
                    className={`zu_common_btn  ${
                      lead?.lead_status === "ACTIVE"
                        ? "zu_btn_pending_red"
                        : "zu_btn_progress_orange"
                    }`}
                    onClick={(e) =>
                      modalForArchiveActiveHandler(lead?.id, lead?.lead_status)
                    }
                  >
                    CANCEL
                  </button>
                )}
                {lead?.lead_status === "PENDING" && (
                  <button
                    type="button"
                    className="zu_common_btn zu_btn_pending_red"
                    onClick={(e) => showLeadDeleteModal(lead?.id)}
                  >
                    DELETE
                  </button>
                )}
              </div>
            )}
            <div className="zu_leads_data_column_btn">
              <p>{6 - lead?.count + "/6"} Reveals Remaining </p>

              {!lead?.client_name && lead?.count < 6 && (
                <button
                  type="button"
                  className="zu_common_btn zu_btn_light_green"
                  onClick={(e) => showLeadModalHandler(lead?.id)}
                >
                  Reveal Lead Contact
                </button>
              )}
              {values?.leadsData === "1" &&
                lead?.client_name &&
                loggedUserRole !== "ROLE_ADMIN" && (
                  <button
                    className="zu_common_btn zu_lead_invoice_btn zu_lead_min"
                    onClick={(e) => leadInvoiceHandler(lead?.id)}
                  >
                    Invoice
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadsColumn;
