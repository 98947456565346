import React from "react";
import { useDropzone } from "react-dropzone";
import "../../css/component.css";
import { uploadImageIcon } from "../../icons";

const DropBox = ({ onDrop }) => {
  const {
    getRootProps,
    getInputProps,
    open,
    isdragaccept,
    isfocused,
    isdragreject,
  } = useDropzone({
    accept: "image/*",
    onDrop,
    noClick: true,
    noKeyboard: true,
  });
  return (
    <>
      <div
        className="zu_upload_image_dropbox_content"
        {...getRootProps({ isdragaccept, isfocused, isdragreject })}
      >
        <input {...getInputProps()} />
        <div className="zu_upload_image_dropbox_content_shap">
          {uploadImageIcon}
        </div>
        <h3 className="zu_upload_image_dropbox_content_heading">
          Drag and drop files here
        </h3>
        <p className="zu_upload_image_dropbox_content_text">or</p>
        <button
          type="button"
          className="zu_upload_image_dropbox_content_btn"
          onClick={open}
        >
          Browse files
        </button>
      </div>
    </>
  );
};

export default DropBox;
