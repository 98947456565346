import React, { useState, useCallback } from "react";
import "../../css/component.css";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

const ShowcaseGallery = ({ galleryPhotos = [], heading }) => {
  const [viewAll, setViewAll] = useState(false);

  const viewAllHandler = () => {
    setViewAll(!viewAll);
  };

  const photos = !viewAll
    ? galleryPhotos
        ?.filter((item, i) => i < 5)
        ?.map((item, index) => {
          return {
            src: `${process.env.REACT_APP_FILE_BASE_URL}/${item}`,
            width: 4,
            height: 3,
          };
        })
    : galleryPhotos?.map((item, index) => {
        return {
          src: `${process.env.REACT_APP_FILE_BASE_URL}/${item}`,
          width: 4,
          height: 3,
        };
      });

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <>
      <section className="zu_showcase_section">
        <div className="zu_showcase_content">
          <div className="zu_profile_common_heading">
            <h2>{heading}</h2>
            {galleryPhotos?.length > 5 && (
              <span
                onClick={viewAllHandler}
                className="zu_profile_common_heading_view_all_btn zu_view_photos_btn"
              >
                {viewAll ? "Close" : "VIEW ALL"}
              </span>
            )}
          </div>
          <Gallery photos={photos} onClick={openLightbox} />
        </div>
      </section>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

export default ShowcaseGallery;
