import { PDFExport } from "@progress/kendo-react-pdf";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CrewTaxInvoice from "../../component/Layout/CrewTaxInvoice";
import { downloadIcon } from "../../icons";
import { useSelector } from "react-redux";

const BusinessInvoice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { jobDetail, person } = location?.state;
  const userD = useSelector((state) => state.user.user);

  const pdfExportComponent = React.useRef(null);
  const container = React.useRef(null);

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const viewJobDetailPage = () => {
    if (userD && userD.role === "ROLE_ADMIN") {
      navigate(-1);
    } else if (
      jobDetail?.invoice_type === "business" ||
      person === "Business"
    ) {
      navigate(`/managejobsdetail/${jobDetail?.id}/true`);
    } else {
      navigate(`/myjobs/page/1/${jobDetail?.id}`);
    }
  };

  return (
    <main className="zu_all_page_main_content">
      <div className="download_invoice_btn">
        <button className="go_back_invoice_btn " onClick={() => navigate(-1)}>
          Go Back
        </button>
        <div className="d-flex">
          <button
            className="zu_common_btn zu_btn_yellow"
            style={{ marginRight: "10px" }}
            onClick={viewJobDetailPage}
          >
            VIEW JOB DETAILS
          </button>
          <button
            className="zu_common_btn zu_btn_voilet"
            onClick={exportPDFWithComponent}
          >
            {downloadIcon} Download Invoice
          </button>
        </div>
      </div>
      <PDFExport
        ref={pdfExportComponent}
        paperSize="auto"
        margin={40}
        fileName={`Invoice for ${jobDetail?.id}`}
        author="Zl ReactJs Team"
      >
        <div ref={container}>
          <CrewTaxInvoice jobDetail={jobDetail} person={person} />
        </div>
      </PDFExport>
    </main>
  );
};

export default BusinessInvoice;
