import React from "react";
import "../../css/component.css";
import { closeIcon } from "../../icons";

const DropBoxImage = ({ image, removeImageHandler }) => {
  return (
    <>
      <div className="zu_upload_image_priview_column">
        <img
          src={
            typeof image === "string"
              ? `${process.env.REACT_APP_FILE_BASE_URL}/${image}`
              : URL.createObjectURL(image)
          }
          alt="img"
          className="zu_upload_image_priview_column_img"
        />
        <button className="zu_images_close_btn" onClick={removeImageHandler}>
          {closeIcon}
        </button>
      </div>
    </>
  );
};

export default DropBoxImage;
